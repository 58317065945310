import React, { useState } from "react";
import Logo from "../../images/2xcell_logo.png";
import MeetIcon from "../../images/meet_icon.png"
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Sidebar from "../Sidebar/Sidebar";
import AvatarImage from "../../images/Navbar/avatar.png";
import Moment from "react-moment";

import { Link, useLocation } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { ReactComponent as AccountIcon } from "../../images/AnnouncementUserLogo.svg";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useMediaQuery } from "react-responsive";
import SearchBar from "../SearchBar/SearchBar";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "-1rem",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  dropdownItem: {
    fontSize: "0.9em",
    marginRight: "2rem",
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [widthState, setWidthState] = useState(false);
  const [searchQuestion, setSearchQuestion] = useState("");
  const [slider, setSlider] = React.useState(false);
  const { userDetails, selectedClass, setUserDetails } =
    useContext(GlobalContext);
  const toggleDrawer = (val) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    val && setUserDetails({ ...userDetails, selectedTab: val });
    setSlider(!slider);
  };

  const signOut = async () => {
    const token = localStorage.getItem("authToken");
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/auth/logout`, {
      headers: {
        token: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("sectionIdTask");
        window.location.href = `${process.env.REACT_APP_REDIRECT}?logout=true`;
      });
  };

  const toggleMenu = () => {
    if (document.querySelector(".dropdown-menu").style.display === "none") {
      document.querySelector(".dropdown-menu").style.display = "block";
      document.querySelector(".dropdown-menu").style.marginLeft = "-150px";
    } else {
      document.querySelector(".dropdown-menu").style.display = "none";
    }
    console.log("7", document.querySelector(".dropdown-menu"));
  };

  const profileImageUrl = localStorage.getItem("profileImageUrl");
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });
  const fixText = (text) => {
    const textLimit = 5;
    if (text.length > textLimit) {
      return text.slice(0, textLimit) + "..";
    }
    return text;
  };
  // console.log("Condition, ", userDetails.selectedTab !== "Resources");
  let questions = userDetails.questions;
  const searchQuestions = () => {
    setUserDetails({
      ...userDetails,
      filterQuestions: questions.filter((item) =>
        item.question.toLowerCase().includes(searchQuestion.toLowerCase())
      ),
    });
  };
  return (
    <>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          style={{
            background: isSmallScreen ? "#5CE0D2" : "white",
            color: "black",
            boxShadow: "0px 4px 6px rgba(51, 122, 115, 0.16)",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              onClick={toggleDrawer()}
              className={!isSmallScreen ? classes.menuButton : ""}
              id="hamburger_btn"
              style={{ color: isSmallScreen ? "white" : "" }}
              aria-label="menu"
            >
              <MenuIcon className="menuIcon" />
            </IconButton>
            {isSmallScreen ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "fit-content",
                  marginRight: "auto",
                  fontWeight: 900,
                  marginTop: "10px",
                }}
              >
                <h3>
                  <b>
                    {userDetails.selectedTab !== "Resources"
                      ? userDetails.selectedTab === "Practice Session" ||
                        userDetails.selectedTab === "Online Test"
                        ? ""
                        : userDetails.selectedTab
                      : selectedClass
                      ? selectedClass
                      : "Classes"}
                    {userDetails.selectedTab !== "Practice Session" &&
                    userDetails.selectedTab !== "Online Test"
                      ? ""
                      : userDetails.pathName.className +
                        "-" +
                        userDetails.pathName.sectionName}
                  </b>
                </h3>
                {userDetails.selectedTab === "Resources" ? (
                  <p
                    style={{
                      color: "white",
                      fontSize: "0.7em",
                      marginBlockEnd: "0px",
                      marginBlockStart: "0px",
                    }}
                  >
                    {userDetails.pathName.subjectName
                      ? (userDetails.pathName.subjectName
                          ? fixText(userDetails.pathName.subjectName) + ">"
                          : "") +
                        (userDetails.pathName.chapterName
                          ? fixText(userDetails.pathName.chapterName) + ">"
                          : "") +
                        (userDetails.pathName.topicName
                          ? fixText(userDetails.pathName.topicName) + ">"
                          : "")
                      : "Subject"}
                  </p>
                ) : (
                  ""
                )}
                {userDetails.selectedTab === "Practice Session" ||
                userDetails.selectedTab === "Online Test" ? (
                  <>
                    <p
                      style={{
                        color: "white",
                        fontSize: "0.7em",
                        marginBlockEnd: "0px",
                        marginBlockStart: "0px",
                      }}
                    >
                      {userDetails.pathName.subjectName
                        ? (userDetails.pathName.subjectName
                            ? fixText(userDetails.pathName.subjectName) + ">"
                            : "") +
                          (userDetails.pathName.chapterName
                            ? fixText(userDetails.pathName.chapterName) + ">"
                            : "") +
                          (userDetails.pathName.topicName
                            ? fixText(userDetails.pathName.topicName) + ">"
                            : "")
                        : ""}
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <Typography variant="h6" className={classes.title}>
                <img src={Logo} alt="TitleImage" width={80} height={70} />
              </Typography>
            )}
            <div className="navbar_nots">
              {/* <NotificationsIcon
                style={{
                  color: "#fff",
                  // marginRight: "-25px",
                  fontSize: "20px",
                  position: "absolute",
                }}
              /> */}
              <SearchBar
                searchQuestion={searchQuestion}
                setSearchQuestion={setSearchQuestion}
                searchQuestions={searchQuestions}
                setWidthState={setWidthState}
                widthState={widthState}
              />
              <span className="dateNavBar">
                {

                  <Moment
                    format="MMM DD, YYYY"
                    style={{
                      marginLeft: "10px",
                      fontSize: "12px",
                      color: "white",
                    }}
                  >
                    {new Date()}
                  </Moment>
                }
                <img 
                  src={MeetIcon} 
                  style={{marginLeft: "15px", marginRight:"0"}}
                  onClick={() => {window.location.href = "https://meet.google.com"}}  
                />
              </span>
            </div>
            <span className="TeacherName">{userDetails.name}</span>
            <li
              className="nav-item"
              style={{ listStyle: "none" }}
              onClick={toggleMenu}
            >
              {isSmallScreen ? (
                ""
              ) : (
                <div className="teacherDetails">
                  {!["undefined", undefined, null, "null"].includes(
                    profileImageUrl
                  ) ? (
                    <img
                      src={profileImageUrl}
                      width="50px"
                      id="navbarDropdown"
                      className="rounded-circle"
                      role="button"
                      data-bs-toggle="dropdown-menu"
                      aria-expanded="false"
                      href="#navbarDropdown"
                      alt=""
                    />
                  ) : (
                    <AccountIcon
                      id="navbarDropdown"
                      style={{ fontSize: "50px" }}
                      role="button"
                      className="userImage"
                      data-bs-toggle="dropdown-menu"
                      href="#navbarDropdown"
                      aria-expanded="false"
                    />
                  )}
                </div>
              )}
              <ul
                className="dropdown-menu"
                style={{ boxShadow: "0px 0px 2px #00000063" }}
                aria-labelledby="navbarDropdown"
                id="navbarDropdown"
              >
                {/* <li className={classes.dropdownItem}>
                  <Link className="dropdown-item" to="/setting">
                    <PersonOutlineOutlinedIcon /> {userDetails.name}
                  </Link>
                </li> */}
                <li className={classes.dropdownItem}>
                  <Link className="dropdown-item" to="#" onClick={signOut}>
                    <ExitToAppIcon /> Sign Out
                  </Link>
                </li>
              </ul>
            </li>
          </Toolbar>
        </AppBar>
      </div>

      <Sidebar slider={slider} toggleDrawer={toggleDrawer} />
    </>
  );
};

export default Navbar;
