import Row from "./Row";

function TableEditor(props) {
  return (
    <div className="table-grid">
      <div className="table-grid-head">
        <div className="table-grid-col">
          <label>Class Activity</label>
        </div>
        <div className="table-grid-col">
          <label>For Teachers</label>
        </div>
        <div className="table-grid-col">
          <label>Question For Students</label>
        </div>
        <div className="table-grid-col">
          <label>Answers From Students</label>
        </div>
        <div className="table-grid-col">
          <label>2xcell Digital Resources</label>
        </div>
      </div>
      <div className="table-grid-body">
        {props.rows.map((row, index) => {
          return (
            <Row
              key={index}
              className="table-grid-row"
              userId={props.userId}
              planId={props.planId}
              {...row}
              editCell={props.editCell}
            />
          );
        })}
      </div>
    </div>
  );
}

export default TableEditor;
