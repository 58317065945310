import React, { useEffect } from "react";

const VideoStyle = {
  width: "100%",
  height: "100%",
  display: "flex",
  marginTop: "1rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "2rem",
};

function Audio(props) {
  useEffect(() => {
    if (props?.location?.useProps?.click) {
      props.location.useProps.click();
    }
  }, []);

  return (
    <div style={VideoStyle}>
      <audio
        width="60%"
        style={{ marginTop: "2rem" }}
        controls
        controlsList="nodownload"
      >
        <source src={props.location.useProps?.fileUrl} type="audio/mp3" />
        <source src={props.location.useProps?.fileUrl} type="audio/ogg" />
        <source src={props.location.useProps?.fileUrl} type="audio/swf" />
        Your browser does not support HTML audio.
      </audio>
    </div>
  );
}

export default Audio;
