import "./LessonPlans.css";
import { useState } from "react";

import CalendarIcon from "../icons/calendar-1.svg";
import TrashIcon from "../icons/trash-1.svg";
import EyeIcon from "../icons/eye.svg";
import EditIcon from "../icons/edit-1.svg";

import PopupModal from "../Modal/Modal";

import axios from "axios";
import { LessonPlanAPIs } from "../api";
import { getFormattedDate } from "../date";

function LessonPlan(props) {
  return (
    <div className="lessonPlan">
      <div className="plan-detail">
        <p>{`${props.plan.subjectId?.name || ""} > ${
          props.plan.chapterId?.name || ""
        } > ${props.plan.topicId?.name || ""}`}</p>

        <span className="plan-grade">{`Class ${
          props.plan?.sectionId?.grade || ""
        }-${props.plan?.sectionId?.section}`}</span>
      </div>

      <div className="plan-action">
        <span className="plan-date">
          <img src={CalendarIcon} alt={"date"} />
          <label>
            {getFormattedDate(props.plan.startDate, "-", "DD-MM-YY")}
          </label>
        </span>

        <div className="actions">
          <img
            onClick={() =>
              (window.location.href = `/lessonplans/${
                props.plan?._id
              }?action=edit&grade=${props.plan?.sectionId?.grade || ""}-${
                props.plan?.sectionId?.section
              }`)
            }
            src={EditIcon}
            alt="edit"
          />
          <img
            onClick={() =>
              (window.location.href = `/lessonplans/${
                props.plan?._id
              }?action=preview&grade=${props.plan?.sectionId?.grade || ""}-${
                props.plan?.sectionId?.section
              }`)
            }
            src={EyeIcon}
            alt="download"
          />
          <img
            onClick={() => props.setLessonPlan(props.plan)}
            src={TrashIcon}
            alt="trash"
          />
        </div>
      </div>
    </div>
  );
}

function LessonPlans(props) {
  const [popup, setPopup] = useState({});
  const [progress, setProgress] = useState(false);
  const [lessonPlan, setLessonPlan] = useState(null);

  function deleteLessonPlan() {
    if (progress) return;
    setProgress(true);
    axios
      .delete(LessonPlanAPIs.deleteLessonPlan(props.userId, lessonPlan._id), {
        headers: {
          token: localStorage.getItem("authToken"),
        },
        validateStatus: () => true,
      })
      .then((res) => {
        if (res.data.status !== "success")
          throw { message: res.data.message || "Oops Failed to delete" };

        const lps = props.lessonPlans.filter(
          (lp) => `${lp._id}` !== `${lessonPlan._id}`
        );
        props.setLessonPlans([...lps]);

        setLessonPlan(null);
        setPopup({
          open: true,
          status: "Success",
          message: "Lesson plan deleted",
        });
      })
      .catch((err) => {
        setPopup({ open: true, status: "Error", message: err.message });
      })
      .finally(() => setProgress(false));
  }

  return (
    <div className={`lessonPlans ${props.classname}`}>
      {props.lessonPlans.map((plan, index) => (
        <LessonPlan setLessonPlan={setLessonPlan} plan={plan} key={index} />
      ))}
      <PopupModal
        open={lessonPlan ? true : false}
        heading="Delete"
        message="Are you sure you want to this plan?"
      >
        <button
          style={{
            color: "#414141",
          }}
          onClick={() => {
            if (progress) return;
            setLessonPlan(null);
          }}
        >
          Cancel
        </button>
        <button
          style={{
            background: "#f13a3a",
            color: "#ffffff",
          }}
          onClick={deleteLessonPlan}
        >
          {progress ? "Deleting" : "Delete"}
        </button>
      </PopupModal>
      <PopupModal
        open={popup.open}
        heading={popup.status}
        message={popup.message}
      >
        <button
          style={{
            color: "#414141",
          }}
          onClick={() => setPopup({})}
        >
          Close
        </button>
      </PopupModal>
    </div>
  );
}

export default LessonPlans;
