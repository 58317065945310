import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

function SearchBar({ search, setSearch }) {
  return (
    <FormControl
      sx={{ m: "1", width: "100ch" }}
      variant="standard"
      id="searchbar"
    >
      <InputLabel htmlFor="standard-adornment-password">Search</InputLabel>
      <Input
        id="standard-adornment-password"
        type="text"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

export default SearchBar;
