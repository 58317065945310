import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { NavLink } from "react-router-dom";
import { ReactComponent as Loader } from "../../../../images/Loader.svg";

const useStyles = makeStyles(() => ({
  table: {
    // minWidth: "auto",
  },
  tableRow: {
    textDecoration: "none",
    "& > td": {
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#e2ddddc2 !important",
    },
  },
  tableHeading: {
    " & > th": {
      color: "#000 !important",
      fontWeight: "bolder",
      fontSize: "1.02em",
    },
  },
}));

const checkstatus = (test) => {
  //When test has been submitted
  if (test.submittedBy && test.submittedBy.length) {
    return "Done";
  }

  //when test has not been submitted and the time to submit has passed
  if (
    (!test.submittedBy || !test.submittedBy.length) &&
    test.to < new Date().toISOString()
  ) {
    return "Not Attempted";
  }

  //When test has started but has not been sbumitted yet
  if (
    test.to > new Date().toISOString() &&
    test.from < new Date().toISOString()
  ) {
    return "In Progress";
  }

  //When test has not started yet
  if (test.from > new Date().toISOString()) {
    return "Assigned";
  }
};

const convertArrayToString = (array) => {
  if (!array) {
    return "";
  }
  array = array && array.map((item) => item.name);
  return array.join(", ");
};

const TestTable = ({ rows, loading }) => {
  console.log(rows);
  const classes = useStyles();
  return (
    <div className={classes.tableContainer}>
      {!loading ? (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeading}>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">Class</TableCell>
                <TableCell align="center">Subject</TableCell>
                <TableCell align="center">Topic</TableCell>
                <TableCell align="center">No of Students</TableCell>
                <TableCell align="center">Total Questions</TableCell>
                <TableCell align="center">Total Time</TableCell>
                <TableCell align="center">Questions Attempted</TableCell>
                <TableCell align="center">Correct</TableCell>
                <TableCell align="center">Incorrect</TableCell>
                <TableCell align="center">1st Attempt</TableCell>
                <TableCell align="center">2nd Attempt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length ? (
                rows.map((row, index) => (
                  <TableRow
                    key={`${row.subject}${index}`}
                    variant="button"
                    component={NavLink}
                    to={`/myassessment/online-test/tests/${row._id}`}
                    className={classes.tableRow}
                    style={{
                      backgroundColor:
                        index % 2 ? "rgba(180, 107, 130, 0.43)" : "white",
                    }}
                  >
                    <TableCell scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell scope="row" align="center">
                      {row.subjectId.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ wordBreak: "break-word", width: "17%" }}
                      dangerouslySetInnerHTML={{
                        __html: convertArrayToString(row.questionSet.chapters),
                      }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      style={{ wordBreak: "break-word", width: "17%" }}
                      dangerouslySetInnerHTML={{
                        __html: convertArrayToString(row.questionSet.topics),
                      }}
                    ></TableCell>
                    <TableCell align="center">
                      {row.from.split("T")[0]}
                    </TableCell>
                    <TableCell align="center">
                      {row.from.split("T")[1].split(".")[0]}
                    </TableCell>
                    <TableCell align="center">{row.to.split("T")[0]}</TableCell>
                    <TableCell align="center">{row.testType}</TableCell>
                    <TableCell align="center">
                      {row.questionSet.totalMarks}
                    </TableCell>
                    <TableCell align="center">{checkstatus(row)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell scope="row" align="center">
                    -
                  </TableCell>
                  <TableCell scope="row" align="center">
                    -
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TestTable;
