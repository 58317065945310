/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import TableTask from './TableTask';
import { Divider } from '@material-ui/core';
import { slice, concat } from 'lodash';
import './styles/TaskFilter.css';
import { GlobalContext } from '../../context/GlobalState';
import axios from 'axios';
import { useContext } from 'react';

const TaskList = () => {
  document.title =  "2xcell.in";
  const [assignedClasses, setAssignedClasses] = useState([]);
  const [classesArray, setClassesArray] = useState([]);
  const [showMoreClass, setshowMoreClass] = useState(true);
  const [classes, setclasses] = useState(null);
  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);

  const { userDetails } = useContext(GlobalContext);

  const loadMoreClass = () => {
    setclasses(concat(classes, slice(classesArray, 3, classesArray?.length)));
    setshowMoreClass(false);
  };

  const [subjectsArray, setSubjectsArray] = useState(null);

  const [showMoreSubject, setShowMoreSubject] = useState(true);
  const [subjects, setSubjects] = useState(slice(subjectsArray, 0, 3));

  const loadMoreSubject = () => {
    setSubjects(
      concat(subjects, slice(subjectsArray, 3, subjectsArray?.length))
    );
    setShowMoreSubject(false);
  };

  useEffect(() => {
    const fetchClasses = async () => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/assignSubjects`,
          {
            headers: {
              token: authToken,
            },
          }
        );

        console.log("Response of fetching assigned subjects for filter===>", response)

        if (response.data.status === 'success') {
          setAssignedClasses([response.data.data.sections]);
          const tempClassesArray =
            response.data.data.sections &&
            response.data.data.sections.map((assignedClass) => {
              return assignedClass._id;
            });

          setClassesArray(tempClassesArray);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchClasses();
  }, []);

  useEffect(() => {
    setclasses(slice(classesArray, 0, 3));
  }, [classesArray]);

  useEffect(() => {
    let tempArr = [];
    selectedClass.forEach((singleClass) => {
      assignedClasses[0] &&
        assignedClasses[0].forEach((assignedClass) => {
          if (singleClass === assignedClass._id) {
            assignedClass.subjects.forEach((data) => {
              tempArr.push(data.subject);
            });
          }
        });
    });

    setSubjectsArray(tempArr);

    showMoreSubject ? setSubjects(slice(tempArr, 0, 3)) : setSubjects(tempArr);
  }, [selectedClass]);

  const handleSelectClass = ({ target: { checked, value } }) => {
    if (checked) {
      setSelectedClass([...selectedClass, value]);
    } else {
      const newCLass = selectedClass.filter((e) => e !== value);
      setSelectedClass(newCLass);
    }
  };

  const handleSelectSubject = ({ target: { checked, value } }) => {
    if (checked) {
      setSelectedSubject([...selectedSubject, value]);
    } else {
      const newSubject = selectedSubject.filter((e) => e !== value);
      setSelectedSubject(newSubject);
    }
  };

  return (
    <>
      <section className='taskList'>
        <div className='container my-5'>
          <div className='row'>
            <div className='col-sm-2'>
              <div className='reportCardFilter border rounded'>
                <h4 className='px-3 py-2'>Filters</h4>
                <Divider className='filterDivider' variant='fullWidth' />

                <div className='class px-3 py-2'>
                  <p>GRADES</p>

                  {classes?.length > 0 &&
                    classes.map((cls, index) => (
                      <div className='form-check' key={index}>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          onChange={handleSelectClass}
                          value={cls}
                          id={cls}
                        />
                        <label className='form-check-label' htmlFor={cls}>
                          {cls}
                        </label>
                      </div>
                    ))}
                  {showMoreClass && (
                    <div className='text-center'>
                      <button className='btn btn-sm' onClick={loadMoreClass}>
                        Show more
                      </button>
                    </div>
                  )}
                </div>
                <Divider className='filterDivider' variant='fullWidth' />
                <div className='subjects px-3 py-2'>
                  <p>SUBJECT</p>

                  {subjects?.length > 0 &&
                    subjects.map((subject, index) => (
                      <div className='form-check' key={index}>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          onChange={handleSelectSubject}
                          value={subject}
                          id={index}
                        />
                        <label className='form-check-label' htmlFor={index}  dangerouslySetInnerHTML={{__html:subject}}>
                        </label>
                      </div>
                    ))}
                  {selectedClass[0] && showMoreSubject && (
                    <div className='text-center'>
                      <button className='btn btn-sm' onClick={loadMoreSubject}>
                        Show more
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-sm-10 mt-lg-0 mt-5'>
              <TableTask
                assignedClasses={assignedClasses}
                selectedclassName={selectedClass}
                selectedSubject={selectedSubject}
                userDetails={userDetails}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TaskList;
