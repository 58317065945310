/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './styles/RevisionTask.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import image from '../../utils/helpers';

const fixThumbnailUrl = (url) => {
  if (!url) {
    return process.env.PUBLIC_URL + '/backgrounds/revisionTask/nature.png';
  }

  if (url.startsWith('https://')) {
    return url;
  } else {
    return 'https://xcell-cms-files.s3.amazonaws.com/' + url;
  }
};

const RevisionTaskStyle = {
  backgroundImage: `url(${
    process.env.PUBLIC_URL + '/backgrounds/revisionTask/nature.png'
  })`,
  backgroundSize: 'cover',
  minHeight: '245px',
  height: '100%',
  borderBottomRightRadius: '17px',
  borderBottomLeftRadius: '17px',
};

const RevisionTask = ({ selectedClass, selectedSubject }) => {
  const [revisionTasks, setRevisionTasks] = useState([]);
  const [revisionTaskLoader, setRevisionTaskLoader] = useState(true);

  useEffect(() => {
    const fetchRevisionTasks = async () => {
      const authToken = localStorage.getItem('authToken');
      const date = new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()).toISOString()
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/tasks?taskType=revision task&section=${selectedClass}&subject=${selectedSubject}`,
          {
            headers: {
              token: authToken,
            },
          }
        );
          console.log('revision task data->',response)
        if (response.data.status === 'success') {
          setRevisionTasks(response.data.data.tasks);
        }
      } catch (error) {
        console.log(error);
      }

      setRevisionTaskLoader(false);
    };

    fetchRevisionTasks();
  }, [selectedClass, selectedSubject]);

  return (
    <>
      <div className='row revisionTask my-3 gy-4'>
        {revisionTaskLoader ? (
          <div className='text-center'>
            <CircularProgress style={{ color: '#FF075B',fontWeight:"600" }} />
          </div>
        ) : revisionTasks[0] ? (
          revisionTasks.map(
            (task, index) =>
              task.taskType === 'revision task' && (
                // <div
                //   key={index}
                //   className='col-sm-3'
                //   style={{ marginBottom: '3rem' }}
                // >
                //   <Link to={`/tasklist/topic/${task._id}`}>
                //     <div className='text-center'>
                //       <div className='cardData' style={RevisionTaskStyle}>
                //         <p>{task.subject}</p>
                //         <p>{task?.content?.name || task?.nam}&emsp;</p>
                //       </div>
                //     </div>
                //   </Link>
                // </div>
                <div className='col-sm-2' key={index}>
                  <Link to={`/tasklist/topic/${task._id}`}>
                    <div className='card card-radius card-height' key={index}>
                      <div className='card-img'>
                        <img
                          src={image(fixThumbnailUrl(task?.content?.thumbnail))}
                          className='card-thumbnail'
                          alt={task?.subject || 'chapter'}
                        />
                      </div>
                      <div className='text-center'>
                        <h5
                          className='text-dark'
                          style={{ fontWeight: 'bold' }}
                        >
                          {task?.subject}
                        </h5>
                        <p className='text-dark'>
                          {task?.content?.name || task?.name}&emsp;
                        </p>
                        <span style={{fontSize:"0.7rem",margin:"auto"}}>{new Date(task.from).toDateString()}</span>
                      </div>
                    </div>
                  </Link>
                </div>
              )
          )
        ) : (
          <h5 className='text-center' style={{marginTop:"6%"}}>No revision task assigned</h5>
        )}
      </div>
    </>
  );
};

export default RevisionTask;
