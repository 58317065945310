import { Button, IconButton, TextField } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import './styles/CreateTest.css';
import QuestionTemplate from './QuestionTemplate';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { GlobalContext } from '../../context/GlobalState';
import { Toast } from 'bootstrap';
import { useAlert, transitions, types } from 'react-alert';
import {useHistory} from 'react-router-dom'

const CreateTest = () => {
  const alert = useAlert();

  const {
    userDetails,
    assignedSubjects,
    setAssignedSubjects,
    selectedClass,
    setSelectedClass,
  } = useContext(GlobalContext);

  document.title = "2xcell.in";
  const sectionId = window.localStorage.getItem('sectionIdTask');
  const subjectId = window.localStorage.getItem('subjectId');
  const topicId = window.localStorage.getItem('topicId');
  const userId = window.localStorage.getItem('userId');
  // const [sectionId,setSectionId]=useState(null) //To be updated in getSectionId method

  //state to contain the questions in form of data, and not as elements
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [difficulty, setDifficulty] = useState(null);
  const [cognitive, setCognitive] = useState(null);
  const [questionList, setQuestionList] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [formData, setFormData] = useState(new FormData());

  //state to contain the questions in form of elements
  const [questionCount, setQuestionCount] = useState(1);
  const [questionArray, setQuestionArray] = useState([
    <QuestionTemplate
      questionCount={questionCount}
      questionList={questionList}
      setQuestionList={setQuestionList}
      correctAnswers={correctAnswers}
      setCorrectAnswers={setCorrectAnswers}
      formData={formData}
      setFormData={setFormData}
    />,
  ]);

  const addQuestion = () => {
    setQuestionCount(questionCount + 1);
    setQuestionArray([
      ...questionArray,
      <QuestionTemplate
        questionCount={questionCount + 1}
        questionArray={questionArray}
        setQuestionArray={setQuestionArray}
        questionList={questionList}
        setQuestionList={setQuestionList}
        correctAnswers={correctAnswers}
        setCorrectAnswers={setCorrectAnswers}
        formData={formData}
        setFormData={setFormData}
      />,
    ]);
  };

  const save = async () => {
    console.log(questionList)
    console.log(correctAnswers)
    //Check if all the correct answers have been selected
    let mcqQuestionCount = 0
    for(let q of questionList) {
      console.log(q)
      console.log(q.questionType)
      if(q.questionType==="mcq"){
        mcqQuestionCount++;
      }
    }
    console.log(mcqQuestionCount)
    if(mcqQuestionCount!==correctAnswers.length) {
      alert.show("Please select correct answers for all MCQs",{type: 'error'})
      return
    }

    //Start uploading test
    alert.show('Uploading test');
    const questions = questionList;
    const test = JSON.stringify({
      name,
      description,
      topicId,
      subjectId,
      sectionId,
      questions,
      correctAnswers,
      difficulty,
      cognitive,
    });

    formData.append('test', test);
    formData.append('topicId', topicId);
    formData.append('subjectId', subjectId);
    formData.append('sectionId', sectionId);

    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/tests`,
      {
        method: 'POST',
        headers: {
          token: `${window.localStorage.getItem('authToken')}`,
        },
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.status === 'success') {
          alert.show('Test uploaded successfuly', { type: 'success' });
          // window.location.reload();
          history.goBack()
        } else {
          alert.show('Failed to upload test', { type: 'error' });
        }
      });
  };

  const history = useHistory()

  return (
    <>
      <section className='createTest my-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-11 order-sm-first order-last'>
              <div className='border rounded p-3'>
                <form action='#'>
                  <div className='mb-2'>
                    <TextField
                      id='standard-basic'
                      label='Test Title'
                      style={{ width: '100%' }}
                      inputProps={{ style: { fontSize: 24 } }}
                      InputLabelProps={{ style: { fontSize: 24 } }}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  <div className='mb-3'>
                    <TextField
                      id='standard-basic'
                      label='Description'
                      style={{ width: '100%' }}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </div>
                </form>
              </div>

              <div>
                <div className='row my-4'>
                  <div className='col-sm mb-3'>
                    <select
                      className='form-select'
                      onChange={(e) => {
                        setDifficulty(e.target.value);
                      }}
                    >
                      <option defaultValue=''>Difficulty</option>
                      <option value='easy'>Easy</option>
                      <option value='intermediate'>Intermediate</option>
                      <option value='difficult'>Difficult</option>
                    </select>
                  </div>
                  <div className='col-sm mb-3'>
                    <select
                      className='form-select'
                      onChange={(e) => {
                        setCognitive(e.target.value);
                      }}
                    >
                      <option defaultValue=''>Cognitive</option>
                      <option value='knowledge'>Knowledge</option>
                      <option value='understanding'>Understanding</option>
                      <option value='application'>Application</option>
                      <option value='analysis'>Analysis</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='testQuestion'>
                {questionArray.map((question, index) => (
                  <span key={index}>{question}</span>
                ))}
              </div>
                {/* add button */}
                <div className='col-sm-3 text-center' style={{cursor:'pointer',margin:'auto'}} onClick={addQuestion}>
                  <div className='border rounded p-1'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                    > <AddBoxOutlinedIcon /> </IconButton> 
                    Add Question
                    {/* <input
                      accept='image/*'
                      style={{ display: 'none' }}
                      id='icon-button-file'
                      type='file'
                    />
                    <label htmlFor='icon-button-file'>
                      <IconButton
                        color='primary'
                        aria-label='upload picture'
                        component='span'
                      >
                        <ImageOutlinedIcon />
                      </IconButton>
                    </label> */}
                  </div>
                </div>
              </div>
            </div>


          <div className='text-center row mt-5'>
            <div className='col-sm mb-3'>
              <Button variant='contained' onClick={()=>{history.goBack()}} className='cancelFormBtn'>
                Back
              </Button>
            </div>
            <div className='col-sm mb-3'>
              <Button
                variant='contained'
                className='saveFormBtn'
                onClick={save}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateTest;
