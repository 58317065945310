import { useEffect } from "react";
import "./Table.css";

import Module from "../Module/Module";
import { getDate } from "../date";

function Table(props) {
  useEffect(() => {
    if (!props.plan?._id || !props.setPrint) return;
    props.setPrint(true);
  }, [props]);

  return (
    <>
      <div className="lp-table-heading">
        <p>
          <b>Date</b>
          {" : "}
          <span>{props.plan?.startDate && getDate(props.plan?.startDate)}</span>
        </p>
        <p>
          <b>{`${props.plan?.subjectId?.name || ""} > ${
            props.plan?.chapterId?.name || ""
          } > ${props.plan?.topicId?.name || ""}`}</b>
        </p>
      </div>
      <div className="table-scroller">
        <table className="lp-table-container">
          <tr className="thead">
            <td className="index">S.no</td>
            <td>Class Activity</td>
            <td>For Teachers</td>
            <td>Question For Students</td>
            <td>Answers From Students</td>
            <td>2xcell Digital Resources</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {props.plan.rows.map((row, index) => {
            if (
              !row.activity &&
              !row.teacher &&
              !row.question &&
              !row.answer &&
              !row.resources?.length
            )
              return <></>;
            return (
              <tr className="trow" key={index}>
                <td>{index + 1 < 10 ? `0${index + 1}` : index + 1}</td>
                <td>{row.activity || ""}</td>
                <td>{row.teacher || ""}</td>
                <td>{row.question || ""}</td>
                <td>{row.answer || ""}</td>
                <td>
                  {row.resources.map((resource, ind) => (
                    <Module
                      style={{ backgroundColor: "#ffffff" }}
                      resource={resource}
                      key={ind}
                    />
                  ))}
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </>
  );
}

export default Table;
