import CheckedIcon from "../icons/radiobutton.svg";
import CheckIcon from "../icons/radiobutton-active.svg";

function Check(props) {
  return (
    <div className="checklist" onClick={props.check}>
      <img src={props.checked ? CheckedIcon : CheckIcon} alt={props.name} />
      <label>{props.name}</label>
    </div>
  );
}
function CheckList(props) {
  return (
    <div style={props.styles} className="form-checklists">
      {props.lists.map((list, index) => (
        <Check
          checked={props.selectedValue?.value === list.value}
          check={() => props.setSelectedValue(list)}
          name={list.name}
          key={index}
        />
      ))}
    </div>
  );
}

export default CheckList;
