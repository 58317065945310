import React from "react";
import { Button, IconButton, TextField } from "@material-ui/core";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import OptionTemplate from "./OptionTemplate";
import { useState,useEffect } from "react";

const QuestionTemplate = ({ questionCount,questionList,setQuestionList,correctAnswers,setCorrectAnswers,formData,setFormData }) => {
 
  let qId = "";
  if(questionCount<10){
    qId = "Q0"+questionCount;
  }else{
    qId = "Q"+questionCount
  }

  //state to contain the questions and its fields in form of data
  const [question,setQuestion] = useState({
    "id":questionCount,
    "questionId":qId,
    "questionType":"mcq",
    "question":"",
    "options":[]
  })

  //states to contain the fields of a question in form of elements
  const [answerType, setAnswerType] = useState("multipleChoice");
  const [optionCount, setOptionCount] = useState(1);
  const [optionArray, setOptionArray] = useState([
    <OptionTemplate optionCount={optionCount} questionCount={questionCount}
                    question={question} setQuestion={setQuestion} 
                    questionList={questionList} setQuestionList={setQuestionList}
                    correctAnswers={correctAnswers} setCorrectAnswers={setCorrectAnswers}
                    formData={formData} setFormData={setFormData}
                  />,
  ]);

  //To push te current question into the list only once
  useEffect(()=>{
    questionList.push(question);
    setQuestionList([...questionList]);
  },[])

  const addOption = () => {
    setOptionCount(optionCount + 1);
    setOptionArray([
      ...optionArray,
      <OptionTemplate
        optionCount={optionCount + 1} questionCount={questionCount}
        optionArray={optionArray}  setOptionArray={setOptionArray}
        question={question}  setQuestion={setQuestion} 
        correctAnswers={correctAnswers}  setCorrectAnswers={setCorrectAnswers}
        formData={formData} setFormData={setFormData}
      />,
    ]);
  };

  //Push the updates input value into the question and update the state,
  // and then update the question list 
  const handleAnswerChoice = (e) => {
    setAnswerType(e.target.value);

    question.questionType = e.target.value;
    setQuestion({...question})
    questionList[questionCount-1]=question;
    setQuestionList([...questionList])
  };

  const handleQuestionInput =(e)=>{
    question.question = e.target.value;
    setQuestion({...question})
    questionList[questionCount-1] = question
    setQuestionList([...questionList])
  }

  const handleImageInput =(e)=>{
    formData.append(question.questionId,e.target.files[0])
    setFormData(formData)
  }

  return (
    <>
      <div className="border rounded p-3 mb-4">
        <div className="row mb-3">
          <div className="col-sm-8">
            <TextField
              id="standard-basic"
              label={`Question ${questionCount}`}
              className={`questionTitle`}
              onChange={(e)=>{handleQuestionInput(e)}}
            />
            {/* <input
              accept="image/*"
              style={{ display: "none" }}
              id={`question${questionCount}File`}
              type="file"
              onChange={(e)=>{handleImageInput(e)}}
            />
            <label htmlFor="icon-button-file">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <ImageOutlinedIcon />
              </IconButton>
            </label> */}
          </div>
          <div className="col-sm-4">
            {/* <span className="ml-auto closeQuestionBtn">
              <button type="button" className="btn-close"></button>
            </span> */}
            {/* <select className="form-select" onChange={handleAnswerChoice}>
              <option value="multipleChoice">Multiple Choice</option>
              <option value="shortAnswer">Short Answer</option>
              <option value="paragraph">Paragraph</option>
            </select> */}
          </div>
        </div>

        <div className=" mb-3">
          {answerType === "multipleChoice" &&
            optionArray.map((option, index) => (
              <span key={index}>{option}</span>
            ))}

          {answerType === "shortAnswer" && (
            <textarea
              className="form-control"
              id={`question${questionCount}ShortAnswer`}
              rows="2"
              placeholder="Type Here..."
            ></textarea>
          )}

          {answerType === "paragraph" && (
            <textarea
              className="form-control"
              id={`question${questionCount}Paragraph`}
              rows="3"
              placeholder="Type Here..."
            ></textarea>
          )}
        </div>

        {answerType === "multipleChoice" && (
          <Button variant="contained" size="small" onClick={addOption}>
            Add Option
          </Button>
        )}
      </div>
    </>
  );
};

export default QuestionTemplate;
