/* Imports */
import "../AssessmentAnalytics/PracticeAssessment.css";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  Label,
  YAxis,
  Bar,
  LabelList,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { Container, Row, Col } from "react-bootstrap";
import student from "../../images/student.svg";
import icon1 from "../../images/Icon.svg";
import icon2 from "../../images/Icon (1).svg";
import icon3 from "../../images/Icon (2).svg";
import icon4 from "../../images/Icon (3).svg";
import icon5 from "../../images/Icon (5).svg";
import icon6 from "../../images/Icon (6).svg";
import ClassReport from "./Tables/ClassReport";
import Sideboard from "../AssessmentAnalytics/Sideboard/Sideboard";
import Backbtn from "../../images/Arrow 9.svg";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import exportFromJSON from "export-from-json";
import SearchBar from "../OnlineTest/TestList/SearchBar/SearchBar";
import ExportButton from "../../images/export (1).svg";
import DropDownShutter from "../DropDown/DropDown";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Moment from "react-moment";
import { makeStyles, Paper, Tab, Tabs, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import HomeIcon from "../../images/HomeIcon.svg";
import assignImg from "../../images/assign.svg";
import assessmentImg from "../../images/assessment.svg";
import analyticGraphImg from "../../images/analyticGraph.svg";
import Box from "@material-ui/core/Box";
import { GlobalContext } from "../../context/GlobalState";
/* End of imports */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const OnlineTest2 = () => {
  /*Instance for Visibility of Topic Graphs */
  const [show, setShow] = useState("none");

  const location = useLocation();
  const params = useParams();
  const grade = params.class;
  const section = params.section;
  const subject = params.subject;
  localStorage.setItem("gradeSection", `${grade}-${section}`);
  localStorage.setItem("subject", subject);
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#edfcfa",
      boxShadow: "none",
    },
    root1: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    card: {
      width: "100%",
      borderRadius: "10px",
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
      padding: "20px",
      margin: "20px auto",
    },
    subjectSection: {
      textAlign: "center",
      "& button": {
        border: "none",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        marginBottom: "10px",
        padding: "4px",
        minWidth: "120px",
        color: "white",
      },
      "& p": {
        marginBlockStart: "0px",
        marginBlockEnd: "0px",
        fontSize: "0.8em",
      },
    },
    report: {
      textAlign: "center",
      "& a": {
        backgroundColor: "rgba(29, 205, 109, 0.78)",
        padding: "7px 10px",
        borderRadius: "10px",
        color: "white",
        textDecoration: "none",
        height: "fit-content",
        transition: "0.2s",
        "& span": {
          backgroundColor: "rgba(112, 253, 175, 0.73)",
          color: "white",
          borderRadius: "50%",
          height: "fit-content",
          width: "fit-content",
          padding: "3px",
          verticalAlign: "middle",
        },
        "&:hover": {
          backgroundColor: "rgba(29, 205, 109, 1)",
          color: "white !important",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    report2: {
      textAlign: "center",
      "& a": {
        backgroundColor: "rgba(0, 121, 234, 0.78);",
        padding: "7px 10px",
        borderRadius: "10px",
        color: "white",
        textDecoration: "none",
        height: "fit-content",
        transition: "0.2s",
        "& span": {
          backgroundColor: "rgba(0, 121, 234, 0.73);",
          color: "white",
          borderRadius: "50%",
          height: "fit-content",
          width: "fit-content",
          padding: "3px",
          verticalAlign: "middle",
        },
        "&:hover": {
          backgroundColor: "rgba(0, 121, 234, 1);",
          color: "white !important",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    menu: {
      textAlign: "center",
    },
    contextMenu: {
      "& .MuiList-root": {
        padding: "10px 10px 5px 10px",
      },
      "& .MuiPaper-rounded": {
        borderRadius: "5px !important",
      },
      "& li": {
        color: "white",
        fontSize: "0.9em",
        background: "rgba(180, 107, 130, 0.8)",
        marginBottom: "5px",
        borderRadius: "5px !important",
        transition: "0.3s",
        "&:hover": {
          background: "rgba(180, 107, 130, 1)",
          fontWeight: "bold",
        },
      },
    },
    "@media screen and (max-width: 472px)": {
      card: {
        width: "95%",
        padding: "10px 7px",
      },
      subjectSection: {
        "& button": {
          fontSize: "0.8em",
          minWidth: "80px",
        },
        "& p": {
          fontSize: "0.6em",
        },
      },
      report: {
        "& a": {
          fontSize: "0.7em",
        },
      },
      report2: {
        "& a": {
          fontSize: "0.7em",
        },
      },
      contextMenu: {
        padding: "5px 5px 3px 5px",
        fontSize: "0.7em",
        "& li": {
          minHeight: "35px",
        },
      },
    },
  }));
  const { userDetails, setUserDetails } = useContext(GlobalContext);
  const [heading, setHeading] = useState("Analytics");

  const [sectionId, setSectionId] = useState("");
  //   const [sectionId, setSectionId] = useState(location.state.sectionId);
  // const [sectionId, setSectionId] = useState("");
  const [subjectValue, setSubjectValue] = useState("");
  const [chapterValue, setChapterValue] = useState("");
  const [attemptStatusTotalQ, setAttemptStatusTotalQ] = useState(0);
  const [attemptStatusTotalTQ, setAttemptStatusTotalTQ] = useState(0);
  const [numStudents, setNumStudents] = useState(0);
  const [numChapters, setNumChapters] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [miniCard, setMiniCard] = useState({
    questions: 0,
    teacherQuestions: 0,
    topics: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  /*Instances for Loaders*/
  const [ch_loader, setCh_loader] = useState(false);
  const [q_loader, setQ_loader] = useState(false);
  const [dlr_loader, setDlr_loader] = useState(false);
  const [clr_loader, setClr_loader] = useState(false);
  const [ls_loader, setLs_loader] = useState(false);
  const [tr_loader, setTr_loader] = useState(false);
  const [tq_loader, setTq_loader] = useState(false);
  const [tdlr_loader, setTdlr_loader] = useState(false);
  const [tclr_loader, setTclr_loader] = useState(false);
  const [tls_loader, setTls_loader] = useState(false);
  const [table_loader, setTable_loader] = useState(false);

  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(1);
  const [assessmentMakeTaskLoader, setAssessmentMakeTaskLoader] =
    useState(false);
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  /*Handling Visibility of Topic Graphs*/
  const handleShow = () => {
    show === "none" ? setShow("unset") : setShow("none");
  };

  /*Data for Chapters*/
  const [ch_data, setCh_data] = useState([
    { name: "", Pending: 0, Completed: 0 },
  ]);

  /*Data for Questions*/
  const [q_data, setQ_data] = useState([
    { name: "Correct", value: 0 },
    { name: "Incorrect", value: 0 },
    { name: "Not Attempted", value: 0 },
  ]);

  /*Data for Difficulty Level Report*/
  const [dlr_data, setDlr_data] = useState([
    { name: "", Easy: 0, Medium: 0, Difficult: 0 },
  ]);

  /*Data for Cognitive Level Report*/
  const [clr_data, setClr_data] = useState([
    {
      name: "",
      Knowledge: 0,
      Understanding: 0,
      Application: 0,
      Analysis: 0,
    },
  ]);

  /*Data for Topicwise Report */
  const [tr_data, setTr_data] = useState([
    {
      name: "",
      Pending: 0,
      Completed: 0,
    },
  ]);

  /*Data for Topicwise Questions*/
  const [tq_data, setTq_data] = useState([
    { name: "Correct", value: 0 },
    { name: "Incorrect", value: 0 },
    { name: "Not Attempted", value: 0 },
  ]);

  /*Data for Topicwise Difficulty Level Report*/
  const [tdlr_data, setTdlr_data] = useState([
    { name: "", Easy: 0, Medium: 0, Difficult: 0 },
  ]);

  /*Data for Topicwise Cognitive Level Report*/
  const [tclr_data, setTclr_data] = useState([
    {
      name: "",
      Knowledge: 0,
      Understanding: 0,
      Application: 0,
      Analysis: 0,
    },
  ]);

  /*Data for Learning Strength*/
  const [ls_data, setLs_data] = useState([]);

  /*Data for Learning Gap*/
  const [lg_data, setLg_data] = useState([]);

  /*Data for Topicwise Learning Strength*/
  const [tls_data, setTls_data] = useState([]);

  /*Data for Topicwise Learning Gap*/
  const [tlg_data, setTlg_data] = useState([]);

  /*Colors for Pie Chart*/
  const COLORS = ["#10A0B6", "#FF8989", "#9DABDD"];

  /*Handling Customized Labelling of Pie Chart*/
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  /*React Media Query*/
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });

  /*Instance for Dropdown*/
  const [dropdown, setdropdown] = useState(isSmallScreen ? false : true);

  const handleShutterOpen = () => {
    setdropdown(!dropdown);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeHeading = (val) => {
    setUserDetails({ ...userDetails, selectedTab: val, pathName: {} });
    history.goBack();
    setHeading(val);
  };

  /*Fetching Data For MiniCards*/
  const fetchMiniCardData = async () => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionsstatus`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        const newMini_card = {
          questions: 0,
          teacherQuestions: 0,
          topics: 0,
        };
        response.data.data.questions.forEach((element) => {
          newMini_card.questions += element.questions;
          newMini_card.teacherQuestions += element.teacherQuestions;
          newMini_card.topics += element.topics;
        });
        setNumChapters(response.data.data.questions.length);
        setMiniCard(newMini_card);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*Fetching Data For Completion Status Of Questions*/
  const fetchChapterwiseQuestions = async () => {
    setCh_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionscompletestatus`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newch_data = response.data.data.questionsCompleteStats.map(
          (element) => {
            return {
              name: element._id ? element._id.name : "",
              Pending: element.pending,
              Completed: element.completed,
            };
          }
        );
        setCh_data(newch_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    // setLoaders({ ...loaders, ch: false });
    setCh_loader(false);
  };

  /*Fetching Data For Attempt Status Of Questions*/
  const fetchChapterwiseAttemptStatus = async () => {
    setQ_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionsattemptstatus`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        const newq_data = [
          { name: "Correct", value: 0 },
          { name: "Incorrect", value: 0 },
          { name: "Not Attempted", value: 0 },
        ];
        let totalq = 0;
        response.data.data.questionsAttemptStats.forEach((element) => {
          newq_data[0].value += element.correct;
          newq_data[1].value += element.incorrect;
          newq_data[2].value += element.unattempted;
          totalq = element.correct + element.incorrect + element.unattempted;
        });
        setAttemptStatusTotalQ(totalq);
        setQ_data(newq_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    // setLoaders({ ...loaders, q: false });
    setQ_loader(false);
  };

  /*Fetching Data For Difficulty Status Of Questions*/
  const fetchDifficultywiseQuestions = async () => {
    setDlr_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionsdifficultystatus`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newdlr_data = response.data.data.questionsDifficultyStats.map(
          (element) => {
            return {
              name: element._id ? element._id.name : "",
              Easy: element.easy,
              Medium: element.intermediate,
              Difficult: element.hard,
            };
          }
        );
        setDlr_data(newdlr_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setDlr_loader(false);
  };

  /*Fetching Data For Cognitive Status Of Questions*/
  const fetchCognitivewiseQuestions = async () => {
    setClr_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionscognitivestatus`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newclr_data = response.data.data.questionsCognitiveStats.map(
          (element) => {
            return {
              name: element._id ? element._id.name : "",
              Knowledge: element.knowledge,
              Understanding: element.understanding,
              Application: element.application,
              Analysis: element.analysis,
            };
          }
        );
        setClr_data(newclr_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setClr_loader(false);
  };

  /*Fetching Data For Learning Strength & Learning Gap Of Questions*/
  const fetchChapterwiseLearning = async () => {
    setLs_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionslearningstatus?students=${numStudents}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setLg_data([]);
        setLs_data([]);
        response.data.data.questionsLearningStats.forEach((element) => {
          if (element._id === "gap") {
            setLg_data(
              element.contents.map((e) => {
                return {
                  name: e.content ? e.content.name : "",
                  value: e.avgScore,
                };
              })
            );
          } else {
            setLs_data(
              element.contents.map((e) => {
                return {
                  name: e.content ? e.content.name : "",
                  value: e.avgScore,
                };
              })
            );
          }
        });
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setLs_loader(false);
  };

  /*Fetching Data For Class Report Table*/
  const fetchClassReport = async () => {
    setTable_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/classreports`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setTableData(response.data.data.reports);
        setTable_loader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    if (!sectionId || !subjectValue) return;
    await fetchChapterwiseQuestions();
    await fetchMiniCardData();
    await fetchChapterwiseAttemptStatus();
    await fetchDifficultywiseQuestions();
    await fetchCognitivewiseQuestions();
    await fetchChapterwiseLearning();
    await fetchClassReport();
  }, [subjectValue, sectionId, numStudents]);

  const fetchTopicwiseQuestions = async () => {
    setTr_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionscompletestatus?chapter=${chapterValue}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtr_data = response.data.data.questionsCompleteStats.map(
          (element) => {
            return {
              name: element._id ? element._id.name : "",
              Pending: element.pending,
              Completed: element.completed,
            };
          }
        );
        setTr_data(newtr_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setTr_loader(false);
  };
  const fetchTopicwiseAttemptStatus = async () => {
    setTq_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionsattemptstatus?chapter=${chapterValue}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        const newq_data = [
          { name: "Correct", value: 0 },
          { name: "Incorrect", value: 0 },
          { name: "Not Attempted", value: 0 },
        ];
        let totaltq = 0;
        response.data.data.questionsAttemptStats.forEach((element) => {
          newq_data[0].value += element.correct;
          newq_data[1].value += element.incorrect;
          newq_data[2].value += element.unattempted;
          totaltq = element.correct + element.incorrect + element.unattempted;
        });
        setAttemptStatusTotalTQ(totaltq);
        setTq_data(newq_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setTq_loader(false);
  };
  const fetchTopicwiseDifficultyQuestions = async () => {
    setTdlr_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionsdifficultystatus?chapter=${chapterValue}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtdlr_data = response.data.data.questionsDifficultyStats.map(
          (element) => {
            return {
              name: element._id ? element._id.name : "",
              Easy: element.easy,
              Medium: element.intermediate,
              Difficult: element.hard,
            };
          }
        );
        setTdlr_data(newtdlr_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setTdlr_loader(false);
  };
  const fetchTopicwiseCognitiveQuestions = async () => {
    setTclr_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionscognitivestatus?chapter=${chapterValue}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtclr_data = response.data.data.questionsCognitiveStats.map(
          (element) => {
            return {
              name: element._id ? element._id.name : "",
              Knowledge: element.knowledge,
              Understanding: element.understanding,
              Application: element.application,
              Analysis: element.analysis,
            };
          }
        );
        setTclr_data(newtclr_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setTclr_loader(false);
  };
  const fetchTopicwiseLearning = async () => {
    setTls_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionslearningstatus?students=${numStudents}?chapter=${chapterValue}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setTlg_data([]);
        setTls_data([]);
        response.data.data.questionsLearningStats.forEach((element) => {
          if (element._id === "gap") {
            setTlg_data(
              element.contents.map((e) => {
                return {
                  name: e.content ? e.content.name : "",
                  value: e.avgScore,
                };
              })
            );
          } else {
            setTls_data(
              element.contents.map((e) => {
                return {
                  name: e.content ? e.content.name : "",
                  value: e.avgScore,
                };
              })
            );
          }
        });
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setTls_loader(false);
  };
  useEffect(async () => {
    if (!chapterValue) return;
    await fetchTopicwiseQuestions();
    await fetchTopicwiseAttemptStatus();
    await fetchTopicwiseDifficultyQuestions();
    await fetchTopicwiseCognitiveQuestions();
    await fetchTopicwiseLearning();
  }, [chapterValue]);

  /*Function To Export Class Report Data*/
  const exportCsv = () => {
    const data = tableData;
    const formatted_data = data.map((element) => {
      return {
        Subject: element.subjectId.name,
        Chapter: element.chapterId.name,
        Topic: element.topicId.name,
        Grade: element.grade,
        Section: element.section,
        Students: element.students,
        "Start Time": element.startedAt,
        Questions: element.questions,
        "Questions Attempted": element.questionsAttempted,
        Score: element.score,
        Easy: element.easy,
        Intermediate: element.intermediate,
        Hard: element.hard,
        Correct: element.correct,
        Incorrect: element.incorrect,
        "Time Spent": `${
          Math.floor(element.timeSpend / 3600000) +
          "h" +
          Math.floor((element.timeSpend % 3600000) / 60000) +
          "m" +
          Math.floor((element.timeSpend / 1000) % 60) +
          "s"
        }`,
      };
    });
    const fileName = "Class Report";
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data: formatted_data, fileName, exportType });
  };
  const search = () => {
    console.log(tableData);
    let searchData = tableData.filter(
      (data) =>
        data.chapterId.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        data.topicId.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setTableData(searchData);
  };

  useEffect(() => {
    //when the search box is empty
    if (!searchValue) {
      // fetchClassReport();
    } else {
      search();
    }
  }, [searchValue]);
  const cardData = [
    {
      subject: "Mathematics",
      date: "Friday, 14 Oct 2021",
      time: "6:00-10:00",
      testId: "1",
      background: "rgba(36, 147, 110, 1)",
    },
    {
      subject: "English",
      date: "Friday, 14 Oct 2021",
      time: "6:00-10:00",
      testId: "2",
      background: "rgba(229, 152, 155, 1)",
    },
    {
      subject: "Hindi",
      date: "Friday, 14 Oct 2021",
      time: "6:00-10:00",
      testId: "3",
      background: "rgba(102, 153, 187, 0.9)",
    },
    {
      subject: "EVS",
      date: "Friday, 14 Oct 2021",
      time: "6:00-10:00",
      testId: "4",
      background: "rgba(255, 180, 162, 1)",
    },
  ];

  const SampleData = [
    {
      name: "VI-A Mathematics",
      "In Progress": 9,
      Incomplete: 15,
      Completed: 7,
    },
    {
      name: "VI-B Mathematics",
      "In Progress": 9,
      Incomplete: 15,
      Completed: 7,
    },
    {
      name: "VI-C Science",
      "In Progress": 9,
      Incomplete: 15,
      Completed: 7,
    },
  ];
  const SampleData2 = [
    {
      name: "VI-A Mathematics",
      value: 398,
    },
    {
      name: "VI-B Mathematics",
      value: 266,
    },
    {
      name: "VI-C Science",
      value: 147,
    },
    {
      name: "VI-B Science",
      value: 147,
    },
  ];
  const SampleData3 = [
    {
      name: "VI-A Mathematics",
      easy: {
        Easy: 332,
        "Total Q'": 68,
      },
      medium: {
        Medium: 155,
        "Total Q'": 95,
      },
      difficult: {
        Difficult: 100,
        "Total Q'": 50,
      },
    },
    {
      name: "VI-B Mathematics",
      easy: {
        Easy: 332,
        "Total Q'": 68,
      },
      medium: {
        Medium: 155,
        "Total Q'": 95,
      },
      difficult: {
        Difficult: 100,
        "Total Q'": 50,
      },
    },
  ];
  const SampleData4 = [
    {
      name: "VI-A Mathematics",
      Knowledge: 150,
      Understanding: 20,
      Analysis: 40,
      Application: 80,
    },
    {
      name: "VI-B Mathematics",
      Knowledge: 100,
      Understanding: 20,
      Analysis: 40,
      Application: 30,
    },
    {
      name: "VI-C Science",
      Knowledge: 200,
      Understanding: 30,
      Analysis: 40,
      Application: 80,
    },
  ];
  var randomColor;
  useEffect(() => {
    randomColor = Math.floor(Math.random() * 16777215).toString(16);
    console.log(`#${randomColor}`);
    console.log(typeof `#${randomColor}`);
  }, []);
  const barColors = ["#0F93A8", "#F2931E", "#AE4132", "#23445D"];
  return (
    <>
      <section className={isSmallScreen ? "modules my-4" : "modules my-5"}>
        <div
          className={
            isSmallScreen ? "container-fluid dashboard_container" : "container"
          }
        >
          <div className="box">
            <div
              className="upperBox"
              style={{ display: isSmallScreen ? "none" : "" }}
            >
              <div className="alignItem">
                <div className="row">
                  <div className="boxRow">
                    <div className="boxRowLeft">
                      <h1 className="className">
                        {/* {grade
                          ? `${grade}-${section}`
                          : localStorage.getItem("gradeSection")} */}
                        {params.class}-{params.section}
                      </h1>
                      <div className="pathDetail">
                        <h2 style={{ fontWeight: "700" }}>
                          Analytics &gt; Online Test
                        </h2>
                        <p style={{ fontWeight: "600", color: "#ffffff" }}>
                          {params.subject}
                        </p>
                      </div>
                    </div>
                    <div className="DateBox">
                      <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                        <CalendarTodayIcon />{" "}
                        {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              <Paper className={classes.root}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  style={{
                    background: "#DCFFFB",
                    marginTop: "-12px",
                    display: dropdown ? "" : "none",
                  }}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={"on"}
                >
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={HomeIcon}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Home"
                    {...a11yProps(0)}
                    onClick={() => changeHeading("Dashboard")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={analyticGraphImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Analytics"
                    {...a11yProps(1)}
                    onClick={() => changeHeading("Analytics")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assignImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assign Task"
                    {...a11yProps(2)}
                    onClick={() => changeHeading("Assign Task")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assessmentImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assessment"
                    {...a11yProps(3)}
                    onClick={() => changeHeading("Assessment")}
                  />
                </Tabs>
              </Paper>
            }
            <div
              className="shutter_PS"
              style={{
                marginTop: dropdown ? "0px" : "-15px",
                display: isSmallScreen ? "flex" : "none",
              }}
            >
              <DropDownShutter
                onClick={handleShutterOpen}
                clicked={dropdown}
                dropdown={dropdown}
                setDropDown={setdropdown}
              />
            </div>
          </div>
        </div>
      </section>
      {/*Graphs Section*/}
      <Container fluid="xl" className="analytics_main_section">
        {/*Back Button*/}
        <Link to="/dashboard">
          <img src={Backbtn} className="back_btn" />
        </Link>
        {/*Side Drawer*/}
        <Sideboard
          sectionId={sectionId}
          setSectionId={setSectionId}
          subjectValue={subjectValue}
          setSubjectValue={setSubjectValue}
          chapterValue={chapterValue}
          setChapterValue={setChapterValue}
          numStudents={numStudents}
          setNumStudents={setNumStudents}
          ch_loader={ch_loader}
          q_loader={q_loader}
          dlr_loader={dlr_loader}
          clr_loader={clr_loader}
          ls_loader={ls_loader}
        />
        {/*Mini Cards*/}
        <Row className="analytics_mini_cards mx-auto my-5">
          <div className="analytics_mini_cards_section">
            <Col className="mini_card">
              <div
                className="mini_card_layer"
                style={{ justifyContent: "flex-start" }}
              >
                <div
                  className="mini_card_icon"
                  style={{
                    backgroundColor: "#fa582e26",
                  }}
                >
                  <img src={student} />
                </div>
              </div>
              <div>
                <div className="mini-card-content-heading">Students</div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{numStudents}</h5>
                </div>
              </div>
            </Col>
            <Col className="mini_card">
              <div
                className="mini_card_layer"
                style={{ justifyContent: "flex-start" }}
              >
                <div
                  className="mini_card_icon"
                  style={{
                    backgroundColor: "#FBFF3447",
                  }}
                >
                  <img src={icon2} />
                </div>
              </div>
              <div>
                <div className="mini-card-content-heading">
                  Questions Assigned
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>98/100</h5>
                </div>
              </div>
            </Col>
          </div>
          <div className="analytics_mini_cards_section">
            <Col className="mini_card">
              <div
                className="mini_card_layer"
                style={{ justifyContent: "flex-start" }}
              >
                <div
                  className="mini_card_icon"
                  style={{
                    backgroundColor: "#2EA4FA36",
                  }}
                >
                  <img src={icon1} />
                </div>
              </div>
              <div>
                <div className="mini-card-content-heading">
                  Concepts Covered In Test
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>66/100</h5>
                </div>
              </div>
            </Col>
            <Col className="mini_card">
              <div
                className="mini_card_layer"
                style={{ justifyContent: "flex-start" }}
              >
                <div
                  className="mini_card_icon"
                  style={{
                    backgroundColor: "#B92EFA29",
                  }}
                >
                  <img src={icon3} />
                </div>
              </div>
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Average Score
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>60</h5>
                </div>
              </div>
            </Col>
          </div>
          <div className="analytics_mini_cards_section">
            <Col className="mini_card">
              <div
                className="mini_card_layer"
                style={{ justifyContent: "flex-start" }}
              >
                <div
                  className="mini_card_icon"
                  style={{
                    backgroundColor: "#10A0B673",
                  }}
                >
                  <img src={icon4} />
                </div>
              </div>
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Class Highest
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>60</h5>
                </div>
              </div>
            </Col>
            <Col className="mini_card">
              <div
                className="mini_card_layer"
                style={{ justifyContent: "flex-start" }}
              >
                <div
                  className="mini_card_icon"
                  style={{
                    backgroundColor: "#9051986E",
                  }}
                >
                  <img src={icon5} />
                </div>
              </div>
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Class Lowest
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>15</h5>
                </div>
              </div>
            </Col>
          </div>
          <div className="analytics_mini_cards_section">
            <Col className="mini_card">
              <div
                className="mini_card_layer"
                style={{ justifyContent: "flex-start" }}
              >
                <div
                  className="mini_card_icon"
                  style={{
                    backgroundColor: "#FA582E26",
                  }}
                >
                  <img src={icon6} />
                </div>
              </div>
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Total Marks
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>60</h5>
                </div>
              </div>
            </Col>
          </div>
        </Row>
        {/*Chapterwise*/}
        {/*First Layer having Bar Graph and Pie Chart*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Test Status Of All Students</b>
            </h5>
            <p>Total Questions - 60</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!ch_loader ? (
                ch_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={SampleData2}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    {/* <Legend
                      verticalAlign="bottom"
                      align="center"
                      layout="horizontal"
                    /> */}
                    <XAxis dataKey="name" />
                    {/* <Label value="Chapters" offset={11} position="bottom" /> */}
                    {/* </XAxis> */}
                    <YAxis>
                      <Label
                        value="Students"
                        // offset={20}
                        position={isSmallScreen ? "" : "insideLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {ch_data ? (
                      <Brush
                        dataKey="name"
                        height={10}
                        stroke="#ddd"
                        // endIndex={isSmallScreen ? 1 : 4}
                      />
                    ) : (
                      ""
                    )}
                    <Bar dataKey="value" fill="#00a0fc" maxBarSize={75}>
                      {SampleData2.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={barColors[index % 20]}
                        />
                      ))}
                      <LabelList dataKey="value" position="top" />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
          <div className="analytics_pie_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Students Test Status %</b>
            </h5>
            <p>Total Questions - 60</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!q_loader ? (
                //   ! to be removed
                !q_data[0].value === 0 &&
                q_data[1].value === 0 &&
                q_data[2].value === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <PieChart>
                    <Pie
                      data={SampleData2}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      dataKey="value"
                      nameKey="name"
                    >
                      {SampleData2.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                          style={{ boxShadow: "100px 200px 3px 0px #00000040" }}
                        />
                      ))}
                    </Pie>
                    <Legend
                      verticalAlign="middle"
                      align="right"
                      layout="vertical"
                      height={56}
                    />
                  </PieChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
            <div className="analytics_card_list">
              <ul style={{ color: "#FF8989" }}>
                <li>
                  <span style={{ color: "black" }}>
                    <b>Total Questions</b>
                  </span>{" "}
                  {attemptStatusTotalQ}
                </li>
                <li>
                  <span style={{ color: "black" }}>
                    <b>Chapter</b>{" "}
                  </span>
                  {numChapters}
                </li>
              </ul>
            </div>
          </div>
        </Row>
        {/*Second Layer having Bar Graphs of Difficulty Level and Cognitive Level*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Questions Report Of All Students</b>
            </h5>
            <p>Total Questions - 60</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!ch_loader ? (
                ch_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={SampleData2}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    {/* <Legend
                      verticalAlign="bottom"
                      align="center"
                      layout="horizontal"
                    /> */}
                    <XAxis dataKey="name" />
                    {/* <Label value="Chapters" offset={11} position="bottom" /> */}
                    {/* </XAxis> */}
                    <YAxis>
                      <Label
                        value="Students"
                        // offset={20}
                        position={isSmallScreen ? "" : "insideLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {ch_data ? (
                      <Brush
                        dataKey="name"
                        height={10}
                        stroke="#ddd"
                        // endIndex={isSmallScreen ? 1 : 4}
                      />
                    ) : (
                      ""
                    )}
                    <Bar dataKey="value" fill="#00a0fc" maxBarSize={75}>
                      {SampleData2.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={barColors[index % 20]}
                        />
                      ))}
                      <LabelList dataKey="value" position="top" />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
          <div className="analytics_pie_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Students Test Status %</b>
            </h5>
            <p>Total Questions - 60</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!q_loader ? (
                //   ! to be removed
                !q_data[0].value === 0 &&
                q_data[1].value === 0 &&
                q_data[2].value === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <PieChart>
                    <Pie
                      data={SampleData2}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      dataKey="value"
                      nameKey="name"
                    >
                      {SampleData2.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                          style={{ boxShadow: "100px 200px 3px 0px #00000040" }}
                        />
                      ))}
                    </Pie>
                    <Legend
                      verticalAlign="middle"
                      align="right"
                      layout="vertical"
                      height={56}
                    />
                  </PieChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
            <div className="analytics_card_list">
              <ul style={{ color: "#FF8989" }}>
                <li>
                  <span style={{ color: "black" }}>
                    <b>Total Questions</b>
                  </span>{" "}
                  {attemptStatusTotalQ}
                </li>
                <li>
                  <span style={{ color: "black" }}>
                    <b>Chapter</b>{" "}
                  </span>
                  {numChapters}
                </li>
              </ul>
            </div>
          </div>
        </Row>
        {/*Third Layer having Bar Graphs of Learning Strength and Learning Gap*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Difficulty Level Questions Report</b>
            </h5>
            <p>Total Questions - 60</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!ch_loader ? (
                ch_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={SampleData}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="bottom"
                      align="center"
                      layout="horizontal"
                      // height={36}
                    />
                    <XAxis dataKey="name" />
                    {/* <Label value="Chapters" offset={11} position="bottom" /> */}
                    {/* </XAxis> */}
                    <YAxis>
                      <Label
                        value="Average Correct Answers"
                        // offset={20}
                        position={isSmallScreen ? "" : "insideBottomLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {ch_data ? (
                      <Brush
                        dataKey="name"
                        height={10}
                        stroke="#ddd"
                        // endIndex={isSmallScreen ? 1 : 4}
                      />
                    ) : (
                      ""
                    )}
                    <Bar
                      dataKey="Completed"
                      stackId="a"
                      fill=" #1190C4"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList fill="#000" />
                    </Bar>
                    <Bar
                      dataKey="In Progress"
                      stackId="a"
                      fill="#C56468"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey="In Progress"
                        position="inside"
                        fill="#000"
                      />
                    </Bar>
                    <Bar
                      dataKey="Incomplete"
                      stackId="a"
                      fill=" #C4C4C459"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey="Incomplete"
                        position="inside"
                        fill="#000"
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
          <div className="analytics_bar_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Cognitive Level Questions Report</b>
            </h5>
            <p>Total Questions - 60</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!ch_loader ? (
                ch_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={SampleData}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="bottom"
                      align="center"
                      layout="horizontal"
                      // height={36}
                    />
                    <XAxis dataKey="name" />
                    {/* <Label value="Chapters" offset={11} position="bottom" /> */}
                    {/* </XAxis> */}
                    <YAxis>
                      <Label
                        value="Average Correct Answers"
                        // offset={20}
                        position={isSmallScreen ? "" : "insideBottomLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {ch_data ? (
                      <Brush
                        dataKey="name"
                        height={10}
                        stroke="#ddd"
                        // endIndex={isSmallScreen ? 1 : 4}
                      />
                    ) : (
                      ""
                    )}
                    <Bar
                      dataKey="Completed"
                      stackId="a"
                      fill=" #1190C4"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList fill="#000" />
                    </Bar>
                    <Bar
                      dataKey="In Progress"
                      stackId="a"
                      fill="#C56468"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey="In Progress"
                        position="inside"
                        fill="#000"
                      />
                    </Bar>
                    <Bar
                      dataKey="Incomplete"
                      stackId="a"
                      fill=" #C4C4C459"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey="Incomplete"
                        position="inside"
                        fill="#000"
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        {/* Fourth Layer */}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card">
            <h5>
              <b>Learning Strength &amp; Gap Of Grade</b>
            </h5>
            <p>Total Questions - 60</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!clr_loader ? (
                clr_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    className="analytics_bar_grid"
                    width={600}
                    height={400}
                    data={SampleData4}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name" />
                    <YAxis>
                      <Label
                        value="Strength &amp; Gap (%)"
                        offset={10}
                        position={isSmallScreen ? "" : "insideLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {clr_data ? (
                      <Brush
                        dataKey="name"
                        height={10}
                        stroke="#ddd"
                        // startIndex={isSmallScreen ? 1 : 0}
                        // endIndex={isSmallScreen ? 1 : 1}
                      />
                    ) : (
                      ""
                    )}
                    <Bar
                      stackId="a"
                      maxBarSize={75}
                      dataKey="Knowledge"
                      fill="#027EB1"
                    >
                      <LabelList />
                    </Bar>
                    <Bar
                      stackId="a"
                      maxBarSize={75}
                      dataKey="Understanding"
                      fill="#C96862"
                    >
                      <LabelList />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        {/*Class Report Table*/}
        <div className="table_container">
          <div
            className="table_title_section"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {isSmallScreen ? (
              <h5
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  marginLeft: "0.5%",
                  marginTop: "5%",
                }}
              >
                Class Report
              </h5>
            ) : (
              <h2
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  marginLeft: "0.5%",
                  marginTop: "0.5%",
                }}
              >
                Class Report
              </h2>
            )}
            {isSmallScreen ? (
              <img
                onClick={exportCsv}
                style={{
                  cursor: "pointer",
                  // marginLeft: 5,
                  transform: "scale(0.7)",
                }}
                src={ExportButton}
              />
            ) : (
              <img
                onClick={exportCsv}
                style={{
                  cursor: "pointer",
                  marginLeft: 5,
                }}
                src={ExportButton}
              />
            )}
            <div className="search_container">
              <SearchBar search={searchValue} setSearch={setSearchValue} />
            </div>
          </div>

          <ClassReport rows={tableData} loading={table_loader} />
        </div>
      </Container>
    </>
  );
};

export default OnlineTest2;
