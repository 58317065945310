import React from 'react';
import { Button, IconButton, TextField } from '@material-ui/core';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { useState, useEffect } from 'react';
import Option from './Options';

function Question({ question, index, correctAnswers }) {
  console.log(question);

  return (
    <>
      <div className='border rounded p-3 mb-4'>
        <div className='row mb-3'>
          <div className='col-sm-8 question_field'>
            <p className='question_question'>
              Q{index + 1 + '. ' + question.question}
            </p>
            {/* <input
                  accept="image/*"
                  style={{ display: "none" }}
                  type="file"
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <ImageOutlinedIcon />
                  </IconButton>
                </label> */}
          </div>
          <div className='col-sm-4'>
            {/* <span className="ml-auto closeQuestionBtn">
                  <button type="button" className="btn-close"></button>
                </span> */}
            {/* <select className='form-select' value={question.questionType}>
              <option value='multipleChoice'>Multiple Choice</option>
              <option value='shortAnswer'>Short Answer</option>
              <option value='paragraph'>Paragraph</option>
            </select> */}
          </div>
        </div>

        <div className=' mb-3'>
          {question.questionType === 'mcq' &&
            question.options.map((option, index) => (
              <Option
                option={option}
                question={question}
                index={index}
                correctAnswers={correctAnswers}
              ></Option>
            ))}

          {question.questionType === 'short' && (
            <textarea className='form-control' rows='2'></textarea>
          )}

          {question.questionType === 'paragraph' && (
            <textarea className='form-control' rows='3'></textarea>
          )}
        </div>

        {/* {answerType === "multipleChoice" && (
              <Button variant="contained" size="small">
                Add Option
              </Button> */}
      </div>
    </>
  );
}

export default Question;
