import "./Panel.css";

function TabButton(props) {
  return (
    <div
      onClick={props.changeTab}
      className={`tab ${props.active ? "active" : ""}`}
    >
      {props.label}
    </div>
  );
}

function Panel(props) {
  return (
    <div className="panel" style={{ ...props.style }}>
      <div className="panel-tabs" style={{ ...props.tabstyles }}>
        {props.tabs.map((tab, index) => (
          <TabButton
            key={index}
            changeTab={() => (tab.click ? tab.click() : props.changeTab(index))}
            active={tab.active}
            label={tab.label}
          />
        ))}
      </div>
      <div ref={props.parentRef} className="panel-pages">
        {props.children}
      </div>
    </div>
  );
}

export default Panel;
