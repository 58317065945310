import React from "react";
import "./Footer.css";
import MainImage from "./Images/footer.png";
import FacebookIcon from "./Images/fb.svg";
import InstagramIcon from "./Images/ig.svg";
import LinkedinIcon from "./Images/lk.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="upper">
        <div className="info">
          <img src={MainImage} className="info-img" alt="" />
          <span>
            <Link to="#">
              <img src={InstagramIcon} alt="insta" id="icon1" />
            </Link>
            <Link to="#">
              <img src={FacebookIcon} alt="facebook" />
            </Link>
            <Link to="#">
              <img src={LinkedinIcon} alt="linkedin" />
            </Link>
          </span>
        </div>
        <div className="links">
          <span>
            <p className="link-heading">Community</p>
            <Link to="#">Create Account</Link>
          </span>
          <span>
            <p className="link-heading">Support</p>
            <Link to="#">Terms & Conditions</Link>
            <Link to="#">Privacy Policy</Link>
            <Link to="#">Copyright Isuue</Link>
            <Link to="#">Get Help</Link>
          </span>
          <span>
            <p className="link-heading">Join Us</p>
            <Link to="#">Student</Link>
            <Link to="#">Schools</Link>
            <Link to="#">Contact Us</Link>
            <Link to="#">Careers</Link>
          </span>
        </div>

        <div className="downloads">
          <p className="link-heading">Download App</p>
          <Link to="#">Apple ios</Link>
          <Link to="#">Google Play</Link>
        </div>
      </div>

      <div className="lower">
        <p>Copyright © 2010-2019 2xCell</p>
      </div>
    </footer>
  );
}

export default Footer;
