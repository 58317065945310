import "./LessonPlans.css";

function LessonPlan(props) {
  return (
    <div
      className="lessonPlan lessonPlan-wrapper"
      onClick={() =>
        (window.location.href = `/lessonplans/${
          props.plan?._id
        }?action=preview&grade=${props.plan?.sectionId?.grade || ""}-${
          props.plan?.sectionId?.section
        }`)
      }
    >
      <h6>Lesson Plan</h6>
      <div className="plan-detail">
        <p>{`${props.plan.subjectId?.name || ""} > ${
          props.plan.chapterId?.name || ""
        } > ${props.plan.topicId?.name || ""}`}</p>

        <span className="plan-grade">{`Class ${
          props.plan?.sectionId?.grade || ""
        }-${props.plan?.sectionId?.section}`}</span>
      </div>
    </div>
  );
}

export default LessonPlan;
