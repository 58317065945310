import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import "@react-pdf-viewer/core/lib/styles/index.css";
import { downloadFile } from "../../../utils/tools";

export default function PDFViewer(props) {
  const history = useHistory();
  const [downloading, setDownloading] = useState(false);

  async function downloadPDF() {
    if (downloading) return;
    setDownloading(true);
    const pdfUrl = props.location?.useProps?.fileUrl;
    const filename = pdfUrl.split("/").pop().trim();
    await downloadFile(pdfUrl, filename)
      .catch(console.log)
      .finally(() => setDownloading(false));
  }

  useEffect(() => {
    const pdfUrl = props.location?.useProps?.fileUrl;
    if (!pdfUrl) {
      history.goBack();
    }
  }, [props.location]);

  const isMobile = useMemo(() => {
    return document.body.clientWidth <= 750;
  }, []);

  return (
    <Fragment>
      {/*
       */}
      {!isMobile ? (
        <div
          style={{
            width: "90%",
            height: "90%",
            margin: "auto",
            marginTop: "1rem",
            paddingTop: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <iframe
            title="PDF Viewer"
            id="pdf_iframe"
            src={props.location?.useProps?.fileUrl}
            width="90%"
            height="800px"
          ></iframe>
        </div>
      ) : (
        <div>
          <button
            style={{
              textAlign: "center",
              padding: "5px 15px",
              borderRadius: "8px",
              fontSize: "1rem",
              border: "transparent",
              background: "rgba(92, 224, 210, 1)",
              margin: "50px auto 0 5%",
            }}
            onClick={downloadPDF}
          >
            {downloading ? "Downloading..." : "Download"}
          </button>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div id="pdf-viewer">
              <Viewer
                defaultScale={1}
                fileUrl={props.location?.useProps?.fileUrl}
              />
            </div>
          </Worker>
        </div>
      )}
    </Fragment>
  );
}
