import "./authorQues.css";
import { useMemo, useState } from "react";
// import { useParams } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import Dropdown from "react-bootstrap/Dropdown";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import attachImg from "../../images/attach.svg";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import QuestionMarks from "../QuestionMarks/QuestionMarks";
import { validateFile, validateThumbnail } from "../../utils/helper";
import { useAlert } from "react-alert";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import { ReactComponent as Loader } from "../../images/Loader.svg";
import { useParams } from "react-router-dom";
export default function AuthorQues({
  selSubjectId,
  selChapterId,
  selTopicId,
  selSectionId,
  setAuthorQues,
  handleRemoveClickCard,
  index,
  initial,
  setArrQues,
  isMarks,
  setReviewQuestion,
  checkreview,
  topicsSel,
  setlibauth,
  isTopic = true,
  resources = false,
}) {
  console.log("topicsSel", topicsSel);
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [inputList, setInputList] = useState([]);
  const chapId = useParams()?.chapterId;
  const [questionText, setQuestionText] = useState("");
  const [authorQuestionFormData, setAuthorQuestionFormData] = useState(
    new FormData()
  );
  const [correctAnswer, setCorrectAnswer] = useState({});
  const [questionType, setQuestionType] = useState("Choose Question Type");
  const [difficulty, setDifficulty] = useState("Difficulty");
  const [cognitive, setCognitive] = useState("Cognitive");
  const [topic, setTopic] = useState({ name: "Topic", id: "" });
  const [visible, setVisible] = useState("Visible To");
  const [authorQuestiontMessage, setAuthorQuestiontMessage] = useState("");
  const [marks, setMarks] = useState(0);
  const [authorQuesContentLoader, setAuthorQuesContentLoader] = useState(false);

  const chapterId = useMemo(() => {
    if (typeof selChapterId === "string") return [selChapterId];
    return selChapterId;
  }, [selChapterId]);
  const topicId = selTopicId;
  const moduleId = localStorage.getItem("moduleId");
  const subjectId = selSubjectId;
  const sectionId = selSectionId;
  const authToken = localStorage.getItem("authToken");
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value, id } = e.target;

    const list = [...inputList];
    list[index][name] = value;
    list[index]["optionId"] = id;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const { userDetails } = useContext(GlobalContext);
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { optionId: "", value: "" }]);
  };

  const handleSelect1 = (e) => {
    inputList.length = 0;
    setQuestionType(e);
  };
  const handleSelect2 = (e) => {
    setDifficulty(e);
  };
  const handleSelect3 = (e) => {
    setCognitive(e);
  };
  const handleSelect4 = (e) => {
    setVisible(e);
  };

  const labelDropdown = {
    mcq: "MCQ",
    mmcq: "MMCQ",
    oneword: "One Word",
    correctstatement: "True False",
    fillup: "Fill in the Blanks",
  };

  const handleCorrectAnswer = (e) => {
    const [id, value] = e.target.id.split("_");
    if (!e.target.checked) return;
    if (["mcq", "correctstatement"].includes(questionType)) {
      setCorrectAnswer({ [questionType]: id });
    } else {
      setCorrectAnswer({
        [questionType]: [
          ...(correctAnswer[questionType] ? correctAnswer[questionType] : []),
          id,
        ],
      });
    }
  };
  const handleSubmit = async () => {
    const isFillUp = ["oneword", "fillup"].includes(questionType);
    console.log("CHAPTER ID", chapterId);

    console.log(chapterId, chapId);

    if (chapterId.length > 1 && !resources) {
      alert.error("You cannot add question as you selected multiple chapter");
      return;
    }
    if (isFillUp) {
      inputList.forEach((x) => {
        if (correctAnswer[questionType]) {
          correctAnswer[questionType].push({
            blankId: x.optionId,
            answer: x.value,
          });
          setCorrectAnswer(correctAnswer);
        } else {
          correctAnswer[questionType] = [
            { blankId: x.optionId, answer: x.value },
          ];
          setCorrectAnswer(correctAnswer);
        }
      });
    }
    setAuthorQuesContentLoader(true);

    if (
      (questionType === "mmcq" || questionType === "mcq") &&
      inputList.length < 4
    ) {
      alert.error("Please Create four options");
      setAuthorQuestionFormData(new FormData());
      setQuestionText("");
      setQuestionType("Choose Question Type");
      setInputList([{ optionId: "", value: "" }]);
      setCorrectAnswer({});
      // setMarks(0);
      setCognitive("Cognitive");
      setDifficulty("Difficulty");
      setVisible("Visible To");
      setAuthorQuesContentLoader(false);
      return null;
    }
    if (questionType === "correctstatement" && inputList.length < 2) {
      alert.error("Please Create two options");
      setAuthorQuesContentLoader(false);
      setAuthorQuestionFormData(new FormData());
      setQuestionText("");
      setQuestionType("Choose Question Type");
      setInputList([{ optionId: "", value: "" }]);
      setCorrectAnswer({});
      // setMarks(0);
      setCognitive("Cognitive");
      setDifficulty("Difficulty");
      setVisible("Visible To");
      return null;
    }
    if (!isFillUp && questionType != "Choose Question Type") {
      let c = 0;
      inputList.forEach((x) => {
        if (x.value === "") c = c + 1;
      });
      if (c > 0) {
        alert.error(`Please fill ${c} more field`);
        setAuthorQuesContentLoader(false);
        setAuthorQuestionFormData(new FormData());
        setQuestionText("");
        setQuestionType("Choose Question Type");
        setInputList([{ optionId: "", value: "" }]);
        setCorrectAnswer({});
        // setMarks(0);
        setCognitive("Cognitive");
        setDifficulty("Difficulty");
        setVisible("Visible To");
        return null;
      }
    }

    if (
      questionType === "mcq" ||
      questionType === "correctstatement" ||
      questionType === "mmcq"
    ) {
      if (correctAnswer[questionType] === undefined) {
        alert.error("Choose one Option");
        setAuthorQuesContentLoader(false);
        return null;
      }
    }
    if (isFillUp && questionType != "Choose Question Type") {
      let c = 0;
      correctAnswer[questionType].forEach((x) => {
        if (x.answer === "") c = c + 1;
      });
      if (c > 0) {
        alert.error(`Please fill ${c} field`);
        setAuthorQuesContentLoader(false);
        setAuthorQuestionFormData(new FormData());
        setQuestionText("");
        setQuestionType("Choose Question Type");
        setInputList([{ optionId: "", value: "" }]);
        setCorrectAnswer({});
        // setMarks(0);
        setCognitive("Cognitive");
        setDifficulty("Difficulty");
        setVisible("Visible To");
        return null;
      }
    }
    const question = {
      question: questionText,
      questionType: questionType,
      options: isFillUp
        ? inputList.map((x) => ({ optionId: x.optionId, value: "" }))
        : inputList,
      correctAnswers: correctAnswer,
      // "marks": marks,
      cognitive: cognitive,
      difficulty: difficulty,
      visibilityStatus: visible,
    };
    chapterId &&
      authorQuestionFormData.append(
        "chapterId",
        !resources ? chapterId[0] : chapId
      );
    if (topic.id) {
      topic.id && authorQuestionFormData.append("topicId", topic.id);
    } else {
      topicId && authorQuestionFormData.append("topicId", topicId);
    }

    authorQuestionFormData.append("question", JSON.stringify(question));
    setAuthorQuestionFormData(authorQuestionFormData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/sections/${sectionId}/subjects/${subjectId}/questions`,
        authorQuestionFormData,
        {
          headers: {
            // 'content-type': 'multipart/form-data',
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        console.log(response);
        setAuthorQuestiontMessage("Question Added.");
        // getOwnAssessment();
        setArrQues(response.data.data.question);

        if (checkreview) {
          setReviewQuestion((arr) => [
            ...arr,
            { ...response.data.data.question, marks: marks },
          ]);
        }
        setAuthorQuestionFormData(new FormData());
        //   window.document.location.reload();
      }
    } catch (error) {
      if (error.response.data.status === "fail") {
        setAuthorQuestiontMessage(error.response.data.message);
      } else {
        setAuthorQuestiontMessage("Try Again.");
      }
    }
    setAuthorQuesContentLoader(false);
    setAuthorQuestionFormData(new FormData());
    setQuestionText("");
    setQuestionType("Choose Question Type");
    setInputList([{ optionId: "", value: "" }]);
    setCorrectAnswer({});
    // setMarks(0);
    setCognitive("Cognitive");
    setDifficulty("Difficulty");
    setVisible("Visible To");
    // setTimeout(function(){ setAuthorQues(false) }, 1000);
  };
  return (
    <div className="container my-4">
      <>
        <div className="authorQues mb-3">
          <div className="row">
            <div className="col-md-6">
              <div className="input-group mb-3 input-attach-group">
                <textarea
                  className="form-control textQues"
                  value={questionText}
                  rows="2"
                  onChange={(e) => setQuestionText(e.target.value)}
                  placeholder="Question Text"
                  aria-label="Question Text"
                  aria-describedby="button-addon2"
                  disabled={
                    questionType === "Choose Question Type" ? true : false
                  }
                />
                <label htmlFor="Quesimages">
                  <AttachFileIcon className="attach_icon" />
                </label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="Quesimages"
                  onChange={(e) => {
                    setAuthorQuesContentLoader(true);
                    if (validateThumbnail(e.target.files[0])) {
                      authorQuestionFormData.append(
                        "questionImg",
                        e.target.files[0]
                      );
                      setAuthorQuestionFormData(authorQuestionFormData);
                      setAuthorQuesContentLoader(false);
                      alert.success("Question Image Uploaded");
                    } else {
                      e.target.value = null;
                      setAuthorQuesContentLoader(false);
                      alert.error("Please select valid file");
                    }
                  }}
                />
              </div>
              {inputList.map((x, i) => (
                <div className="add_option_section">
                  <div className="form-check styleRadio">
                    <input
                      className="form-check-input"
                      type={
                        questionType === "mcq" ||
                        questionType === "correctstatement"
                          ? "radio"
                          : "checkbox"
                      }
                      name={
                        questionType === "mcq" ||
                        questionType === "correctstatement"
                          ? "mcq"
                          : ""
                      }
                      id={`op${i + 1}_${x.value}`}
                      onClick={(e) => handleCorrectAnswer(e)} //questiontype change
                      checked={
                        questionType === "fillup" || questionType === "oneword"
                          ? true
                          : null
                      }
                    />
                  </div>
                  <div className="input-group mb-3 input-attach-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={
                        questionType === "fillup" || questionType === "oneword"
                          ? `blank ${i + 1}`
                          : `option ${i + 1}`
                      }
                      name="value"
                      id={`op${i + 1}`}
                      value={x.value}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                    <label htmlFor={`image${i + 1}`}>
                      <AttachFileIcon className="attach_icon" />
                    </label>
                    <input
                      style={{ display: "none" }}
                      id={`image${i + 1}`}
                      // name="file-input"
                      type="file"
                      onChange={(e) => {
                        if (validateThumbnail(e.target.files[0])) {
                          authorQuestionFormData.append(
                            `op${i + 1}`,
                            e.target.files[0]
                          );
                          setAuthorQuestionFormData(authorQuestionFormData);
                          alert.success(`Image ${i + 1} Uploaded`);
                        } else {
                          e.target.value = null;
                          alert.error("Please select valid file");
                        }
                      }}
                    />
                  </div>
                  {inputList.length !== 1 && (
                    <CloseIcon
                      className="cancelOption"
                      onClick={() => handleRemoveClick(i)}
                    />
                  )}
                </div>
              ))}
              {console.log("leng", inputList.length)}
              {questionType === "mcq" || questionType === "mmcq" ? (
                inputList.length >= 4 ? null : (
                  <button
                    type="button"
                    className="btn btn-add-option"
                    onClick={handleAddClick}
                  >
                    <AddIcon className="ico-block" /> Add Option
                  </button>
                )
              ) : questionType === "oneword" ? (
                inputList.length >= 1 ? null : (
                  <button
                    type="button"
                    className="btn btn-add-option"
                    onClick={handleAddClick}
                  >
                    <AddIcon className="ico-block" /> Add Option
                  </button>
                )
              ) : questionType === "correctstatement" ? (
                inputList.length >= 2 ? null : (
                  <button
                    type="button"
                    className="btn btn-add-option"
                    onClick={handleAddClick}
                  >
                    <AddIcon className="ico-block" /> Add Option
                  </button>
                )
              ) : questionType === "fillup" ? (
                <button
                  type="button"
                  className="btn btn-add-option"
                  onClick={handleAddClick}
                >
                  <AddIcon className="ico-block" /> Add Option
                </button>
              ) : null}
            </div>
            <div className="col-md-6">
              <Dropdown className="option-dropdown mb-3">
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="btn-question-type"
                >
                  {labelDropdown[questionType] || "Choose Question type"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="pra-dropdown edit-dropdown">
                  <Dropdown.Item
                    onSelect={handleSelect1}
                    eventKey="mcq"
                    className="menu_option pra-drop-list edit_option"
                  >
                    {labelDropdown.mcq}
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={handleSelect1}
                    eventKey="mmcq"
                    className="menu_option pra-drop-list edit_option"
                  >
                    {labelDropdown.mmcq}
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={handleSelect1}
                    eventKey="oneword"
                    className="menu_option pra-drop-list edit_option"
                  >
                    {labelDropdown.oneword}
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={handleSelect1}
                    eventKey="correctstatement"
                    className="menu_option pra-drop-list edit_option"
                  >
                    {labelDropdown.correctstatement}
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={handleSelect1}
                    eventKey="fillup"
                    className="menu_option pra-drop-list edit_option"
                  >
                    {labelDropdown.fillup}
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="option-dropdown mb-3">
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="btn-question-type"
                  disabled={
                    questionType && questionType != "Choose Question Type"
                      ? false
                      : true
                  }
                >
                  {difficulty}
                </Dropdown.Toggle>
                <Dropdown.Menu className="pra-dropdown edit-dropdown">
                  <Dropdown.Item
                    onSelect={handleSelect2}
                    eventKey="easy"
                    className="menu_option pra-drop-list edit_option"
                  >
                    easy
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={handleSelect2}
                    eventKey="intermediate"
                    className="menu_option pra-drop-list edit_option"
                  >
                    intermediate
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={handleSelect2}
                    eventKey="hard"
                    className="menu_option pra-drop-list edit_option"
                  >
                    hard
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="option-dropdown mb-3">
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="btn-question-type"
                  disabled={
                    difficulty && difficulty != "Difficulty" ? false : true
                  }
                >
                  {cognitive}
                </Dropdown.Toggle>

                <Dropdown.Menu className="pra-dropdown edit-dropdown">
                  <Dropdown.Item
                    onSelect={handleSelect3}
                    eventKey="knowledge"
                    className="menu_option pra-drop-list edit_option"
                  >
                    knowledge
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={handleSelect3}
                    eventKey="understanding"
                    className="menu_option pra-drop-list edit_option"
                  >
                    understanding
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={handleSelect3}
                    eventKey="application"
                    className="menu_option pra-drop-list edit_option"
                  >
                    application
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={handleSelect3}
                    eventKey="analysis"
                    className="menu_option pra-drop-list edit_option"
                  >
                    analysis
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="option-dropdown mb-3">
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="btn-question-type"
                  disabled={
                    cognitive && cognitive != "Cognitive" ? false : true
                  }
                >
                  {visible}
                </Dropdown.Toggle>

                <Dropdown.Menu className="pra-dropdown edit-dropdown">
                  <Dropdown.Item
                    onSelect={handleSelect4}
                    eventKey="private"
                    className="menu_option pra-drop-list edit_option"
                  >
                    private
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={handleSelect4}
                    eventKey="publicForSchool"
                    className="menu_option pra-drop-list edit_option"
                  >
                    public for school
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={handleSelect4}
                    eventKey="publicForAll"
                    className="menu_option pra-drop-list edit_option"
                  >
                    public for all
                    <div>
                      <input type="radio" name="radio" />
                      <span></span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {topicsSel.length > 1 && (
                <Dropdown className="option-dropdown mb-3">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="btn-question-type"
                    disabled={visible && visible != "Visible" ? false : true}
                  >
                    {topic.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="pra-dropdown edit-dropdown">
                    {isTopic &&
                      topicsSel?.map((x) => (
                        <Dropdown.Item
                          onSelect={() => {
                            setTopic({
                              ...topic,
                              ["name"]: x.name,
                              ["id"]: x.id,
                            });
                          }}
                          eventKey={x.name}
                          className="menu_option pra-drop-list edit_option"
                        >
                          {x.name}
                          <div>
                            <input type="radio" name="radio" />
                            <span></span>
                          </div>
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {/* {isMarks && (
                <div className="authorQues_marks">
                  <span>Marks</span>

                  <span style={{ backgroundColor: "#90E0EF" }}>
                    <input
                      type="number"
                      value={marks}
                      onChange={(e) => setMarks(e.target.value)}
                      className="marks-input-blue"
                    />
                  </span>
                </div>
              )} */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {authorQuesContentLoader ? (
                <div className="text-center">
                  <CircularProgress
                    style={{ color: "#FF075B", fontWeight: "600" }}
                  />
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-add-option"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              )}
              {initial && (
                <div className="opt-edit">
                  <span
                    className="round-btn"
                    onClick={() => handleRemoveClickCard(index)}
                  >
                    <svg
                      width="24"
                      height="26"
                      viewBox="0 0 24 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23 4H16V3C16 2.20435 15.6839 1.44129 15.1213 0.87868C14.5587 0.316071 13.7956 0 13 0H11C10.2044 0 9.44129 0.316071 8.87868 0.87868C8.31607 1.44129 8 2.20435 8 3V4H1C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6H2.09L3.64 21.5C3.76437 22.7376 4.34557 23.8844 5.27012 24.7165C6.19467 25.5486 7.39617 26.0062 8.64 26H15.4C16.6438 26.0062 17.8453 25.5486 18.7699 24.7165C19.6944 23.8844 20.2756 22.7376 20.4 21.5L21.91 6H23C23.2652 6 23.5196 5.89464 23.7071 5.70711C23.8946 5.51957 24 5.26522 24 5C24 4.73478 23.8946 4.48043 23.7071 4.29289C23.5196 4.10536 23.2652 4 23 4ZM10 3C10 2.73478 10.1054 2.48043 10.2929 2.29289C10.4804 2.10536 10.7348 2 11 2H13C13.2652 2 13.5196 2.10536 13.7071 2.29289C13.8946 2.48043 14 2.73478 14 3V4H10V3ZM10.09 19H10C9.74966 19.001 9.50805 18.9081 9.3229 18.7396C9.13775 18.5711 9.02253 18.3393 9 18.09L8.48 12.09C8.45746 11.8248 8.54119 11.5615 8.71279 11.358C8.88439 11.1545 9.12978 11.0275 9.395 11.005C9.66022 10.9825 9.92353 11.0662 10.127 11.2378C10.3305 11.4094 10.4575 11.6548 10.48 11.92L11.01 17.92C11.0209 18.0514 11.0058 18.1837 10.9654 18.3092C10.925 18.4347 10.8602 18.551 10.7747 18.6514C10.6892 18.7518 10.5847 18.8343 10.4672 18.8941C10.3497 18.9539 10.2215 18.9899 10.09 19ZM15 18.09C14.9775 18.3393 14.8623 18.5711 14.6771 18.7396C14.4919 18.9081 14.2503 19.001 14 19H13.91C13.7785 18.9887 13.6505 18.9515 13.5334 18.8905C13.4164 18.8295 13.3126 18.7459 13.228 18.6445C13.1434 18.5432 13.0797 18.4261 13.0405 18.3001C13.0014 18.174 12.9876 18.0414 13 17.91L13.53 11.91C13.5525 11.6448 13.6795 11.3994 13.883 11.2278C14.0865 11.0562 14.3498 10.9725 14.615 10.995C14.8802 11.0175 15.1256 11.1445 15.2972 11.348C15.4688 11.5515 15.5525 11.8148 15.53 12.08L15 18.09Z"
                        fill="#0F93A8"
                      />
                    </svg>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {authorQuestiontMessage && (
          <div className="text-center">
            <p style={{ color: "#FF075B", fontWeight: "600" }}>
              {authorQuestiontMessage}
            </p>
          </div>
        )}
      </>
    </div>
  );
}
