import React from 'react';

const RightArrow = (props) => {
  return (
    <svg
      width='59'
      height='59'
      viewBox='0 0 59 59'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g filter='url(#filter0_d_13:4946)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M29.5 55C14.3122 55 2 42.6878 2 27.5C2 12.3122 14.3122 0 29.5 0C44.6878 0 57 12.3122 57 27.5C57 42.6878 44.6878 55 29.5 55ZM27.0693 19.6182C25.7269 18.2758 25.7269 16.0992 27.0693 14.7568C28.4118 13.4144 30.5883 13.4144 31.9307 14.7568L42.2432 25.0693C43.5856 26.4118 43.5856 28.5883 42.2432 29.9307L31.9307 40.2432C30.5882 41.5856 28.4117 41.5856 27.0693 40.2432C25.7269 38.9007 25.7269 36.7242 27.0693 35.3818L31.5136 30.9375H19.1875C17.289 30.9375 15.75 29.3985 15.75 27.5C15.75 25.6015 17.289 24.0625 19.1875 24.0625L31.5136 24.0625L27.0693 19.6182Z'
          fill={props.color || '#FF574B'}
        />
      </g>
      <defs>
        <filter
          id='filter0_d_13:4946'
          x='0'
          y='0'
          width='59'
          height='59'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_13:4946'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_13:4946'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default RightArrow;