export function groupGrades(assignedSections) {
  const grades = {};
  assignedSections.forEach((section) => {
    if (!grades[section.grade]) {
      grades[section.grade] = [];
    }
    grades[section.grade].push(section);
  });
  return grades;
}

export function groupGradeSections(sections) {
  const grades = {};
  sections.forEach((section) => {
    const [grade, sec] = section.split("-");
    if (!grades[grade]) {
      grades[grade] = [];
    }
    grades[grade].push(sec);
  });
  return grades;
}
