import { useEffect, useState } from "react";

import Module from "../../Module/Module";
import ModalBackdrop from "../../Modal/Backdrop";
import PopupModal from "../../Modal/Modal";
import SearchInput from "../../Input/SearchInput";

import axios from "axios";
import { LessonPlanAPIs } from "../../api";

export function TextCell(props) {
  function saveCellData(value) {
    axios
      .post(
        LessonPlanAPIs.updateLessonPlan(props.userId, props.planId),
        {
          [props.name]: value,
          rowId: props.rowId,
        },
        {
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        if (res.data.status !== "success") throw res.data;
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        //setSavingProgress({ progress: false, value: saveInProgress.value });
      });
  }

  //load cell data
  useEffect(() => {
    saveCellData(props.value);
  }, [props.value]);

  return (
    <div className="text-cell">
      <textarea
        name={props.name}
        value={props.value}
        onChange={(e) => {
          props.editCell(props.rowId, props.name, e.target.value);
        }}
      ></textarea>
    </div>
  );
}

export function ModuleCell(props) {
  const [open, setOpen] = useState(false);
  const [popup, setPopup] = useState({});
  const [search, setSearch] = useState("");
  const [progress, setProgress] = useState(false);
  const [modules, setModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);

  function updateCellModules() {
    if (progress) return;
    setProgress(true);
    axios
      .post(
        LessonPlanAPIs.updateResourceCellData(props.userId, props.planId),
        { rowId: props.rowId, resources: selectedModules },
        {
          headers: {
            token: localStorage.getItem("authToken"),
          },
          validateStatus: () => true,
        }
      )
      .then((res) => {
        if (res.data.status !== "success") throw res.data;
        //sync local state with updatd modules
        props.editCell(props.rowId, "resources", res.data.data.modules);
        //update other states
        setPopup({
          open: true,
          status: "Success",
          message: `Module Updated on row ${props.rowId}`,
        });
        setOpen(false);
        setModules([]);
      })
      .catch((err) => {
        //update other states
        setPopup({
          open: true,
          status: "Error",
          message: err.message,
        });
      })
      .finally(() => setProgress(false));
  }

  function moduleSelector(id) {
    //unselect module
    const selected = selectedModules.filter((sm) => sm.id !== id);
    if (selected.length !== selectedModules.length) {
      setSelectedModules(selected);
      return;
    }
    //select module
    const module = [...props.resources, ...modules].find(
      (m) => `${m.id?._id || ""}` === id
    );
    if (!module) return;
    selectedModules.push({ id: `${module.id?._id}`, grade: module.grade });
    setSelectedModules([...selectedModules]);
  }

  useEffect(() => {
    if (!props.resources || !props.resources.length) return;
    setSelectedModules(
      props.resources.map((res) => {
        return {
          id: `${res?.id?._id}`,
          grade: res.grade,
        };
      })
    );
  }, [props.resources]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!search) {
        setModules([]);
        return;
      }
      axios
        .get(LessonPlanAPIs.searchModules(search), {
          headers: {
            token: localStorage.getItem("authToken"),
          },
          validateStatus: () => true,
        })
        .then((res) => {
          if (res.data.status !== "success") throw res.data;
          setModules(res.data.data.modules.splice(0, 30));
        })
        .catch((err) => {
          console.log(err.message);
          setModules([]);
        });
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <div className="text-cell module-cell">
      {props.resources.length ? (
        <div
          onClick={() => {
            setOpen(true);
          }}
        >
          {props.resources.map((resource, index) => (
            <Module resource={resource} key={index} />
          ))}
        </div>
      ) : (
        <div onClick={() => {
          setOpen(true);
        }} className="module-placeholder">Click Here To Add Module</div>
      )}

      <ModalBackdrop open={open}>
        <div className="module-modal-body">
          <SearchInput
            placeholder="Search Module Here"
            handleInput={(e) => setSearch(e.target.value)}
            value={search}
          />
          <p style={{ fontSize: "0.7rem", margin: "5px 0px" }}>
            Total Results <b>{modules.length}</b>
          </p>
          <div className="module-modal-contents">
            {[...props.resources, ...modules].map((resource, index) => {
              return (
                <Module
                  resource={resource}
                  key={index}
                  checkbox={true}
                  editor={true}
                  selected={selectedModules.find(
                    (sM) => sM.id === `${resource.id?._id}`
                  )}
                  selectModule={() => moduleSelector(resource.id?._id)}
                />
              );
            })}
          </div>
          <div className="buttons">
            <button onClick={() => setOpen(false)}>Close</button>
            <button onClick={updateCellModules}>
              {progress ? "Adding..." : `Add (${selectedModules.length})`}
            </button>
          </div>
        </div>
      </ModalBackdrop>
      <PopupModal
        open={popup.open}
        heading={popup.status}
        message={popup.message}
      >
        <button
          style={{
            color: "#414141",
          }}
          onClick={() => setPopup({})}
        >
          Close
        </button>
      </PopupModal>
    </div>
  );
}
