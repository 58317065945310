import Fillup from "./Fillup";
import Mcq from "./MCQ";
import MMCQ from "./MMCQ";
import OneWord from "./OneWord";
import TrueFalse from "./TrueFalse";

const Question = ({ type, question, ind }) => {
  console.log(question);
  if (type === "mcq") return <Mcq q={question} index={ind} />;
  else if (type === "oneword") return <OneWord q={question} index={ind} />;
  else if (type === "correctstatement")
    return <TrueFalse q={question} index={ind} />;
  // else if (type === "fillup") return <Fillup q={question} index={ind} />;
  else if (type === "mmcq") return <MMCQ q={question} index={ind} />;
  else return <></>;
};

export default Question;
