import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";

import { GlobalContext } from "../../../context/GlobalState";

import Select from "react-select";

import { groupGradeSections } from "../../../utils/groupGrades";

export default function TaskModal(props) {
  const alert = useAlert();
  const selectedClass = window.localStorage.getItem("selectedClass");

  const { userDetails } = useContext(GlobalContext);

  const { register, handleSubmit, reset } = useForm();
  const [loader, setLoader] = useState(false);
  const [grade, setGrade] = useState({ name: "", sections: [] });
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (!selectedClass) return;
    const grade = selectedClass.split("-")[0];
    const grades = groupGradeSections(userDetails.grades);
    setGrade({
      name: grade,
      sections: (grades[grade] || []).map((section, index) => ({
        value: section,
        label: section,
        key: index,
      })),
    });
  }, [selectedClass]);

  const selectSections = (e) => setSections(e);

  const submitModuleTaskTypeForm = async (data) => {
    const { modulePopUpTaskType, moduleEnableSubmissions } = data;
    setLoader(true);
    if (props.sectionId !== "") {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_ORIGIN}/api/v1/tasks`,
          {
            contentId: props.moduleId,
            subjectId: props.subjectId,
            sections: sections.map(
              (section) => `${grade.name}-${section.value}`
            ),
            topicId: props.topicId || props.subjectId,
            contentType: "module",
            folder: "subject",
            taskType: modulePopUpTaskType,
            submission: moduleEnableSubmissions,
          },
          {
            headers: {
              token: authToken,
            },
          }
        );

        if (response.data.status === "success") {
          alert.success("Task Added.");
          reset();
        }
      } catch (error) {
        if (error.response?.data.status === "fail") {
          alert.error(error.response.data.message);
        } else {
          alert.show("Try Again.");
        }
      }
    } else {
      alert.show("Try Again.");
    }

    setLoader(false);
  };

  return (
    <div
      className="modal fade moduleTaskTypeModal mt-5"
      id="moduleTaskTypeModal"
      tabIndex="-1"
      aria-labelledby="moduleTaskTypeModalLabel"
      data-bs-backdrop="static"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content" style={{ width: "400px !important" }}>
          <div className="modal-header">
            <h4 className="modal-title" id="moduleTaskTypeModalLabel">
              Assign Task
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form onSubmit={handleSubmit(submitModuleTaskTypeForm)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm mb-3">
                  <label htmlFor="modulePopUpTaskType">Task Type</label>
                  <select
                    className="form-select"
                    id="modulePopUpTaskType"
                    {...register("modulePopUpTaskType")}
                  >
                    <option defaultValue="">Select Task Type</option>
                    <option value="homework">Home Work</option>
                    <option value="revision task">Revision Task</option>
                    <option value="flipped class">Flipped Class</option>
                  </select>
                </div>
                <div className="col-sm mb-3">
                  <label
                    htmlFor="sectionSelect"
                    className="form-label"
                    style={{ margin: 0 }}
                  >
                    Sections
                  </label>
                  <Select
                    id="sectionSelect"
                    name="sections"
                    {...register("sections")}
                    options={grade.sections}
                    isMulti
                    placeholder="Select"
                    onChange={selectSections}
                  />
                </div>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="moduleEnableSubmissions"
                  {...register("moduleEnableSubmissions")}
                />
                <label
                  className="form-check-label"
                  htmlFor="moduleEnableSubmissions"
                >
                  Enable Submissions
                </label>
              </div>
            </div>

            <div className="mb-3 p-2">
              <div className="row">
                <div className="col d-grid">
                  <button
                    type="button"
                    className="btn moduleTaskTypeModalBtnCancel"
                    data-bs-dismiss="modal"
                  >
                    Clear
                  </button>
                </div>
                <div className="col d-grid">
                  {loader ? (
                    <div className="text-center">
                      <CircularProgress
                        style={{ color: "#FF075B", fontWeight: "600" }}
                      />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="btn moduleTaskTypeModalBtnApply"
                      data-bs-dismiss="modal"
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
