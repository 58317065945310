import React from "react";
import { Col, Row } from "react-bootstrap";
import Stat from "../Common/Stat";
import StatBar from "../Common/StatBar";
import "../QuestionReport.css";
import IsSmallScreen from "../Common/IsSmallScreen";
import { useParams } from "react-router-dom";

import { htmlDecode } from "../../../../utils/parse";

const TrueFalse = ({ q, index }) => {
  console.log(q);
  const opt = ["op1", "op2"];
  const options = {
    op1: { total: 0, correct: false },
    op2: { total: 0, correct: false },
  };
  const markedOpt = {
    op1: { selected: false, correct: false },
    op2: { selected: false, correct: false },
  };

  // Overall Attempts
  opt.forEach((op) => {
    let st = q.statistics.filter(
      (stats) => stats.answer?.correctstatement === op
    );
    options[op].correct = q.questionId.correctAnswers?.correctstatement === op;
    console.log(st);
    options[op].total = st ? st.length : 0;
  });

  // Selected User Attempt
  const params = useParams();
  const user = q.statistics.filter(
    (stats) => stats.userId === params.studentId
  );
  if (user && user.length) {
    opt.forEach((op) => {
      markedOpt[op].selected = user[0].answer.correctstatement?.includes(op);
      markedOpt[op].correct =
        q.questionId.correctAnswers.correctstatement?.includes(op);
    });
  }
  console.log(options);
  console.log("Stats:", options);

  const isSmallScreen = IsSmallScreen;
  const parseString = (str) => {
    console.log(str);
    return new DOMParser()
      .parseFromString(str, "text/html")
      .body.textContent.trimStart();
  };
  return (
    <Row className="analytics_question_layer">
      <Col className="analytics_question_content">
        <Row className="analytics_question_heading">
          <p>Question {index}</p>
          <p className="analytics_question_heading_card">{q?.marks} Marks</p>
          <p className="analytics_question_heading_card">
            {q.questionId.difficulty}
          </p>
          <p className="analytics_question_heading_card">
            {q.questionId.cognitive}
          </p>
        </Row>
        <Row
          className="analytics_question_statement"
          dangerouslySetInnerHTML={{
            __html: htmlDecode(q.questionId.question),
          }}
        />
        <Row>
          {q.questionId.options.map((opt, ind) => {
            return (
              <Row
                className="analytics_question_option"
                style={{
                  fontWeight: 900,
                  color: markedOpt[opt.optionId].selected
                    ? markedOpt[opt.optionId].correct
                      ? " #7EB6A2"
                      : "#FF675E"
                    : "#000",
                }}
                // style={{
                //   background: options[opt.optionId].correct
                //     ? "linear-gradient(90deg, #7EB6A2 32.75%, #FFFFFF 96.35%)"
                //     : "",
                // }}
              >
                {/* <Col className="option_label">{opt.value}</Col> */}
                <Col
                  className="option_content"
                  dangerouslySetInnerHTML={{ __html: htmlDecode(opt.value) }}
                />
              </Row>
            );
          })}
        </Row>
      </Col>
      <Col className="analytics_question_stats">
        {q.questionId.options.map((op, i) => {
          return (
            <Stat>
              {op.value}
              <StatBar
                style={{
                  width:
                    (options[op.optionId].total / q.statistics.length) * 80,
                  height: isSmallScreen ? 15 : 30,
                  backgroundColor: options[op.optionId].correct
                    ? "#7EB6A2"
                    : "",
                }}
              />{" "}
              {options[op.optionId].total} Students
            </Stat>
          );
        })}
      </Col>
    </Row>
  );
};

export default TrueFalse;
