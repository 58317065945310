export function getCorrectAnswer(questionType, correctAnswer, options) {
  let correct = "";
  switch (questionType) {
    case "mcq":
      correctAnswer = correctAnswer?.replace(/[" "]/g, "");
      var correctIndex = options.findIndex(
        (option) => option.optionId === correctAnswer
      );
      correct = `Option ${correctIndex + 1}`;
      break;
    case "oneword":
      correct = correctAnswer[0]?.answer || "";
      break;
    case "correctstatement":
      correctAnswer = correctAnswer?.replace(/[" "]/g, "");
      var correctIndex = options.findIndex(
        (option) => option.optionId === correctAnswer
      );
      correct = options[correctIndex].value;
      break;
    case "mmcq":
      let ans = [];
      for (let csAns of correctAnswer) {
        let newAns = csAns?.replace(/[" "]/g, "");
        var correctIndex = options.findIndex(
          (option) => option.optionId === newAns
        );
        ans.push(`Option ${correctIndex + 1}`);
      }
      correct = ans.join(", ");
      break;
    case "fillup":
      correct = correctAnswer.map((cAns) => cAns.answer).join(", ");
      break;
  }
  return correct;
}

export function checkAnswer(questionType, correctAnswer, answer) {
  let passed = false;
  switch (questionType) {
    case "mcq":
      passed = correctAnswer === answer;
      break;
    case "oneword":
      if (correctAnswer[0]) {
        passed =
          correctAnswer[0].answer.toLowerCase() === answer.answer.toLowerCase();
      }
      break;
    case "correctstatement":
      passed = correctAnswer === answer;
      break;
    case "mmcq":
      var cAnsCount = 0;
      if (correctAnswer.length === answer.length) {
        for (let csOption of correctAnswer) {
          const isCorrect = answer.findIndex(
            (option) => option.optionId === csOption
          );
          cAnsCount += isCorrect > -1 ? 1 : 0;
        }
        passed = correctAnswer.length === cAnsCount;
      } else {
        passed = false;
      }
      break;
    case "fillup":
      for (let ans of answer) {
        const isCorrect = correctAnswer.findIndex(
          (blank) =>
            blank.blankId === ans.blankId &&
            blank.answer.toLowerCase() == ans.answer.toLowerCase()
        );
        passed = isCorrect > -1;
        if (!passed) break;
      }
      break;
  }
  return passed;
}
