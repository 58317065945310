import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import ShutterOpen from "../../images/ShutterDown.svg";
import ShutterClose from "../../images/ShutterUp.svg";

const DropDownShutter = ({ dropdown, clicked, setDropDown }) => {
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });
  const Shutter = styled.div`
    position: ${(props) => (clicked ? "absolute" : "relative")};
    display: flex;
    justify-content: center;
    z-index: 999;
    width: 78px;
    height: fit-content;
    margin-top: ${(props) => (clicked ? "72px" : "2px")};
    /* -webkit-margin-top-collapse: ${(props) => (clicked ? "72px" : "2px")}; */
    padding: 5px;
    /* padding-left: 1.8rem !important; */
    background-color: ${(props) => (clicked ? "#DCFFFB" : "#5ce0d2")};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    img {
      transition: all 0.3s ease;
    }
  `;
  return (
    <Shutter
      style={{ display: isSmallScreen ? "" : "none" }}
      onClick={() => {
        setDropDown(!dropdown);
      }}
    >
      <img src={dropdown ? ShutterClose : ShutterOpen} color="#5CE0D2" />
    </Shutter>
  );
};

export default DropDownShutter;
