import { Tooltip } from "@material-ui/core";
import "./QuestionReport.css";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import TestList from "../OnlineTest/TestList/TestList";
import Sideboard from "./Sideboard/Sideboard";
import Backbtn from "../../images/Arrow 9.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Moment from "react-moment";
import ExportButton from "../../images/export (1).svg";
import {
  CircularProgress,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import HomeIcon from "../../images/HomeIcon.svg";
import assignImg from "../../images/assign.svg";
import assessmentImg from "../../images/assessment.svg";
import analyticGraphImg from "../../images/analyticGraph.svg";
import Box from "@material-ui/core/Box";
import { GlobalContext } from "../../context/GlobalState";
import DropDownShutter from "../DropDown/DropDown";
import axios from "axios";
import QuestionSection from "./Question Report/QuestionSection";
import QuestionReportTable from "./Tables/QuestionReportTable";
import SearchBar from "../OnlineTest/TestList/SearchBar/SearchBar";
import exportFromJSON from "export-from-json";

const Stat = styled.div`
  display: flex;
  margin-bottom: 5px;
  font-weight: 700;
`;

const StatBar = styled.div`
  padding: 5px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #c4c4c494;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const QuestionReport = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#edfcfa",
      boxShadow: "none",
    },
  }));
  const { userDetails, setUserDetails } = useContext(GlobalContext);
  const params = useParams();
  const [heading, setHeading] = useState("Analytics");
  const [sectionId, setSectionId] = useState(params.sectionId);
  const [studentId, setStudentId] = useState(params.studentId);
  const [subjectValue, setSubjectValue] = useState(params.subjectValue);
  const [chapterValue, setChapterValue] = useState("");
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState(1);
  const [miniCard, setMiniCard] = useState({
    practiced: 0,
    time: 0,
    questionsAttempted: 0,
    rank: 0,
  });
  const [as_loader, setAs_loader] = useState(false);
  const [q_loader, setQ_loader] = useState(false);
  const [attemptStatusTotalQ, setAttemptStatusTotalQ] = useState(0);

  const [q_data, setQ_data] = useState([
    { name: "Correct", value: 0 },
    { name: "Incorrect", value: 0 },
    { name: "Not Attempted", value: 0 },
  ]);

  const [as_data, setAs_data] = useState([]);
  const [question, setQuestion] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const COLORS = ["#1DCD6D", "#9DABDD", "#FF8989"];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });

  const [dropdown, setdropdown] = useState(isSmallScreen ? false : true);

  const handleShutterOpen = () => {
    setdropdown(!dropdown);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeHeading = (val) => {
    setUserDetails({ ...userDetails, selectedTab: val, pathName: {} });
    history.goBack();
    setHeading(val);
  };

  const fetchMiniCardData = async () => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/practiceanalytics/sections/${sectionId}/subjects/${subjectValue}/students/${studentId}/practicestatus`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        const newMini_card = {
          practiced: 0,
          time: 0,
          questionsAttempted: 0,
          rank: 0,
        };
        response.data.data.sessions.forEach((element) => {
          newMini_card.practiced += element.practiced;
          newMini_card.time += element.timeSpend;
          newMini_card.questionsAttempted += element.questionsAttempted;
          newMini_card.rank += element.rank;
        });
        // setNumChapters(response.data.data.questions.length);
        setMiniCard(newMini_card);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAverageScore = async () => {
    setAs_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_RANDOMIZE_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/scores`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      console.log("Res", response);

      if (response.data.status === "success") {
        setAs_data([]);
        console.log("Res", response);
        var new_data = response.data.data.scores.map((e) => {
          return {
            name: e._id ? e._id.name : "",
            value: e.scores,
          };
        });
        setAs_data(new_data);
      } else {
        return;
      }
    } catch (error) {}
    setAs_loader(false);
  };

  const fetchChapterwiseAttemptStatus = async () => {
    setQ_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionsattemptstatus?student=${studentId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        const newq_data = [
          { name: "Correct", value: 0 },
          { name: "Incorrect", value: 0 },
          { name: "Not Attempted", value: 0 },
        ];
        let totalq = 0;
        response.data.data.questionsAttemptStats.forEach((element) => {
          newq_data[0].value += element.correct;
          newq_data[1].value += element.incorrect;
          newq_data[2].value += element.unattempted;
          totalq = element.correct + element.incorrect + element.unattempted;
        });
        setAttemptStatusTotalQ(totalq);
        setQ_data(newq_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setQ_loader(false);
  };

  const fetchQuestionDetails = async () => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_RANDOMIZE_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questiondetails`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setQuestion(response.data.data.questiondetails);
      }
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTableData = async () => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_RANDOMIZE_ORIGIN}/api/v1/practiceanalytics/sections/${sectionId}/subjects/${subjectValue}/students/${studentId}/questionreports`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      console.log(response);
      if (response.data.status === "success") {
        setTableData(response.data.data.reports);
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    await fetchMiniCardData();
    await fetchAverageScore();
    await fetchChapterwiseAttemptStatus();
    await fetchQuestionDetails();
    await fetchTableData();
  }, []);

  const barColors = ["#7CB4A0", "#ECBE7A", "#B6E4FF", "#9DABDD"];

  const search = () => {
    console.log(tableData);
    let searchData = tableData.filter(
      (data) =>
        data.questionId.question
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        data?.subjectId?.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setTableData(searchData);
  };

  useEffect(() => {
    //when the search box is empty
    if (!searchValue) {
      fetchTableData();
    } else {
      search();
    }
  }, [searchValue]);

  const exportCsv = () => {
    const data = tableData;
    const formatted_data = data.map((element) => {
      return {
        Question: element?.questionId?.question,
        Subject: element?.subjectId?.name,
        Topic: element?.topicId?.name,
        Difficulty: element.difficulty,
        Cognitive: element.cognitive,
        "Time Spent": `${
          Math.floor((element.timeTaken % 3600000) / 60000) +
          "m" +
          ((element.timeTaken / 1000) % 60).toPrecision(2) +
          "s"
        }`,
        "Correct/ Incorrect": `${
          element.status !== "passed" || element.status === "failed"
            ? "Incorrect"
            : "Correct"
        }`,
        Status: `${
          element.status === "unattempted" ? "Unattempted" : "Attempted"
        }`,
      };
    });
    const fileName = "Practice Report";
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data: formatted_data, fileName, exportType });
  };

  return (
    <>
      <section className={isSmallScreen ? "modules my-4" : "modules my-5"}>
        <div
          className={
            isSmallScreen ? "container-fluid dashboard_container" : "container"
          }
        >
          <div className="box">
            <div
              className="upperBox"
              style={{ display: isSmallScreen ? "none" : "" }}
            >
              <div className="alignItem">
                <div className="row">
                  <div className="boxRow">
                    <div className="boxRowLeft">
                      <h1 className="className">
                        {params.class}-{params.section}
                      </h1>
                      <div className="pathDetail">
                        <h2 style={{ fontWeight: "700" }}>
                          Analytics &gt; Practice Assessment
                        </h2>
                        <p style={{ fontWeight: "600", color: "#ffffff" }}>
                          {params.subject}
                        </p>
                        <p style={{ fontWeight: "600", color: "#ff0000" }}>
                          {params.student}
                        </p>
                      </div>
                    </div>
                    <div className="DateBox">
                      <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                        <CalendarTodayIcon />{" "}
                        {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              <Paper className={classes.root}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  style={{
                    background: "#DCFFFB",
                    marginTop: "-12px",
                    display: dropdown ? "" : "none",
                  }}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={"on"}
                >
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={HomeIcon}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Home"
                    {...a11yProps(0)}
                    onClick={() => changeHeading("Dashboard")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={analyticGraphImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Analytics"
                    {...a11yProps(1)}
                    onClick={() => changeHeading("Analytics")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assignImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assign Task"
                    {...a11yProps(2)}
                    onClick={() => changeHeading("Assign Task")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assessmentImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assessment"
                    {...a11yProps(3)}
                    onClick={() => changeHeading("Assessment")}
                  />
                </Tabs>
              </Paper>
            }
            <div
              className="shutter_PS"
              style={{
                marginTop: dropdown ? "0px" : "-15px",
                display: isSmallScreen ? "flex" : "none",
              }}
            >
              <DropDownShutter
                onClick={handleShutterOpen}
                clicked={dropdown}
                dropdown={dropdown}
                setDropDown={setdropdown}
              />
            </div>
          </div>
        </div>
      </section>
      <Container fluid="xl" className="analytics_main_section">
        <Link
          to={{
            pathname: `/dashboard/analytics/practiceAssessment/${params.class}/${params.section}/${params.subject}/${params.student}/${params.studentId}/${params.sectionId}/${params.subjectValue}`,
            state: {
              sectionId: sectionId,
            },
          }}
        >
          <img src={Backbtn} className="back_btn" />
        </Link>
        <Sideboard
          sectionId={sectionId}
          setSectionId={setSectionId}
          subjectValue={subjectValue}
          setSubjectValue={setSubjectValue}
          chapterValue={chapterValue}
        />
        <Row
          className="studentanalytics_mini_cards mx-auto my-5"
          style={{ color: "white" }}
        >
          <div className="student_analytics_mini_cards_section">
            <Col
              className="student_mini_card"
              style={{
                marginLeft: "0px",
                backgroundColor: "#AD73F5",
              }}
            >
              <div>
                <div style={{ textAlign: "center" }}>Practiced Assessment</div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.practiced}</h5>
                </div>
              </div>
            </Col>
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#47CFE9" }}
            >
              <div>
                <div style={{ textAlign: "center" }}>Time</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <h5 style={{ textAlign: "center" }}>
                    {Math.floor(miniCard.time / 3600000)}/
                    {Math.floor((miniCard.time % 3600000) / 60000)}/
                    {Math.floor((miniCard.time / 1000) % 60)}
                  </h5>
                  <h5>Hrs/Min/Sec</h5>
                </div>
              </div>
            </Col>
          </div>
          <div className="student_analytics_mini_cards_section">
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#F3B592" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Total No. of questions Attempted
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.questionsAttempted}</h5>
                </div>
              </div>
            </Col>
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#FF7E7E" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Rank
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.rank}</h5>
                </div>
              </div>
            </Col>
          </div>
        </Row>
        <Row className="questionanalytics_section_layer">
          <div className="question_analytics_card">
            <ResponsiveContainer className="Question_Analytics_Responsive_Container">
              {!as_loader ? (
                as_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    className="analytics_bar_grid"
                    width={600}
                    height={400}
                    data={as_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <XAxis dataKey="name" />
                    <YAxis>
                      <Label
                        value="Average Score"
                        offset={10}
                        position={isSmallScreen ? "" : "insideLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {as_data ? (
                      <Brush
                        dataKey="name"
                        height={10}
                        stroke="#ddd"
                        // endIndex={3}
                      />
                    ) : (
                      ""
                    )}
                    <Bar dataKey="value" fill="#00a0fc" maxBarSize={75}>
                      {as_data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={barColors[index % 4]}
                        />
                      ))}
                      <LabelList dataKey="value" position="top" />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
            <div className="Question_Analytics_Diversion" />
            <ResponsiveContainer className="Question_Analytics_Responsive_Container">
              <PieChart>
                <Pie
                  data={q_data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  dataKey="value"
                  nameKey="name"
                >
                  {q_data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      style={{ boxShadow: "100px 200px 3px 0px #00000040" }}
                    />
                  ))}
                </Pie>
                <Legend
                  verticalAlign="middle"
                  align="right"
                  layout="vertical"
                  height={56}
                />
              </PieChart>
            </ResponsiveContainer>
            <div className="questionanalytics_card_list">
              <ul style={{ color: "#5CE0D2" }}>
                <li>
                  <span style={{ color: "black" }}>
                    <b>Total Questions</b>
                  </span>{" "}
                  {attemptStatusTotalQ}
                </li>
                <li>
                  <span style={{ color: "black" }}>
                    <b>Chapter</b>{" "}
                  </span>
                  05
                </li>
              </ul>
            </div>
          </div>
          {/* <Row className="analytics_question_layer">
            <Col className="analytics_question_content">
            {Question &&
              Question.map((question, i) => {
                return (
                  <>
                  <Row
                  className="analytics_question_heading"
                        style={{ color: "#5CE0D2" }}
                        >
                        {console.log(i)}
                        Question {i + 1}
                        </Row>
                        <Row className="analytics_question_statement">
                        {question.questionId.question}
                        </Row>
                      {question.questionId.options.map((option) => {
                        return (
                          <Row>
                          <Row className="analytics_question_option">
                          <Col className="option_label">
                          {option.optionId}
                          </Col>
                          <Col className="option_content">
                                {option.value}
                                </Col>
                                </Row>
                                </Row>
                        );
                        // style={{
                          //     background:
                          //       "linear-gradient(90deg, #FF675E 32.75%, #FFFFFF 96.35%)",
                          //   }}
                          // 
                          // style={{
                        //     background:
                        //       "linear-gradient(90deg, #7EB6A2 32.75%, #FFFFFF 96.35%)",
                        //   }}
                      })}
                      </>
                  );
                })}
                </Col>
            <Col className="analytics_question_stats">
            <Stat>
            A
            <StatBar
            style={{ width: 80, height: isSmallScreen ? 15 : 30 }}
            />{" "}
            5 Students
            </Stat>
              <Stat>
              B
              <StatBar
              style={{ width: 120, height: isSmallScreen ? 15 : 30 }}
              />{" "}
              15 Students
              </Stat>
              <Stat>
              C
              <StatBar
                  style={{
                    width: 180,
                    height: isSmallScreen ? 15 : 30,
                    backgroundColor: "#7EB6A2",
                  }}
                  />{" "}
                  30 Students
                  </Stat>
                  <Stat>
                  D
                  <StatBar
                  style={{ width: 100, height: isSmallScreen ? 15 : 30 }}
                  />{" "}
                  10 Students
                  </Stat>
                  </Col>
                  </Row>
                  <Row className="analytics_question_layer">
                  <Col className="analytics_question_content">
                  <Row
                  className="analytics_question_heading"
                  style={{ color: "#5CE0D2" }}
                  >
                  Question 2
                  </Row>
                  <Row className="analytics_question_statement">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque
                cupiditate doloribus nesciunt illum rem inventore quibusdam,
                adipisci doloremque autem consequatur similique laudantium odio
                commodi nulla architecto ipsa repudiandae, sint temporibus?
                </Row>
                <Row>
                <Row
                className="analytics_question_fill_in_the_blank"
                  style={{ color: "#47DE85" }}
                  >
                  <u>lorem ipsum dolor sit amet</u>
                  </Row>
                  </Row>
                  <Row
                  className="correct_answer_fill_in_the_blank"
                  style={{ marginBottom: isSmallScreen ? 5 : 0 }}
                  >
                  Correct Answer: lorem ipsum dolor sit amet
                  </Row>
                  </Col>
                  <Col className="analytics_question_stats">
              <Stat>
              <StatBar style={{ width: 180, backgroundColor: "#7EB6A2" }} />{" "}
                40%
                </Stat>
                </Col>
          </Row>
          <Row className="analytics_question_layer">
            <Col className="analytics_question_content">
            <Row
            className="analytics_question_heading"
            style={{ color: "#5CE0D2" }}
            >
            Question 3
              </Row>
              <Row className="analytics_question_statement">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque
                cupiditate doloribus nesciunt illum rem inventore quibusdam,
                adipisci doloremque autem consequatur similique laudantium odio
                commodi nulla architecto ipsa repudiandae, sint temporibus?
                </Row>
                <Row>
                <Row className="analytics_question_option">
                <Col className="option_tof">True</Col>
                </Row>
                <Row className="analytics_question_option">
                <Col className="option_tof" style={{ color: "#FA582E" }}>
                False
                </Col>
                </Row>
                </Row>
                <Row
                className="correct_answer_fill_in_the_blank"
                style={{ marginBottom: isSmallScreen ? 5 : 0 }}
                >
                Correct Answer: True
                </Row>
                </Col>
                <Col className="analytics_question_stats">
                <Stat>
                <StatBar style={{ width: 200, backgroundColor: "#7EB6A2" }}>
                True
                </StatBar>
                40 Students
                </Stat>
                <Stat>
                <StatBar style={{ width: 100 }}>False</StatBar>20 Students
                </Stat>
                </Col>
              </Row> */}
          <QuestionSection question={question} />
        </Row>
        <div className="table_container">
          <div
            className="table_title_section"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {isSmallScreen ? (
              <h5
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  marginLeft: "0.5%",
                  marginTop: "5%",
                }}
              >
                Practice Report
              </h5>
            ) : (
              <h2
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  marginLeft: "0.5%",
                  marginTop: "0.5%",
                }}
              >
                Practice Report
              </h2>
            )}
            {isSmallScreen ? (
              <img
                onClick={exportCsv}
                style={{
                  cursor: "pointer",
                  // marginLeft: 5,
                  transform: "scale(0.7)",
                }}
                src={ExportButton}
              />
            ) : (
              <img
                onClick={exportCsv}
                style={{
                  cursor: "pointer",
                  marginLeft: 5,
                }}
                src={ExportButton}
              />
            )}
            <div className="search_container">
              <SearchBar search={searchValue} setSearch={setSearchValue} />
            </div>
          </div>

          <QuestionReportTable rows={tableData} />
        </div>
      </Container>
    </>
  );
};

export default QuestionReport;
