import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Stat from "../Common/Stat";
import StatBar from "../Common/StatBar";
import "../QuestionReport.css";
import IsSmallScreen from "../Common/IsSmallScreen";
import { useParams } from "react-router-dom";

import { htmlDecode } from "../../../../utils/parse";

const MCQ = ({ q, index }) => {
  console.log(q);
  const opt = ["op1", "op2", "op3", "op4"];
  const options = {
    op1: { total: 0, correct: false },
    op2: { total: 0, correct: false },
    op3: { total: 0, correct: false },
    op4: { total: 0, correct: false },
  };
  const markedOpt = {
    op1: { selected: false, correct: false },
    op2: { selected: false, correct: false },
    op3: { selected: false, correct: false },
    op4: { selected: false, correct: false },
  };

  // Overall Attempts
  opt.forEach((op) => {
    let st = q.statistics.filter((stats) => stats?.answer?.mcq === op);
    options[op].correct = q.questionId?.correctAnswers?.mcq === op;
    console.log(st);
    options[op].total = st ? st.length : 0;
  });

  // Selected User Attempt
  const params = useParams();
  // React.useEffect(()=>{

    const user = q.statistics.filter(
      (stats) => stats.userId === params.studentId
    );
    console.log(user);
    if (user && user.length) {
      opt.forEach((op) => {
        markedOpt[op].selected = user[0]?.answer?.mcq.includes(op);
        markedOpt[op].correct = q.questionId.correctAnswers?.mcq?.includes(op);
      });
    }
  // },[q])

  q.statistics.forEach((e) => {
    let op1 = 0;
    let op2 = 0;
    let op3 = 0;
    let op4 = 0;
    if (e?.answer?.mcq === "op1") {
      op1 = op1 + 1;
    } else if (e?.answer?.mcq === "op2") {
      op2 = op2 + 1;
    } else if (e?.answer?.mcq === "op3") {
      op3 = op3 + 1;
    } else if (e?.answer?.mcq === "op4") {
      op4 = op4 + 1;
    }
  });

  const isSmallScreen = IsSmallScreen;
  const parseString = (str) => {
    return new DOMParser()
      .parseFromString(str, "text/html")
      .body.textContent.trimStart();
  };
  return (
    <Row className="analytics_question_layer">
      <Col className="analytics_question_content">
        <Row className="analytics_question_heading">
          <p>Question {index}</p>
          <p className="analytics_question_heading_card">{q.marks} Marks</p>
          <p className="analytics_question_heading_card">
            {q.questionId.difficulty}
          </p>
          <p className="analytics_question_heading_card">
            {q.questionId.cognitive}
          </p>
        </Row>
        <Row
          className="analytics_question_statement"
          dangerouslySetInnerHTML={{
            __html: htmlDecode(q.questionId.question),
          }}
        />
        <Row>
          {q.questionId.options.map((opt, ind) => {
            return (
              <Row
                className="analytics_question_option"
                style={{
                  background: markedOpt[opt.optionId].selected
                    ? markedOpt[opt.optionId].correct
                      ? "linear-gradient(90deg, #7EB6A2 32.75%, #FFFFFF 96.35%)"
                      : "linear-gradient(90deg, #FF675E 32.75%, #FFFFFF 96.35%)"
                    : "linear-gradient(90deg, #ddd 32.75%, #FFFFFF 96.35%)",
                }}
                // style={{
                //   background: options[opt.optionId].correct
                //     ? "linear-gradient(90deg, #7EB6A2 32.75%, #FFFFFF 96.35%)"
                //     : "",
                // }}
              >
                <Col className="option_label">
                  {String.fromCharCode(65 + ind)}
                </Col>
                <Col
                  className="option_content"
                  dangerouslySetInnerHTML={{ __html: htmlDecode(opt.value) }}
                />
              </Row>
            );
          })}
        </Row>
      </Col>
      <Col className="analytics_question_stats">
        {opt.map((op, i) => {
          return (
            <Stat>
              {String.fromCharCode(65 + i)}
              <StatBar
                style={{
                  width: (options[op].total / q.statistics.length) * 80,
                  height: isSmallScreen ? 15 : 30,
                  backgroundColor: options[op].correct ? "#7EB6A2" : "",
                }}
              />{" "}
              {options[op].total} Students
            </Stat>
          );
        })}
      </Col>
    </Row>
  );
};

export default MCQ;
