import React, { useContext, useEffect, useState } from "react";
import "./myclasses.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Paper from "@material-ui/core/Paper";
import Moment from "react-moment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import axios from "axios";
import { ReactComponent as AnalyticsIcon } from "../../images/analyticGraph.svg";
import { ReactComponent as TaskIcon } from "../../images/assign.svg";
import { ReactComponent as DashboardIcon } from "../../images/Dashboard.svg";
import AssessmentIcon from "../../images/asses.svg";
import LessonImg from "../../images/Lesson.svg";
import WhiteBoardImg from "../../images/whiteBoard.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Assesment from "../MyAssessment/Assesment";
import TaskDashboard from "../Dashboard/TaskDashboard";

import MyClass from "./MyClass";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useMediaQuery } from "react-responsive";
import { GlobalContext } from "../../context/GlobalState";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#edfcfa",
    boxShadow: "none",
    color: "#000000 !important",
  },
  dashboardTabStyle: {
    color: "#10A0B6",
    minWidth: "100px",
    fontWeight: "normal !important",
  },
  bottomSectionStyle: {
    color: "white",
    fontSize: "0.8em",
  },
  table: {
    minWidth: "auto",
    margin: "12px !important",
    width: "97.5% !important",
  },
  tableRow: {
    textDecoration: "none",
    color: "#5CE0D2 !important",
    background: "#0F93A824 !important",
    // padding:"12px !important"
    // '&:hover': {
    //   backgroundColor: '#A6EDE5 !important',
    // },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
function MyClasses() {
  document.title = "2xcell.in";
  const { userDetails } = useContext(GlobalContext);
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [assignedClasses, setAssignedClasses] = useState([]);
  const [assignedSubjects, setAssignedSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const tabList = ["My Grades", "Analytics", "Assign Task", "Assessment"];
  const [assessmentMakeTaskLoader, setAssessmentMakeTaskLoader] =
    useState(false);
  const [section, setSection] = useState("Section");
  const [subjectName, setSubjectName] = useState("Subject");
  const [chapterName, setChapterName] = useState("Chapter");
  const [topicName, setTopicName] = useState("Topic");
  const [select, setSelect] = useState(0);
  const [classCode, setClassCode] = useState([]);
  const [student, setStudent] = useState([]);
  const handleSelectSubject = (e) => {
    setSelectedSubject(e.target.value);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const authToken = localStorage.getItem("authToken");
  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${userDetails.userId}/sections/${classCode?.sectionId._id}/classcodes/students`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          setStudent(response.data.data.students);
          console.log(response.data.data.students, "they are students");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchStudentDetails();
  }, [classCode]);
  console.log("classCode", classCode);
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });
  return (
    <>
      <section
        className={
          isSmallScreen
            ? "myResources my-2 p-lg-0 p-4"
            : "myResources my-5 p-lg-0 p-4"
        }
      >
        <div className="container">
          <div className="box" style={{ display: isSmallScreen ? "none" : "" }}>
            <div className="upperBox col-sm-11 mx-auto">
              <div className="dashboardInfo">
                {/* <div className='col-sm text-center text-sm-start class-subject-text'> */}
                <h1 style={{ fontWeight: "bolder" }}>{tabList[value]}</h1>
                {/* </div> */}
                <div className="DateBox">
                  <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                    <CalendarTodayIcon />{" "}
                    {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MyClass
        select={select}
        setSelect={setSelect}
        setClassCode={setClassCode}
      />
      <div className="class_table">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="Class table">
            <TableHead style={{ color: "#000000 !important" }}>
              <TableRow className={classes.tableRow}>
                <TableCell align="center">S.No</TableCell>
                <TableCell align="center">Student Name</TableCell>
                <TableCell align="center">Date of Registration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {student?.map((st, i) => (
                <TableRow className={classes.tableRow}>
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell align="center">{st.name}</TableCell>
                  <TableCell align="center">
                    {new Date(st.signUpDate).toLocaleDateString("en-GB")}
                  </TableCell>
                </TableRow>
              ))}

              {/* <TableRow style={{ height: 53}}>
                <TableCell colSpan={6} />
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default MyClasses;
