import ModalBackdrop from "./Backdrop";

function PopupModal(props) {
  return (
    <ModalBackdrop open={props.open}>
      <div className="lp-popup-body">
        <h5 className={props.heading}>{props.heading}</h5>
        <p>{props.message}</p>
        <div className="buttons">{props.children}</div>
      </div>
    </ModalBackdrop>
  );
}

export default PopupModal;
