/* Imports */
import "../AssessmentAnalytics/PracticeAssessment.css";
import React, { useContext, useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { useMediaQuery } from "react-responsive";
import { FilterComponent } from "./Sidebar/DropDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  Label,
  YAxis,
  Bar,
  LabelList,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { Container, Dropdown, Modal, Row } from "react-bootstrap";
import ClassReport from "./Tables/ClassReport";
import Sideboard from "./SideboardOT/Sideboard";
import Backbtn from "../../images/Arrow 9.svg";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import exportFromJSON from "export-from-json";
import SearchBar from "../OnlineTest/TestList/SearchBar/SearchBar";
import ExportButton from "../../images/export (1).svg";
import DropDownShutter from "../DropDown/DropDown";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import Moment from "react-moment";
import {
  Button,
  CircularProgress,
  Grid,
  ListItem,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";
import HomeIcon from "../../images/HomeIcon.svg";
import assignImg from "../../images/assign.svg";
import assessmentImg from "../../images/assessment.svg";
import analyticGraphImg from "../../images/analyticGraph.svg";
import Box from "@material-ui/core/Box";
import { GlobalContext } from "../../context/GlobalState";
import CloseIcon from "@material-ui/icons/Close";
import GradeSorter from "../../utils/gradeSorter";
import CalenderImg from "../../images/Calender.svg";
import clockIcon from "../../images/clock.svg";
import { CalenderComponent } from "./Sidebar/DropDown";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useAlert } from "react-alert";
/* End of imports */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const OnlineTestAnalytics = () => {
  /*Instance for Visibility of Topic Graphs */
  const [show, setShow] = useState("none");
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const location = useLocation();
  const params = useParams();
  // const grade = params.class;
  // const section = params.section;
  // const subject = params.subject;
  // localStorage.setItem("gradeSection", `${grade}-${section}`);
  // localStorage.setItem("subject", subject);
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#edfcfa",
      boxShadow: "none",
    },
    root1: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    card: {
      width: "100%",
      borderRadius: "10px",
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
      padding: "20px",
      margin: "20px auto",
    },
    subjectSection: {
      textAlign: "center",
      "& button": {
        border: "none",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        marginBottom: "10px",
        padding: "4px",
        minWidth: "120px",
        color: "white",
      },
      "& p": {
        marginBlockStart: "0px",
        marginBlockEnd: "0px",
        fontSize: "0.8em",
      },
    },
    report: {
      textAlign: "center",
      "& a": {
        backgroundColor: "rgba(29, 205, 109, 0.78)",
        padding: "7px 10px",
        borderRadius: "10px",
        color: "white",
        textDecoration: "none",
        height: "fit-content",
        transition: "0.2s",
        "& span": {
          backgroundColor: "rgba(112, 253, 175, 0.73)",
          color: "white",
          borderRadius: "50%",
          height: "fit-content",
          width: "fit-content",
          padding: "3px",
          verticalAlign: "middle",
        },
        "&:hover": {
          backgroundColor: "rgba(29, 205, 109, 1)",
          color: "white !important",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    report2: {
      textAlign: "center",
      "& a": {
        backgroundColor: "rgba(0, 121, 234, 0.78);",
        padding: "7px 10px",
        borderRadius: "10px",
        color: "white",
        textDecoration: "none",
        height: "fit-content",
        transition: "0.2s",
        "& span": {
          backgroundColor: "rgba(0, 121, 234, 0.73);",
          color: "white",
          borderRadius: "50%",
          height: "fit-content",
          width: "fit-content",
          padding: "3px",
          verticalAlign: "middle",
        },
        "&:hover": {
          backgroundColor: "rgba(0, 121, 234, 1);",
          color: "white !important",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    menu: {
      textAlign: "center",
    },
    contextMenu: {
      "& .MuiList-root": {
        padding: "10px 10px 5px 10px",
      },
      "& .MuiPaper-rounded": {
        borderRadius: "5px !important",
      },
      "& li": {
        color: "white",
        fontSize: "0.9em",
        background: "rgba(180, 107, 130, 0.8)",
        marginBottom: "5px",
        borderRadius: "5px !important",
        transition: "0.3s",
        "&:hover": {
          background: "rgba(180, 107, 130, 1)",
          fontWeight: "bold",
        },
      },
    },
    "@media screen and (max-width: 472px)": {
      card: {
        width: "95%",
        padding: "10px 7px",
      },
      subjectSection: {
        "& button": {
          fontSize: "0.6rem",
          minWidth: "80px",
        },
        "& p": {
          fontSize: "0.6rem",
        },
      },
      report: {
        "& a": {
          fontSize: "0.7rem",
        },
      },
      tableHeading: {
        " & > th": {
          color: "#000 !important",
          fontWeight: "bolder",
          fontSize: "0.6rem",
        },
      },
      "@media screen and (max-width: 1000px)": {
        root: {},
      },
      report2: {
        "& a": {
          fontSize: "0.6rem",
        },
      },
      contextMenu: {
        padding: "5px 5px 3px 5px",
        fontSize: "0.7rem",
        "& li": {
          minHeight: "35px",
        },
      },
    },
  }));
  const { userDetails, setUserDetails } = useContext(GlobalContext);
  const [heading, setHeading] = useState("Analytics");

  const [sectionId, setSectionId] = useState(location.state.sectionId);
  const [subjectValue, setSubjectValue] = useState("");
  const [chapterValue, setChapterValue] = useState("");
  const [attemptStatusTotalQ, setAttemptStatusTotalQ] = useState(0);
  const [numStudents, setNumStudents] = useState(0);
  const [numChapters, setNumChapters] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [ts_total, setTs_total] = useState(0);
  const [ta_total, setTa_total] = useState(0);
  const [qa_total, setQa_total] = useState(0);
  const [qs_total, setQs_total] = useState(0);
  const [exportingData, setExportingData] = useState([]);
  const [filterReports, setFilterReports] = useState({
    name: "",
    subject: "",
    date: "",
    testType: "",
    grade: "",
  });
  const [filterSubjectCards, setFilterSubjectCards] = useState({
    grade: "",
    subject: "",
    date: "",
    topic: "",
    chapter: "",
  });
  const [visible, setVisible] = useState(false);
  const [students, setStudents] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [grade, setAllGrade] = useState([]);
  const [filterByDate, setFilterByDate] = useState({ date: "" });
  const [topics, setTopics] = useState([]);
  // console.log(students);
  console.log(grade);
  /*Instances for Loaders*/
  const [ts_loader, setTs_loader] = useState(false);
  const [tta_loader, setTta_loader] = useState(false);
  const [qs_loader, setQs_loader] = useState(false);
  const [qr_loader, setQr_loader] = useState(false);
  const [qa_loader, setQa_loader] = useState(false);
  const [dclr_loader, setDclr_loader] = useState(false);
  const [dclcr_loader, setDclcr_loader] = useState(false);
  const [lsg_loader, setLsg_loader] = useState(false);
  const [table_loader, setTable_loader] = useState(false);
  const [card_loader, setCard_loader] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [subData, setSubData] = useState([]);

  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState(1);
  const [testId, setTestId] = useState({
    sectionId: "",
    subjectId: "",
    testId: "",
  });

  /*Handling Visibility of Topic Graphs*/
  const handleShow = () => {
    show === "none" ? setShow("unset") : setShow("none");
  };

  /*Test Status All Classes*/
  const [t_status, setT_status] = useState([
    {
      name: "",
      "In Progress": 0,
      Incomplete: 0,
      Completed: 0,
    },
  ]);

  /*Total Tests Assigned */
  const [T_tests, setT_tests] = useState([
    {
      name: "",
      value: 0,
    },
  ]);

  /*Question Report */
  const [qr_data, setQr_data] = useState([
    { name: "Correct", value: 0 },
    { name: "Incorrect", value: 0 },
    { name: "Unattempted", value: 0 },
  ]);

  /*Questions Assigned */
  const [qa_data, setQa_data] = useState([
    {
      name: "",
      value: 0,
    },
  ]);

  /*Questions Status */
  const [q_status, setQ_status] = useState([
    {
      name: "",
      Correct: 0,
      Incorrect: 0,
      Unattempted: 0,
    },
  ]);

  /*Learning Strength and Gap */
  const [lsg_data, setLsg_data] = useState([
    {
      name: "",
      Gap: 0,
      Strength: 0,
    },
  ]);

  /*Difficulty Level Questions */
  const [dlq_data, setDlq_data] = useState([
    {
      name: "",
      Easy: 0,
      Medium: 0,
      Difficult: 0,
    },
  ]);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 320) {
      setVisible(true);
    } else if (scrolled <= 320) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);

  /*Cognitive Level Questions */
  const [clq_data, setClq_data] = useState([
    {
      name: "",
      Knowledge: 0,
      Understanding: 0,
      Application: 0,
      Analysis: 0,
    },
  ]);

  /*Correct and Total Difficulty Level Questions */
  const [correct_dlq_data, setCorrect_dlq_data] = useState([
    {
      name: "",
      easy: {
        Easy: 0,
        "Total Q'": 0,
      },
      medium: {
        Medium: 0,
        "Total Q'": 0,
      },
      difficult: {
        Difficult: 0,
        "Total Q'": 0,
      },
    },
  ]);

  /*Correct and Total Cognitive Level Questions */
  const [correct_clq_data, setCorrect_clq_data] = useState([
    {
      name: "",
      knowledge: {
        Knowledge: 0,
        "Total Q'": 0,
      },
      understanding: {
        Understanding: 0,
        "Total Q'": 0,
      },
      application: {
        Application: 0,
        "Total Q'": 0,
      },
      analysis: {
        Analysis: 0,
        "Total Q'": 0,
      },
    },
  ]);

  const [card_Data, setCard_Data] = useState([]);

  /*Colors for Pie Chart*/
  const COLORS = ["#10A0B6", "#FF8989", "#9DABDD"];

  /*Handling Customized Labelling of Pie Chart*/
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {percent !== 0 && `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  /*React Media Query*/
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });

  /*Instance for Dropdown*/
  const [dropdown, setdropdown] = useState(isSmallScreen ? false : true);

  /*Function for toggling the Shutter in Mobile View */
  const handleShutterOpen = () => {
    setdropdown(!dropdown);
  };

  /*Function for switching between tabs */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /*Function for switching the headings according to tabs */
  const changeHeading = (val) => {
    setUserDetails({ ...userDetails, selectedTab: val, pathName: {} });
    history.goBack();
    setHeading(val);
  };

  /*Fetching Data For Test Status*/
  const fetchTestStatus = async () => {
    setTs_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/testsstatus`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtotal = 0;
        var newt_data = response.data.data.analytics.map((element) => {
          newtotal += element.tests;
          return {
            name: `${element.sectionId?.grade}-${element.sectionId?.section} ${element.subjectId?.name}`,
            "In Progress": element.progress,
            // Incomplete: element.incomplete,
            Completed: element.completed,
          };
        });
        setTs_total(newtotal);
        setT_status(newt_data);
        console.log(newt_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setTs_loader(false);
  };

  /*Fetching Data For Total Tests Assigned*/
  const fetchTotalTestsAssigned = async () => {
    setTta_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/totaltestsassigned`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtotal = 0;
        var newt_tests = response.data.data.analytics.map((element) => {
          newtotal += element.tests;
          return {
            name: `${element?.sectionId?.grade || ""}-${element?.sectionId?.section || ""} ${element.subjectId?.name}`,
            value: element.tests,
          };
        });
        setTa_total(newtotal);
        setT_tests(newt_tests);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setTta_loader(false);
  };

  /*Fetching Data For Difficulty Status Of Questions*/
  const fetchQuestionsReport = async () => {
    setQr_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/questionsreport`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        const newqr_data = [
          { name: "Correct", value: 0 },
          { name: "Incorrect", value: 0 },
          { name: "Unattempted", value: 0 },
        ];
        response.data.data.analytics.forEach((element) => {
          newqr_data[0].value += element.correct;
          newqr_data[1].value += element.incorrect;
          newqr_data[2].value += element.unattempted;
        });
        setQr_data(newqr_data);
        setAttemptStatusTotalQ(response.data.data.analytics[0].questions);
        setNumChapters(response.data.data.analytics[0].tests);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setQr_loader(false);
  };

  /*Fetching Data For Questions Assigned*/
  const fetchQuestionsAssigned = async () => {
    setQa_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/totalquestionsassigned`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtotal = 0;
        var newqa_data = response.data.data.analytics.map((element) => {
          newtotal += element.questions;
          return {
            name: `${element.sectionId?.grade}-${element.sectionId?.section} ${element.subjectId?.name}`,
            value: element.questions,
          };
        });
        setQa_total(newtotal);
        setQa_data(newqa_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setQa_loader(false);
  };

  /*Fetching Data for Questions Status */
  const fetchQuestionsStatus = async () => {
    setQs_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/questionsstatus`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtotal = 0;
        var newq_status = response.data.data.analytics.map((element) => {
          newtotal += element.totalQuestions;
          return {
            name: `${element.sectionId?.grade}-${element.sectionId?.section} ${element.subjectId?.name}`,
            Correct: element.totalCorrect,
            Incorrect: element.totalIncorrect,
            Unattempted: element.totalUnattempted,
          };
        });
        setQs_total(newtotal);
        setQ_status(newq_status);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setQs_loader(false);
  };

  /*Fetching Data for Difficulty and Cognitive Status */
  const fetchDifficultyAndCognitiveStatus = async () => {
    setDclr_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/questionlevelstatuspersubjects`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        var newdlq_data = response.data.data.analytics.map((element) => {
          return {
            name: `${element.sectionId?.grade}-${element.sectionId?.section} ${element.subjectId?.name}`,
            Easy: element.difficulty.easy,
            Medium: element.difficulty.intermediate,
            Difficult: element.difficulty.hard,
          };
        });
        var newclq_data = response.data.data.analytics.map((element) => {
          return {
            name: `${element.sectionId?.grade}-${element.sectionId?.section} ${element.subjectId.name}`,
            Knowledge: element.cognitive.knowledge,
            Understanding: element.cognitive.understanding,
            Application: element.cognitive.application,
            Analysis: element.cognitive.analysis,
          };
        });
        setDlq_data(newdlq_data);
        setClq_data(newclq_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setDclr_loader(false);
  };

  /*Fetching Data for Difficulty and Cognitive Correct and Total Status */
  const fetchDifficultyAndCognitiveCorrectStatus = async () => {
    setDclcr_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/questioncorrectlevelstatus`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        var newcorrect_dlq_data = response.data.data.analytics.map(
          (element) => {
            return {
              name: `${element.sectionId?.grade}-${element.sectionId?.section} ${element.subjectId.name}`,
              easy: {
                Easy: element.difficulty.easy.correct,
                "Total Q": element.difficulty.easy.total,
              },
              medium: {
                Medium: element.difficulty.intermediate.correct,
                "Total Q": element.difficulty.intermediate.total,
              },
              difficult: {
                Difficult: element.difficulty.hard.correct,
                "Total Q": element.difficulty.hard.total,
              },
            };
          }
        );
        var newcorrect_clq_data = response.data.data.analytics.map(
          (element) => {
            return {
              name: `${element.sectionId?.grade}-${element.sectionId?.section} ${element.subjectId?.name}`,
              knowledge: {
                Knowledge: element.cognitive.knowledge.correct,
                "Total Q": element.cognitive.knowledge.total,
              },
              understanding: {
                Understanding: element.cognitive.understanding.correct,
                "Total Q": element.cognitive.understanding.total,
              },
              application: {
                Application: element.cognitive.application.correct,
                "Total Q": element.cognitive.application.total,
              },
              analysis: {
                Analysis: element.cognitive.analysis.correct,
                "Total Q": element.cognitive.analysis.total,
              },
            };
          }
        );
        setCorrect_dlq_data(newcorrect_dlq_data);
        setCorrect_clq_data(newcorrect_clq_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setDclcr_loader(false);
  };

  /*Fetching Data For Learning Strength & Learning Gap Of Questions*/
  useEffect(() => {
    console.log(tableData);
    let dt = tabData;
    if (filterReports.name) {
      dt = dt.filter(
        (item) => item.studentId?.name === filterReports?.name?.name
      );
    }
    if (filterReports.subject) {
      dt = dt.filter(
        (item) => item.subjectId?.name === filterReports?.subject?.name
      );
    }
    if (filterReports.grade) {
      dt = dt.filter(
        (item) =>
          `${item?.studentId?.grade}-${item?.studentId?.section}` ===
          filterReports.grade?._id
      );
    }
    if (filterReports.testType) {
      dt = dt.filter((item) => item.testType === filterReports.testType?._id);
    }

    if (filterByDate.date) {
      dt = dt.filter(
        (item) =>
          new Date(item.startedAt.split("T")[0]) >= new Date(filterByDate.date)
      );
    }

    console.log(dt);
    console.log(filterReports);
    setTableData(dt);
  }, [filterByDate, filterReports]);
  const fetchLearningStrengthAndGap = async () => {
    setLsg_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/testlearningstatus`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        var newlsg_data = response.data.data.analytics.map((element) => {
          return {
            name: `${element.sectionId?.grade}-${element.sectionId?.section} ${element.subjectId?.name}`,
            Gap: element.gap,
            Strength: element.strength,
          };
        });
        setLsg_data(newlsg_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setLsg_loader(false);
  };

  const fetchClassReportData = async () => {
    setTable_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/classreports`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setTableData(response.data.data.analytics);
        setTabData(response.data.data.analytics);
        setTable_loader(false);
      }
    } catch (error) {
      console.log(error);
      setTable_loader(false);
    }
  };

  const fetchReassignTestData = async () => {
    setCard_loader(true);
    const authToken = localStorage.getItem("authToken");
    const staffId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/staffs/${staffId}/assigntests`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        console.log(response);
        setExportingData(response.data.data.assignTests);
        var newcard_Data = response.data.data.assignTests.map((element) => {
          return {
            subject: `${element.subjectId.name}`,
            testTitle: `${element?.questionSet?.chapters[0]?.name}`,
            date: `${new Date(element.from).toDateString()}`,
            time: `${
              Math.floor(element.duration / 3600000) +
              "h " +
              Math.floor((element.duration % 3600000) / 60000) +
              "m " +
              Math.floor((element.duration / 1000) % 60) +
              "s"
            }`,
            testId: `${element._id}`,
            testReAssignId: `${element.questionSet._id}`,
            sectionId: `${element.sectionId?._id}`,
            testName: `${element?.questionSet?.title}`,
            subjectId: `${element.subjectId._id}`,
            chapterId: `${element.questionSet?.chapters?.map(
              (item) => item._id
            )}`,
            topicId: `${element.questionSet?.topics?.map((item) => item._id)}`,
          };
        });
        setCard_Data(newcard_Data);
        setSubData(newcard_Data);
        setCard_loader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*Sequence of API Calls */
  useEffect(async () => {
    await fetchTestStatus();
    await fetchTotalTestsAssigned();
    await fetchQuestionsReport();
    await fetchQuestionsAssigned();
    await fetchQuestionsStatus();
    await fetchDifficultyAndCognitiveStatus();
    await fetchDifficultyAndCognitiveCorrectStatus();
    await fetchLearningStrengthAndGap();
    await fetchClassReportData();
    await fetchReassignTestData();
  }, []);

  /*Function To Export Class Report Data*/
  const exportCsv = () => {
    const data = tableData;
    const formatted_data = data.map((element) => {
      return {
        Name: element.studentId.name,
        Class: `${element.studentId.grade}-${element.studentId.section}`,
        Subject: element.subjectId.name,
        Chapter: element.chapters,
        Topic: element.topics,
        "Test Title": element.testId.questionSet.title,
        "Test Type": element.testType,
        Date: element.startedAt.split("T")[0],
        "Start Time Of Test": element.startedAt.split("T")[1].slice(0, 8),
        "Time Spent": `${
          Math.floor(element.timeSpent / 3600000) +
          "h" +
          Math.floor((element.timeSpent % 3600000) / 60000) +
          "m" +
          Math.floor((element.timeSpent / 1000) % 60) +
          "s"
        }`,
        Questions: element.questions,
        "Total Marks": element.totalMarks,
        Correct: element.correct,
        Incorrect: element.incorrect,
        Easy: element.correctAttempt.easy,
        Intermediate: element.correctAttempt.intermediate,
        Hard: element.correctAttempt.hard,
        Knowledge: element.correctAttempt.knowledge,
        Understanding: element.correctAttempt.understanding,
        Analysis: element.correctAttempt.analysis,
        Application: element.correctAttempt.application,
        "Marks Obtained": element.totalScore,
        Rank: element.rank,
      };
    });
    const fileName = "Class Report";
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data: formatted_data, fileName, exportType });
  };

  const search = () => {
    console.log(tableData);
    let searchData = tableData.filter(
      (data) =>
        data.studentId?.name
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        data.subjectId?.name
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        data?.testId?.questionSet?.title
          .toLowerCase()
          .includes(searchValue.toLowerCase())
    );
    setTableData(searchData);
  };

  useEffect(() => {
    //when the search box is empty
    if (!searchValue) {
      fetchClassReportData();
    } else {
      search();
    }
  }, [searchValue]);

  /*Colors for multicolored bar graphs */
  const barColors = ["#0F93A8", "#F2931E", "#AE4132", "#23445D"];

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${userDetails.userId}/sections/${filterSubjectCards.grade._id}/subjects/${filterSubjectCards.subject._id}/chapters`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          setChapters(response.data.data.chapters);
          console.log(response);
          // setNumChapters(response.data.data.chapters.length);
          // setSubjectValue(id);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [filterSubjectCards.subject]);
  useEffect(() => {
    const getData = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${userDetails.userId}/sections/${filterSubjectCards.grade._id}/subjects/${filterSubjectCards.subject._id}/chapters/${filterSubjectCards.chapter._id}/topics`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          setTopics(response.data.data.topics);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (filterSubjectCards.chapter) {
      getData();
    }
  }, [filterSubjectCards.chapter]);
  const cardColors = [
    "rgba(36, 147, 110, 1)",
    "rgba(229, 152, 155, 1)",
    "rgba(102, 153, 187, 0.9)",
    "rgba(255, 180, 162, 1)",
  ];
  const [assignedClasses, setAssignedClasses] = useState([]);

  useEffect(() => {
    const fetchClasses = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_NEWORIGIN}/api/v1/staffs/${userDetails.userId}/assignSubjects`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          setAssignedClasses([
            new GradeSorter(response.data.data.sections).sort(),
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchClasses();
  }, []);
  const [assignClass, setAssignClass] = useState("Class");
  const [assignSection, setAssignSection] = useState("Section");
  const [assignVisible, setAssignVisible] = useState("Visibility");
  const [assisgnTestLoader, setAssisgnTestLoader] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const handleCloseAssignModal = () => setAssignModal(false);
  const handleShowAssignModal = () => setAssignModal(true);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [assignTime, setAssignTime] = useState({ hours: 0, minutes: 0 });
  const [assignSuccessshow, setAssignSuccessshow] = useState(false);
  let submitsectionId = "";
  let submitsubjectId = "";
  let submittestId = "";

  const handleVisible = (e) => {
    setAssignVisible(e);
  };
  const convertToMilliSeconds = (t) => {
    return t.hours * 3600000 + t.minutes * 60000;
  };
  const handleCloseAssignSuccessshow = () => setAssignSuccessshow(false);

  const handleShowAssignSuccessshow = () => {
    setAssignSuccessshow(true);
    setAssignModal(false);
  };
  const myassessment = location.pathname.split("/");
  const handleClass = (e) => {
    setAssignClass(e);
  };
  const evaluateMinEndDate = (date) => {
    const newDateStr = new Date(date);
    const newDate = newDateStr.getDate() + 1;
    newDateStr.setDate(newDate);
    return newDateStr;
  };

  const renderCustomizedLabels = (props) => {
    const { x, y, width, height, value, stroke } = props;
    const radius = 10;
    console.log(props.value);

    return (
      <g>
        {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
        <text
          x={x + width / 2}
          y={y + height / 2}
          fill={stroke}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {/* <foreignObject x={0} y={0} width={100} height={100}> */}
          {props.value ? props.value : ""}
          {/* </foreignObject> */}
        </text>
      </g>
    );
  };

  useEffect(() => {
    if (filterReports.grade) {
      setFilterReports({ ...filterReports, name: "" });
      const getName = async () => {
        const authToken = localStorage.getItem("authToken");
        try {
          const grade = filterReports.grade._id.split("-")[0];
          const section = filterReports.grade._id.split("-")[1];
          const response = await axios.get(
            `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/students`,
            {
              headers: {
                token: authToken,
              },
            }
          );
          if (response.status === 200) {
            setStudents(response.data.data.students);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getName();
    }
  }, [filterReports.grade]);

  const exportCsv2 = () => {
    const data = tableData;
    const formatted_data = data.map((element) => {
      return {
        Question: element?.questionId?.question,
        Subject: element?.subjectId?.name,
        Topic: element?.topicId?.name,
        Difficulty: element.difficulty,
        Cognitive: element.cognitive,
        "Time Spent": `${
          Math.floor((element.timeTaken % 3600000) / 60000) +
          "m" +
          ((element.timeTaken / 1000) % 60).toPrecision(2) +
          "s"
        }`,
        "Correct/ Incorrect": `${
          element.status !== "passed" || element.status === "failed"
            ? "Incorrect"
            : "Correct"
        }`,
        Status: `${
          element.status === "unattempted" ? "Unattempted" : "Attempted"
        }`,
      };
    });
    const fileName = "Practice Report";
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data: formatted_data, fileName, exportType });
  };

  useEffect(() => {
    let dt = subData;
    if (filterSubjectCards.grade) {
      dt = dt.filter((item) => item.sectionId === filterSubjectCards.grade._id);
    }
    if (filterSubjectCards.subject) {
      dt = dt.filter(
        (item) => item.subjectId === filterSubjectCards.subject._id
      );
    }
    if (filterSubjectCards.chapter) {
      dt = dt.filter((item) =>
        item.chapterId.includes(filterSubjectCards.chapter._id)
      );
    }
    if (filterSubjectCards.topic) {
      dt = dt.filter((item) =>
        item.topicId.includes(filterSubjectCards.topic._id)
      );
    }
    if (filterSubjectCards.date) {
      dt = dt.filter(
        (item) => new Date(item.date) >= new Date(filterSubjectCards.date)
      );
    }
    setCard_Data(dt);
  }, [filterSubjectCards]);

  const SubjectCard = ({
    index,
    subject,
    date,
    time,
    testId,
    testTitle,
    testReassign,
    background,
    sectionId,
    subjectId,
  }) => {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    submittestId = testId;
    submitsectionId = sectionId;
    submitsubjectId = subjectId;
    return (
      <Grid
        container
        className={classes.root1}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={1} style={{ textAlign: "center" }}>
          {index}
        </Grid>
        <Grid
          container
          item
          className={classes.card}
          lg={10}
          justifyContent={isSmallScreen ? "space-between" : "center"}
          alignItems="center"
        >
          <Grid item lg={6} className={classes.subjectSection}>
            <button style={{ background: background }}>{testTitle}</button>
            <p>{date}</p>
            <p>{time}</p>
            <p>{subject}</p>
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            className={classes.report}
            style={isSmallScreen ? { marginLeft: "auto" } : {}}
          >
            <Link to={`/report/reportcard/${testId}`}>
              <span>
                <DescriptionOutlinedIcon fontSize="small" />
              </span>
              &nbsp;Report
            </Link>
          </Grid>
          <Grid item lg={1} sm={0} className={classes.menu}>
            <Button
              ariaControls={`context-menu-${index}`}
              id={`card-${index}`}
              onClick={handleOpen}
            >
              <MoreHorizIcon />
            </Button>
            <Menu
              id={`context-menu-${index}`}
              className={classes.contextMenu}
              anchorEl={document.getElementById(`card-${index}`)}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={async () => {
                  await setTestId({
                    sectionId: sectionId,
                    subjectId: subjectId,
                    testId: testReassign,
                  });
                  handleClose();
                  handleShowAssignModal();
                }}
                className={classes.contextMenuItem}
              >
                Reassign
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  const authToken = localStorage.getItem("authToken");
                  try {
                    const response = await axios.get(
                      `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/assigntests/${testId}?sort=-totalScore,duration&submissionStatus=done`,
                      {
                        headers: {
                          token: authToken,
                        },
                      }
                    );
                    if (response.data.status === "success") {
                      // setTableData(response.data.data.reports);
                      console.log("Response", response.data.data.reports);
                      if (response.data.data.reports.length === 0)
                        alert.error("No Data available to export");
                      else {
                        const data = response.data.data.reports.map(
                          (item, idx) => {
                            return {
                              Sno: idx + 1,
                              Name: item.studentId.name,
                              Class: `${item.studentId.grade}-${item.studentId.section}`,
                              Questions: `${item?.totalQuestions}`,
                              Correct: `${item.correctAnswers}/${item?.totalQuestions}`,
                              Incorrect: `${item?.wrongAnswers}/${item?.totalQuestions}`,
                              Unattempted: `${item?.unattemptedAnswers}`,
                              Score: `${item?.totalScore}/${item?.totalMarks}`,
                              Duration: `${Math.floor(
                                (new Date(item.submittedAt) -
                                  new Date(item.startedAt)) /
                                  3600000
                              )}h
                  ${Math.round(
                    ((new Date(item.submittedAt) - new Date(item.startedAt)) %
                      3600000) /
                      60000
                  )}m`,
                              "Start Time": new Date(
                                item.startedAt
                              ).toLocaleTimeString(),
                              "End Time": new Date(
                                item.submittedAt
                              ).toLocaleTimeString(),
                            };
                          }
                        );
                        const fileName = "Question Report";
                        const exportType = exportFromJSON.types.csv;

                        exportFromJSON({ data, fileName, exportType });
                      }
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
                className={classes.contextMenuItem}
              >
                Export
              </MenuItem>
              {/* <MenuItem
                onClick={handleClose}
                className={classes.contextMenuItem}
              >
                Export as PDF
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                className={classes.contextMenuItem}
              >
                Share
              </MenuItem> */}
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const alert = useAlert();
  return (
    <>
      <Modal
        show={assignModal}
        onHide={handleCloseAssignModal}
        animation={false}
        centered
        className="success_assign"
      >
        <Modal.Body>
          <div className="assignModal">
            <div className="assignModal_top">
              <div>
                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "10px" }}
                >
                  <path
                    d="M5 15C2.79086 15 1 13.2091 1 11C1 9.09295 2.33457 7.4976 4.12071 7.09695C4.04169 6.74395 4 6.37684 4 6C4 3.23858 6.23858 1 9 1C11.4193 1 13.4373 2.71825 13.9002 5.00098C13.9334 5.00033 13.9666 5 14 5C16.7614 5 19 7.23858 19 10C19 12.419 17.2822 14.4367 15 14.9M13 12L10 9M10 9L7 12M10 9L10 21"
                    stroke="#111827"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>ASSIGN</span>
              </div>
              <CloseIcon
                className="closeAssignModal"
                onClick={handleCloseAssignModal}
              />
            </div>
            <div
              className="assignModal_mid"
              style={
                myassessment[1] !== "myassessment" ? { display: "none" } : null
              }
            >
              <div className="dropDown_assign">
                <span>Set Class(es)</span>
                <Dropdown className="assignModal_dropdown">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="btn-question-type"
                  >
                    {assignClass}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="assignModal_menu onl-dropdown menu_option_extend scrollmenu"
                    style={{ height: "170px" }}
                  >
                    {assignedClasses?.map((x) => (
                      <Dropdown.Item
                        onSelect={handleClass}
                        eventKey={`${x}`}
                        className="menu_option1 onl-drop-list"
                      >
                        {`${x}`}
                        <div>
                          <input type="radio" name="radio" />
                          <span className="colorRed"></span>
                        </div>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="dropDown_assign">
                <span>Set Section(es)</span>
                <Dropdown className="assignModal_dropdown">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="btn-question-type"
                    disabled={assignClass !== "Class" ? false : true}
                  >
                    {assignSection}
                  </Dropdown.Toggle>

                  {/* <Dropdown.Menu
                      className="assignModal_menu onl-dropdown menu_option_extend scrollmenu"
                      style={{ height: "170px" }}
                    >
                      {sections.map((x) => (
                        <Dropdown.Item
                          className="menu_option1 onl-drop-list"
                          onSelect={(e) => {
                            setAssignSection(e);
                            setAssignSectionId(x.sectionId);
                            // console.log("sectionId", x.sectionId);
                          }}
                          eventKey={`${x.section}`}
                        >
                          {`${x.section}`}
                          <div>
                            <input type="radio" name="radio" />
                            <span className="colorRed"></span>
                          </div>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu> */}
                </Dropdown>
              </div>
            </div>

            <div className="assignModal_mid">
              <div
                className="dropDown_assign"
                style={{
                  width: "50%",
                  marginLeft: "25px",
                  marginBottom: "0px",
                }}
              >
                <span style={{ marginLeft: "11px" }}>Start Date</span>
                <label htmlFor="urlFromDate" className="assignCalenderDate">
                  <img
                    src={CalenderImg}
                    style={
                      myassessment[0] === "myassessment"
                        ? assignSection != "Section"
                          ? {}
                          : { filter: "opacity(0.5)" }
                        : null
                    }
                  />
                  {selectedFromDate?.toLocaleDateString("en-GB")}
                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className="dateStyle colorSelect"
                    size="small"
                    inputVariant={"filled"}
                    disableToolbar
                    disabled={
                      myassessment[0] === "myassessment"
                        ? assignSection != "Section"
                          ? false
                          : true
                        : false
                    }
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    minDate={new Date()}
                    value={selectedFromDate}
                    onChange={(date) => {
                      setSelectedFromDate(date);
                      setSelectedToDate(evaluateMinEndDate(date));
                      setStartDate(true);
                      setEndDate(true);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "From Date",
                      id: "urlFromDate",
                    }}
                    autoOk={true}
                  />
                  {/* </div> */}
                </MuiPickersUtilsProvider>
              </div>
              <div
                className="dropDown_assign"
                style={{ width: "50%", marginBottom: "0px" }}
              >
                <span style={{ marginLeft: "3px" }}>End Date</span>
                <label htmlFor="urlToDate" className="assignCalenderDate">
                  <img
                    src={CalenderImg}
                    style={startDate ? {} : { filter: "opacity(0.5)" }}
                  />
                  {selectedToDate?.toLocaleDateString("en-GB")}
                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className="dateStyle colorSelect"
                    size="small"
                    inputVariant={"filled"}
                    disableToolbar
                    disabled={startDate ? false : true}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    minDate={evaluateMinEndDate(selectedFromDate)}
                    value={selectedToDate}
                    onChange={(date) => {
                      setSelectedToDate(date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "From Date",
                      id: "urlToDate",
                    }}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>

            <div className="assignModal_mid">
              <div
                className="dropDown_assign"
                style={{ width: "50%", marginLeft: "25px" }}
              >
                <span style={{ marginLeft: "11px" }}>Duration</span>
                <div className="assignTimeDate">
                  <img
                    src={clockIcon}
                    style={endDate ? {} : { filter: "opacity(0.5)" }}
                  />
                  <TextField
                    value={assignTime.hours}
                    disabled={endDate ? false : true}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 4000 } }}
                    className="text-field-input margin-10 text-field color"
                    id="outlined-basic"
                    onChange={(e) =>
                      setAssignTime({ ...assignTime, hours: e.target.value })
                    }
                    label="HH"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                  <TextField
                    value={assignTime.minutes}
                    disabled={endDate ? false : true}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 4000 } }}
                    className="text-field-input margin-10 text-field color"
                    id="outlined-basic"
                    onChange={(e) =>
                      setAssignTime({
                        ...assignTime,
                        minutes: e.target.value,
                      })
                    }
                    label="MM"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="dropDown_assign" style={{ width: "50%" }}>
                <span style={{ marginLeft: "3px" }}>Set Visibility</span>
                <Dropdown className="assignModal_dropdown">
                  {/* option-dropdown */}
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="btn-question-type"
                    disabled={assignTime != "00:00" ? false : true}
                  >
                    {assignVisible}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="onl-dropdown menu_option_extend scrollmenu"
                    style={{ height: "170px" }}
                  >
                    <Dropdown.Item
                      onSelect={handleVisible}
                      eventKey="private"
                      className="menu_option1 onl-drop-list"
                    >
                      private
                      <div>
                        <input type="radio" name="radio" />
                        <span className="colorRed"></span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onSelect={handleVisible}
                      eventKey="publicForSchool"
                      className="menu_option1 onl-drop-list"
                    >
                      public for school
                      <div>
                        <input type="radio" name="radio" />
                        <span className="colorRed"></span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onSelect={handleVisible}
                      eventKey="publicForAll"
                      className="menu_option1 onl-drop-list"
                    >
                      public for all
                      <div>
                        <input type="radio" name="radio" />
                        <span className="colorRed"></span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="assignModal_bot">
              {assisgnTestLoader ? (
                <>
                  <div className="text-center">
                    <CircularProgress
                      style={{ color: "#FF075B", fontWeight: "600" }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <button
                    className="review-onlineTest"
                    onClick={async () => {
                      setAssisgnTestLoader(true);
                      // setLock(false);
                      // setCheckAssign(true);
                      // setAssignDetails(true);
                      const authToken = localStorage.getItem("authToken");

                      try {
                        // const topicQuery =
                        //   myassessment[1] != "myresources"
                        //     ? {}
                        //     : { topicId: topi };

                        const response = await axios.post(
                          `${process.env.REACT_APP_NEWORIGIN}/api/v1/staffs/${userDetails.userId}/sections/${testId.sectionId}/subjects/${testId.subjectId}/tests/${testId.testId}/assign`,
                          {
                            sectionId: testId.sectionId,
                            startDate: selectedFromDate.getTime(),
                            endDate: selectedToDate.getTime(),
                            duration: convertToMilliSeconds(assignTime),
                            visibilityStatus: assignVisible,
                            // ...topicQuery,
                          },
                          {
                            headers: {
                              token: authToken,
                            },
                          }
                        );
                        console.log(response);
                        if (response.data.status === "success") {
                          setStartDate(false);
                          handleShowAssignSuccessshow();
                        } else {
                          alert.error(response.data.message);
                        }
                      } catch (error) {
                        alert.error("Test not Assigned");
                        console.log(error);
                      }
                      setAssisgnTestLoader(false);
                    }}
                  >
                    Submit
                  </button>
                  <br />
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={assignSuccessshow}
        onHide={handleCloseAssignSuccessshow}
        animation={false}
        centered
        className="online_test_modal_head_one"
      >
        <Modal.Body>
          <div className="upload_done_modal">
            <div
              className="upload_done_modal__top"
              style={{ justifyContent: "space-between" }}
            >
              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 15C2.79086 15 1 13.2091 1 11C1 9.09295 2.33457 7.4976 4.12071 7.09695C4.04169 6.74395 4 6.37684 4 6C4 3.23858 6.23858 1 9 1C11.4193 1 13.4373 2.71825 13.9002 5.00098C13.9334 5.00033 13.9666 5 14 5C16.7614 5 19 7.23858 19 10C19 12.419 17.2822 14.4367 15 14.9M13 12L10 9M10 9L7 12M10 9L10 21"
                  stroke="#111827"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style={{ left: "15%", position: "absolute" }}>Assign</span>
              <CloseIcon onClick={handleCloseAssignSuccessshow} />
            </div>
            <div className="upload_done_modal__mid">
              <span
                style={{ fontSize: "16px" }}
              >{`Test Assigned To ${assignClass} Successfully`}</span>
              <svg
                width="70"
                height="70"
                viewBox="0 0 70 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70ZM51.2186 29.3436C52.9271 27.635 52.9271 24.865 51.2186 23.1564C49.51 21.4479 46.74 21.4479 45.0314 23.1564L30.625 37.5628L24.9686 31.9064C23.26 30.1979 20.49 30.1979 18.7814 31.9064C17.0729 33.615 17.0729 36.385 18.7814 38.0936L27.5314 46.8436C29.24 48.5521 32.01 48.5521 33.7186 46.8436L51.2186 29.3436Z"
                  fill="#5CDA8E"
                />
              </svg>
            </div>
            <div className="upload_done_modal__bottom">
              <button
                className="btn modal_done colorModal"
                onClick={handleCloseAssignSuccessshow}
              >
                Done
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <section className={isSmallScreen ? "modules my-4" : "modules my-5"}>
        <div
          className={
            isSmallScreen ? "container-fluid dashboard_container" : "container"
          }
        >
          <div className="box">
            <div
              className="upperBox"
              style={{ display: isSmallScreen ? "none" : "" }}
            >
              <div className="alignItem">
                <div className="row">
                  <div className="boxRow">
                    <div className="boxRowLeft">
                      <h1 className="className">
                        {/* {grade
                          ? `${grade}-${section}`
                          : localStorage.getItem("gradeSection")} */}
                        {/* {params.class}-{params.section} */}
                      </h1>
                      <div className="pathDetail">
                        <h2 style={{ fontWeight: "700" }}>
                          Analytics &gt; Online Test
                        </h2>
                        <p style={{ fontWeight: "600", color: "#ffffff" }}>
                          {/* {params.subject} */}
                        </p>
                      </div>
                    </div>
                    <div className="DateBox">
                      <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                        <CalendarTodayIcon />{" "}
                        {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              <Paper className={classes.root}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  style={{
                    background: "#DCFFFB",
                    marginTop: "-12px",
                    display: dropdown ? "" : "none",
                  }}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={"on"}
                >
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={HomeIcon}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Home"
                    {...a11yProps(0)}
                    onClick={() => changeHeading("Dashboard")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={analyticGraphImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Analytics"
                    {...a11yProps(1)}
                    onClick={() => changeHeading("Analytics")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assignImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assign Task"
                    {...a11yProps(2)}
                    onClick={() => changeHeading("Assign Task")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assessmentImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assessment"
                    {...a11yProps(3)}
                    onClick={() => changeHeading("Assessment")}
                  />
                </Tabs>
              </Paper>
            }
            <div
              className="shutter_PS"
              style={{
                marginTop: dropdown ? "0px" : "-15px",
                display: isSmallScreen ? "flex" : "none",
              }}
            >
              <DropDownShutter
                onClick={handleShutterOpen}
                clicked={dropdown}
                dropdown={dropdown}
                setDropDown={setdropdown}
              />
            </div>
          </div>
        </div>
      </section>
      {/*Graphs Section*/}
      <Container fluid="xl" className="analytics_main_section">
        {/*Back Button*/}
        <Link to="/dashboard">
          <img src={Backbtn} className="back_btn" />
        </Link>
        {/*Side Drawer*/}
        <Sideboard
          sectionId={sectionId}
          setSectionId={setSectionId}
          subjectValue={subjectValue}
          setSubjectValue={setSubjectValue}
          chapterValue={chapterValue}
          setChapterValue={setChapterValue}
          numStudents={numStudents}
          // setAllStudents={setStudents}
          setAllSubjects={setSubjects}
          setAllGrade={setAllGrade}
          setNumStudents={setNumStudents}
          ch_loader={ts_loader}
          q_loader={qr_loader}
          qs_loader={qs_loader}
          qa_loader={qa_loader}
          dlr_loader={dclr_loader}
          clr_loader={dclcr_loader}
          ls_loader={lsg_loader}
        />
        {/*Chapterwise*/}
        {/*First Layer having Bar Graph and Pie Chart*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Test Status All Grades</b>
            </h5>
            <p>Total Tests Assigned - {ts_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!ts_loader ? (
                t_status.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={t_status}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 0, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false}>
                      <Label
                        value="Test Assigned"
                        position={isSmallScreen ? "" : ""}
                        // position=""
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {t_status ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar
                      dataKey="Completed"
                      stackId="a"
                      fill=" #1190C4"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        content={renderCustomizedLabels}
                        position="middle"
                        style={{ zIndex: "10000" }}
                        dataKey={"Completed"}
                        fill="#000"
                      />
                    </Bar>
                    <Bar
                      dataKey="In Progress"
                      stackId="a"
                      fill="#C56468"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey="In Progress"
                        position="middle"
                        fill="#000"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    {/* <Bar
                      dataKey="Incomplete"
                      stackId="a"
                      fill=" #C4C4C459"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey="Incomplete"
                        position="middle"
                        fill="#000"
                        content={renderCustomizedLabels}
                      />
                    </Bar> */}
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
          <div className="analytics_bar_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Test Assigned to All Grades</b>
            </h5>
            <p>Total Tests Assigned - {ta_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!tta_loader ? (
                T_tests.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={T_tests}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 0, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false}>
                      <Label
                        value="Tests Assigned"
                        position={isSmallScreen ? "" : "insideCenterLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {T_tests ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar dataKey="value" fill="#00a0fc" maxBarSize={75}>
                      {T_tests.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={barColors[index % 20]}
                        />
                      ))}
                      <LabelList
                        dataKey="value"
                        content={renderCustomizedLabels}
                        position="top"
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        {/*Second Layer having Bar Graphs of Difficulty Level and Cognitive Level*/}
        <Row className="analytics_section_layer">
          <div className="analytics_pie_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Questions Report All Classes</b>
            </h5>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!qr_loader ? (
                qr_data[0].value === 0 &&
                qr_data[1].value === 0 &&
                qr_data[2].value === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <PieChart>
                    <Pie
                      data={qr_data}
                      cx="50%"
                      cy="50%"
                      isAnimationActive={false}
                      labelLine={false}
                      label={renderCustomizedLabel}
                      dataKey="value"
                      nameKey="name"
                    >
                      {qr_data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                          style={{ boxShadow: "100px 200px 3px 0px #00000040" }}
                        />
                      ))}
                    </Pie>
                    <Legend
                      verticalAlign="middle"
                      align="right"
                      layout="vertical"
                      height={56}
                    />
                  </PieChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
            <div className="analytics_card_list">
              <ul style={{ color: "#FF8989" }}>
                <li>
                  <span style={{ color: "black" }}>
                    <b>Total Questions</b>
                  </span>{" "}
                  {attemptStatusTotalQ}
                </li>
                <li>
                  <span style={{ color: "black" }}>
                    <b>Tests</b>{" "}
                  </span>
                  {numChapters}
                </li>
              </ul>
            </div>
          </div>
          <div className="analytics_bar_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Questions Assigned to All Grades</b>
            </h5>
            <p>Total Questions Assigned - {qa_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!qa_loader ? (
                qa_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={qa_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 0, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false}>
                      <Label
                        value="Questions"
                        position={isSmallScreen ? "" : "insideCenterLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {qa_data ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar dataKey="value" fill="#00a0fc" maxBarSize={75}>
                      {qa_data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={barColors[index % 20]}
                        />
                      ))}
                      <LabelList
                        dataKey="value"
                        position="middle"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        {/*Third Layer having Bar Graphs of Learning Strength and Learning Gap*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Questions Status All Grades</b>
            </h5>
            <p>Total Questions - {qs_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!qs_loader ? (
                q_status.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={q_status}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 0, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false}>
                      <Label
                        value="Questions"
                        position={isSmallScreen ? "" : "insideCenterLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {q_status ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar
                      dataKey="Correct"
                      stackId="a"
                      fill=" #1190C4"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey={"Correct"}
                        fill="#000"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      dataKey="Incorrect"
                      stackId="a"
                      fill="#C56468"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey={"Incorrect"}
                        position="middle"
                        fill="#000"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      dataKey="Unattempted"
                      stackId="a"
                      fill=" #C4C4C459"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey={"Unattempted"}
                        position="inside"
                        fill="#000"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>

          {/** 
          <div className="analytics_pie_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Questions Report All Classes</b>
            </h5>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!qr_loader ? (
                qr_data[0].value === 0 &&
                qr_data[1].value === 0 &&
                qr_data[2].value === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <PieChart>
                    <Pie
                      data={qr_data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      dataKey="value"
                      nameKey="name"
                    >
                      {qr_data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                          style={{ boxShadow: "100px 200px 3px 0px #00000040" }}
                        />
                      ))}
                    </Pie>
                    <Legend
                      verticalAlign="middle"
                      align="right"
                      layout="vertical"
                      height={56}
                    />
                  </PieChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
            

            <div className="analytics_card_list">
              <ul style={{ color: "#FF8989" }}>
                <li>
                  <span style={{ color: "black" }}>
                    <b>Total Questions</b>
                  </span>{" "}
                  {attemptStatusTotalQ}
                </li>
                <li>
                  <span style={{ color: "black" }}>
                    <b>Tests</b>{" "}
                  </span>
                  {numChapters}
                </li>
              </ul>
            </div>
          </div>
             */}
        </Row>
        {/*Fourth Layer*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Difficulty Level Questions All Grades</b>
            </h5>
            <p>Total Tests Assigned - {ts_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!dclr_loader ? (
                dlq_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={dlq_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 0, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false}>
                      <Label
                        value="Questions"
                        position={isSmallScreen ? "" : "insideCenterLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {dlq_data ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar
                      dataKey="Easy"
                      stackId="a"
                      fill=" #1190C4"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey={"Easy"}
                        fill="#000"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      dataKey="Medium"
                      stackId="a"
                      fill="#C56468"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey={"Medium"}
                        position="inside"
                        fill="#000"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      dataKey="Difficult"
                      stackId="a"
                      fill="#F1C686"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey={"Difficult"}
                        position="inside"
                        fill="#000"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>

          <div className="analytics_bar_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Correct Answers Report of Difficult Level Questions</b>
            </h5>
            <p>Total Tests Assigned - {ts_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!dclcr_loader ? (
                correct_dlq_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    className="analytics_bar_grid"
                    width={600}
                    height={400}
                    data={correct_dlq_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 0, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name"></XAxis>
                    <YAxis allowDecimals={false}>
                      <Label
                        value="Questions"
                        offset={10}
                        position={isSmallScreen ? "" : "insideCenterLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    <Brush dataKey="name" height={10} stroke="#ddd" />
                    <Bar
                      stackId="a"
                      dataKey="easy.Easy"
                      name="Easy"
                      fill="#0092CE"
                    >
                      <LabelList
                        dataKey="easy.Easy"
                        position="inside"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="a"
                      dataKey={`easy.Total Q`}
                      tooltipType="none"
                      legendType="none"
                      fill="#CCCCCCB2"
                    >
                      <LabelList
                        dataKey={`easy.Total Q`}
                        position="inside"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="b"
                      dataKey="medium.Medium"
                      name="Medium"
                      fill="#C96862"
                    >
                      <LabelList
                        dataKey="medium.Medium"
                        position="inside"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="b"
                      tooltipType="none"
                      dataKey={`medium.Total Q`}
                      legendType="none"
                      fill="#CCCCCCB2"
                    >
                      <LabelList
                        dataKey={`medium.Total Q`}
                        position="top"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="c"
                      dataKey="difficult.Difficult"
                      name="Difficult"
                      fill="#F1C686"
                    >
                      <LabelList
                        dataKey="difficult.Difficult"
                        position="inside"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="c"
                      tooltipType="none"
                      dataKey={`difficult.Total Q`}
                      name="Total Q'"
                      fill="#CCCCCCB2"
                    >
                      <LabelList
                        dataKey={`difficult.Total Q`}
                        position="top"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        {/*Fifth Layer*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Cognitive Level Questions All Grades</b>
            </h5>
            <p>Total Tests Assigned - {ts_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!dclr_loader ? (
                clq_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    className="analytics_bar_grid"
                    width={600}
                    height={400}
                    data={clq_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 0, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name"></XAxis>
                    <YAxis allowDecimals={false}>
                      <Label
                        value="Questions"
                        offset={10}
                        position={isSmallScreen ? "" : "insideCenterLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    <Brush dataKey="name" height={10} stroke="#ddd" />
                    <Bar
                      stackId="a"
                      maxBarSize={75}
                      dataKey="Knowledge"
                      fill="#027EB1"
                    >
                      <LabelList
                        dataKey={"Knowledge"}
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="a"
                      maxBarSize={75}
                      dataKey="Understanding"
                      fill="#C96862"
                    >
                      <LabelList
                        dataKey={"Understanding"}
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="a"
                      maxBarSize={75}
                      dataKey="Analysis"
                      fill="#F1C686"
                    >
                      <LabelList
                        dataKey={"Analysis"}
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="a"
                      maxBarSize={75}
                      dataKey="Application"
                      fill="#6DBCDA"
                    >
                      <LabelList
                        dataKey={"Application"}
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
          <div className="analytics_bar_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Correct Answers Report of Cognitive Level Questions</b>
            </h5>
            <p>Total Tests Assigned - {ts_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!dclcr_loader ? (
                correct_clq_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    className="analytics_bar_grid"
                    width={600}
                    height={400}
                    data={correct_clq_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 0, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name"></XAxis>
                    <YAxis allowDecimals={false}>
                      <Label
                        value="Questions"
                        offset={10}
                        position={isSmallScreen ? "" : "insideCenterLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    <Brush dataKey="name" height={10} stroke="#ddd" />
                    <Bar
                      stackId="a"
                      dataKey="knowledge.Knowledge"
                      name="Knowledge"
                      fill="#0092CE"
                    >
                      <LabelList
                        dataKey={"knowledge.Knowledge"}
                        position="inside"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="a"
                      dataKey={`knowledge.Total Q`}
                      tooltipType="none"
                      legendType="none"
                      fill="#CCCCCCB2"
                    >
                      <LabelList
                        dataKey={"knowledge.Total Q"}
                        position="inside"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="b"
                      dataKey="understanding.Understanding"
                      name="Understanding"
                      fill="#C96862"
                    >
                      <LabelList
                        dataKey={"understanding.Understanding"}
                        position="inside"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="b"
                      tooltipType="none"
                      dataKey={`understanding.Total Q`}
                      legendType="none"
                      fill="#CCCCCCB2"
                    >
                      <LabelList
                        dataKey={"understanding.Total Q"}
                        position="inside"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="c"
                      dataKey="application.Application"
                      name="Application"
                      fill="#F1C686"
                    >
                      <LabelList
                        dataKey={"application.Application"}
                        position="inside"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="c"
                      tooltipType="none"
                      legendType="none"
                      dataKey={`application.Total Q`}
                      fill="#CCCCCCB2"
                    >
                      <LabelList
                        dataKey={`application.Total Q`}
                        position="inside"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="d"
                      dataKey="analysis.Analysis"
                      name="Analysis"
                      fill="#6DBCDA"
                    >
                      <LabelList
                        dataKey={"analysis.Analysis"}
                        position="inside"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="d"
                      tooltipType="none"
                      dataKey={`analysis.Total Q`}
                      name="Total Q'"
                      fill="#CCCCCCB2"
                    >
                      <LabelList
                        dataKey={`analysis.Total Q`}
                        position="inside"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        {/* Sixth Layer */}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card">
            <h5>
              <b>Learning Strength and Gap All Grades</b>
            </h5>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!lsg_loader ? (
                lsg_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    className="analytics_bar_grid"
                    width={600}
                    height={400}
                    data={lsg_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 0, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false}>
                      <Label
                        value="Strength &amp; Gap (%)"
                        offset={-120}
                        position={isSmallScreen ? "" : "outside"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {lsg_data ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar
                      stackId="a"
                      maxBarSize={75}
                      dataKey="Strength"
                      fill="#027EB1"
                    >
                      <LabelList
                        dataKey={"Strength"}
                        position="center"
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      stackId="a"
                      maxBarSize={75}
                      dataKey="Gap"
                      fill="#C96862"
                    >
                      <LabelList
                        dataKey={"Gap"}
                        content={renderCustomizedLabels}
                        position="middle"
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: "0px 20px",
            marginBottom: "10px",
          }}
          className="filters"
        >
          <FilterComponent
            options={
              grade.length &&
              grade.map((item) => {
                return { ...item, name: item._id };
              })
            }
            name="grade"
            formData={filterReports}
            setFormData={setFilterReports}
            placeholder="Grade"
          />
          <FilterComponent
            options={students}
            name="name"
            formData={filterReports}
            setFormData={setFilterReports}
            placeholder="Name"
          />

          <FilterComponent
            options={subjects}
            name="subject"
            formData={filterReports}
            setFormData={setFilterReports}
            placeholder="Subject"
          />
          <FilterComponent
            options={[
              { name: "CT", _id: "CT" },
              { name: "UT", _id: "UT" },
              { name: "HY", _id: "HY" },
            ]}
            name="testType"
            formData={filterReports}
            setFormData={setFilterReports}
            placeholder="Test Type"
          />

          <CalenderComponent
            placeholder="date"
            formData={filterByDate}
            setFormData={setFilterByDate}
            name="date"
          />
          <div
            onClick={() => {
              setFilterReports({
                name: "",
                subject: "",
                testType: "",
                grade: "",
              });
              setFilterByDate({ date: "" });
            }}
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <svg
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.5 8.25C6.57107 8.25 8.25 6.57107 8.25 4.5C8.25 2.42893 6.57107 0.75 4.5 0.75C2.42893 0.75 0.75 2.42893 0.75 4.5C0.75 6.57107 2.42893 8.25 4.5 8.25Z"
                stroke="black"
                stroke-opacity="0.54"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.625 3.375L3.375 5.625"
                stroke="black"
                stroke-opacity="0.54"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.375 3.375L5.625 5.625"
                stroke="black"
                stroke-opacity="0.54"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span style={{ marginLeft: "4px" }}>Clear</span>
          </div>
        </div>
        {/*Class Report Table*/}

        <div className="table_container">
          <div
            className="table_title_section"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {isSmallScreen ? (
              <h5
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  marginLeft: "0.5%",
                  marginTop: "5%",
                }}
              >
                Class Report
              </h5>
            ) : (
              <h2
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  marginLeft: "0.5%",
                  marginTop: "0.5%",
                }}
              >
                Class Report
              </h2>
            )}
            {isSmallScreen ? (
              <img
                onClick={exportCsv}
                style={{
                  cursor: "pointer",
                  transform: "scale(0.7)",
                }}
                src={ExportButton}
              />
            ) : (
              <img
                onClick={exportCsv}
                style={{
                  cursor: "pointer",
                  marginLeft: 5,
                }}
                src={ExportButton}
              />
            )}
            <div className="search_container">
              <SearchBar search={searchValue} setSearch={setSearchValue} />
            </div>
          </div>

          <ClassReport rows={tableData} loading={table_loader} />
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: "10px 20px",
            marginBottom: "0px",
          }}
          className="filters"
        >
          {console.log(grade)}

          <FilterComponent
            options={
              grade.length &&
              grade.map((item) => {
                return {
                  ...item,
                  name: item._id,
                  _id: item.subjects[0]?.sectionId,
                };
              })
            }
            name="grade"
            formData={filterSubjectCards}
            setFormData={setFilterSubjectCards}
            placeholder="Grade"
          />
          <FilterComponent
            options={subjects}
            name="subject"
            formData={filterSubjectCards}
            setFormData={setFilterSubjectCards}
            placeholder="Subject"
          />
          <FilterComponent
            options={chapters}
            name="chapter"
            formData={filterSubjectCards}
            setFormData={setFilterSubjectCards}
            placeholder="Chapter"
          />
          <FilterComponent
            options={topics}
            name="topic"
            formData={filterSubjectCards}
            setFormData={setFilterSubjectCards}
            placeholder="Topic"
          />
          {/* <FilterComponent options={[{name:"CT",_id:"CT"},{name:"UT",_id:"UT"},{name:"HY",_id:"HY"}]} name="testType" formData={filterReports} setFormData={setFilterReports} placeholder="Test Type"/> */}

          <CalenderComponent
            placeholder="date"
            formData={filterSubjectCards}
            setFormData={setFilterSubjectCards}
            name="date"
          />
          <div
            onClick={() => {
              setFilterSubjectCards({
                subject: "",
                chapter: "",
                topic: "",
                date: "",
              });
            }}
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <svg
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.5 8.25C6.57107 8.25 8.25 6.57107 8.25 4.5C8.25 2.42893 6.57107 0.75 4.5 0.75C2.42893 0.75 0.75 2.42893 0.75 4.5C0.75 6.57107 2.42893 8.25 4.5 8.25Z"
                stroke="black"
                stroke-opacity="0.54"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.625 3.375L3.375 5.625"
                stroke="black"
                stroke-opacity="0.54"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.375 3.375L5.625 5.625"
                stroke="black"
                stroke-opacity="0.54"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span style={{ marginLeft: "4px" }}>Clear</span>
          </div>
        </div>
        <div className="analytics_subject_card">
          {console.log(card_Data)}
          {!card_loader ? (
            card_Data.map((item, index) => (
              <SubjectCard
                index={index + 1}
                testTitle={item.testName}
                subject={item.subject}
                date={item.date}
                time={item.time}
                testId={item.testId}
                testReassign={item?.testReAssignId}
                background={cardColors[index % 4]}
                sectionId={item.sectionId}
                subjectId={item.subjectId}
              />
            ))
          ) : (
            <div className="graph_loaders">
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            </div>
          )}
        </div>
      </Container>
      <div className="scroll_button_head">
        {visible && (
          <div className="scroll_button" onClick={scrollToTop}>
            <KeyboardArrowUpIcon className="scroll" />
            {/* <span>scroll up</span> */}
          </div>
        )}
      </div>
    </>
  );
};

export default OnlineTestAnalytics;
