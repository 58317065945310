import React from "react";
import "./ClassAnalytics.css";
import { Col, Container, Row } from "react-bootstrap";
import icon1 from "../../images/Icon.svg";
import icon2 from "../../images/Icon (1).svg";
import icon3 from "../../images/Icon (2).svg";
import icon4 from "../../images/Icon (3).svg";
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import student from "../../images/student.svg";
import TestList from "../OnlineTest/TestList/TestList";
import Sideboard from "./Sideboard/Sideboard";
import Backbtn from "../../images/Arrow 9.svg";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const ClassAnalytics = () => {
  // Data for Chapters
  const ch_data = [
    { name: "Chapter Name", Pending: 50, Completed: 79 },
    { name: "Chapter Name", Pending: 50, Completed: 19 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 50, Completed: 19 },
    { name: "Chapter Name", Pending: 50, Completed: 79 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
    { name: "Chapter Name", Pending: 79, Completed: 52 },
  ];
  // Data for Questions
  const q_data = [
    {
      name: "Correct",
      value: 350,
    },
    {
      name: "Incorrect",
      value: 135,
    },
    {
      name: "Not Attempted",
      value: 15,
    },
  ];
  // Data for Learning Strength
  const ls_data = [
    { name: "c1", value: "79" },
    { name: "c2", value: "50" },
    { name: "c3", value: "10" },
    { name: "c4", value: "58" },
    { name: "c5", value: "50" },
    { name: "c6", value: "30" },
  ];
  // Colors for Pie Chart
  const COLORS = ["#1DCD6D", "#9DABDD", "#FF8989"];

  const RADIAN = Math.PI / 180;
  // Function to render Custom labels
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });

  return (
    <Container fluid="xl" className="analytics_main_section">
      <Link to="/myassessment/analytics">
        <img src={Backbtn} className="back_btn" />
      </Link>
      <Sideboard />
      <Row className="analytics_mini_cards mx-auto">
        <div className="analytics_mini_cards_section">
          <Col className="mini_card">
            <div
              className="mini_card_layer"
              style={{ justifyContent: "flex-start" }}
            >
              <div
                className="mini_card_icon"
                style={{
                  backgroundColor: "#fa582e26",
                }}
              >
                <img src={student} />
              </div>
            </div>
            <div>
              <div className="mini-card-content-heading">Students</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h5>60</h5>
              </div>
            </div>
          </Col>
          <Col className="mini_card">
            <div
              className="mini_card_layer"
              style={{ justifyContent: "flex-start" }}
            >
              <div
                className="mini_card_icon"
                style={{
                  backgroundColor: "#2EA4FA36",
                }}
              >
                <img src={icon1} />
              </div>
            </div>
            <div>
              <div className="mini-card-content-heading">Chapters</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h5>6</h5>
              </div>
            </div>
          </Col>
        </div>
        <div className="analytics_mini_cards_section">
          <Col className="mini_card">
            <div
              className="mini_card_layer"
              style={{ justifyContent: "flex-start" }}
            >
              <div
                className="mini_card_icon"
                style={{
                  backgroundColor: "#FBFF3447",
                }}
              >
                <img src={icon2} />
              </div>
            </div>
            <div>
              <div className="mini-card-content-heading">Topic</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h5>2</h5>
              </div>
            </div>
          </Col>
          <Col className="mini_card">
            <div
              className="mini_card_layer"
              style={{ justifyContent: "flex-start" }}
            >
              <div
                className="mini_card_icon"
                style={{
                  backgroundColor: "#B92EFA29",
                }}
              >
                <img src={icon3} />
              </div>
            </div>
            <div>
              <div
                className="mini-card-content-heading"
                style={{ textAlign: "center" }}
              >
                Total Questions
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h5>55</h5>
              </div>
            </div>
          </Col>
        </div>
        <div className="analytics_mini_cards_section">
          <Col className="mini_card">
            <div
              className="mini_card_layer"
              style={{ justifyContent: "flex-start" }}
            >
              <div
                className="mini_card_icon"
                style={{
                  backgroundColor: "#10A0B673",
                }}
              >
                <img src={icon4} />
              </div>
            </div>
            <div>
              <div
                className="mini-card-content-heading"
                style={{ textAlign: "center" }}
              >
                Teacher's Questions
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h5>500</h5>
              </div>
            </div>
          </Col>
        </div>
      </Row>
      <Row className="classanalytics_section_layer">
        <div className="analytics_bar_card">
          <h5>
            <b>Chapter's Report</b>
          </h5>
          <ResponsiveContainer className="Analytics_Responsive_Container">
            <BarChart
              data={ch_data}
              barGap={0}
              margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
            >
              <CartesianGrid
                style={{ display: "inherit" }}
                strokeDasharray="0 0"
                vertical={false}
              />
              <Legend
                verticalAlign="top"
                align="right"
                layout="vertical"
                height={36}
              />
              <XAxis dataKey="name">
                <Label value="Chapters" offset={11} position="bottom" />
              </XAxis>
              <YAxis>
                <Label
                  value="Score"
                  offset={10}
                  position={isSmallScreen ? "" : "insideLeft"}
                  angle={-90}
                />
              </YAxis>
              <Tooltip />
              <Brush
                dataKey="name"
                height={10}
                stroke="#ddd"
                endIndex={isSmallScreen ? 1 : 4}
              />
              <Bar dataKey="Completed" fill="#5CE0D2">
                <LabelList dataKey="Completed" position="top" fill="#FF8989" />
              </Bar>
              <Bar dataKey="Pending" fill=" #FF7E7E">
                <LabelList dataKey="Pending" position="top" fill="#9DABDD" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="analytics_pie_card">
          <ResponsiveContainer className="Analytics_Responsive_Container">
            <PieChart>
              <Pie
                data={q_data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                dataKey="value"
                nameKey="name"
              >
                {q_data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    style={{ boxShadow: "100px 200px 3px 0px #00000040" }}
                  />
                ))}
              </Pie>
              <Legend
                verticalAlign="middle"
                align="right"
                layout="vertical"
                height={56}
              />
            </PieChart>
          </ResponsiveContainer>
          <div className="classanalytics_card_list">
            <ul style={{ color: "#5CE0D2" }}>
              <li>
                <span style={{ color: "black" }}>
                  <b>Total Questions</b>
                </span>{" "}
                500
              </li>
              <li>
                <span style={{ color: "black" }}>
                  <b>Chapter</b>{" "}
                </span>
                05
              </li>
            </ul>
          </div>
        </div>
      </Row>
      <Row className="classanalytics_section_layer">
        <div className="classanalytics_bar_card">
          <ResponsiveContainer className="Class_Analytics_Responsive_Container">
            <BarChart
              width={400}
              height={400}
              data={ls_data}
              barGap={0}
              margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
            >
              <CartesianGrid strokeDasharray="0 0" vertical={false} />
              <Legend
                verticalAlign="top"
                align="right"
                layout="vertical"
                height={36}
              />
              <XAxis dataKey="name">
                <Label value="Chapters" offset={11} position="bottom" />
              </XAxis>
              <YAxis>
                <Label
                  value="Learning Gap"
                  offset={10}
                  position={isSmallScreen ? "" : "insideLeft"}
                  angle={-90}
                />
              </YAxis>
              <Tooltip />
              <Brush
                dataKey="name"
                height={10}
                stroke="#ddd"
                endIndex={isSmallScreen ? 3 : 5}
              />
              <Bar dataKey="value" fill="#444">
                <LabelList dataKey="value" position="top" fill="#5CE0D2" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          <div className="classanalytics_bullet_list">
            <ul style={{ listStyle: "none" }}>
              <li>
                <input
                  type="radio"
                  name="r1"
                  value="Class/Section"
                  style={{
                    border: "2px solid #5CE0D2 !important",
                    marginRight: "5px",
                  }}
                />
                Class/Section
              </li>
              <li>
                <input
                  type="radio"
                  name="r1"
                  value="Chapters"
                  style={{
                    border: "2px solid #5CE0D2 !important",
                    marginRight: "5px",
                  }}
                />
                Chapters
              </li>
            </ul>
          </div>
          <ResponsiveContainer className="Class_Analytics_Responsive_Container">
            <PieChart>
              <Pie
                data={q_data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                dataKey="value"
                nameKey="name"
              >
                {q_data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    style={{ boxShadow: "100px 200px 3px 0px #00000040" }}
                  />
                ))}
              </Pie>
              <Legend
                verticalAlign="middle"
                align="right"
                layout="vertical"
                height={56}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </Row>
      <TestList />
    </Container>
  );
};

export default ClassAnalytics;
