import "./Selector.css";
function Selector(props) {
  return (
    <div
      style={{ ...props.style }}
      className={`check-outer ${props.selected ? "check-outer-selected" : ""} ${
        props.checkbox ? "check-outer-square" : ""
      }`}
    >
      <div className="check-inner"></div>
    </div>
  );
}

export default Selector;
