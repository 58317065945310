class GradeSorter {
  constructor(unsortedSections) {
    this.sortOrder = [
      "NURSERY",
      "LKG",
      "UKG",
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
      "XI",
      "XII",
    ];
    this.unsortedGrades = unsortedSections;
    this.sortedGrades = [];
    this.groupedGrades = {};
  }

  insertGrade = (grade) => {
    const gradeSection = this.groupedGrades[grade];
    if (!gradeSection) return;
    const sections = Object.keys(gradeSection).sort();
    sections.forEach((section) => {
      this.sortedGrades.push({
        _id: `${grade}-${section}`,
        subjects: gradeSection[section],
      });
    });
    delete this.groupedGrades[grade];
  };

  groupGrades = () => {
    this.unsortedGrades.map((list) => {
      var grade = null,
        section = null;
      const splittedGrade = list._id.split("-");
     
      if (splittedGrade.length === 3) {
        grade = `${splittedGrade[0]}-${splittedGrade[1]}`;
        section = `${splittedGrade[splittedGrade.length - 1]}`;
      } else {
        grade = splittedGrade[0];
        section = splittedGrade[1];
      }
      if (this.groupedGrades[grade]) {
        this.groupedGrades[grade][section] = list.subjects;
      } else {
        this.groupedGrades[grade] = { [section]: list.subjects };
      }
    });
    this.unsortedGrades = {};
  };

  sortGrades = () => {
    this.sortOrder.forEach(this.insertGrade);
  };

  appendRemainingGrades = () => {
    const remainingGrades = Object.keys(this.groupedGrades);
    remainingGrades.forEach(this.insertGrade);
  };

  sort = () => {
    this.groupGrades();
    this.sortGrades();
    this.appendRemainingGrades();
    return this.sortedGrades;
  };
}
export default GradeSorter;

