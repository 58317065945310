import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import readXlsxFile from "read-excel-file";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import PropTypes from "prop-types";
import Assesment from "./Assesment";
import Analytics from "../AssessmentAnalytics/Analytics";
// import OnlineTest from "./OnlineTest"
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GlobalContext } from "../../context/GlobalState";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.js";
import { useAlert, types } from "react-alert";
// import ParseQuestion from '../../utils/questionparser';
import ParseQuestion from "../../utils/QuestionParser-v6";
import moduleImg from "../../images/module.svg";
import assessmentImg from "../../images/assessment.svg";
import analyticGraphImg from "../../images/analyticGraph.svg";
import assignImg from "../../images/assign.svg";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#edfcfa",
    boxShadow: "none",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function MyAssessment() {
  document.title = "2xcell.in";
  const [selectedClass, setSelectedClass] = useState("Class-Section");
  const [section, setSection] = useState("Section");
  const [subjectName, setSubjectName] = useState("Subject");
  const [chapterName, setChapterName] = useState("Chapter");
  const [topicName, setTopicName] = useState("Topic");
  // const chapterId = props.match.params.chapterId;
  const subjectId = "";
  const topicId = "";
  window.localStorage.setItem("subjectId", subjectId);
  window.localStorage.setItem("topicId", topicId);

  const { userDetails, sectionId } = useContext(GlobalContext);

  console.log(sectionId, 6664, window.localStorage.getItem("topicId"));
  window.localStorage.setItem("userId", userDetails.userId);

  const alert = useAlert();

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [heading, setHeading] = useState("Analytics");
  //   const [border,setBorder]=useState()
  //   const [assignedSections, setAssignedSections] = useState([]);
  //   const [assesmentMessage, setAssesmentMessage] = useState('');
  //   const [moduleMessage, setModuleMessage] = useState('');
  //   const [assesmentContentLoader, setAssesmentContentLoader] = useState(false);
  //   const [moduleContentLoader, setModuleContentLoader] = useState(false);
  const [moduleTaskTypeId, setModuleTaskTypeId] = useState("");
  //   const [assessmentTaskTypeId, setAssessmentTaskTypeId] = useState('');
  const [moduleMakeTaskLoader, setModuleMakeTaskLoader] = useState(false);
  const [assessmentMakeTaskLoader, setAssessmentMakeTaskLoader] =
    useState(false);
  //   // used on Module.jsx page
  //   const [ownModules, setOwnModules] = useState([]);
  //   const [fetchOwnModulesLoader, setFetchOwnModulesLoader] = useState(true);
  //   // used on Assessment.jsx page
  //   const [ownAssessment, setOwnAssessment] = useState([]);
  //   const [fetchOwnAssessmentLoader, setFetchOwnAssessmentLoader] =
  //     useState(true);
  const [questions, setQuestions] = useState([]);
  const [parsedQuestions, setParsedQuestions] = useState({
    questions: null,
    correctAnswers: null,
  });
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeHeading = (val) => {
    setHeading(val);
  };

  const handleFileChange = async (e) => {
    const files = e.target.files[0];
    if (files) {
      const type = files.name.split(".")[1];
      if (type.toLowerCase() !== "xlsx") {
        alert.show("Please upload excel file", { type: "error" });
        e.target.value = null;
        return;
      }
    }
    await readXlsxFile(files).then(async (rows) => {
      console.log(rows);
      let headers = rows[0];
      rows.forEach(async (row, index) => {
        if (index != 0) {
          let newObj = {};
          for (let i in headers) {
            newObj[headers[i]] = row[i];
          }

          questions.push(newObj);
          setQuestions(questions);
        }
      });

      const parseQuestions = new ParseQuestion(questions);
      let result = parseQuestions.getQuestionsAndCorrectAnswers();

      parsedQuestions.questions = result.questions;
      parsedQuestions.correctAnswers = result.correctAnswers;

      setParsedQuestions(parsedQuestions);
    });
  };

  const moduleTaskTypeModalFunc = (id) => {
    setModuleTaskTypeId(id);
    const myModal = new Modal(document.getElementById("moduleTaskTypeModal"));
    myModal.toggle();
  };

  console.log("1221", selectedClass, subjectName, chapterName, topicName);
  return (
    <>
      <section className="modules my-4">
        <div className="container">
          <div className="box">
            <div className="upperBox">
              <div className="text-center text-sm-start">
                <div className="row">
                  <div className="whiteDiv col-sm-10">
                    <h1 style={{ fontSize: "36px", fontWeight: "600" }}>
                      {heading}
                    </h1>
                    {selectedClass !== "Class-Section" &&
                    section !== "Section" ? (
                      <h5>{selectedClass}</h5>
                    ) : (
                      ""
                    )}
                    {/* <h1>{topicName}</h1> */}
                    <p>
                      {subjectName != "Subject" ? `${subjectName} >` : ""}{" "}
                      {chapterName != "Chapter" ? `${chapterName} >` : ""}{" "}
                      {topicName != "Topic" ? topicName : ""}
                      {/* {heading} */}
                    </p>
                  </div>
                  <div className="col-sm-2">
                    <span>
                      <CalendarTodayIcon />{" "}
                      {new Date().toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                  {/* <div className='col-sm-2 mt-4'>
                    <Link
                      to='/createtest'
                      className=' fw-bolder createTest'
                      style={{ textDecoration: 'none' }}
                    >
                      <AddBoxOutlinedIcon /> Create Assessment
                    </Link>
                    
                    <p
                      className='fw-bolder my-3'
                      data-bs-toggle='modal'
                      data-bs-target='#assesmentContentUploadModal'
                      style={{ cursor: 'pointer' }}
                    >
                      <CloudUploadOutlinedIcon /> Upload Assessment
                    </p>
                  </div> */}
                </div>
              </div>
            </div>

            <Paper className={classes.root}>
              <Tabs
                value={value}
                onChange={handleChange}
                style={{ background: "#DCFFFB" }}
                indicatorColor="primary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons={"on"}
              >
                <Tab
                  style={{ color: "#10A0B6" }}
                  icon={
                    <img
                      src={analyticGraphImg}
                      style={{ height: "23px", width: "23px" }}
                    />
                  }
                  label="Anaytics"
                  {...a11yProps(0)}
                  onClick={() => changeHeading("Analytics")}
                />
                <Tab
                  style={{ color: "#10A0B6" }}
                  icon={
                    <img
                      src={assignImg}
                      style={{ height: "23px", width: "23px" }}
                    />
                  }
                  label="Assign Task"
                  {...a11yProps(1)}
                  onClick={() => changeHeading("Modules")}
                />
                <Tab
                  style={{ color: "#10A0B6" }}
                  icon={
                    <img
                      src={assessmentImg}
                      style={{ height: "23px", width: "23px" }}
                    />
                  }
                  label="Assessment"
                  {...a11yProps(2)}
                  onClick={() => changeHeading("Assessment")}
                />
              </Tabs>
            </Paper>

            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                {moduleMakeTaskLoader && (
                  <div className="text-center">
                    <CircularProgress
                      style={{ color: "#FF075B", fontWeight: "600" }}
                    />
                  </div>
                )}
                <Analytics
                  setSection={setSection}
                  setSelectedclassName={setSelectedClass}
                  setSubjectName={setSubjectName}
                />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {assessmentMakeTaskLoader && (
                  <div className="text-center">
                    <CircularProgress
                      style={{ color: "#FF075B", fontWeight: "600" }}
                    />
                  </div>
                )}
                {/* <Assesment
                  subjectName={subjectName}
                  chapterName={chapterName}
                  topicName={topicName}
                /> */}
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                {assessmentMakeTaskLoader && (
                  <div className="text-center">
                    <CircularProgress
                      style={{ color: "#FF075B", fontWeight: "600" }}
                    />
                  </div>
                )}
                <Assesment
                  setSection={setSection}
                  setSelectedclassName={setSelectedClass}
                  setSubjectName={setSubjectName}
                  setChapterName={setChapterName}
                  setTopicName={setTopicName}
                />
              </TabPanel>
            </SwipeableViews>
          </div>
        </div>
      </section>
    </>
  );
}
