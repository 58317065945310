import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useDisableContextMenu from "../../../utils/useDisableContextMenu";

import "../../MyResources/styles/docViewer.css";
import "../../MyResources/MediaViewers/mediaViewer.css";

import { LessonPlanAPIs } from "../api";
import axios from "axios";

const pdfViewer = {
  width: "90%",
  height: "150%",
  margin: "auto",
  marginTop: "1rem",
  paddingTop: "1rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const VideoStyle = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginTop: "1rem",
  alignItems: "center",
  paddingTop: "2rem",
};

const ImageBox = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "2rem",
  marginTop: "1rem",
};

const image = {
  margin: "auto",
};

function Image(props) {
  return (
    <div style={ImageBox}>
      <img style={image} src={props.fileUrl} alt={props.fileUrl} />
    </div>
  );
}

function Video(props) {
  useDisableContextMenu();
  return (
    <div style={VideoStyle}>
      <video
        width="60%"
        style={{
          marginTop: "2rem",
          border: "5px solid #5ce0d2",
          borderRadius: "3px",
        }}
        controls
        controlsList="nodownload"
      >
        <source src={props.fileUrl} type="video/mp4" />
        <source src={props.fileUrl} type="video/ogg" />
        <source src={props.fileUrl} type="video/swf" />
        Your browser does not support HTML video.
      </video>
    </div>
  );
}

function Document(props) {
  const history = useHistory();
  const [loaded, setLoaded] = useState(true);
  const [docs, setDocs] = useState([]);
  useEffect(() => {
    setDocs([{ uri: props?.fileUrl }]);
    const pdfViewer = document.getElementById("pdf_iframe");
    pdfViewer.addEventListener("load", () => {});
    pdfViewer.addEventListener("error", (error) => {
      console.log("Error Occured on loading");
      console.log(error);
    });
  }, []);

  useEffect(() => {
    if (!props.fileUrl) {
      history.goBack();
    }
  }, [props.location]);

  return (
    <div style={pdfViewer}>
      <iframe
        id="pdf_iframe"
        src={props?.fileUrl}
        width="90%"
        height="800px"
        style={{ display: loaded ? "block" : "none" }}
      ></iframe>
      {/* <DocViewer documents={docs} /> */}
      <h4
        className="text-center"
        style={{ display: !loaded ? "block" : "none", marginTop: "1rem" }}
      >
        <div className="spinner">
          Loading
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </h4>
    </div>
  );
}

function Audio(props) {
  return (
    <div style={VideoStyle}>
      <audio
        width="60%"
        style={{ marginTop: "2rem" }}
        controls
        controlsList="nodownload"
      >
        <source src={props.fileUrl} type="audio/mp3" />
        <source src={props.fileUrl} type="audio/ogg" />
        <source src={props.fileUrl} type="audio/swf" />
        Your browser does not support HTML audio.
      </audio>
    </div>
  );
}

function Viewer(props) {
  console.log(props);
  return (
    {
      view: <Document fileUrl={props.fileUrl} />,
      audio: <Audio fileUrl={props.fileUrl} />,
      video: <Video fileUrl={props.fileUrl} />,
      show: <Image fileUrl={props.fileUrl} />,
    }[props.viewer] || <></>
  );
}

function ViewModule() {
  const params = useParams();

  const [moduleState, setModuleState] = useState({});

  const fileType = (type) => {
    if (["png", "jpg", "jpeg", "gif"].includes(type)) {
      return "Image";
    } else if (["pdf", "txt"].includes(type)) {
      return "Document";
    } else if (["mp4", "mkv", "webm", "m4v"].includes(type)) {
      return "Video";
    } else if (type === "swf") {
      return "swfVideo";
    } else if (["ppt", "pptx"].includes(type)) {
      return "PowerPoint";
    } else if (type === "html") {
      return "Activity";
    } else if (type === "mp3") {
      return "Audio";
    } else if (type === "docx") {
      return "WordDocument";
    } else if (type === "doc") {
      return "WordDocument";
    } else {
      return "Unsupported";
    }
  };

  //Function to decide the action to be executed based on the file type
  const actionType = (name) => {
    const type = fileType(name);
    if (type === "Video") {
      return "watch";
    } else if (type === "Image") {
      return "show";
    } else if (
      type === "Document" ||
      type === "PowerPoint" ||
      type === "WordDocument"
    ) {
      return "view";
    } else if (type === "swfVideo") {
      return "watchSWF";
    } else if (type === "Audio") {
      return "listen";
    }
  };

  const getModuleType = (resources) => {
    var result = { notFound: true };
    const modulesTypes = [
      ["html"],
      ["mp4", "mkv", "m4v"],
      ["pptx", "ppt", "docx", "pdf"],
      ["mp3"],
      ["png", "jpg", "jpeg"],
    ];
    var move = 0,
      lastStep = modulesTypes.length - 1;
    while (result.notFound) {
      result = findMainModuleFile(resources, modulesTypes[move], result);
      if (move === lastStep) break;
      move += 1;
    }
    return result;
  };

  const findMainModuleFile = (resources, mainFileTypes, data) => {
    for (let resource of resources) {
      const nameParts = resource.fileName?.split(".");
      const fileType = nameParts[nameParts.length - 1].toLowerCase();
      if (mainFileTypes.includes(fileType)) {
        data["notFound"] = false;
        data["resource"] = resource;
        data["fileType"] = fileType;
        break;
      }
    }
    return data;
  };

  const fixFileUrl = (fileUrl, fileName) => {
    if (!fileUrl) {
      return false;
    }

    if (!fileUrl.startsWith("https://") && fileUrl) {
      fileUrl = fileUrl.split(" ").join("%20");
      fileUrl = "https://xcell-cms-files.s3.amazonaws.com" + fileUrl;
    }

    if (
      fileType(fileName) === "PowerPoint" ||
      fileType(fileName) === "WordDocument"
    ) {
      return "https://view.officeapps.live.com/op/embed.aspx?src=" + fileUrl;
      // return "https://docs.google.com/gview?url=" + fileUrl;
    } else if (fileType(fileName) === "Document") {
      return `${fileUrl}#toolbar=0`;
    } else {
      return fileUrl;
    }
  };

  useEffect(() => {
    //fetch module
    axios
      .get(LessonPlanAPIs.getModule(params.moduleId), {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
        validateStatus: () => true,
      })
      .then((res) => {
        if (res.data.status !== "success") throw res.data;
        const moduleType = getModuleType(res.data.data?.module?.resources);
        if (moduleType.notFound) return;
        console.log("Module Type", moduleType.fileType);
        console.log("File Type", fileType(moduleType.fileType));
        console.log("Viewer", actionType(moduleType.fileType));
        setModuleState({
          fileUrl: fixFileUrl(
            moduleType.resource.fileUrl,
            moduleType.resource.fileName
          ),
          viewer:actionType(moduleType.fileType),
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return <Viewer {...moduleState} />;
}

export default ViewModule;
