/* Imports */
import "../AssessmentAnalytics/PracticeAssessment.css";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  Label,
  YAxis,
  Bar,
  LabelList,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
  Brush,
  PieChart,
  Pie,
} from "recharts";
import { FilterComponent,CalenderComponent } from "./Sidebar/DropDown";

import { Col, Container, Row } from "react-bootstrap";
import ClassReport from "./Tables/ClassReport";
import Sideboard from "./SideboardOT/Sideboard";
import Backbtn from "../../images/Arrow 9.svg";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import exportFromJSON from "export-from-json";
import SearchBar from "../OnlineTest/TestList/SearchBar/SearchBar";
import ExportButton from "../../images/export (1).svg";
import DropDownShutter from "../DropDown/DropDown";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Moment from "react-moment";
import { makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";
import HomeIcon from "../../images/HomeIcon.svg";
import assignImg from "../../images/assign.svg";
import assessmentImg from "../../images/assessment.svg";
import analyticGraphImg from "../../images/analyticGraph.svg";
import Box from "@material-ui/core/Box";
import { GlobalContext } from "../../context/GlobalState";
/* End of imports */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const OnlineTestStudentAnalytics = () => {
  /*Instance for Visibility of Topic Graphs */
  const [show, setShow] = useState("none");

  const location = useLocation();
  console.log("LOcation", location);
  const params = useParams();
  console.log(params);
  const grade = params.class;
  const section = params.section;
  const subject = params.subject;
  localStorage.setItem("gradeSection", `${grade}-${section}`);
  localStorage.setItem("subject", subject);
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#edfcfa",
      boxShadow: "none",
    },
    root1: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    card: {
      width: "100%",
      borderRadius: "10px",
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
      padding: "20px",
      margin: "20px auto",
    },
    subjectSection: {
      textAlign: "center",
      "& button": {
        border: "none",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        marginBottom: "10px",
        padding: "4px",
        minWidth: "120px",
        color: "white",
      },
      "& p": {
        marginBlockStart: "0px",
        marginBlockEnd: "0px",
        fontSize: "0.8em",
      },
    },
    report: {
      textAlign: "center",
      "& a": {
        backgroundColor: "rgba(29, 205, 109, 0.78)",
        padding: "7px 10px",
        borderRadius: "10px",
        color: "white",
        textDecoration: "none",
        height: "fit-content",
        transition: "0.2s",
        "& span": {
          backgroundColor: "rgba(112, 253, 175, 0.73)",
          color: "white",
          borderRadius: "50%",
          height: "fit-content",
          width: "fit-content",
          padding: "3px",
          verticalAlign: "middle",
        },
        "&:hover": {
          backgroundColor: "rgba(29, 205, 109, 1)",
          color: "white !important",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    report2: {
      textAlign: "center",
      "& a": {
        backgroundColor: "rgba(0, 121, 234, 0.78);",
        padding: "7px 10px",
        borderRadius: "10px",
        color: "white",
        textDecoration: "none",
        height: "fit-content",
        transition: "0.2s",
        "& span": {
          backgroundColor: "rgba(0, 121, 234, 0.73);",
          color: "white",
          borderRadius: "50%",
          height: "fit-content",
          width: "fit-content",
          padding: "3px",
          verticalAlign: "middle",
        },
        "&:hover": {
          backgroundColor: "rgba(0, 121, 234, 1);",
          color: "white !important",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    menu: {
      textAlign: "center",
    },
    contextMenu: {
      "& .MuiList-root": {
        padding: "10px 10px 5px 10px",
      },
      "& .MuiPaper-rounded": {
        borderRadius: "5px !important",
      },
      "& li": {
        color: "white",
        fontSize: "0.9em",
        background: "rgba(180, 107, 130, 0.8)",
        marginBottom: "5px",
        borderRadius: "5px !important",
        transition: "0.3s",
        "&:hover": {
          background: "rgba(180, 107, 130, 1)",
          fontWeight: "bold",
        },
      },
    },
    "@media screen and (max-width: 472px)": {
      card: {
        width: "95%",
        padding: "10px 7px",
      },
      subjectSection: {
        "& button": {
          fontSize: "0.8em",
          minWidth: "80px",
        },
        "& p": {
          fontSize: "0.6em",
        },
      },
      report: {
        "& a": {
          fontSize: "0.7em",
        },
      },
      report2: {
        "& a": {
          fontSize: "0.7em",
        },
      },
      contextMenu: {
        padding: "5px 5px 3px 5px",
        fontSize: "0.7em",
        "& li": {
          minHeight: "35px",
        },
      },
    },
  }));
  const { userDetails, setUserDetails } = useContext(GlobalContext);
  const [heading, setHeading] = useState("Analytics");

  const [sectionId, setSectionId] = useState(params.sectionId);
  const [subjectValue, setSubjectValue] = useState(params.subjectId);
  const [chapterValue, setChapterValue] = useState(params.chapterId);
  const [topicValue, setTopicValue] = useState(params.topicId);
  const [studentId, setStudentId] = useState(params.studentId);
  const queryState = { subject: params.subjectId };
  useEffect(() => {
    params.chapterId
      ? (queryState["chapter"] = params.chapterId)
      : (queryState["subject"] = params.subjectId);
    params.topicId
      ? (queryState["topic"] = params.topicId)
      : (queryState["subject"] = params.subjectId);
  }, []);
  const [numStudents, setNumStudents] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [ts_total, setTs_total] = useState(0);
  const [ta_total, setTa_total] = useState(0);
  const [qa_total, setQa_total] = useState(0);
  const [qs_total, setQs_total] = useState(0);
  const [qr_total, setQr_total] = useState(0);
  const [miniCard, setMiniCard] = useState({
    Tests_Assigned: 0,
    Questions_Assigned: 0,
    Score: 0,
    ClassScore: 0,
    Time: 0,
    ClassTime: 0,
  });

  /*Instances for Loaders*/
  const [ts_loader, setTs_loader] = useState(false);
  const [tta_loader, setTta_loader] = useState(false);
  const [qs_loader, setQs_loader] = useState(false);
  const [qr_loader, setQr_loader] = useState(false);
  const [qa_loader, setQa_loader] = useState(false);
  const [dclr_loader, setDclr_loader] = useState(false);
  const [dclcr_loader, setDclcr_loader] = useState(false);
  const [lsg_loader, setLsg_loader] = useState(false);
  const [table_loader, setTable_loader] = useState(false);
  const [tabData,setTabData]=useState([])
  const [filterReports,setFilterReports]=useState({
    name:"",
    subject:"",
    date:"",
    testType:"",
    grade:""
  })
  const [students,setStudents]=useState([]);
  const [subjects,setSubjects]=useState([]);
  const [allgrade,setAllGrade]=useState([]);
  const [filterByDate,setFilterByDate]=useState({date:""})

  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState(1);

  /*Handling Visibility of Topic Graphs*/
  const handleShow = () => {
    show === "none" ? setShow("unset") : setShow("none");
  };

  /*Test Status All Classes*/
  const [t_taken, setT_taken] = useState([
    {
      name: "",
      Class: 0,
      Student: 0,
    },
  ]);

  const renderCustomizedLabels  = (props) => {
    const { x, y, width, height, value,stroke } = props;
    const radius = 10;
    console.log(props.value);
  
    return (
      <g>
        {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
        <text x={x + width / 2} y={y +height/2} fill={stroke}   textAnchor="middle" dominantBaseline="middle">
        {/* <foreignObject x={0} y={0} width={100} height={100}> */}
        {props.value?props.value:""}
        {/* </foreignObject> */}
         
        </text>
      </g>
    );
  };
  const [t_score, setT_score] = useState([
    {
      name: "",
      Class: 0,
      Student: 0,
    },
  ]);

  /*Total Tests Assigned */
  const [T_tests, setT_tests] = useState([
    {
      name: "",
      value: 0,
    },
  ]);

  /*Question Report */
  const [qr_data, setQr_data] = useState([
    { name: "Correct", value: 0 },
    { name: "Incorrect", value: 0 },
    { name: "Unattempted", value: 0 },
  ]);
  const [dlq_report, setDlq_report] = useState([
    { name: "Easy", value: 0 },
    { name: "Medium", value: 0 },
    { name: "Difficult", value: 0 },
  ]);
  const [clq_report, setClq_report] = useState([
    { name: "Knowledge", value: 0 },
    { name: "Understanding", value: 0 },
    { name: "Application", value: 0 },
    { name: "Analysis", value: 0 },
  ]);

  /*Questions Assigned */
  const [qa_data, setQa_data] = useState([
    {
      name: "",
      value: 0,
    },
  ]);

  /*Questions Status */
  const [q_status, setQ_status] = useState([
    {
      name: "",
      Correct: 0,
      Incorrect: 0,
      Unattempted: 0,
    },
  ]);

  /*Learning Strength and Gap */
  const [lsg_data, setLsg_data] = useState([
    {
      name: "",
      Gap: 0,
      Strength: 0,
    },
  ]);

  /*Difficulty Level Questions */
  const [dlq_data, setDlq_data] = useState([
    {
      name: "",
      Easy: 0,
      Medium: 0,
      Difficult: 0,
    },
  ]);

  /*Cognitive Level Questions */
  const [clq_data, setClq_data] = useState([
    {
      name: "",
      Knowledge: 0,
      Understanding: 0,
      Application: 0,
      Analysis: 0,
    },
  ]);

  /*Correct and Total Difficulty Level Questions */
  const [correct_dlq_data, setCorrect_dlq_data] = useState([
    {
      name: "",
      easy: {
        Easy: 0,
        "Total Q'": 0,
      },
      medium: {
        Medium: 0,
        "Total Q'": 0,
      },
      difficult: {
        Difficult: 0,
        "Total Q'": 0,
      },
    },
  ]);

  /*Correct and Total Cognitive Level Questions */
  const [correct_clq_data, setCorrect_clq_data] = useState([
    {
      name: "",
      knowledge: {
        Knowledge: 0,
        "Total Q'": 0,
      },
      understanding: {
        Understanding: 0,
        "Total Q'": 0,
      },
      application: {
        Application: 0,
        "Total Q'": 0,
      },
      analysis: {
        Analysis: 0,
        "Total Q'": 0,
      },
    },
  ]);

  /*Colors for Pie Chart*/
  const COLORS = ["#10A0B6", "#FF8989", "#9DABDD"];
  const COLORS2 = ["#10A0B6", "#FF8989", "#9DABDD", "#56E58F"];

  /*Handling Customized Labelling of Pie Chart*/
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {percent!==0&&`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  /*React Media Query*/
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });

  /*Instance for Dropdown*/
  const [dropdown, setdropdown] = useState(isSmallScreen ? false : true);

  /*Function for toggling the Shutter in Mobile View */
  const handleShutterOpen = () => {
    setdropdown(!dropdown);
  };

  /*Function for switching between tabs */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /*Function for switching the headings according to tabs */
  const changeHeading = (val) => {
    setUserDetails({ ...userDetails, selectedTab: val, pathName: {} });
    history.goBack();
    setHeading(val);
  };

  /*Fetching Mini Card Data*/
  const fetchMiniCardData = async () => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/studentoverallteststatus?section=${params?.sectionId}&student=${params?.studentId}&subject=${params?.subjectId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        const newMini_card = {
          Tests_Assigned: 0,
          Questions_Assigned: 0,
          Score: 0,
          ClassScore: 0,
          Time: 0,
          ClassTime: 0,
        };
        response.data.data.analytics.forEach((element) => {
          newMini_card.Tests_Assigned = `${element.totalTests}`;
          newMini_card.Questions_Assigned = `${element.attemptedQuestions}/${element.totalQuestions}`;
          newMini_card.Score = element.score;
          newMini_card.ClassScore = element.gradeAvgScore;
          newMini_card.Time = `${Math.floor(
            element.timeTakenInTest / 3600000
          )}h ${Math.floor(
            (element.timeTakenInTest % 3600000) / 60000
          )}m ${Math.floor((element.timeTakenInTest / 1000) % 60)}s`;
          newMini_card.ClassTime = `${Math.floor(
            element.avgTimeTakenInQues / 3600000
          )}h ${Math.floor(
            (element.avgTimeTakenInQues % 3600000) / 60000
          )}m ${Math.floor((element.avgTimeTakenInQues / 1000) % 60)}s`;
        });
        setMiniCard(newMini_card);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    console.log(payload,label,active);

    if (active && payload && payload.length) {
      return (
        <div style={{backgroundColor:"#fff",boxShadow:"1px 2px 10px #ccc",padding:"10px",borderRadius:"7px"}} className="custom-tooltip">
          <p className="label">{`${payload[0].name} : ${payload[0].payload.time}`}</p>
          <p className="label">{`${payload[1].name} : ${payload[1].payload.time}`}</p>
          {/* <p className="intro">{getIntroOfPage(label)}</p> */}
          {/* <p className="desc">Anything you want can be displayed here.</p> */}
        </div>
      );
    }
  
    return null;
  };
  
  /*Fetching Data For Test Status*/
  const fetchTimeTaken = async () => {
    setTs_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_NEWORIGIN
        }api/v1/testanalytics/timetakenintest?section=${params?.sectionId}&student=${params?.studentId}&${new URLSearchParams(
          queryState
        ).toString()}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        var newt_taken = response.data.data.analytics.map((element) => {
          return {
            name: `${
              params.topicId
                ? element.testId.questionSet.title
                : element.chapterId.name
            }`,
            Class: ((element.timeTakenByGrade % 3600000) / 60000).toPrecision(
              4
            ),
            Student: (
              (element.timeTakenByStudent % 3600000) /
              60000
            ).toPrecision(4),
            time:`${Math.floor(element.timeTakenByGrade/(1000*60))}m ${parseFloat((element.timeTakenByGrade/1000)%60).toFixed(0)}s`
          };
        });
        setT_taken(newt_taken);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setTs_loader(false);
  };
  const fetchTestScore = async () => {
    setTs_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_NEWORIGIN
        }api/v1/testanalytics/totalscoreintest?section=${params?.sectionId}&student=${params?.studentId}&${new URLSearchParams(
          queryState
        ).toString()}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        var newt_score = response.data.data.analytics.map((element) => {
          return {
            name: `${
              params.topicId
                ? element.testId.questionSet.title
                : element.chapterId.name
            }`,
            Class: element.scoreOfGrade,
            Student: element.scoreOfStudent,
          };
        });
        setT_score(newt_score);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setTs_loader(false);
  };

  /*Fetching Data for Questions Status */
  const fetchQuestionsStatus = async () => {
    setQs_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_NEWORIGIN
        }api/v1/testanalytics/questionsstatus?section=${params?.sectionId}&student=${params?.studentId}&${new URLSearchParams(
          queryState
        ).toString()}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtotal = 0;
        var newq_status = response.data.data.analytics.map((element) => {
          newtotal += element.totalQuestions;
          return {
            name: `${
              params.topicId
                ? element.testId.questionSet.title
                : element.chapterId.name
            }`,
            Correct: element.totalCorrect,
            Incorrect: element.totalIncorrect,
            Unattempted: element.totalUnattempted,
          };
        });
        setQs_total(newtotal);
        setQ_status(newq_status);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setQs_loader(false);
  };
  const fetchQuestionsReport = async () => {
    setQr_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_NEWORIGIN
       }api/v1/testanalytics/questionsreport?section=${params?.sectionId}&student=${params?.studentId}&${new URLSearchParams(
          queryState
        ).toString()}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtotal = 0;
        const newqr_data = [
          { name: "Correct", value: 0 },
          { name: "Incorrect", value: 0 },
          { name: "Unattempted", value: 0 },
        ];
        response.data.data.analytics.forEach((element) => {
          newtotal += element.questions;
          newqr_data[0].value += element.correct;
          newqr_data[1].value += element.incorrect;
          newqr_data[2].value += element.unattempted;
        });
        setQr_total(newtotal);
        setQr_data(newqr_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setQr_loader(false);
  };

  const fetchDifficultyAndCognitiveReport = async () => {
    setDclr_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/questionlevelstatuspersubjects?section=${params?.sectionId}&student=${params?.studentId}&subject=${params?.subjectId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        const newdlq_data = [
          { name: "Easy", value: 0 },
          { name: "Medium", value: 0 },
          { name: "Difficult", value: 0 },
        ];
        const newclq_data = [
          { name: "Knowledge", value: 0 },
          { name: "Understanding", value: 0 },
          { name: "Application", value: 0 },
          { name: "Analysis", value: 0 },
        ];
        response.data.data.analytics.forEach((element) => {
          newdlq_data[0].value += element.difficulty.easy;
          newdlq_data[1].value += element.difficulty.intermediate;
          newdlq_data[2].value += element.difficulty.hard;
        });
        response.data.data.analytics.forEach((element) => {
          newclq_data[0].value += element.cognitive.knowledge;
          newclq_data[1].value += element.cognitive.understanding;
          newclq_data[2].value += element.cognitive.application;
          newclq_data[3].value += element.cognitive.analysis;
        });
        setDlq_report(newdlq_data);
        setClq_report(newclq_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setDclr_loader(false);
  };
  /*Fetching Data for Difficulty and Cognitive Correct and Total Status */
  const fetchDifficultyAndCognitiveCorrectStatus = async () => {
    setDclcr_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_NEWORIGIN
        }api/v1/testanalytics/questioncorrectlevelstatus?section=${params?.sectionId}&student=${params?.studentId}&${new URLSearchParams(
          queryState
        ).toString()}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        var newcorrect_dlq_data = response.data.data.analytics.map(
          (element) => {
            return {
              name: `${
                params.topicId
                  ? element.testId.questionSet.title
                  : element.chapterId.name
              }`,
              easy: {
                Easy: element.difficulty.easy.correct,
                "Total Q'": element.difficulty.easy.total,
              },
              medium: {
                Medium: element.difficulty.intermediate.correct,
                "Total Q'": element.difficulty.intermediate.total,
              },
              difficult: {
                Difficult: element.difficulty.hard.correct,
                "Total Q'": element.difficulty.hard.total,
              },
            };
          }
        );
        var newcorrect_clq_data = response.data.data.analytics.map(
          (element) => {
            return {
              name: `${
                params.topicId
                  ? element.testId.questionSet.title
                  : element.chapterId.name
              }`,
              knowledge: {
                Knowledge: element.cognitive.knowledge.correct,
                "Total Q'": element.cognitive.knowledge.total,
              },
              understanding: {
                Understanding: element.cognitive.understanding.correct,
                "Total Q'": element.cognitive.understanding.total,
              },
              application: {
                Application: element.cognitive.application.correct,
                "Total Q'": element.cognitive.application.total,
              },
              analysis: {
                Analysis: element.cognitive.analysis.correct,
                "Total Q'": element.cognitive.analysis.total,
              },
            };
          }
        );
        setCorrect_dlq_data(newcorrect_dlq_data);
        setCorrect_clq_data(newcorrect_clq_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setDclcr_loader(false);
  };

  /*Fetching Data For Learning Strength & Learning Gap Of Questions*/
  const fetchLearningStrengthAndGap = async () => {
    setLsg_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_NEWORIGIN
       }api/v1/testanalytics/testlearningstatus?section=${params?.sectionId}&student=${params?.studentId}&${new URLSearchParams(
          queryState
        ).toString()}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        var newlsg_data = response.data.data.analytics.map((element) => {
          return {
            name: `${
              params.topicId
                ? element.testId.questionSet.title
                : element.chapterId.name
            }`,
            Gap: element.gap,
            Strength: element.strength,
          };
        });
        setLsg_data(newlsg_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setLsg_loader(false);
  };

  const fetchClassReportData = async () => {
    setTable_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/classreports?student=${params?.studentId}&subject=${params?.subjectId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setTableData(response.data.data.analytics);
        setTabData(response.data.data.analytics);
        setTable_loader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*Sequence of API Calls */
  useEffect(async () => {
    await fetchMiniCardData();
    await fetchTimeTaken();
    await fetchTestScore();
    await fetchQuestionsStatus();
    await fetchQuestionsReport();
    await fetchDifficultyAndCognitiveCorrectStatus();
    await fetchDifficultyAndCognitiveReport();
    await fetchLearningStrengthAndGap();
    await fetchClassReportData();
  }, [params]);

  useEffect(()=>{
    console.log(tableData);
    let dt=tabData;
    if(filterReports.name)
    {
        dt=dt.filter(item=>item.studentId.name===filterReports?.name?.name)
      
    }
    if(filterReports.subject)
    {
      dt=dt.filter(item=>item.subjectId.name===filterReports?.subject?.name)
    }
    if(filterReports.grade)
    {
      dt=dt.filter(item=>`${item.studentId.grade}-${item.studentId.section}`===filterReports.grade._id)
    }
    if (filterReports.testType)
    {
      dt=dt.filter(item=>item.testType===filterReports.testType._id);
    }

    if(filterByDate.date)
    {
      
      dt=dt.filter(item=>new Date(item.startedAt.split("T")[0])>=new Date(filterByDate.date))
    }
    
    console.log(dt);
    console.log(filterReports);
    setTableData(dt);


  },[filterByDate,filterReports])

  /*Function To Export Class Report Data*/
  const exportCsv = () => {
    const data = tableData;
    const formatted_data = data.map((element) => {
      return {
        Subject: element.subjectId.name,
        Chapter: element.chapterId.name,
        Topic: element.topicId.name,
        Grade: element.grade,
        Section: element.section,
        Students: element.students,
        "Start Time": element.startedAt,
        Questions: element.questions,
        "Questions Attempted": element.questionsAttempted,
        Score: element.score,
        Easy: element.easy,
        Intermediate: element.intermediate,
        Hard: element.hard,
        Correct: element.correct,
        Incorrect: element.incorrect,
        "Time Spent": `${
          Math.floor(element.timeSpend / 3600000) +
          "h" +
          Math.floor((element.timeSpend % 3600000) / 60000) +
          "m" +
          Math.floor((element.timeSpend / 1000) % 60) +
          "s"
        }`,
      };
    });
    const fileName = "Class Report";
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data: formatted_data, fileName, exportType });
  };

  const search = () => {
    console.log(tableData);
    let searchData = tableData.filter(
      (data) =>
        data.studentId?.name
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        data.subjectId?.name
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        data?.testId?.questionSet?.title
          .toLowerCase()
          .includes(searchValue.toLowerCase())
    );
    setTableData(searchData);
  };

  useEffect(() => {
    //when the search box is empty
    if (!searchValue) {
      fetchClassReportData();
    } else {
      search();
    }
  }, [searchValue]);

  /*Colors for multicolored bar graphs */

  useEffect(()=>{
    if(filterReports.grade)
    {
      setFilterReports({...filterReports,name:""});
      const getName=async()=>{
        const authToken = localStorage.getItem("authToken");
      try {
        const grade = filterReports.grade._id.split("-")[0];
        const section = filterReports.grade._id.split("-")[1];
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/students`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.status === 200) {
          setStudents(response.data.data.students);
         
        }
      } catch (error) {
        console.log(error);
      }
        
      }
  
      getName();
    }
  
  },[filterReports.grade])
  
  const barColors = ["#0F93A8", "#F2931E", "#AE4132", "#23445D"];
  return (
    <>
      <section className={isSmallScreen ? "modules my-4" : "modules my-5"}>
        <div
          className={
            isSmallScreen ? "container-fluid dashboard_container" : "container"
          }
        >
          <div className="box">
            <div
              className="upperBox"
              style={{ display: isSmallScreen ? "none" : "" }}
            >
              <div className="alignItem">
                <div className="row">
                  <div className="boxRow">
                    <div className="boxRowLeft">
                      <h1 className="className">
                        {/* {grade
                          ? `${grade}-${section}`
                          : localStorage.getItem("gradeSection")} */}
                        {params.class}-{params.section}
                      </h1>
                      <div className="pathDetail">
                        <h2 style={{ fontWeight: "700" }}>
                          Analytics &gt; Online Test
                        </h2>
                        <p style={{ fontWeight: "600", color: "#ffffff" }}>
                          {params.subject}  &gt;   {params?.student}
                        </p>
                      </div>
                    </div>
                    <div className="DateBox">
                      <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                        <CalendarTodayIcon />{" "}
                        {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              <Paper className={classes.root}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  style={{
                    background: "#DCFFFB",
                    marginTop: "-12px",
                    display: dropdown ? "" : "none",
                  }}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={"on"}
                >
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={HomeIcon}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Home"
                    {...a11yProps(0)}
                    onClick={() => changeHeading("Dashboard")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={analyticGraphImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Analytics"
                    {...a11yProps(1)}
                    onClick={() => changeHeading("Analytics")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assignImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assign Task"
                    {...a11yProps(2)}
                    onClick={() => changeHeading("Assign Task")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assessmentImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assessment"
                    {...a11yProps(3)}
                    onClick={() => changeHeading("Assessment")}
                  />
                </Tabs>
              </Paper>
            }
            <div
              className="shutter_PS"
              style={{
                marginTop: dropdown ? "0px" : "-15px",
                display: isSmallScreen ? "flex" : "none",
              }}
            >
              <DropDownShutter
                onClick={handleShutterOpen}
                clicked={dropdown}
                dropdown={dropdown}
                setDropDown={setdropdown}
              />
            </div>
          </div>
        </div>
      </section>
      {/*Graphs Section*/}
      <Container fluid="xl" className="analytics_main_section">
        {/*Back Button*/}
        <img
          src={Backbtn}
          className="back_btn"
          onClick={() => history.goBack()}
          style={{ cursor: "pointer" }}
        />
        {/*Side Drawer*/}
        <Sideboard
          sectionId={sectionId}
          setSectionId={setSectionId}
          subjectValue={subjectValue}
          setSubjectValue={setSubjectValue}
          chapterValue={chapterValue}
          setChapterValue={setChapterValue}
          numStudents={numStudents}
          setNumStudents={setNumStudents}
          // setAllStudents={setStudents}
          setAllSubjects={setSubjects}
          setAllGrade={setAllGrade}
          ch_loader={ts_loader}
          q_loader={qr_loader}
          qs_loader={qs_loader}
          qa_loader={qa_loader}
          dlr_loader={dclr_loader}
          clr_loader={dclcr_loader}
          ls_loader={lsg_loader}
        />
        <Row
          className="studentanalytics_mini_cards mx-auto my-5"
          style={{ color: "white" }}
        >
          <div className="student_analytics_mini_cards_section">
            <Col
              className="student_mini_card"
              style={{
                marginLeft: "0px",
                backgroundColor: "#AD73F5",
              }}
            >
              <div>
                <div style={{ textAlign: "center" }}>Test Assigned</div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.Tests_Assigned}</h5>
                </div>
              </div>
            </Col>
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#47CFE9" }}
            >
              <div>
                <div style={{ textAlign: "center" }}>Questions Assigned</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <h5 style={{ textAlign: "center" }}>
                    {miniCard.Questions_Assigned}
                  </h5>
                </div>
              </div>
            </Col>
          </div>
          <div
            className="student_analytics_mini_cards_section"
            style={{ marginLeft: "-2.5rem", marginRight: "-2.5rem" }}
          >
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#F3B592" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Score
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.Score}</h5>
                </div>
              </div>
            </Col>
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#70E2AE" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Class Average
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.ClassScore}</h5>
                </div>
              </div>
            </Col>
          </div>
          <div className="student_analytics_mini_cards_section">
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#0079EADE" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Average Time Taken By Student
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.Time}</h5>
                </div>
              </div>
            </Col>
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#FF7E7E" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Average Time Taken Per Question
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.ClassTime}</h5>
                </div>
              </div>
            </Col>
          </div>
        </Row>
        {/*Chapterwise*/}
        {/*First Layer having Bar Graph and Pie Chart*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Time Taken to Complete The Test by Student Vs Grade</b>
            </h5>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!ts_loader ? (
                t_taken.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={t_taken}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name" />
                    <YAxis>
                      <Label
                        value="Time (min)"
                        position={isSmallScreen ? "" : "insideBottomLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip content={<CustomTooltip />} />
                    {t_taken ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar
                      dataKey="Student"
                      stackId="a"
                      fill="#C56468"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                      
                    >
                      <LabelList
                        // position="inside"
                        fill="#000"
                        dataKey={"time"}
                        style={{fontSize:".7rem"}}
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      dataKey="Class"
                      stackId="a"
                      fill=" #C4C4C459"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        // position="inside"
                        fill="#000"
                        style={{fontSize:".7rem"}}
                        dataKey={"time"}
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
          <div className="analytics_bar_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Test Score Student Vs Grade</b>
            </h5>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!ts_loader ? (
                t_score.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={t_score}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name" />
                    <YAxis>
                      <Label
                        value="Score"
                        position={isSmallScreen ? "" : "insideBottomLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {t_taken ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar
                      dataKey="Student"
                      stackId="a"
                      fill="#1190C4"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        // position="inside"
                        fill="#000"
                        dataKey={"Student"}
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                    <Bar
                      dataKey="Class"
                      stackId="a"
                      fill=" #C4C4C459"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        // position="inside"
                        fill="#000"
                        dataKey={"Class"}
                        content={renderCustomizedLabels}
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        {/*Second Layer having Bar Graphs of Difficulty Level and Cognitive Level*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Questions Status Report</b>
            </h5>
            <p>Total Questions - {qs_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!qs_loader ? (
                q_status.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={q_status}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name" />
                    <YAxis>
                      <Label
                        value="Questions"
                        position={isSmallScreen ? "" : "insideLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {q_status ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar
                      dataKey="Correct"
                      stackId="a"
                      fill=" #1190C4"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList dataKey={"Correct"} fill="#000" content={renderCustomizedLabels} />
                    </Bar>
                    <Bar
                      dataKey="Incorrect"
                      stackId="a"
                      fill="#C56468"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList dataKey={"Incorrect"} content={renderCustomizedLabels} position="inside" fill="#000" />
                    </Bar>
                    <Bar
                      dataKey="Unattempted"
                      stackId="a"
                      fill=" #C4C4C459"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList dataKey={"Unattempted"} content={renderCustomizedLabels} position="inside" fill="#000" />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
          <div className="analytics_pie_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Students Test Status %</b>
            </h5>
            <p>Total Questions - {qr_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!qr_loader ? (
                qr_data[0].value === 0 &&
                qr_data[1].value === 0 &&
                qr_data[2].value === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <PieChart>
                    <Pie
                      data={qr_data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      dataKey="value"
                      isAnimationActive={false}
                      
                      // nameKey="name"
                    >
                      {/* <LabelList content={renderCustomizedLabel}/> */}
                      {qr_data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                          style={{ boxShadow: "100px 200px 3px 0px #00000040" }}
                        />
                      ))}
                    </Pie>
                    <Legend
                      verticalAlign="middle"
                      align="right"
                      layout="vertical"
                      height={56}
                    />
                  </PieChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        {/*Third Layer*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Correct Answers Report of Difficult Level Questions</b>
            </h5>
            {/* <p>Total Tests Assigned - 500</p> */}
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!dclcr_loader ? (
                correct_dlq_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    className="analytics_bar_grid"
                    width={600}
                    height={400}
                    data={correct_dlq_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name">
                      <Label value="Chapters" offset={11} position="bottom" />
                    </XAxis>
                    <YAxis>
                      <Label
                        value="Questions"
                        offset={10}
                        position={isSmallScreen ? "" : "insideLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    <Brush dataKey="name" height={10} stroke="#ddd" />
                    <Bar
                      stackId="a"
                      dataKey="easy.Easy"
                      name="Easy"
                      fill="#0092CE"
                    >
                      <LabelList dataKey={"easy.Easy"} content={renderCustomizedLabels} position="inside" />
                    </Bar>
                    <Bar
                      stackId="a"
                      dataKey={`easy.Total Q'`}
                      tooltipType="none"
                      legendType="none"
                      fill="#CCCCCCB2"
                    >
                      <LabelList dataKey={"easy.Total Q'"} content={renderCustomizedLabels} position="top" />
                    </Bar>
                    <Bar
                      stackId="b"
                      dataKey="medium.Medium"
                      name="Medium"
                      fill="#C96862"
                    >
                      <LabelList dataKey={"medium.Medium"} content={renderCustomizedLabels} position="inside" />
                    </Bar>
                    <Bar
                      stackId="b"
                      tooltipType="none"
                      dataKey={`medium.Total Q'`}
                      legendType="none"
                      fill="#CCCCCCB2"
                    >
                      <LabelList dataKey={"medium.Total Q'"} content={renderCustomizedLabels} position="top" />
                    </Bar>
                    <Bar
                      stackId="c"
                      dataKey="difficult.Difficult"
                      name="Difficult"
                      fill="#F1C686"
                    >
                      <LabelList dataKey={"difficult.Difficult"} content={renderCustomizedLabels} position="inside" />
                    </Bar>
                    <Bar
                      stackId="c"
                      tooltipType="none"
                      dataKey={`difficult.Total Q'`}
                      name="Total Q'"
                      fill="#CCCCCCB2"
                    >
                      <LabelList dataKey={"difficult.Total Q'"} content={renderCustomizedLabels} position="top" />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
          <div className="analytics_pie_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Students Test Status %</b>
            </h5>
            <p>Total Questions - {qr_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!dclr_loader ? (
                dlq_report[0].value === 0 &&
                dlq_report[1].value === 0 &&
                dlq_report[2].value === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <PieChart>
                    <Pie
                      data={dlq_report}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      dataKey="value"
                      nameKey="name"
                      isAnimationActive={false}
                    >
                      {dlq_report.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                          style={{ boxShadow: "100px 200px 3px 0px #00000040" }}
                        />
                      ))}
                    </Pie>
                    <Legend
                      verticalAlign="middle"
                      align="right"
                      layout="vertical"
                      height={56}
                    />
                  </PieChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        {/*Fourth Layer*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Correct Answers Report of Cognitive Level Questions</b>
            </h5>
            {/* <p>Total Tests Assigned - 500</p> */}
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!dclcr_loader ? (
                correct_clq_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    className="analytics_bar_grid"
                    width={600}
                    height={400}
                    data={correct_clq_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name">
                      <Label value="Chapters" offset={11} position="bottom" />
                    </XAxis>
                    <YAxis>
                      <Label
                        value="Questions"
                        offset={10}
                        position={isSmallScreen ? "" : "insideLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    <Brush dataKey="name" height={10} stroke="#ddd" />
                    <Bar
                      stackId="a"
                      dataKey="knowledge.Knowledge"
                      name="Knowledge"
                      fill="#0092CE"
                    >
                      <LabelList dataKey={"knowledge.Knowledge"} content={renderCustomizedLabels} position="inside" />
                    </Bar>
                    <Bar
                      stackId="a"
                      dataKey={`knowledge.Total Q'`}
                      tooltipType="none"
                      legendType="none"
                      fill="#CCCCCCB2"
                    >
                      <LabelList dataKey={"knowledge.Total Q'"} content={renderCustomizedLabels} position="top" />
                    </Bar>
                    <Bar
                      stackId="b"
                      dataKey="understanding.Understanding"
                      name="Understanding"
                      fill="#C96862"
                    >
                      <LabelList dataKey={"understanding.Understanding"} content={renderCustomizedLabels} position="inside" />
                    </Bar>
                    <Bar
                      stackId="b"
                      tooltipType="none"
                      dataKey={`understanding.Total Q'`}
                      legendType="none"
                      fill="#CCCCCCB2"
                    >
                      <LabelList dataKey={"understanding.Total Q'"} content={renderCustomizedLabels} position="top" />
                    </Bar>
                    <Bar
                      stackId="c"
                      dataKey="application.Application"
                      name="Application"
                      fill="#F1C686"
                    >
                      <LabelList dataKey={"application.Application"} content={renderCustomizedLabels} position="inside" />
                    </Bar>
                    <Bar
                      stackId="c"
                      tooltipType="none"
                      legendType="none"
                      dataKey={`application.Total Q'`}
                      fill="#CCCCCCB2"
                    >
                      <LabelList dataKey={"application.Total Q'"} content={renderCustomizedLabels} position="top" />
                    </Bar>
                    <Bar
                      stackId="d"
                      dataKey="analysis.Analysis"
                      name="Analysis"
                      fill="#6DBCDA"
                    >
                      <LabelList dataKey={"analysis.Analysis"} content={renderCustomizedLabels} position="inside" />
                    </Bar>
                    <Bar
                      stackId="d"
                      tooltipType="none"
                      dataKey={`analysis.Total Q'`}
                      name="Total Q'"
                      fill="#CCCCCCB2"
                    >
                      <LabelList dataKey={"analysis.Total Q'"} content={renderCustomizedLabels} position="top" />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
          <div className="analytics_pie_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Students Test Status %</b>
            </h5>
            <p>Total Questions - {qr_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!dclr_loader ? (
                clq_report[0].value === 0 &&
                clq_report[1].value === 0 &&
                clq_report[2].value === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <PieChart>
                    <Pie
                      data={clq_report}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      dataKey="value"
                      nameKey="name"
                      isAnimationActive={false}
                    >
                      {clq_report.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS2[index % COLORS2.length]}
                          style={{ boxShadow: "100px 200px 3px 0px #00000040" }}
                        />
                      ))}
                    </Pie>
                    <Legend
                      verticalAlign="middle"
                      align="right"
                      layout="vertical"
                      height={56}
                    />
                  </PieChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card">
            <h5>
              <b>Learning Strength &amp; Gap Of Grade</b>
            </h5>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!lsg_loader ? (
                lsg_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    className="analytics_bar_grid"
                    width={600}
                    height={400}
                    data={lsg_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name" />
                    <YAxis>
                      <Label
                        value="Strength &amp; Gap (%)"
                        offset={10}
                        position={isSmallScreen ? "" : "insideLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {lsg_data ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar
                      stackId="a"
                      maxBarSize={75}
                      dataKey="Strength"
                      fill="#027EB1"
                    >
                      <LabelList  dataKey={"Strength"} content={renderCustomizedLabels} />
                    </Bar>
                    <Bar
                      stackId="a"
                      maxBarSize={75}
                      dataKey="Gap"
                      fill="#C96862"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"Gap"} />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>

        <div style={{display:"flex",flexWrap:"wrap",margin:"0px 20px",marginBottom:"10px"}} className="filters">
           {console.log(students)}

           <FilterComponent options={allgrade.length&&allgrade.map(item=>{return{...item,name:item._id}})} name="grade" formData={filterReports} setFormData={setFilterReports}  placeholder="Grade"/>
            <FilterComponent options={students} name="name" formData={filterReports} setFormData={setFilterReports}  placeholder="Name"/>
            
            <FilterComponent options={subjects} name="subject" formData={filterReports} setFormData={setFilterReports} placeholder="Subject"/>
            <FilterComponent options={[{name:"CT",_id:"CT"},{name:"UT",_id:"UT"},{name:"HY",_id:"HY"}]} name="testType" formData={filterReports} setFormData={setFilterReports} placeholder="Test Type"/>
            {/* <FilterComponent placeholder="Date"/> */}
            {/* <FilterComponent placeholder="Date"/> */}
            <CalenderComponent placeholder="date" formData={filterByDate} setFormData={setFilterByDate} name="date"/>
            <div onClick={()=>{setFilterReports({name:"",subject:"",testType:"",grade:""});setFilterByDate({date:""})}} style={{display:"flex",cursor:"pointer",alignItems:"center",marginLeft:"10px"}}>
            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.5 8.25C6.57107 8.25 8.25 6.57107 8.25 4.5C8.25 2.42893 6.57107 0.75 4.5 0.75C2.42893 0.75 0.75 2.42893 0.75 4.5C0.75 6.57107 2.42893 8.25 4.5 8.25Z" stroke="black" stroke-opacity="0.54" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.625 3.375L3.375 5.625" stroke="black" stroke-opacity="0.54" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.375 3.375L5.625 5.625" stroke="black" stroke-opacity="0.54" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

               <span style={{marginLeft:"4px"}}>Clear</span>
            </div>
          </div>
        {/*Class Report Table*/}
        <div className="table_container">
          <div
            className="table_title_section"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {isSmallScreen ? (
              <h5
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  marginLeft: "0.5%",
                  marginTop: "5%",
                }}
              >
                Class Report
              </h5>
            ) : (
              <h2
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  marginLeft: "0.5%",
                  marginTop: "0.5%",
                }}
              >
                Class Report
              </h2>
            )}
            {isSmallScreen ? (
              <img
                onClick={exportCsv}
                style={{
                  cursor: "pointer",
                  transform: "scale(0.7)",
                }}
                src={ExportButton}
              />
            ) : (
              <img
                onClick={exportCsv}
                style={{
                  cursor: "pointer",
                  marginLeft: 5,
                }}
                src={ExportButton}
              />
            )}
            <div className="search_container">
              <SearchBar search={searchValue} setSearch={setSearchValue} />
            </div>
          </div>

          <ClassReport rows={tableData} loading={table_loader} />
        </div>
      </Container>
    </>
  );
};

export default OnlineTestStudentAnalytics;
