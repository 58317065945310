import { useState } from "react";
import "./QuestionExtender.css";
import { checkAnswer, getCorrectAnswer } from "../../../utils/questionTool";

import x2cellQuestionLogo from "../../../images/2xcellQuestionLogo.svg";
import { ReactComponent as EditImg } from "../../../images/edit.svg";
import { ReactComponent as DeleteImg } from "../../../images/delete.svg";

function QuestionChecker(props) {
  const [status, setStatus] = useState({
    attempted: false,
    correct: false,
    message: "",
  });

  function isAnswerCorrect() {
    const isCorrect = checkAnswer(
      props.type,
      props.correctAnswers,
      props.answers
    );

    if (!isCorrect) {
      const correctAnswer = getCorrectAnswer(
        props.type,
        props.correctAnswers,
        props.options
      );
      setStatus({
        attempted: true,
        correct: isCorrect,
        message: `Wrong Answer!, Correct Answer is <b>${correctAnswer}<b>`,
      });
    } else {
      setStatus({
        attempted: true,
        correct: isCorrect,
        message: `Hurray Correct Answer`,
      });
    }
  }

  function reset() {
    props.reset();
    setStatus({
      attempted: false,
      correct: false,
      message: "",
    });
  }

  return (
    <div className="question-checker">
      {props.children}
      <div className="last">
        {props.isStaff ? (
          <div
            className={
              props.isPraticeSession ? "signarture ps_sign" : "signarture ot_sign"
            }
          >
            {props.staff?.name} <br />
            {props.staff.schoolName}
          </div>
        ) : (
          <div
            className={
              props.isPraticeSession
                ? "signarture ps_sign"
                : "signarture ot_sign"
            }
            style={{ fontSize: "30px", textAlign: "center" }}
          >
            <img className="imgLogo" src={x2cellQuestionLogo} />
          </div>
        )}

        <div className="icon_res">
          {props.isStaff && (
            <EditImg
              onClick={props.openEdit}
              className={props.isPraticeSession ? "ps_edit" : "ot_edit"}
            />
          )}
          {props.isStaff && (
            <DeleteImg
              onClick={props.delete}
              className={props.isPraticeSession ? "ps_delete" : "ot_delete"}
            />
          )}
          {status.attempted ? (
            <button
              className={`${status.correct ? "correct" : "incorrect"} check-button`}
              onClick={reset}
            >
              Reset
            </button>
          ) : (
            <button className={`check-button`} onClick={isAnswerCorrect}>Check</button>
          )}
        </div>
      </div>
      {status.attempted && (
        <p
          dangerouslySetInnerHTML={{ __html: status.message }}
          className={`check-status ${status.correct ? "correct" : "incorrect"}`}
        ></p>
      )}
    </div>
  );
}
export default QuestionChecker;
