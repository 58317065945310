/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useState, useContext, useMemo } from "react";
import { useEffect } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { useAlert } from "react-alert";
import { useMediaQuery } from "react-responsive";
import Moment from "react-moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";

import {
  fixname,
  decodeHTMLEntities,
  groupModulesByCategory,
} from "../../utils/tools";
import { Contents, ModuleContents } from "./Contents";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SubjectModuleModal from "./Modal/SubjectModuleModal";
import TaskModal from "./Modal/TaskModal";

export default function Subjects(props) {
  const alert = useAlert();
  document.title = "Chapters";
  //const [folder, setFolder] = useState("chapter");
  const [chapters, setChapters] = useState([]);
  const [modules, setModules] = useState([]);
  const [moduleId, setModuleId] = useState("");
  const [fetchChapterLoader, setFetchChapterLoader] = useState(true);
  const [moduleLoader, setModuleLoader] = useState(true);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const { userDetails, setUserDetails, selectedClass } =
    useContext(GlobalContext);

  const subjectName = props.match.params.subject;
  const subjectId = props.match.params.subjectId;
  const sectionIdTask = localStorage.getItem("sectionIdTask");
  window.localStorage.setItem("subjectId", subjectId);

  useEffect(() => {
    const getChapters = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/sections/${sectionIdTask}/subjects/${subjectId}/chapters`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          setChapters(response.data.data.chapters);
        }
      } catch (error) {
        console.log(error);
      }

      setFetchChapterLoader(false);
    };
    const getSubjectModules = async () => {
      const authToken = localStorage.getItem("authToken");
      const url = `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${
        userDetails.userId
      }/modules?folder=subject&topicId=${subjectId}&section=${
        selectedClass || localStorage.getItem("gradeSection")
      }`;
      try {
        const response = await axios.get(url, {
          headers: {
            token: authToken,
          },
        });
        if (response.data.status === "success") {
          setModules(response.data.data.modules);
        }
      } catch (error) {
        console.log(error);
      }
      setModuleLoader(false);
    };
    getChapters();
    getSubjectModules();
  }, []);

  const chapterCompleteStatus = async (id, setStatus) => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/sections/${sectionIdTask}/subjects/${subjectId}/chapters/${id}/status`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setStatus(response.data.data.completed);
        alert.success(
          response.data.data.completed
            ? "Chapter marked complete"
            : "Chapter marked incomplete"
        );
      }
    } catch (error) {
      alert.error(error.message);
      console.log(error);
    }
  };

  const deleteOwnModule = async (id) => {
    setDeleteLoader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/modules/${id}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        const newOwnModules = modules.filter((module) => module._id !== id);
        setModules(newOwnModules);
      }
    } catch (error) {
      if (error.response.data.status === "fail") {
      } else {
        alert.show("Try Again.");
      }
    }
    setDeleteLoader(false);
  };

  const toggleModuleVisibility = async (moduleData) => {
    if (deleteLoader) return;
    setDeleteLoader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const makePublic = !moduleData.public;
      const response = await axios.patch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/modules/${moduleData._id}`,
        {
          public: makePublic,
        },
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        const moduleIndex = modules.findIndex(
          (mod) => mod._id === moduleData._id
        );
        modules[moduleIndex].public = makePublic;
        setModules([...modules]);
        alert.success(
          makePublic
            ? `${moduleData.name} is now visible to other sections`
            : `${moduleData.name} is now hidden from other sections`
        );
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data?.status === "fail") {
      } else {
        alert.show("Try Again.");
      }
    }
    setDeleteLoader(false);
  };

  function insertModule(newModule) {
    setModules([...modules, newModule]);
  }

  const visited = async (moduleName) => {
    const subject = subjectName;
    const teacherId = userDetails.userId;
    const selectedGrade = localStorage.getItem("gradeSection");
    const grade = selectedGrade.split("-")[0];
    const section = selectedGrade.split("-")[1];

    try {
      await fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/analytics/pagevisits`,
        {
          method: "POST",
          headers: {
            token: `${window.localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            teacherId,
            subject,
            chapter: "",
            topic: "",
            module: moduleName,
            grade,
            section,
          }),
        }
      )
        .then((response) => response.json())
        .then(() => {
          console.log(`Marked ${moduleName} as visited..`);
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });

  const updateQuery = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    history.push({ search: searchParams.toString() });
  };

  const getQuery = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key);
  };

  const folder = useMemo(() => {
    return getQuery("folder") || "chapter";
  }, [location]);

  const groupedModules = useMemo(() => {
    return groupModulesByCategory(modules);
  }, [modules]);

  return (
    <section
      className={
        isSmallScreen
          ? "myResources my-2 p-lg-0 p-4"
          : "myResources my-5 p-lg-0 p-4"
      }
    >
      <div className="container">
        <div
          className="box mb-5"
          style={{ display: isSmallScreen ? "none" : "" }}
        >
          <div className="boxRow">
            <div className="pathDetail" style={{ color: "white" }}>
              <h1>{selectedClass || localStorage.getItem("gradeSection")}</h1>
              <h5>{subjectName}</h5>
            </div>
            <div className="DateBox">
              <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                <CalendarTodayIcon />{" "}
                {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
              </h5>
            </div>
          </div>
        </div>

        <div
          className="button_container"
          style={{
            width: "100% !important",
          }}
        >
          <button
            type="button"
            className={
              folder === "chapter" ? "button selected_button" : "button"
            }
            onClick={() => {
              updateQuery("folder", "chapter");
            }}
            id="button_70"
          >
            2XCELL RESOURCES
          </button>
          <div className="right-content">
            <p
              className="fw-bolder"
              data-bs-toggle="modal"
              data-bs-target="#contentModal"
              style={{
                cursor: "pointer",
                display: folder === "module" ? "block" : "none",
                height:"fit-content",
                margin:"auto 0"
              }}
            >
              <AddBoxOutlinedIcon /> <span>ADD RESOURCE</span>
            </p>
            <button
              type="button"
              className={
                folder === "module" ? "button selected_button" : "button"
              }
              id="button_70"
              onClick={() => {
                updateQuery("folder", "module");
              }}
            >
              TEACHER RESOURCES
            </button>
          </div>
        </div>

        {deleteLoader && (
          <div className="text-center">
            <CircularProgress style={{ color: "#FF075B", fontWeight: "600" }} />
          </div>
        )}

        {/*Show Chapter Contents*/}
        {folder === "chapter" ? (
          <Contents
            loading={fetchChapterLoader}
            contents={chapters}
            placeholder={"Create chapter"}
            goto={(name) => {
              setUserDetails({
                ...userDetails,
                pathName: {
                  subjectName: `${subjectName}`,
                  chapterName: `${decodeHTMLEntities(name)}`,
                  topicName: "",
                },
              });
            }}
            getURL={(content) =>
              `/myresources/${subjectName}/${subjectId}/${decodeHTMLEntities(
                fixname(content?.name)
              )}/${content._id}`
            }
            updateStatus={chapterCompleteStatus}
          />
        ) : moduleLoader ? (
          <div className="text-center" style={{ overflow: "hidden" }}>
            <CircularProgress style={{ color: "#FF075B", fontWeight: "600" }} />
          </div>
        ) : modules.length ? (
          groupedModules.map((content) => {
            return (
              <ModuleContents
                loading={moduleLoader}
                heading={content.categoryName}
                contents={content.modules}
                delete={deleteOwnModule}
                toggleVisibility={toggleModuleVisibility}
                visited={visited}
                sectionId={sectionIdTask}
                subjectId={subjectId}
                selectModule={(id) => setModuleId(id)}
              />
            );
          })
        ) : (
          <h3 className="text-center">{props.placeholder}</h3>
        )}

        <SubjectModuleModal
          userId={userDetails.userId}
          subjectId={subjectId}
          sectionId={sectionIdTask}
          language={window.localStorage.getItem("language") || "english"}
          insertModule={insertModule}
        />

        <TaskModal
          sectionId={sectionIdTask}
          subjectId={subjectId}
          chapterId={""}
          topicId={""}
          moduleId={moduleId}
        />
      </div>
    </section>
  );
}
