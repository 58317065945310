/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles/TableTask.css";
import "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";

import { useAlert } from "react-alert";
import { groupGrades } from "../../utils/groupGrades";
import TaskAssignModal from "./Modal/TaskAssignModal";
import TaskURLModal from "./Modal/TaskURLModal";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },
  formControl: {
    minWidth: 280,
    margin: 0,
    marginBottom: "-16px",
  },
  selectBoxStyle: {
    padding: "1px 2px !important",
    border: "1px solid black !important",
    margin: "0",
    fontFamily: "inherit",
    fontSize: "inherit",
    lineHeight: "inherit",
  },
  datePickerStyle: {
    backgroundColor: "transparent",
  },
  tableRow: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#A6EDE5 !important",
    },
  },
}));

const TableTask = ({
  assignedClasses,
  selectedClass,
  selectedSubject,
  userDetails,
}) => {
  const classes = useStyles();
  const [addTask, setAddTask] = React.useState({
    taskValue: "",
  });
  const [filterData, setFilterData] = useState({
    fromDate: null,
    toDate: null,
    task: "",
  });
  const [tasks, setTasks] = useState([]);
  const [assignedSections, setAssignedSections] = useState([]);
  const [selectedClassForTask] = useState(null);
  //List of assigned subjects for a selected class
  const [assignedSubjectsList, setAssignedSubjectsList] = useState([]);
  //List of assigned classes for task by URL modal
  const [assignedClassesList, setAssignedClassesList] = useState([]);
  const [grades, setGrades] = useState({});

  const alert = useAlert();

  useEffect(() => {
    const fetchTasks = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/tasks`,
          {
            headers: {
              token: authToken,
            },
          }
        );

        if (response.data.status === "success") {
          setTasks(response.data.data.tasks);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchSections = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          setAssignedSections([response.data.data.staff.sections]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchTasks();
    fetchSections();
  }, []);

  // handle add task
  const handleAddTask = (e) => {
    setAddTask({ ...addTask, taskValue: e.target.value });
  };

  // filter data handle
  const handleFromDateChange = (date) => {
    setFilterData({ ...filterData, fromDate: date });
    let tempTask;
    filterData.task === "" ? (tempTask = null) : (tempTask = filterData.task);
    filterTasks(date, filterData.toDate, tempTask);
  };

  const handleToDateChange = (date) => {
    setFilterData({ ...filterData, toDate: date });
    let tempTask;
    filterData.task === "" ? (tempTask = null) : (tempTask = filterData.task);
    filterTasks(filterData.fromDate, date, tempTask);
  };

  const handleTaskChange = (e) => {
    document.getElementById("taskSelector").style.marginTop = "5px";
    setFilterData({ ...filterData, task: e.target.value });

    filterTasks(filterData.fromDate, filterData.toDate, e.target.value);
  };

  const filterTasks = async (fromDate, toDate, taskType) => {
    toDate?.toISOString() === undefined
      ? (toDate = null)
      : (toDate = toDate.toISOString());
    fromDate?.toISOString() === undefined
      ? (fromDate = null)
      : (fromDate = fromDate.toISOString());

    const authToken = localStorage.getItem("authToken");

    let url = `${process.env.REACT_APP_ORIGIN}/api/v1/tasks?taskType=${taskType}&from[gte]=${fromDate}&to[lte]=${toDate}`;

    selectedClass &&
      selectedClass.forEach((singleClass) => {
        url += `&section=${singleClass}`;
      });

    selectedSubject &&
      selectedSubject.forEach((singleSubject) => {
        url += `&subject=${singleSubject}`;
      });

    try {
      const response = await axios.get(url, {
        headers: {
          token: authToken,
        },
      });

      if (response.data.status === "success") {
        setTasks(response.data.data.tasks);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let tempTask;
    filterData.task === "" ? (tempTask = null) : (tempTask = filterData.task);
    filterTasks(filterData.fromDate, filterData.toDate, tempTask);
  }, [selectedClass, selectedSubject]);

  const disableModule = async (task) => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/tasks/${task._id}`,
        {
          public: !task.public,
        },
        {
          headers: {
            token: authToken,
          },
        }
      );

      if (response.data.status === "success") {
        tasks.forEach((taskInner) => {
          if (taskInner._id === task._id) {
            taskInner.public = !taskInner.public;
          }
        });

        const newTask = tasks.map((task) => {
          return task;
        });

        setTasks(newTask);
        alert.success("Task Updated.");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === "fail") {
        alert.error(error.response.message);
      } else {
        alert.show("Try again.");
      }
    }
  };

  useEffect(() => {
    setAssignedClassesList([]);
    //Create the list from ids of assigned class
    if (assignedClasses && assignedClasses.length > 0) {
      assignedClasses[0].map((item) => {
        assignedClassesList.push(item._id);
      });
      setAssignedClassesList(assignedClassesList);
    }
  }, [assignedClasses]);

  useEffect(() => {
    if (assignedSections && assignedSections.length > 0) {
      const grades = groupGrades(assignedSections[0]);
      setGrades(grades);
    }
  }, [assignedSections]);

  useEffect(() => {
    setAssignedSubjectsList([]);
    //Extract the subjects nased on the selected class
    if (selectedClassForTask) {
      setAssignedSubjectsList(
        assignedClasses[0].filter(
          (item) => item._id === selectedClassForTask
        )[0].subjects
      );
    }
  }, [selectedClassForTask]);

  return (
    <>
      <section className="taskFilter">
        <div className="row mb-4 test-sm-start text-center">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="col-sm">
              <KeyboardDatePicker
                size="small"
                inputVariant={"filled"}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="fromDate"
                label="From Date"
                value={filterData.fromDate}
                onChange={handleFromDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk={true}
              />
            </div>
            <div className="col-sm">
              <KeyboardDatePicker
                size="small"
                inputVariant={"filled"}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="toDate"
                label="To Date"
                value={filterData.toDate}
                onChange={handleToDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk={true}
              />
            </div>
          </MuiPickersUtilsProvider>
          <div className="col-sm work">
            <FormControl
              size="small"
              variant="outlined"
              inputVariant="outlined"
              className={classes.formControl}
              style={{ fontFamily: "Poppins", fontWeight: "bold" }}
            >
              <InputLabel id="taskSelector">Task</InputLabel>
              <Select
                labelId="taskLabel"
                id="task"
                value={filterData.task}
                onChange={handleTaskChange}
                inputVariant="outlined"
                name="task"
                style={{
                  width: "100%",
                  borderWidth: "2px",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
              >
                <MenuItem value="" disabled>
                  <em>None</em>
                </MenuItem>
                <MenuItem value="homework">Home Work</MenuItem>
                <MenuItem value="flipped class">Flipped Class</MenuItem>
                <MenuItem value="revision task">Revision Task</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-sm work">
            <FormControl
              size="small"
              variant="filled"
              className={classes.formControl}
            >
              <InputLabel
                id="addTaskSelector"
                style={{ marginLeft: "50%", marginTop: "-5px" }}
              >
                Add Task
              </InputLabel>
              <Select
                labelId="addTaskLabel"
                id="addTask"
                value={addTask.taskValue}
                onChange={handleAddTask}
                name="taskValue"
                variant="outlined"
                style={{ width: "150px", marginLeft: "auto" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem
                  data-bs-toggle="modal"
                  data-bs-target="#resourceModal"
                  value="url"
                >
                  By Resources
                </MenuItem>
                <MenuItem
                  data-bs-toggle="modal"
                  data-bs-target="#urlModal"
                  value="resources"
                >
                  By URL
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </section>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Grade</TableCell>
              <TableCell align="center">Subject</TableCell>
              <TableCell align="center">Module</TableCell>
              <TableCell align="center">Task</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">From</TableCell>
              <TableCell align="center">To</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks[0] &&
              tasks.map((task, index) => (
                <TableRow
                  key={index}
                  variant="button"
                  className={classes.tableRow}
                >
                  <TableCell
                    scope="row"
                    align="center"
                    component={NavLink}
                    to={`/tasklist/topic/${task._id}`}
                    className={classes.tableRow}
                  >
                    {task.sections.join(",")}
                  </TableCell>
                  <TableCell
                    align="center"
                    component={NavLink}
                    to={`/tasklist/topic/${task._id}`}
                    className={classes.tableRow}
                  >
                    {task.subject}
                  </TableCell>
                  <TableCell
                    align="center"
                    component={NavLink}
                    to={`/tasklist/topic/${task._id}`}
                    className={classes.tableRow}
                  >
                    <p
                      style={{
                        width: "300px",
                        wordBreak: "break-word",
                        margin: "auto",
                      }}
                    >
                      {task.content?.name || task?.url}
                    </p>
                  </TableCell>
                  <TableCell
                    align="center"
                    component={NavLink}
                    to={`/tasklist/topic/${task._id}`}
                    className={classes.tableRow}
                  >
                    {task.taskType}
                  </TableCell>
                  <TableCell
                    align="center"
                    component={NavLink}
                    to={`/tasklist/topic/${task._id}`}
                    className={classes.tableRow}
                  >
                    {task.public ? "Active" : "Hidden"}
                  </TableCell>
                  <TableCell
                    align="center"
                    component={NavLink}
                    to={`/tasklist/topic/${task._id}`}
                    className={classes.tableRow}
                  >
                    <Moment format="DD-MM-YYYY">{task.from}</Moment>
                  </TableCell>
                  <TableCell
                    align="center"
                    component={NavLink}
                    to={`/tasklist/topic/${task._id}`}
                    className={classes.tableRow}
                  >
                    <Moment format="DD-MM-YYYY">{task.to}</Moment>
                  </TableCell>
                  <TableCell align="center">
                    <div className="form-check form-switch tableTask">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={task.public}
                        onChange={() => disableModule(task)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* url Modal */}
      <TaskURLModal
        assignedClasses={assignedClasses}
        grades={grades}
        tasks={tasks}
        setTasks={setTasks}
      />
      {/* resources Modal */}
      <TaskAssignModal
        assignedClasses={assignedClasses}
        grades={grades}
        tasks={tasks}
        setTasks={setTasks}
      />
    </>
  );
};

export default TableTask;
