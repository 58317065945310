import { useState } from "react";
import Option from "./Option";
import "./input.css";

import DropdownIcon from "../icons/dropdown.svg";

function Select(props) {
  const [active, setActive] = useState(false);

  function selectOption(option) {
    if (props.lock) return;
    props.setSelectedValue(option);
    setActive(false);
  }

  function openList() {
    if (props.lock) {
      setActive(false);
      return;
    }
    setActive(!active);
  }

  function isSelected(option) {
    return option.value === props.selectedValue.value;
  }

  return (
    <div style={props.styles} className={`form-input ${props.lock ? "lock" : ""}`}>
      {props.label && (
        <div className="label">
          <label>{props.label}</label>
        </div>
      )}
      <div className="main-style select-style">
        <span
          onClick={openList}
          style={{
            display: "flex",
            color: "#414141",
            fontFamily: "Poppins",
          }}
        >
          <span style={{overflow:"hidden"}}>{props.selectedValue.name || props.placeholder}</span>
          <img
            className={active ? "active" : ""}
            alt="dropdown"
            style={{}}
            src={DropdownIcon}
          />
        </span>
        <ul className={active ? "option-list active" : "option-list"}>
          {props.options.map((option, index) => {
            return (
              <Option
                selectable={true}
                selected={isSelected(option)}
                key={index}
                option={option}
                selectOption={() => selectOption(option)}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Select;
