import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalState";
import Navbar from "../Navbar/Navbar";

const LPSecuredRoute = (props) => {
  const { userDetails } = useContext(GlobalContext);

  return userDetails.isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect to={`/logout`} />
  );
};

export default LPSecuredRoute;
