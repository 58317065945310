import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { useAlert, types } from "react-alert";

const messages = [
  "Authenticating, please wait..",
  "Retrying, please wait..",
  "Error occured, please try again.. ",
  "Facing Difficulty while accessing local storage",
];

function isLocalStorageNameSupported() {
  var testKey = "test",
    storage = window.localStorage;
  try {
    storage.setItem(testKey, "1");
    storage.removeItem(testKey);
    return true;
  } catch (error) {
    fetch(`${process.env.REACT_APP_TEST_ORIGIN_2}/api/v1/errorlogs`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        error: error,
      }),
    });
    return false;
  }
}

function Auth() {
  const alert = useAlert();

  const { setUserDetails } = useContext(GlobalContext);
  const [mssg, setMssg] = useState(0);
  const url = window.location.href.split("/");
  const token = url[url.length - 2];
  const id = url[url.length - 1];

  const getUserDetails = async () => {
    try {
      await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${id}`, {
        headers: {
          token: token,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setUserDetails({
              isAuthenticated: true,
              name: response.data.staff.name,
              userId: response.data.staff.userId,
              schoolId: response.data.staff.school,
              role: response.data.staff.role,
              selectedTab: "Dashboard",
              grades: response.data.staff.grades || [],
              // profileImageUrl: response.data.staff.profile?.fileUrl
            });
            window.localStorage.setItem(
              "profileImageUrl",
              response.data.staff.profile?.fileUrl
            );
            window.localStorage.setItem("language", "english");
            window.location.href = "/dashboard";
          } else if (response.status === "connecting") {
            if (mssg === 1) {
              setMssg(2);
            } else {
              setMssg(1);
              setTimeout(async () => getUserDetails(), 1000);
            }
          } else {
            setMssg(2);
            alert.show(response.message, { type: "error" });
          }
        });
    } catch (error) {
      setMssg(2);
      fetch(`${process.env.REACT_APP_TEST_ORIGIN_2}/api/v1/errorlogs`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          error: error,
        }),
      })
        .then(() => {
          window.location.href = `${process.env.REACT_APP_REDIRECT}/login`;
        })
        .catch(() => {
          window.location.href = `${process.env.REACT_APP_REDIRECT}/login`;
        })
        .finally(() => {
          window.location.href = `${process.env.REACT_APP_REDIRECT}/login`;
        });
    }
  };

  useEffect(() => {
    if (isLocalStorageNameSupported()) {
      window.localStorage.setItem("authToken", token);
      window.localStorage.setItem("userId", id);
      getUserDetails();
    } else {
      setMssg(3);
    }
  }, [token, id, setMssg]);

  return (
    <div style={{ textAlign: "center" }}>
      <p style={{ marginTop: "2rem", fontFamily: "Poppins" }}>
        {messages[mssg]}
      </p>
    </div>
  );
}

export default Auth;
