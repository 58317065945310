import React from "react";
import { Col, Row } from "react-bootstrap";
import Stat from "../Common/Stat";
import StatBar from "../Common/StatBar";
import "../QuestionReport.css";
import IsSmallScreen from "../Common/IsSmallScreen";
import { useParams } from "react-router-dom";

import { htmlDecode } from "../../../../utils/parse";

const OneWord = ({ q, index }) => {
  const corrAns = q.questionId.correctAnswers.oneword[0].answer;
  let markedAns = "";

  // Overall Attempts
  let st = q.statistics.filter((stats) => stats.answer.oneword === corrAns);
  console.log(st);

  // Selected User Attempt
  const params = useParams();
  const user = q.statistics.filter(
    (stats) => stats.userId === params.studentId
  );
  console.log("user", user);
  if (user && user.length) {
    markedAns = user[0].answer.oneword;
  }
  console.log(markedAns);

  const isSmallScreen = IsSmallScreen;
  const parseString = (str) => {
    console.log(str);
    return new DOMParser()
      .parseFromString(str, "text/html")
      .body.textContent.trimStart();
  };
  return (
    <Row className="analytics_question_layer">
      <Col className="analytics_question_content">
        <Row className="analytics_question_heading">
          <p>Question {index}</p>
          <p className="analytics_question_heading_card">{q?.marks} Marks</p>
          <p className="analytics_question_heading_card">
            {q.questionId.difficulty}
          </p>
          <p className="analytics_question_heading_card">
            {q.questionId.cognitive}
          </p>
        </Row>
        <Row
          className="analytics_question_statement"
          dangerouslySetInnerHTML={{
            __html: htmlDecode(q.questionId.question),
          }}
        />
        <Row>
          <Row
            className="analytics_question_fill_in_the_blank"
            style={{ color: markedAns === corrAns ? "#47DE85" : "#FF675E" }}
          >
            <u>{markedAns}</u>
          </Row>
        </Row>
        <Row
          className="correct_answer_fill_in_the_blank"
          style={{ marginBottom: isSmallScreen ? 5 : 0 }}
        >
          Correct Answer: {q.questionId.correctAnswers.oneword[0].answer}
        </Row>
      </Col>
      <Col className="analytics_question_stats">
        <Stat>
          <StatBar
            style={{
              width: (st.length / q.statistics.length) * 180,
              backgroundColor: "#7EB6A2",
            }}
          />{" "}
          {(st.length / q.statistics.length) * 100}%
        </Stat>
      </Col>
    </Row>
  );
};

export default OneWord;
/*<Row className="analytics_question_layer">
      <Col className="analytics_question_content">
        <Row className="analytics_question_heading">
          <p>Question 2</p>
          <p className="analytics_question_heading_card">2 Marks</p>
          <p className="analytics_question_heading_card">Easy</p>
        </Row>
        <Row className="analytics_question_statement">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque
          cupiditate doloribus nesciunt illum rem inventore quibusdam, adipisci
          doloremque autem consequatur similique laudantium odio commodi nulla
          architecto ipsa repudiandae, sint temporibus?
        </Row>
        <Row>
          <Row
            className="analytics_question_fill_in_the_blank"
            style={{ color: "#47DE85" }}
          >
            <u>lorem ipsum dolor sit amet</u>
          </Row>
        </Row>
        <Row
          className="correct_answer_fill_in_the_blank"
          style={{ marginBottom: isSmallScreen ? 5 : 0 }}
        >
          Correct Answer: lorem ipsum dolor sit amet
        </Row>
      </Col>
      <Col className="analytics_question_stats">
        <Stat>
          <StatBar style={{ width: 180, backgroundColor: "#7EB6A2" }} /> 40%
        </Stat>
      </Col>
    </Row> */
