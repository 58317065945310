import "./LPNavbar.css";
import LeftArrowBack from "../icons/arrow-left.svg";
import DefaultAvatar from "../icons/default-avatar.jpg";
import { useState } from "react";
import { useEffect } from "react";

function LPNavbar(props) {
  const [profile, setProfile] = useState(DefaultAvatar);

  useEffect(() => {
    console.log(props.profile);
    if (![undefined, "undefined",null].includes(props.picture))
      setProfile(props.picture);
    else
      setProfile(DefaultAvatar);

  }, [props.picture]);
  return (
    <div className="lp-navbar">
      <img
        className="go-back"
        src={LeftArrowBack}
        onClick={props.goBack}
        alt="goback"
      />
      <h6>{props.mainHeading}</h6>
      {props.username && (
        <div
          style={{visibility:"hidden"}}
          className="profile"
          onClick={() => (window.location.href = "/setting")}
        >
          <img src={profile} alt={props.username} />
          <label>Hey, {props.username}</label>
        </div>
      )}
      {props.children}
    </div>
  );
}

export default LPNavbar;
