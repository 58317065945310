import LPSvg from "../icons/LP.svg";

function Grade(props) {
  return (
    <div
      className="lp-grade-wrapper"
      onClick={props.setGrade}
      style={{ backgroundColor: props?.palette?.color }}
    >
      <p
        className="lp-grade"
        style={{ backgroundImage: `url(${props.palette?.icon})` }}
      >
        <span>{props.grade}</span>
      </p>
      <div className="lesson-icon">
        <img src={LPSvg} alt={props.grade} />
      </div>
    </div>
  );
}

export default Grade;
