import React, { useEffect, useState, useContext } from 'react';
import './styles/TableAnalytics.css';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { TableHead } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import 'date-fns';
import axios from 'axios';
import Moment from 'react-moment';
import exportFromJSON from 'export-from-json';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAlert } from 'react-alert';
import { GlobalContext } from '../../context/GlobalState';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 'auto',
  },
});

const TableAnalytics = () => {
  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterData, setFilterData] = useState({
    fromDate: null,
    toDate: null,
    class: null,
    subject: null,
    studentName: null,
  });
  const [visitors, setVisitors] = useState([]);
  console.log(visitors);

  const alert = useAlert();
  const { userDetails } = useContext(GlobalContext);
  const [schClasses, setSchClasses] = useState([]);
  const [schSubjects, setSchSubjects] = useState([]);
  const [student, setStudent] = useState(false);
  const [studentsList, setStudentsList] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        if (!filterData.class) {
          const id = userDetails.schoolId._id;
          const url = `${process.env.REACT_APP_ORIGIN}/api/v1/grades?schoolId=${id}`;
          const response = await fetch(url, {
            headers: {
              token: authToken,
            },
          });
          const data = await response.json();
          let classess = [];
          let subjectss = [];
          data.data?.grades.forEach((e) => {
            e.sections.forEach((c) => classess.push(`${e.grade}-${c.section}`));
            e.subjects.forEach((s) => subjectss.push(`${s?.subject}`));
          });
          setSchClasses([...classess]);
          setSchSubjects([...subjectss]);
        }
      } catch (e) {
        console.log(e);
        alert.show(e.message);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const fetchVisitors = async () => {
      const authToken = localStorage.getItem('authToken');

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/analytics/pagevisitbystudents?sort=visitOn`,
          {
            headers: {
              token: authToken,
            },
          }
        );

        console.log('Data of fetching visitors==>', response);
        if (response.data.status === 'success') {
          setVisitors(response.data.data.pageVisits.reverse());
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchVisitors();
  }, []);

  const exportCsv = () => {
    const data = visitors;
    const fileName = 'Analytics';
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType });
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, visitors.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // filter data handle
  const handleFilterFromDateChange = (date) => {
    setFilterData({ ...filterData, fromDate: date });
  };

  const handleFilterToDateChange = (date) => {
    setFilterData({ ...filterData, toDate: date });
  };

  const getStudentsList = async (grade) => {
    let students = [];
    visitors?.forEach((v) => {
      if (v?.role === 'student' && v?.section === grade) {
        students.push(v?.user?.name);
      }
    });
    let uniqueStudents = new Set(students);
    setStudentsList([...uniqueStudents]);
  };

  const handleClassChange = async (data) => {
    setFilterData({ ...filterData, class: data, studentName: null });
    if (data !== null) {
      setStudent(true);
      getStudentsList(data);
    }
  };

  const handleStudentChange = async (data) => {
    setFilterData({ ...filterData, studentName: data });
  };

  const filterDataModal = (record) => {
    if (filterData.class !== null) {
      if (filterData.subject !== null && filterData.studentName !== null) {
        return (
          record.section === filterData.class &&
          record.subject === filterData.subject &&
          record?.user?.name === filterData.studentName
        );
      }
      if (filterData.subject !== null) {
        return (
          record.section === filterData.class &&
          record.subject === filterData.subject
        );
      }
      if (filterData.studentName !== null) {
        return (
          record.section === filterData.class &&
          record?.user?.name === filterData.studentName
        );
      }
      return record.section === filterData.class;
    } else if (filterData.subject !== null) {
      return false;
    }
    return true;
  };

  const filterVisitorData = async () => {
    const authToken = localStorage.getItem('authToken');
    let toDate = '';
    let fromDate = '';
    filterData.toDate?.toISOString() === undefined
      ? (toDate = null)
      : (toDate = filterData.toDate.toISOString());
    filterData.fromDate?.toISOString() === undefined
      ? (fromDate = null)
      : (fromDate = filterData.fromDate.toISOString());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/analytics/pagevisitbystudents?visitOn[gte]=${fromDate}&visitOn[lte]=${toDate}`,
        {
          headers: {
            token: authToken,
          },
        }
      );

      console.log('Filter page visit response ===> ', response);
      if (response.data.status === 'success') {
        const res = await response.data.data.pageVisits.filter(filterDataModal);
        console.log('res', res);
        // setVisitors(response.data.data.pageVisits);
        setVisitors(res);
        setFilterData({ ...filterData, subject: null, studentName: null });
      }
    } catch (error) {
      alert.error('Please, Select Date.');
    }
  };
  return (
    <>
      <div className='row'>
        <div className='col-sm-10'>
          <TablePagination
            rowsPerPageOptions={[10, 25, 30, { label: 'All', value: -1 }]}
            colSpan={10}
            count={visitors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </div>
        <div className='col-sm-2 mt-3 mb-lg-0 mb-3'>
          <span
            className='fw-bold mx-3'
            style={{ cursor: 'pointer' }}
            onClick={exportCsv}
          >
            <ExitToAppIcon /> Export
          </span>
          <span
            className='fw-bold'
            style={{ cursor: 'pointer' }}
            data-bs-toggle='modal'
            data-bs-target='#filterAnalyticsModal'
          >
            <FilterListIcon /> Filter
          </span>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-label='Report Card table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='center'>Name</TableCell>
              <TableCell align='center'>Email</TableCell>
              <TableCell align='center'>Grade</TableCell>
              <TableCell align='center'>Subject</TableCell>
              <TableCell align='center'>Chapter</TableCell>
              <TableCell align='center'>Topic</TableCell>
              <TableCell align='center'>Module</TableCell>
              <TableCell align='center'>Role</TableCell>
              <TableCell align='center'>Visit On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? visitors.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : visitors
            ).map((visitor, index) => (
              <TableRow key={index}>
                <TableCell align='center'>{visitor.user.name}</TableCell>
                <TableCell align='center'>{visitor.user.email}</TableCell>
                <TableCell align='center'>{visitor.section}</TableCell>
                <TableCell align='center'>{visitor.subject}</TableCell>
                <TableCell align='center'>{visitor.chapter}</TableCell>
                <TableCell align='center'>{visitor.topic}</TableCell>
                <TableCell align='center'>{visitor.module}</TableCell>
                <TableCell align='center'>
                  {visitor.role[0].toUpperCase() + visitor.role.slice(1)}
                </TableCell>
                <TableCell align='center'>
                  <Moment format='DD-MM-YYYY hh:mm:ss a'>
                    {visitor.visitOn}
                  </Moment>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* filter Modal */}
      <div
        className='modal fade filterAnalyticsModal mt-5'
        id='filterAnalyticsModal'
        tabIndex='-1'
        aria-labelledby='filterAnalyticsModalLabel'
        data-bs-backdrop='static'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4
                className='modal-title'
                id='filterAnalyticsModalLabel'
              >
                FILTERS
              </h4>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <form action='#'>
                <div className='row mb-3'>
                  <div className='col-sm mb-3'>
                    <label htmlFor='filterFromDate'>From</label>
                    <DatePicker
                      selected={filterData.fromDate}
                      dateFormat='dd/MM/yyyy'
                      id='filterFromDate'
                      onChange={handleFilterFromDateChange}
                      placeholderText='From Date'
                    />
                  </div>
                  <div className='col-sm'>
                    <label htmlFor='filterToDate'>To</label>
                    <DatePicker
                      selected={filterData.toDate}
                      dateFormat='dd/MM/yyyy'
                      id='filterToDate'
                      onChange={handleFilterToDateChange}
                      placeholderText='To Date'
                    />
                  </div>
                  <div className='col-sm mb-3'>
                    <label htmlFor='filter_class_input'>Class</label>
                    <br></br>
                    <select
                      name='filter_class_input'
                      id='filter_class_input'
                      onChange={(e) => {
                        handleClassChange(e.target.value);
                      }}
                      value={filterData.class ? filterData.class : ''}
                    >
                      <option value={null}>select</option>
                      {schClasses.length > 0 &&
                        schClasses.map((c) => <option value={c}>{c}</option>)}
                    </select>
                  </div>
                  <div className='col-sm mb-3'>
                    <label htmlFor='filter_subject_input'>Subject</label>
                    <br></br>
                    <select
                      name='filter_subject_input'
                      id='filter_subject_input'
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          subject: e.target.value,
                        })
                      }
                      value={filterData.subject ? filterData.subject : ''}
                    >
                      <option value={null}>select</option>
                      {schSubjects.length > 0 &&
                        schSubjects.map((s) => <option value={s}>{s}</option>)}
                    </select>
                  </div>
                  <div>
                    {student && (
                      <div className='col-sm mb-3'>
                        <label htmlFor='filter_student_input'>Student's</label>
                        <br></br>
                        <select
                          name='filter_student_input'
                          id='filter_students_input'
                          onChange={(e) => {
                            handleStudentChange(e.target.value);
                          }}
                          value={
                            filterData?.studentName
                              ? filterData.studentName
                              : ''
                          }
                        >
                          <option value={null}>Select</option>
                          {studentsList?.length > 0 &&
                            studentsList.map((c) => (
                              <option value={c}>{c}</option>
                            ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>

            <div className='mb-3 p-2'>
              <div className='row'>
                <div className='col d-grid'>
                  <button
                    type='button'
                    className='btn filterAnalyticsModalBtnCancel'
                    data-bs-dismiss='modal'
                    onClick={() => {
                      setFilterData({
                        fromDate: null,
                        toDate: null,
                        class: null,
                        subject: null,
                        studentName: null,
                      });
                      setStudent(false);
                    }}
                  >
                    Clear
                  </button>
                </div>
                <div className='col d-grid'>
                  <button
                    type='button'
                    className='btn  filterAnalyticsModalBtnApply'
                    onClick={filterVisitorData}
                    data-bs-dismiss='modal'
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableAnalytics;
