/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import ProfileImage from "../../images/Setting/profileImage.png";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useAlert } from "react-alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Settings.css";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  btnColor: {
    backgroundColor: "#5CE0D2",
    minWidth: "max-content",
    "&:hover": {
      backgroundColor: "#5CE0D2",
    },
  },
  progressColor: {
    color: "#5CE0D2",
  },
  deleteProfileImage: {
    fontSize: "2em",
    borderRadius: "50%",
    padding: "2px",
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
      boxShadow: "0px 0px 10px rgb(0,0,0,0.2)",
    },
  },
}));

const Setting = () => {
  document.title = "2xcell.in";
  const classes = useStyles();
  const alert = useAlert();

  const { userDetails, setUserDetails } = useContext(GlobalContext);
  const { register, handleSubmit, reset, setValue } = useForm();

  const [userData, setUserData] = useState({
    primaryEmail: "",
    primaryContact: "",
    schoolName: "",
    profileImage: undefined,
  });
  const [changePasswordLoader, setChangePasswordLoader] = useState(false);
  const [saveChangesLoader, setSaveChangesLoader] = useState(false);
  const [profilePicLoader, setProfilePicLoader] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(
    window.localStorage.getItem("profileImageUrl")
  );

  useEffect(() => {
    const fetchUserData = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}`,
          {
            headers: {
              token: authToken,
            },
          }
        );

        if (response.data.status === "success") {
          setUserData({
            ...userData,
            primaryEmail: response.data.data.staff.email,
            primaryContact: response.data.data.staff.contact,
            schoolName: response.data.data.staff.school.name,
            profileImage: window.localStorage.getItem("profileImgeUrl"),
          });

          setValue("email", response.data.data.staff.email);
          setValue("contact", response.data.data.staff.contact);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserData();
  }, []);

  const changePassword = async (data) => {
    const { currentPassword, newPassword } = data;
    setChangePasswordLoader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/auth/changepassword?user=staff`,
        {
          currentPassword,
          newPassword,
        },
        {
          headers: {
            token: authToken,
          },
        }
      );

      if (response.data.status === "succcess") {
        alert.success("Password Changed.");
      }

      reset({
        currentPassword: "",
        newPassword: "",
      });
    } catch (error) {
      alert.show("Try Again.");
    }

    setChangePasswordLoader(false);
  };

  const saveChanges = async (data) => {
    const { email, contact } = data;

    if (email !== "" && contact !== "") {
      const authToken = localStorage.getItem("authToken");
      setSaveChangesLoader(true);
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}`,
          {
            email,
            contact,
          },
          {
            headers: {
              token: authToken,
            },
          }
        );

        if (response.data.status === "success") {
          alert.success("Save Changes");
        }
      } catch (error) {
        alert.show("Try Again.");
      }

      setSaveChangesLoader(false);
    }
  };

  const uploadProfileImage = async (e) => {
    setProfilePicLoader(true);
    const formData = new FormData();
    formData.append("thumbnail", e.target.files[0]);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/profile`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            token: authToken,
          },
        }
      );
      console.log("Profile picture change response->", response);
      if (response.data.status === "success") {
        alert.success("Profile Pictuce Uploaded.");
        setUserData({
          ...userData,
          profileImage: response.data.data.profile.fileUrl,
        });
        setUserDetails({
          ...userDetails,
          profileImageUrl: response.data.data.profile.fileUrl,
        });
        window.localStorage.setItem(
          "profileImageUrl",
          response.data.data.profile.fileUrl
        );
        setProfileImageUrl(window.localStorage.getItem("profileImageUrl"));

        window.location.reload();
      }
    } catch (error) {
      if (error.response?.data?.status === "fail") {
        alert.error(error.response.data.message);
      } else {
        alert.show("Try Again.");
      }
    }

    setProfilePicLoader(false);
  };

  const deleteProfileImage = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/profile`,
        {
          method: "DELETE",
          headers: {
            token: `${window.localStorage.getItem("authToken")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setProfileImageUrl(undefined);
            alert.show("Profile picture deleted successfully");
            setUserData({ ...userData, profileImage: undefined });
            setUserDetails({ ...userDetails, profileImageUrl: undefined });
            window.localStorage.removeItem("profileImageUrl");

            window.location.reload();
          } else {
            alert.error(response.message);
          }
        });
    } catch (error) {
      alert.error(error.message);
      console.log(error);
    }
  };

  return (
    <>
      <section className="setting my-5">
        <div className="container pb-3">
          {/* Profile */}
          <div className="profile border rounded p-4">
            {profilePicLoader && (
              <div className="text-center">
                <CircularProgress className={classes.progressColor} />
              </div>
            )}

            <h1 className="fw-bold" style={{ textTransform: "capitalize" }}>
              {userDetails?.role || "Profile"}
            </h1>

            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <input
                  accept="image/*"
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  name="profileImage"
                  onChange={uploadProfileImage}
                />
                <label htmlFor="icon-button-file">
                  {!["undefined", undefined, null, "null"].includes(
                    profileImageUrl
                  ) ? (
                    <img src={profileImageUrl} alt="" width="100px" />
                  ) : (
                    <img src={ProfileImage} alt="" />
                  )}
                </label>
              </div>
              <div className="flex-grow-1 ms-3">
                <h3 className="fw-bold">{userDetails.name}</h3>
                <small>{userData.schoolName}</small>
                <br />
                {/* <small className="text-muted">Sector 62</small> */}
                <DeleteOutlineOutlinedIcon
                  className={classes.deleteProfileImage}
                  style={{ cursor: "pointer", fontSize: "2em" }}
                  title="Delete Profile Picture"
                  onClick={deleteProfileImage}
                />
              </div>
            </div>

            <div className="mt-3">
              <form onSubmit={handleSubmit(saveChanges)}>
                <div className="row">
                  <div className="col-sm-5">
                    <div className="mb-3">
                      <label htmlFor="primaryEmail" className="form-label">
                        Primary Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="primaryEmail"
                        placeholder="example@example.com"
                        defaultValue={userData.primaryEmail}
                        {...register("email")}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="mb-3">
                      <label htmlFor="primaryContact" className="form-label">
                        Primary Contact
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="primaryContact"
                        placeholder="0120-2404xxx"
                        defaultValue={userData.primaryContact}
                        {...register("contact")}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-sm-2 pt-2">
                    <div className="text-center mt-4">
                      {saveChangesLoader ? (
                        <CircularProgress className={classes.progressColor} />
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          className={classes.btnColor}
                        >
                          Save Changes
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* Notification */}
          <div className="border rounded notification p-4 mt-5">
            <h3 className="fw-bold">Notifications</h3>

            <div className="row">
              <div className="col-sm-11">
                <p>Send Email Notifications </p>
              </div>
              <div className="col-sm-1">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="notification"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Password */}
          <div className="border password rounded p-4 mt-5">
            <h3 className="fw-bold">Password</h3>

            <form onSubmit={handleSubmit(changePassword)}>
              <div className="row">
                <div className="col-sm-5">
                  <div className="mb-3">
                    <label htmlFor="cpassword" className="form-label">
                      Current Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="cpassword"
                      {...register("currentPassword")}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="mb-3">
                    <label htmlFor="nPassword" className="form-label">
                      New Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="nPassword"
                      {...register("newPassword")}
                      required
                    />
                  </div>
                </div>

                <div className="col-sm-2 pt-2">
                  <div className="text-center mt-4">
                    {changePasswordLoader ? (
                      <CircularProgress className={classes.progressColor} />
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        className={classes.btnColor}
                      >
                        Change Password
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Button
            type="submit"
            variant="contained"
            onClick={() => window.location.href = "https://www.2xcell.in/contact.html"}
            style={{ display:"block", background: "red", color: "white",margin:"20px 0 20px auto"}}
          >
            Delete account
          </Button>
        </div>
      </section>
    </>
  );
};

export default Setting;
