import Selector from "./Selector/Selector";

function Option(props) {
  return (
    <li className={`form-input-option`} onClick={props.selectOption}>
      {props.selectable && (
        <Selector checkbox={props.checkbox} selected={props.selected} />
      )}
      <span>{props.option.name}</span>
    </li>
  );
}

export default Option;
