import React, { useState } from "react";
import Graph from "./Graph";
import TableAnalytics from "./TableAnalytics";
import CircularProgress from "@material-ui/core/CircularProgress";

const Analytics = () => {
  document.title = "2xcell.in";
  const [barGraphLoader, setBarGraphLoader] = useState(false);

  return (
    <>
      <section className="my-5">
        <div className="container">
          {barGraphLoader && (
            <div className="text-center">
              <CircularProgress style={{ color: "#5CE0D2" }} />
            </div>
          )}

          <Graph setBarGraphLoader={setBarGraphLoader} />

          <div className="mt-5">
            <TableAnalytics />
          </div>
        </div>
      </section>
    </>
  );
};

export default Analytics;
