export function Option({
  optionId,
  value,
  image,
  isPraticeSession,
  isSelected,
  selectOption,
}) {
  return (
    <div
      className={
        isPraticeSession ? "opt1-container ps-op1" : "opt1-container ot-op1"
      }
      style={{
        position: "relative",
        backgroundColor: isSelected ? "#CDFFE1" : "",
      }}
    >
      <label onClick={selectOption}>
        <span
          style={{fontSize:"1.1rem"}}
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        ></span>
        <input type="radio" name="radio" id={optionId} />
        <span
          className={
            isPraticeSession ? "checkmark ps_check" : "checkmark ot_check"
          }
          style={
            isSelected
              ? {
                  backgroundColor: "#4bdf3f",
                  border: "3px solid #4bdf3f",
                }
              : null
          }
        ></span>
      </label>

      <div
        className="imgBlock"
        style={
          image
            ? {
                display: "flex",
                width: "50%",
                maxWidth: "300px",
                height: "fit-content",
                margin: "25px",
              }
            : { display: "none" }
        }
      >
        <img
          src={image}
          style={{
            width: "100%",
            maxWidth: "fit-content",
            maxHeight: "fit-content",
          }}
        />
      </div>
      <div
        onClick={selectOption}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top:"0",
          left:"0",
          cursor:"pointer"
        }}
      ></div>
    </div>
  );
}
