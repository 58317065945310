import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalState";
import ReportCardTable from "./ReportCardTable";

const ReportCard = () => {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { userDetails } = useContext(GlobalContext);
  const params = useParams();
  const id = params.rowid;
  useEffect(() => {
    // /api/v1/staffs/:staffId/assigntests/:assigntestId
    const fetchData = async () => {
      setLoader(true);
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/assigntests/${id}?sort=-totalScore,duration&submissionStatus=done`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          setTableData(response.data.data.reports);
          console.log("Response", response.data.data.reports);
          setLoader(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className=" table_container my-5">
      <div
        className="table_title_section"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <h2
          style={{
            fontWeight: 700,
            whiteSpace: "nowrap",
            marginLeft: "0.5%",
            marginTop: "0.5%",
          }}
        >
          Test Reports
        </h2>
        {/* <div className="search_container">
          <SearchBar search={searchValue} setSearch={setSearchValue} />
        </div> */}
      </div>
      {!loader ? (
        <ReportCardTable rows={tableData} />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <div className="spinner">
            Loading
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportCard;
