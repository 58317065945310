import "./QuestionReport.css";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Question from "./Questions/Question";

const QuestionSection = ({ question }) => {
  return (
    <Container fluid="xl" className="analytics_main_section">
      <Row
        className="questionanalytics_section_layer my-5"
        style={{ boxShadow: "none" }}
      >
        {question
          .filter((q) =>
            ["mmcq", "mcq", "correctstatement", "oneword"].includes(
              q.questionId?.questionType
            )
          )
          .map((q, i) => {
            return (
              <Question
                type={q.questionId.questionType}
                question={q}
                ind={i + 1}
              />
            );
          })}
      </Row>
    </Container>
  );
};

export default QuestionSection;
