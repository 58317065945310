import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ReactComponent as Loader } from "../../../images/Loader.svg";
import { useState } from "react";
import { TablePagination } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  tableRow: {
    textDecoration: "none",
    "& > td": {
      fontWeight: "bold",
      fontSize: "1.02em",
    },
    "&:hover": {
      backgroundColor: "#5ce0d252 !important",
    },
  },
  tableHeading: {
    " & > th": {
      color: "#000 !important",
      fontWeight: "bolder",
      fontSize: "1.02em",
    },
  },
}));
const parseString = (str) => {
  return new DOMParser()
    .parseFromString(str, "text/html")
    .body.textContent.trimStart();
};

const QuestionReportTable = ({ rows, loading }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };
  return (
    <div className={classes.tableContainer}>
      {!loading ? (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeading}>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">Question</TableCell>
                <TableCell align="center">Subject</TableCell>
                <TableCell align="center">Topic</TableCell>
                <TableCell align="center">Difficulty Level</TableCell>
                <TableCell align="center">Cognitive Level</TableCell>
                <TableCell align="center">Time Spent</TableCell>
                <TableCell align="center">Correct/ Incorrect</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length ? (
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      variant="button"
                      className={classes.tableRow}
                      style={{
                        backgroundColor: index % 2 ? "#5ce0d2" : "white",
                      }}
                    >
                      <TableCell scope="row" align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell
                        scope="row"
                        align="center"
                        dangerouslySetInnerHTML={{
                          __html: row?.questionId?.question,
                        }}
                      />
                      <TableCell
                        scope="row"
                        align="center"
                        dangerouslySetInnerHTML={{
                          __html: row?.subjectId?.name,
                        }}
                      />
                      <TableCell
                        scope="row"
                        align="center"
                        dangerouslySetInnerHTML={{
                          __html: row?.topicId?.name,
                        }}
                      />
                      <TableCell
                        scope="row"
                        align="center"
                        dangerouslySetInnerHTML={{
                          __html: row.difficulty,
                        }}
                      />
                      <TableCell
                        scope="row"
                        align="center"
                        dangerouslySetInnerHTML={{
                          __html: row.cognitive,
                        }}
                      />
                      <TableCell scope="row" align="center">
                        {/* {Math.floor(row.timeTaken / 3600000)}h{" "} */}
                        {Math.floor((row.timeTaken / 1000 / 60) << 0)}m{" "}
                        {((row.timeTaken / 1000) % 60).toPrecision(2)}s
                      </TableCell>
                      <TableCell scope="row" align="center">
                        {row.status !== "passed" || row.status === "failed"
                          ? "Incorrect"
                          : "Correct"}
                      </TableCell>
                      <TableCell scope="row" align="center">
                        {row.status === "unattempted"
                          ? "Unattempted"
                          : "Attempted"}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell scope="row" align="center">
                    -
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            labelRowsPerPage={false}
            rowsPerPageOptions={false}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default QuestionReportTable;
