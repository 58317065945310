import { useEffect, useCallback } from 'react';

const useDisableContextMenu = () => {
  const disableMenu = useCallback((e) => {
    e.preventDefault();
  }, []);
  useEffect(() => {
    if (!window) return;
    window.document.addEventListener('contextmenu', disableMenu);
    return () => {
      if (window)
        window.document.removeEventListener('contextmenu', disableMenu);
    };
  }, [disableMenu]);
};

export default useDisableContextMenu;
