import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import "../styles/docViewer.css";

const pdfViewer = {
  width: "90%",
  height: "150%",
  margin: "auto",
  marginTop: "1rem",
  paddingTop: "1rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

function Document(props) {
  const history = useHistory();
  const [loaded, setLoaded] = useState(true);
  const iframeRef = useRef();

  useEffect(() => {
    if (props?.location?.useProps?.click) {
      props.location.useProps.click();
    }
  }, []);

  useEffect(() => {
    if (!props.location?.useProps?.fileUrl) {
      history.goBack();
    }
  }, [props.location]);

  return (
    <div style={pdfViewer}>
      <iframe
        id="pdf_iframe"
        ref={iframeRef}
        src={props?.location?.useProps?.fileUrl}
        width="90%"
        height="800px"
        style={{ display: loaded ? "block" : "none" }}
      ></iframe>
      <h4
        className="text-center"
        style={{ display: !loaded ? "block" : "none", marginTop: "1rem" }}
      >
        <div className="spinner">
          Loading
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </h4>
    </div>
  );
}

export default Document;
