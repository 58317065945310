import { ModuleCell, TextCell } from "./Cell";

function Row(props) {
  return (
    <div className={`${props.className}`}>
      <TextCell
        name="activity"
        userId={props.userId}
        planId={props.planId}
        value={props.activity}
        rowId={props.rowId}
        editCell={props.editCell}
      />
      <TextCell
        name="teacher"
        userId={props.userId}
        planId={props.planId}
        value={props.teacher}
        rowId={props.rowId}
        editCell={props.editCell}
      />
      <TextCell
        name="question"
        userId={props.userId}
        planId={props.planId}
        value={props.question}
        rowId={props.rowId}
        editCell={props.editCell}
      />
      <TextCell
        name="answer"
        userId={props.userId}
        planId={props.planId}
        rowId={props.rowId}
        value={props.answer}
        editCell={props.editCell}
      />
      <ModuleCell
        name="resources"
        resources={props.resources}
        userId={props.userId}
        planId={props.planId}
        rowId={props.rowId}
        editCell={props.editCell}
        editor={true}
      />
    </div>
  );
}

export default Row;
