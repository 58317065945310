import "../AssessmentAnalytics/QuestionReport.css";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Backbtn from "../../images/Arrow 9.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Moment from "react-moment";
import ExportButton from "../../images/export (1).svg";
import { makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";
import HomeIcon from "../../images/HomeIcon.svg";
import assignImg from "../../images/assign.svg";
import assessmentImg from "../../images/assessment.svg";
import analyticGraphImg from "../../images/analyticGraph.svg";
import Box from "@material-ui/core/Box";
import { GlobalContext } from "../../context/GlobalState";
import DropDownShutter from "../DropDown/DropDown";
import axios from "axios";
import QuestionSection from "./Question Report/QuestionSection";
import QuestionReportTable from "./Tables/QuestionReportTable";
import SearchBar from "../OnlineTest/TestList/SearchBar/SearchBar";
import exportFromJSON from "export-from-json";
import Sideboard from "./SideboardOT/Sideboard";
import { FilterComponent } from "./Sidebar/DropDown";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const OnlineTestQuestionReport = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#edfcfa",
      boxShadow: "none",
    },
  }));
  const { userDetails, setUserDetails } = useContext(GlobalContext);
  const params = useParams();
  const [heading, setHeading] = useState("Analytics");
  const [sectionId, setSectionId] = useState(params.sectionId);
  const [studentId, setStudentId] = useState(params.studentId);
  const [subjectValue, setSubjectValue] = useState(params.subjectValue);
  const [chapterValue, setChapterValue] = useState("");
  const history = useHistory();
  const classes = useStyles();
  const [subject,setSubject]=useState([]);
  const [filterReports,setFilterReports]=useState({
    subject:"",
    topic:"",
    difficulty:"",
    cognitive:"",
    correct:"",
    status:"",



  })
  const [tabData,setTabData]=useState([]);

  const [value, setValue] = useState(1);
  const [miniCard, setMiniCard] = useState({
    TotalMarks: 0,
    Score: 0,
    Avg_Score: 0,
    Time: 0,
    Rank: 0,
  });
  const [as_loader, setAs_loader] = useState(false);
  const [q_loader, setQ_loader] = useState(false);
  const [attemptStatusTotalQ, setAttemptStatusTotalQ] = useState(0);

  const [q_data, setQ_data] = useState([
    { name: "Correct", value: 0 },
    { name: "Incorrect", value: 0 },
    { name: "Not Attempted", value: 0 },
  ]);

  const [as_data, setAs_data] = useState([]);
  const [question, setQuestion] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [topic,setTopics]=useState([]);
  const [searchValue, setSearchValue] = useState("");

  const COLORS = ["#1DCD6D", "#9DABDD", "#FF8989"];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });

  const [dropdown, setdropdown] = useState(isSmallScreen ? false : true);

  const handleShutterOpen = () => {
    setdropdown(!dropdown);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeHeading = (val) => {
    setUserDetails({ ...userDetails, selectedTab: val, pathName: {} });
    history.goBack();
    setHeading(val);
  };

  const fetchMiniCardData = async () => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_RANDOMIZE_ORIGIN}/api/v1/testanalytics/testoverview?submission=${params.submissionId}&test=${params.testId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        const newMini_card = {
          TotalMarks: 0,
          Score: 0,
          Avg_Score: 0,
          Time: 0,
          Rank: 0,
        };
        response.data.data.overview.forEach((element) => {
          newMini_card.TotalMarks += element.totalMarks;
          newMini_card.Score += element.totalScore;
          newMini_card.Avg_Score += element.avgScores;
          newMini_card.Time += `${Math.floor(
            element.timeSpend / 3600000
          )}h ${Math.floor(
            (element.timeSpend % 3600000) / 60000
          )}m ${Math.floor((element.timeSpend / 1000) % 60)}s`;
          newMini_card.Rank += element.rank;
        });
        setMiniCard(newMini_card);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAverageScore = async () => {
    setAs_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_RANDOMIZE_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/scores`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      console.log("Res", response);

      if (response.data.status === "success") {
        setAs_data([]);
        console.log("Res", response);
        var new_data = response.data.data.scores.map((e) => {
          return {
            name: e._id ? e._id.name : "",
            value: e.scores,
          };
        });
        setAs_data(new_data);
      } else {
        return;
      }
    } catch (error) {}
    setAs_loader(false);
  };

  const fetchChapterwiseAttemptStatus = async () => {
    setQ_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questionanalytics/sections/${sectionId}/subjects/${subjectValue}/questionsattemptstatus?student=${studentId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        const newq_data = [
          { name: "Correct", value: 0 },
          { name: "Incorrect", value: 0 },
          { name: "Not Attempted", value: 0 },
        ];
        let totalq = 0;
        response.data.data.questionsAttemptStats.forEach((element) => {
          newq_data[0].value += element.correct;
          newq_data[1].value += element.incorrect;
          newq_data[2].value += element.unattempted;
          totalq = element.correct + element.incorrect + element.unattempted;
        });
        setAttemptStatusTotalQ(totalq);
        setQ_data(newq_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setQ_loader(false);
  };

  const fetchQuestionDetails = async () => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_RANDOMIZE_ORIGIN}/api/v1/testanalytics/testquestionsdetail?submission=${params.submissionId}&test=${params.testId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setQuestion(response.data.data.analytics);
      }
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTableData = async () => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_RANDOMIZE_ORIGIN}/api/v1/testanalytics/testquestionsreportofstudent?submission=${params.submissionId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        const filtered_data = response.data.data.analytics.filter((e) => {
          return e.question;
        });
        setTableData(filtered_data);
        let dt=[];
        let temp=[]
        filtered_data.forEach(item=>{
          if(item.topicId&&!temp.includes(item.topicId._id))
          {
          dt.push({...item?.topicId})
          temp.push(item?.topicId?._id);
          }
        })
        setTopics(dt);
        setTabData(filtered_data)
      }
    } catch (error) {
      console.log(error);
    }
  };


  const getSubjects=async()=>{
    const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/assignSubjects`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          
          let dt=[]
          response.data.data.sections.forEach((item)=>{
            item.subjects.forEach(sub=>{
              dt.push({name:sub.subject,_id:sub.subjectId})
            })
          });
          setSubject(dt);
          
        }
      } catch (error) {
        console.log(error);
      }
  }

  useEffect(async () => {
    await fetchMiniCardData();
    await fetchAverageScore();
    await fetchChapterwiseAttemptStatus();
    await fetchQuestionDetails();
    await fetchTableData();
    await getSubjects()
  }, []);


  useEffect(()=>{
    let dt=tabData;
    console.log(dt);
    if(filterReports.subject)
    {
      dt=dt.filter(item=>item.subjectId._id===filterReports.subject._id)
    }
    if(filterReports.topic)
    {
      dt=dt.filter(item=>item.topicId._id===filterReports.topic._id);
    }
    if(filterReports.difficulty)
    {
      dt=dt.filter(item=>item.difficulty===filterReports.difficulty._id)
    }
    if(filterReports.cognitive)
    {
      dt=dt.filter(item=>item.cognitive===filterReports.cognitive._id)
    }
    if(filterReports.status)
    {
     if(filterReports.status._id==="unattempted")
     {
      dt=dt.filter(item=>item.status===filterReports.status._id)
     }
     else{
      dt=dt.filter(item=>item.status!=="unattempted")
     }
    }
    if(filterReports.correct)
    {
      dt=dt.filter(item=>item.status===filterReports.correct._id)

    }
    setTableData(dt);
  },[filterReports])

  const barColors = ["#7CB4A0", "#ECBE7A", "#B6E4FF", "#9DABDD"];

  const search = () => {
    console.log(tableData);
    let searchData = tableData.filter(
      (data) =>
        data?.chapterId?.name
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        data?.subjectId?.name
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        data?.testId?.questionSet?.title
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        data?.question.toLowerCase().includes(searchValue.toLowerCase()) ||
        data?.topicId?.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setTableData(searchData);
  };

  useEffect(() => {
    //when the search box is empty
    if (!searchValue) {
      fetchTableData();
    } else {
      search();
    }
  }, [searchValue]);

  const exportCsv = () => {
    const data = tableData;
    const formatted_data = data.map((element) => {
      return {
        Question: element?.questionId?.question,
        Subject: element?.subjectId?.name,
        Topic: element?.topicId?.name,
        Difficulty: element.difficulty,
        Cognitive: element.cognitive,
        "Time Spent": `${
          Math.floor((element.timeTaken % 3600000) / 60000) +
          "m" +
          ((element.timeTaken / 1000) % 60).toPrecision(2) +
          "s"
        }`,
        "Correct/ Incorrect": `${
          element.status !== "passed" || element.status === "failed"
            ? "Incorrect"
            : "Correct"
        }`,
        Status: `${
          element.status === "unattempted" ? "Unattempted" : "Attempted"
        }`,
      };
    });
    const fileName = "Practice Report";
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data: formatted_data, fileName, exportType });
  };

  return (
    <>
      <section className={isSmallScreen ? "modules my-4" : "modules my-5"}>
        <div
          className={
            isSmallScreen ? "container-fluid dashboard_container" : "container"
          }
        >
          <div className="box">
            <div
              className="upperBox"
              style={{ display: isSmallScreen ? "none" : "" }}
            >
              <div className="alignItem">
                <div className="row">
                  <div className="boxRow">
                    <div className="boxRowLeft">
                      <h1 className="className">
                        {params.class}-{params.section}
                      </h1>
                      <div className="pathDetail">
                        <h2 style={{ fontWeight: "700" }}>
                          Analytics &gt; Online Assessment
                        </h2>
                        <p style={{ fontWeight: "600", color: "#ffffff" }}>
                          {params.subject}
                        </p>
                        <p style={{ fontWeight: "600", color: "#ff0000" }}>
                          {params.student}
                        </p>
                      </div>
                    </div>
                    <div className="DateBox">
                      <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                        <CalendarTodayIcon />{" "}
                        {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              <Paper className={classes.root}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  style={{
                    background: "#DCFFFB",
                    marginTop: "-12px",
                    display: dropdown ? "" : "none",
                  }}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={"on"}
                >
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={HomeIcon}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Home"
                    {...a11yProps(0)}
                    onClick={() => changeHeading("Dashboard")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={analyticGraphImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Analytics"
                    {...a11yProps(1)}
                    onClick={() => changeHeading("Analytics")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assignImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assign Task"
                    {...a11yProps(2)}
                    onClick={() => changeHeading("Assign Task")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assessmentImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assessment"
                    {...a11yProps(3)}
                    onClick={() => changeHeading("Assessment")}
                  />
                </Tabs>
              </Paper>
            }
            <div
              className="shutter_PS"
              style={{
                marginTop: dropdown ? "0px" : "-15px",
                display: isSmallScreen ? "flex" : "none",
              }}
            >
              <DropDownShutter
                onClick={handleShutterOpen}
                clicked={dropdown}
                dropdown={dropdown}
                setDropDown={setdropdown}
              />
            </div>
          </div>
        </div>
      </section>
      <Container fluid="xl" className="analytics_main_section">
        <img
          src={Backbtn}
          className="back_btn"
          onClick={() => history.goBack()}
          style={{ cursor: "pointer" }}
        />
        <Sideboard
          sectionId={sectionId}
          setSectionId={setSectionId}
          subjectValue={subjectValue}
          setSubjectValue={setSubjectValue}
          chapterValue={chapterValue}
        />
        <Row
          className="studentanalytics_mini_cards mx-auto my-5"
          style={{ color: "white" }}
        >
          <div className="student_analytics_mini_cards_section">
            <Col
              className="student_mini_card"
              style={{
                marginLeft: "0px",
                backgroundColor: "#AD73F5",
              }}
            >
              <div>
                <div style={{ textAlign: "center" }}>Total Marks</div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.TotalMarks}</h5>
                </div>
              </div>
            </Col>
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#47CFE9" }}
            >
              <div>
                <div style={{ textAlign: "center" }}>Score</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <h5 style={{ textAlign: "center" }}>{miniCard.Score}</h5>
                </div>
              </div>
            </Col>
          </div>
          <div
            className="student_analytics_mini_cards_section"
            style={{ marginLeft: "-8.5rem", marginRight: "-8.5rem" }}
          >
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#F3B592" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Average Score
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.Avg_Score}</h5>
                </div>
              </div>
            </Col>
          </div>
          <div className="student_analytics_mini_cards_section">
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#70E2AE" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Time
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.Time}</h5>
                </div>
              </div>
            </Col>
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#0079EADE" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Rank
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.Rank}</h5>
                </div>
              </div>
            </Col>
          </div>
        </Row>
        <Row>
          <QuestionSection question={question} />
        </Row>



        <div style={{display:"flex",flexWrap:"wrap",margin:"10px 20px",marginBottom:"0px"}} className="filters">
           
        
           
            <FilterComponent options={subject} name="subject" formData={filterReports} setFormData={setFilterReports} placeholder="Subject"/>
            
            <FilterComponent options={topic} name="topic" formData={filterReports} setFormData={setFilterReports} placeholder="Topic"/>
            <FilterComponent options={[{name:"Easy",_id:"easy"},{name:"Intermediate",_id:"intermediate"},{name:"Hard",_id:"hard"}]} name="difficulty" formData={filterReports} setFormData={setFilterReports} placeholder="Difficulty"/>
            <FilterComponent options={[{name:"Correct",_id:"correct"},{name:"Incorrect",_id:"incorrect"}]} name="correct" formData={filterReports} setFormData={setFilterReports} placeholder="Correct"/>
            <FilterComponent options={[{name:"Knowledge",_id:"knowledge"},{name:"Understanding",_id:"understanding"},{name:"Analysis",_id:"analysis"},{name:"Application",_id:"application"}]} name="cognitive" formData={filterReports} setFormData={setFilterReports} placeholder="Cognitive"/>
            <FilterComponent options={[{name:"Attempted",_id:"attempted"},{name:"Unattempted",_id:"unattempted"}]} name="status"formData={filterReports} setFormData={setFilterReports} placeholder="Status"/>
            {/* <FilterComponent options={[{name:"CT",_id:"CT"},{name:"UT",_id:"UT"},{name:"HY",_id:"HY"}]} name="testType" formData={filterReports} setFormData={setFilterReports} placeholder="Test Type"/> */}
           
           
            <div onClick={()=>{setFilterReports({
    subject:"",
    topic:"",
    difficulty:"",
    cognitive:"",
    correct:"",
    status:"",



  })}} style={{display:"flex",cursor:"pointer",alignItems:"center",marginLeft:"10px"}}>
            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.5 8.25C6.57107 8.25 8.25 6.57107 8.25 4.5C8.25 2.42893 6.57107 0.75 4.5 0.75C2.42893 0.75 0.75 2.42893 0.75 4.5C0.75 6.57107 2.42893 8.25 4.5 8.25Z" stroke="black" stroke-opacity="0.54" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.625 3.375L3.375 5.625" stroke="black" stroke-opacity="0.54" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.375 3.375L5.625 5.625" stroke="black" stroke-opacity="0.54" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

               <span style={{marginLeft:"4px"}}>Clear</span>
            </div>
            </div>
        <div className="table_container">
          <div
            className="table_title_section"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {isSmallScreen ? (
              <h5
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  marginLeft: "0.5%",
                  marginTop: "5%",
                }}
              >
                Question Report
              </h5>
            ) : (
              <h2
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  marginLeft: "0.5%",
                  marginTop: "0.5%",
                }}
              >
                Question Report
              </h2>
            )}
            {isSmallScreen ? (
              <img
                onClick={exportCsv}
                style={{
                  cursor: "pointer",
                  // marginLeft: 5,
                  transform: "scale(0.7)",
                }}
                src={ExportButton}
              />
            ) : (
              <img
                onClick={exportCsv}
                style={{
                  cursor: "pointer",
                  marginLeft: 5,
                }}
                src={ExportButton}
              />
            )}
            <div className="search_container">
              <SearchBar search={searchValue} setSearch={setSearchValue} />
            </div>
          </div>

          <QuestionReportTable rows={tableData} />
        </div>
      </Container>
    </>
  );
};

export default OnlineTestQuestionReport;
