import React from 'react';

const LeftArrow = (props) => {
  return (
    <svg
      width='59'
      height='59'
      viewBox='0 0 59 59'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g filter='url(#filter0_d_13:4945)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M29.5 55C44.6878 55 57 42.6878 57 27.5C57 12.3122 44.6878 0 29.5 0C14.3122 0 2 12.3122 2 27.5C2 42.6878 14.3122 55 29.5 55ZM31.9307 19.6182C33.2731 18.2758 33.2731 16.0992 31.9307 14.7568C30.5882 13.4144 28.4117 13.4144 27.0693 14.7568L16.7568 25.0693C15.4144 26.4118 15.4144 28.5883 16.7568 29.9307L27.0693 40.2432C28.4118 41.5856 30.5883 41.5856 31.9307 40.2432C33.2731 38.9007 33.2731 36.7242 31.9307 35.3818L27.4864 30.9375H39.8125C41.711 30.9375 43.25 29.3985 43.25 27.5C43.25 25.6015 41.711 24.0625 39.8125 24.0625L27.4864 24.0625L31.9307 19.6182Z'
          fill={props.color || '#FF574B'}
        />
      </g>
      <defs>
        <filter
          id='filter0_d_13:4945'
          x='0'
          y='0'
          width='59'
          height='59'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_13:4945'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_13:4945'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LeftArrow;