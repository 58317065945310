const appendZero = (number) => (number < 10 ? `0${number}` : number);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const getFormattedDate = (date, separator, format) => {
  const newdate = new Date(date);
  const value = {
    DD: appendZero(newdate.getDate()),
    MM: appendZero(newdate.getMonth()+1),
    YY: appendZero(newdate.getFullYear()),
  };
  return format
    .split(separator)
    .map((field) => value[field])
    .join(separator);
};

export const getDate = (date) => {
  const newdate = new Date(date);
  return `${appendZero(newdate.getDate())} ${
    months[newdate.getMonth()]
  }, ${newdate.getFullYear()}`;
};
