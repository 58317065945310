import LPNavbar from "../LPNavbar/LPNavbar";
import EditIcon from "../icons/edit-1.svg";
import DownloadIcon from "../icons/download-1.svg";
import Table from "./Table";
import { useEffect, useState } from "react";
function LessonPlanTable(props) {
  const [print, setPrint] = useState(false);

  useEffect(() => {
    if (!print) return;
    function handleAfterPrint() {
      setPrint(false);
    }
    //attach listener
    window.addEventListener("afterprint", handleAfterPrint);
    window.print();
    //remove listener
    return () => {
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, [print]);

  return (
    <div className="lp-table">
      {!print && (
        <LPNavbar
          mainHeading={`${props?.plan?.sectionId?.grade || ""}-${
            props?.plan?.sectionId?.section || ""
          } ${props?.plan?.subjectId?.name || ""}`}
          goBack={() => props.updateAction("edit")}
        >
          <div
            className="actions"
            style={{
              width: "fit-content",
              margin: "auto 0 auto auto",
            }}
          >
            <img onClick={() => setPrint(true)} src={DownloadIcon} alt="edit" />
          </div>
        </LPNavbar>
      )}
      <Table plan={props.plan} />
    </div>
  );
}

export default LessonPlanTable;
