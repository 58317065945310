import React, { useEffect, useState } from "react";
import "./styles/Submission.css";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },
}));

const Submission = (props) => {
  document.title = "2xcell.in";
  // console.log(props.match.params.taskid);
  const classes = useStyles();

  const [submissions, setSubmissions] = useState([]);
  const [onModel, setOnModel] = useState(null);
  const [fetched, setFetched] = useState(false);

  //function to fetch the submissions
  const getSubmissions = async () => {
    const grade = window.localStorage.getItem("grade");
    const section = window.localStorage.getItem("section");
    const taskId = window.localStorage.getItem("taskId");
    const type = window.localStorage.getItem("type");

    try {
      await fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/tasks/${taskId}/submit${type}`,
        {
          headers: {
            token: `${window.localStorage.getItem("authToken")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          setFetched(true);
          setSubmissions(response.data.submissions);
          setOnModel(response.data.submissions[0].taskId.onModel || "module");
        });
    } catch (e) {}
  };

  useEffect(() => {
    getSubmissions();
  }, []);

  //To calculate percentage of score
  const percentage = (a, b) => {
    return (a / b) * 100 + "%";
  };

  return (
    <>
      <section className="submission my-5">
        <div className="container">
          <div className="box mb-5">
            <h1 className="fw-bold">Submissions</h1>
          </div>

          {/* Wait till an onModel is assigned by the response  */}
          {onModel ? (
            // Show table structure for a test if the onModel is test
            onModel === "test" ? (
              <TableContainer className="submission_table" component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Student</TableCell>
                      <TableCell align="center">Class</TableCell>
                      <TableCell align="center">Subject</TableCell>
                      <TableCell align="center">Chapter</TableCell>
                      <TableCell align="center">Topic</TableCell>
                      <TableCell align="center">Correct Answers</TableCell>
                      <TableCell align="center">Incorrect Answers</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Score</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {submissions.map((item) => (
                      <TableRow>
                        <TableCell align="center">
                          {item?.userId?.name}
                        </TableCell>
                        <TableCell align="center">
                          {item?.userId?.grade + "-" + item?.userId?.section}
                        </TableCell>
                        <TableCell align="center">
                          {item.taskId.subject}
                        </TableCell>
                        <TableCell align="center">
                          {item.taskId.chapter}
                        </TableCell>
                        <TableCell align="center">
                          {item.taskId.topic}
                        </TableCell>
                        <TableCell align="center">
                          {item.correctAnswers + "/" + item.totalQuestions}
                        </TableCell>
                        <TableCell align="center">
                          {item.wrongAnswers + "/" + item.totalQuestions}
                        </TableCell>
                        <TableCell align="center">
                          {item.submittedAt.slice(0, 10)}
                        </TableCell>
                        <TableCell align="center">
                          {percentage(item.correctAnswers, item.totalQuestions)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              //If onModel is module or worksheet then shoe different table structure
              <TableContainer className="submission_table" component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Student</TableCell>
                      <TableCell align="center">Class</TableCell>
                      <TableCell align="center">Subject</TableCell>
                      <TableCell align="center">File</TableCell>
                      <TableCell align="center">Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {submissions.map((item) => (
                      <TableRow>
                        <TableCell align="center">
                          {item?.userId?.name}
                        </TableCell>
                        <TableCell align="center">
                          {item?.userId?.grade + "-" + item?.userId?.section}
                        </TableCell>
                        <TableCell align="center">
                          {item.taskId.subject}
                        </TableCell>
                        <TableCell align="center">
                          <a
                            href={`${
                              item.resources ? item.resources[0].fileUrl : ""
                            }`}
                            target="_blank"
                            style={{ color: "black" }}
                          >
                            {item.resources
                              ? item.resources[0].fileName.split("/")[1]
                              : ""}
                          </a>
                        </TableCell>
                        <TableCell align="center">
                          {item.submittedAt.slice(0, 10)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          ) : (
            // Show Loading till the data is fetched
            <h1 className="text-center">
              {fetched ? "No submissions yet" : "Loading..."}
            </h1>
          )}
        </div>
      </section>
    </>
  );
};

export default Submission;
