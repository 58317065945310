/* Imports */
import "../AssessmentAnalytics/PracticeAssessment.css";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  Label,
  YAxis,
  Bar,
  LabelList,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { Col, Container, Row } from "react-bootstrap";
import { FilterComponent,CalenderComponent } from "./Sidebar/DropDown";
import ClassReport from "./Tables/ClassReport";
import Sideboard from "./SideboardOT/Sideboard";
import Backbtn from "../../images/Arrow 9.svg";
import {
  Link,
  NavLink,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import axios from "axios";
import exportFromJSON from "export-from-json";
import SearchBar from "../OnlineTest/TestList/SearchBar/SearchBar";
import ExportButton from "../../images/export (1).svg";
import DropDownShutter from "../DropDown/DropDown";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Moment from "react-moment";
import { makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";
import HomeIcon from "../../images/HomeIcon.svg";
import assignImg from "../../images/assign.svg";
import assessmentImg from "../../images/assessment.svg";
import analyticGraphImg from "../../images/analyticGraph.svg";
import Box from "@material-ui/core/Box";
import { GlobalContext } from "../../context/GlobalState";
/* End of imports */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const OnlineTest5 = () => {
  /*Instance for Visibility of Topic Graphs */
  const [show, setShow] = useState("none");

  const location = useLocation();
  const params = useParams();
  const grade = params.class;
  const section = params.section;
  const subject = params.subject;
  localStorage.setItem("gradeSection", `${grade}-${section}`);
  localStorage.setItem("subject", subject);
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#edfcfa",
      boxShadow: "none",
    },
    root1: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    card: {
      width: "100%",
      borderRadius: "10px",
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
      padding: "20px",
      margin: "20px auto",
    },
    subjectSection: {
      textAlign: "center",
      "& button": {
        border: "none",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        marginBottom: "10px",
        padding: "4px",
        minWidth: "120px",
        color: "white",
      },
      "& p": {
        marginBlockStart: "0px",
        marginBlockEnd: "0px",
        fontSize: "0.8em",
      },
    },
    report: {
      textAlign: "center",
      "& a": {
        backgroundColor: "rgba(29, 205, 109, 0.78)",
        padding: "7px 10px",
        borderRadius: "10px",
        color: "white",
        textDecoration: "none",
        height: "fit-content",
        transition: "0.2s",
        "& span": {
          backgroundColor: "rgba(112, 253, 175, 0.73)",
          color: "white",
          borderRadius: "50%",
          height: "fit-content",
          width: "fit-content",
          padding: "3px",
          verticalAlign: "middle",
        },
        "&:hover": {
          backgroundColor: "rgba(29, 205, 109, 1)",
          color: "white !important",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    report2: {
      textAlign: "center",
      "& a": {
        backgroundColor: "rgba(0, 121, 234, 0.78);",
        padding: "7px 10px",
        borderRadius: "10px",
        color: "white",
        textDecoration: "none",
        height: "fit-content",
        transition: "0.2s",
        "& span": {
          backgroundColor: "rgba(0, 121, 234, 0.73);",
          color: "white",
          borderRadius: "50%",
          height: "fit-content",
          width: "fit-content",
          padding: "3px",
          verticalAlign: "middle",
        },
        "&:hover": {
          backgroundColor: "rgba(0, 121, 234, 1);",
          color: "white !important",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    menu: {
      textAlign: "center",
    },
    contextMenu: {
      "& .MuiList-root": {
        padding: "10px 10px 5px 10px",
      },
      "& .MuiPaper-rounded": {
        borderRadius: "5px !important",
      },
      "& li": {
        color: "white",
        fontSize: "0.9em",
        background: "rgba(180, 107, 130, 0.8)",
        marginBottom: "5px",
        borderRadius: "5px !important",
        transition: "0.3s",
        "&:hover": {
          background: "rgba(180, 107, 130, 1)",
          fontWeight: "bold",
        },
      },
    },
    "@media screen and (max-width: 472px)": {
      card: {
        width: "95%",
        padding: "10px 7px",
      },
      subjectSection: {
        "& button": {
          fontSize: "0.8em",
          minWidth: "80px",
        },
        "& p": {
          fontSize: "0.6em",
        },
      },
      report: {
        "& a": {
          fontSize: "0.7em",
        },
      },
      report2: {
        "& a": {
          fontSize: "0.7em",
        },
      },
      contextMenu: {
        padding: "5px 5px 3px 5px",
        fontSize: "0.7em",
        "& li": {
          minHeight: "35px",
        },
      },
    },
  }));
  const { userDetails, setUserDetails } = useContext(GlobalContext);
  const [heading, setHeading] = useState("Analytics");

  const [sectionId, setSectionId] = useState(location.state.sectionId);
  const [subjectValue, setSubjectValue] = useState(params.subjectId);
  const [chapterValue, setChapterValue] = useState(params.chapterId);
  const [numStudents, setNumStudents] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [ts_total, setTs_total] = useState(0);
  const [ta_total, setTa_total] = useState(0);
  const [qa_total, setQa_total] = useState(0);
  const [qs_total, setQs_total] = useState(0);
  const [miniCard, setMiniCard] = useState({
    Tests_Assigned: 0,
    Students: 0,
    Questions_Assigned: 0,
    Chapters: 0,
    Topics: 0,
    Avg_Time: 0,
  });
  const [filterReports,setFilterReports]=useState({
    name:"",
    subject:"",
    date:"",
    testType:"",
    grade:""
  })
  const [students,setStudents]=useState([]);
  const [subjects,setSubjects]=useState([]);
  const [allgrade,setAllGrade]=useState([]);
  const [tabData,setTabData]=useState([]);
  const [filterByDate,setFilterByDate]=useState({date:""})
  /*Instances for Loaders*/
  const [ts_loader, setTs_loader] = useState(false);
  const [tta_loader, setTta_loader] = useState(false);
  const [qs_loader, setQs_loader] = useState(false);
  const [qr_loader, setQr_loader] = useState(false);
  const [qa_loader, setQa_loader] = useState(false);
  const [dclr_loader, setDclr_loader] = useState(false);
  const [dclcr_loader, setDclcr_loader] = useState(false);
  const [lsg_loader, setLsg_loader] = useState(false);
  const [table_loader, setTable_loader] = useState(false);

  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState(1);

  /*Handling Visibility of Topic Graphs*/
  const handleShow = () => {
    show === "none" ? setShow("unset") : setShow("none");
  };

  /*Test Status All Classes*/
  const [t_status, setT_status] = useState([
    {
      name: "",
      "In Progress": 0,
      Incomplete: 0,
      Completed: 0,
    },
  ]);

  /*Total Tests Assigned */
  const [T_tests, setT_tests] = useState([
    {
      name: "",
      value: 0,
    },
  ]);

  /*Question Report */
  const [qr_data, setQr_data] = useState([
    { name: "Correct", value: 0 },
    { name: "Incorrect", value: 0 },
    { name: "Unattempted", value: 0 },
  ]);

  /*Questions Assigned */
  const [qa_data, setQa_data] = useState([
    {
      name: "",
      value: 0,
    },
  ]);

  /*Questions Status */
  const [q_status, setQ_status] = useState([
    {
      name: "",
      Correct: 0,
      Incorrect: 0,
      Unattempted: 0,
    },
  ]);

  /*Learning Strength and Gap */
  const [lsg_data, setLsg_data] = useState([
    {
      name: "",
      Gap: 0,
      Strength: 0,
    },
  ]);

  /*Difficulty Level Questions */
  const [dlq_data, setDlq_data] = useState([
    {
      name: "",
      Easy: 0,
      Medium: 0,
      Difficult: 0,
    },
  ]);

  /*Cognitive Level Questions */
  const [clq_data, setClq_data] = useState([
    {
      name: "",
      Knowledge: 0,
      Understanding: 0,
      Application: 0,
      Analysis: 0,
    },
  ]);

  /*Correct and Total Difficulty Level Questions */
  const [correct_dlq_data, setCorrect_dlq_data] = useState([
    {
      name: "",
      easy: {
        Easy: 0,
        "Total Q'": 0,
      },
      medium: {
        Medium: 0,
        "Total Q'": 0,
      },
      difficult: {
        Difficult: 0,
        "Total Q'": 0,
      },
    },
  ]);

  /*Correct and Total Cognitive Level Questions */
  const [correct_clq_data, setCorrect_clq_data] = useState([
    {
      name: "",
      knowledge: {
        Knowledge: 0,
        "Total Q'": 0,
      },
      understanding: {
        Understanding: 0,
        "Total Q'": 0,
      },
      application: {
        Application: 0,
        "Total Q'": 0,
      },
      analysis: {
        Analysis: 0,
        "Total Q'": 0,
      },
    },
  ]);

  /*Colors for Pie Chart*/
  const COLORS = ["#10A0B6", "#FF8989", "#9DABDD"];

  /*Handling Customized Labelling of Pie Chart*/
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  /*React Media Query*/
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });

  /*Instance for Dropdown*/
  const [dropdown, setdropdown] = useState(isSmallScreen ? false : true);

  /*Function for toggling the Shutter in Mobile View */
  const handleShutterOpen = () => {
    setdropdown(!dropdown);
  };

  /*Function for switching between tabs */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /*Function for switching the headings according to tabs */
  const changeHeading = (val) => {
    setUserDetails({ ...userDetails, selectedTab: val, pathName: {} });
    history.goBack();
    setHeading(val);
  };

  /*Fetching Mini Card Data*/
  const fetchMiniCardData = async () => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/overallteststatus?section=${sectionId||params?.sectionId}&subject=${subjectValue||params?.subjectId}&chapter=${params?.chapterId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        const newMini_card = {
          Tests_Assigned: 0,
          Students: 0,
          Questions_Assigned: 0,
          Chapters: 0,
          Topics: 0,
          Avg_Time: 0,
        };
        response.data.data.analytics.forEach((element) => {
          newMini_card.Tests_Assigned = `${element.completedTests}/${element.totalTests}`;
          newMini_card.Students = element.students.students;
          newMini_card.Questions_Assigned = element.totalQuestions;
          newMini_card.Chapters = element.chapters;
          newMini_card.Topics = element.topics;
          newMini_card.Avg_Time = `${Math.floor(
            element.avgTime / 3600000
          )}h ${Math.floor((element.avgTime % 3600000) / 60000)}m ${Math.floor(
            (element.avgTime / 1000) % 60
          )}s`;
        });
        setMiniCard(newMini_card);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*Fetching Data For Test Status*/
  const fetchTestStatus = async () => {
    setTs_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/testsstatus?section=${params?.sectionId}&subject=${params?.subjectId}&chapter=${params?.chapterId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtotal = 0;
        var newt_data = response.data.data.analytics.filter((element) => {
          if(element.topicId)
          {
          newtotal += element.tests;
          return {
            name: `${element.topicId ? element.topicId.name : ""}`,
            "In Progress": element.progress,
            Incomplete: element.incomplete,
            Completed: element.completed,
          };
        }
        });
        setTs_total(newtotal);
        setT_status(newt_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setTs_loader(false);
  };

  /*Fetching Data for Questions Status */
  useEffect(()=>{
    console.log(tableData);
    let dt=tabData;
    if(filterReports.name)
    {
        dt=dt.filter(item=>item.studentId.name===filterReports?.name?.name)
      
    }
    if(filterReports.subject)
    {
      dt=dt.filter(item=>item.subjectId.name===filterReports?.subject?.name)
    }
    if(filterReports.grade)
    {
      dt=dt.filter(item=>`${item.studentId.grade}-${item.studentId.section}`===filterReports.grade._id)
    }
    if (filterReports.testType)
    {
      dt=dt.filter(item=>item.testType===filterReports.testType._id);
    }

    if(filterByDate.date)
    {
      
      dt=dt.filter(item=>new Date(item.startedAt.split("T")[0])>=new Date(filterByDate.date))
    }
    
    console.log(dt);
    console.log(filterReports);
    setTableData(dt);


  },[filterByDate,filterReports])

  const fetchQuestionsStatus = async () => {
    setQs_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/questionsstatus?section=${params?.sectionId}&subject=${params?.subjectId}&chapter=${params?.chapterId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtotal = 0;
        var newq_status = response.data.data.analytics.filter((element) => {
          if(element.topicId)
          {
          newtotal += element.totalQuestions;
          return {
            name: `${element.topicId ? element.topicId.name : ""}`,
            Correct: element.totalCorrect,
            Incorrect: element.totalIncorrect,
            Unattempted: element.totalUnattempted,
          };
        }
        });
        setQs_total(newtotal);
        setQ_status(newq_status);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setQs_loader(false);
  };

  /*Fetching Data For Total Tests Assigned*/
  const fetchTotalTestsAssigned = async () => {
    setTta_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/totaltestsassigned?section=${params?.sectionId}&subject=${params?.subjectId}&chapter=${params?.chapterId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtotal = 0;
        var newt_tests = response.data.data.analytics.filter((element) => {
          if(element.topicId)
          {
          newtotal += element.tests;

          return {
            name: `${element.topicId ? element.topicId.name : ""}`,
            value: element.tests,
          };
        }
        });
        setTa_total(newtotal);
        setT_tests(newt_tests);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setTta_loader(false);
  };

  /*Fetching Data For Questions Assigned*/
  const fetchQuestionsAssigned = async () => {
    setQa_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/totalquestionsassigned?section=${params?.sectionId}&subject=${params?.subjectId}&chapter=${params?.chapterId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        var newtotal = 0;
        var newqa_data = response.data.data.analytics.filter((element) => {
          if(element.topicId)
          {
          newtotal += element.questions;
          return {
            name: `${element.topicId ? element.topicId.name : ""}`,
            value: element.questions,
          };
        }
        });
        setQa_total(newtotal);
        setQa_data(newqa_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setQa_loader(false);
  };

  /*Fetching Data for Difficulty and Cognitive Correct and Total Status */
  const fetchDifficultyAndCognitiveCorrectStatus = async () => {
    setDclcr_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/questioncorrectlevelstatus?section=${params?.sectionId}&subject=${params?.subjectId}&chapter=${params?.chapterId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        var newcorrect_dlq_data = response.data.data.analytics.filter(
          (element) => {
            if(element.topicId)
            {
            return {
              name: `${element.topicId ? element.topicId.name : ""}`,
              easy: {
                Easy: element.difficulty.easy.correct,
                "Total Q'": element.difficulty.easy.total,
              },
              medium: {
                Medium: element.difficulty.intermediate.correct,
                "Total Q'": element.difficulty.intermediate.total,
              },
              difficult: {
                Difficult: element.difficulty.hard.correct,
                "Total Q'": element.difficulty.hard.total,
              },
            };
          }
          }
        );
        var newcorrect_clq_data = response.data.data.analytics.filter(
          (element) => {
            if(element.topicId)
            {
            return {
              name: `${element.topicId ? element.topicId.name : ""}`,
              knowledge: {
                Knowledge: element.cognitive.knowledge.correct,
                "Total Q'": element.cognitive.knowledge.total,
              },
              understanding: {
                Understanding: element.cognitive.understanding.correct,
                "Total Q'": element.cognitive.understanding.total,
              },
              application: {
                Application: element.cognitive.application.correct,
                "Total Q'": element.cognitive.application.total,
              },
              analysis: {
                Analysis: element.cognitive.analysis.correct,
                "Total Q'": element.cognitive.analysis.total,
              },
            };
          }
        }
        );
        setCorrect_dlq_data(newcorrect_dlq_data);
        setCorrect_clq_data(newcorrect_clq_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setDclcr_loader(false);
  };

  /*Fetching Data For Learning Strength & Learning Gap Of Questions*/
  const fetchLearningStrengthAndGap = async () => {
    setLsg_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/testlearningstatus?section=${params?.sectionId}&subject=${params?.subjectId}&chapter=${params?.chapterId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        var newlsg_data = response.data.data.analytics.filter((element) => {

          if(element.topicId)
          {
          return {
            name: `${element.topicId ? element.topicId.name : ""}`,
            Gap: element.gap,
            Strength: element.strength,
          };
        }
        });
        setLsg_data(newlsg_data);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setLsg_loader(false);
  };

  const fetchClassReportData = async () => {
    setTable_loader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/testanalytics/classreports?section=${params?.sectionId}&subject=${params?.subjectId}&chapter=${params?.chapterId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setTableData(response.data.data.analytics);
        setTabData(response.data.data.analytics);
        setTable_loader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  
  const renderCustomizedLabels  = (props) => {
    const { x, y, width, height, value,stroke } = props;
    const radius = 10;
    console.log(props.value);
  
    return (
      <g>
        {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
        <text x={x + width / 2} y={y +height/2} fill={stroke}   textAnchor="middle" dominantBaseline="middle">
        {/* <foreignObject x={0} y={0} width={100} height={100}> */}
        {props.value?props.value:""}
        {/* </foreignObject> */}
         
        </text>
      </g>
    );
  };

  /*Sequence of API Calls */
  useEffect(async () => {
    await fetchMiniCardData();
    await fetchTestStatus();
    await fetchQuestionsStatus();
    await fetchTotalTestsAssigned();
    await fetchQuestionsAssigned();
    await fetchDifficultyAndCognitiveCorrectStatus();
    await fetchLearningStrengthAndGap();
    await fetchClassReportData();
  }, [params]);

  /*Function To Export Class Report Data*/
  const exportCsv = () => {
    const data = tableData;
    const formatted_data = data.map((element) => {
      return {
        Name: element.studentId.name,
        Class: `${element.studentId.grade}-${element.studentId.section}`,
        Subject: element.subjectId.name,
        Chapter: element.chapters,
        Topic: element.topics,
        "Test Title": element.testId.questionSet.title,
        "Test Type": element.testType,
        Date: element.startedAt.split("T")[0],
        "Start Time Of Test": element.startedAt.split("T")[1].slice(0, 8),
        "Time Spent": `${
          Math.floor(element.timeSpent / 3600000) +
          "h" +
          Math.floor((element.timeSpent % 3600000) / 60000) +
          "m" +
          Math.floor((element.timeSpent / 1000) % 60) +
          "s"
        }`,
        Questions: element.questions,
        "Total Marks": element.totalMarks,
        Correct: element.correct,
        Incorrect: element.incorrect,
        Easy: element.correctAttempt.easy,
        Intermediate: element.correctAttempt.intermediate,
        Hard: element.correctAttempt.hard,
        Knowledge: element.correctAttempt.knowledge,
        Understanding: element.correctAttempt.understanding,
        Analysis: element.correctAttempt.analysis,
        Application: element.correctAttempt.application,
        "Marks Obtained": element.totalScore,
        Rank: element.rank,
      };
    });
    const fileName = "Class Report";
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data: formatted_data, fileName, exportType });
  };


  useEffect(()=>{
    if(filterReports.grade)
    {
      setFilterReports({...filterReports,name:""});
      const getName=async()=>{
        const authToken = localStorage.getItem("authToken");
      try {
        const grade = filterReports.grade._id.split("-")[0];
        const section = filterReports.grade._id.split("-")[1];
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/students`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.status === 200) {
          setStudents(response.data.data.students);
         
        }
      } catch (error) {
        console.log(error);
      }
        
      }
  
      getName();
    }
  
  },[filterReports.grade])
  
  const search = () => {
    console.log(tableData);
    let searchData = tableData.filter(
      (data) =>
        data.studentId?.name
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        data.subjectId?.name
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        data?.testId?.questionSet?.title
          .toLowerCase()
          .includes(searchValue.toLowerCase())
    );
    setTableData(searchData);
  };

  useEffect(() => {
    //when the search box is empty
    if (!searchValue) {
      fetchClassReportData();
    } else {
      search();
    }
  }, [searchValue]);

  /*Colors for multicolored bar graphs */
  const barColors = ["#0F93A8", "#F2931E", "#AE4132", "#23445D"];
  return (
    <>
      <section className={isSmallScreen ? "modules my-4" : "modules my-5"}>
        <div
          className={
            isSmallScreen ? "container-fluid dashboard_container" : "container"
          }
        >
          <div className="box">
            <div
              className="upperBox"
              style={{ display: isSmallScreen ? "none" : "" }}
            >
              <div className="alignItem">
                <div className="row">
                  <div className="boxRow">
                    <div className="boxRowLeft">
                      <h1 className="className">
                        {/* {grade
                          ? `${grade}-${section}`
                          : localStorage.getItem("gradeSection")} */}
                        {params.class}-{params.section}
                      </h1>
                      <div className="pathDetail">
                        <h2 style={{ fontWeight: "700" }}>
                          Analytics &gt; Online Test
                        </h2>
                        <p style={{ fontWeight: "600", color: "#ffffff" }}>
                          {params.subject}
                        </p>
                      </div>
                    </div>
                    <div className="DateBox">
                      <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                        <CalendarTodayIcon />{" "}
                        {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              <Paper className={classes.root}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  style={{
                    background: "#DCFFFB",
                    marginTop: "-12px",
                    display: dropdown ? "" : "none",
                  }}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={"on"}
                >
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={HomeIcon}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Home"
                    {...a11yProps(0)}
                    onClick={() => changeHeading("Dashboard")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={analyticGraphImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Analytics"
                    {...a11yProps(1)}
                    onClick={() => changeHeading("Analytics")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assignImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assign Task"
                    {...a11yProps(2)}
                    onClick={() => changeHeading("Assign Task")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assessmentImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assessment"
                    {...a11yProps(3)}
                    onClick={() => changeHeading("Assessment")}
                  />
                </Tabs>
              </Paper>
            }
            <div
              className="shutter_PS"
              style={{
                marginTop: dropdown ? "0px" : "-15px",
                display: isSmallScreen ? "flex" : "none",
              }}
            >
              <DropDownShutter
                onClick={handleShutterOpen}
                clicked={dropdown}
                dropdown={dropdown}
                setDropDown={setdropdown}
              />
            </div>
          </div>
        </div>
      </section>
      {/*Graphs Section*/}
      <Container fluid="xl" className="analytics_main_section">
        {/*Back Button*/}
        {/* <NavLink
          to={{
            pathName: `/dashboard/analytics/onlineTest/${params.class}/${params.section}/${params.subject}/${params.subjectId}`,
            state: { sectionId: sectionId },
          }}
        > */}
        <img
          src={Backbtn}
          className="back_btn"
          onClick={() => history.goBack()}
          style={{ cursor: "pointer" }}
        />
        {/* </NavLink> */}
        {/*Side Drawer*/}
        <Sideboard
          sectionId={sectionId}
          setSectionId={setSectionId}
          subjectValue={subjectValue}
          setSubjectValue={setSubjectValue}
          chapterValue={chapterValue}
          setChapterValue={setChapterValue}
          numStudents={numStudents}
          setNumStudents={setNumStudents}
          ch_loader={ts_loader}
          q_loader={qr_loader}
          qs_loader={qs_loader}
          qa_loader={qa_loader}
          dlr_loader={dclr_loader}
          clr_loader={dclcr_loader}
          ls_loader={lsg_loader}
          // setAllStudents={setStudents}
          setAllSubjects={setSubjects}
          setAllGrade={setAllGrade}
        />
        <Row
          className="studentanalytics_mini_cards mx-auto my-5"
          style={{ color: "white" }}
        >
          <div className="student_analytics_mini_cards_section">
            <Col
              className="student_mini_card"
              style={{
                marginLeft: "0px",
                backgroundColor: "#AD73F5",
              }}
            >
              <div>
                <div style={{ textAlign: "center" }}>Test Assigned</div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.Tests_Assigned}</h5>
                </div>
              </div>
            </Col>
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#47CFE9" }}
            >
              <div>
                <div style={{ textAlign: "center" }}>Students</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <h5 style={{ textAlign: "center" }}>{miniCard.Students}</h5>
                </div>
              </div>
            </Col>
          </div>
          <div
            className="student_analytics_mini_cards_section"
            style={{ marginLeft: "-2.5rem", marginRight: "-2.5rem" }}
          >
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#F3B592" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Questions Assigned
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.Questions_Assigned}</h5>
                </div>
              </div>
            </Col>
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#70E2AE" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Chapters
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.Chapters}</h5>
                </div>
              </div>
            </Col>
          </div>
          <div className="student_analytics_mini_cards_section">
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#0079EADE" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Topics
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.Topics}</h5>
                </div>
              </div>
            </Col>
            <Col
              className="student_mini_card"
              style={{ backgroundColor: "#FF7E7E" }}
            >
              <div>
                <div
                  className="mini-card-content-heading"
                  style={{ textAlign: "center" }}
                >
                  Average Time To Complete Test
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h5>{miniCard.Avg_Time}</h5>
                </div>
              </div>
            </Col>
          </div>
        </Row>
        {/*Chapterwise*/}
        {/*First Layer having Bar Graph and Pie Chart*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Test Status All Grades</b>
            </h5>
            <p>Total Tests Assigned - {ts_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!ts_loader ? (
                t_status.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={t_status}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name" />
                    <YAxis>
                      <Label
                        value="Test Assigned"
                        position={isSmallScreen ? "" : "insideBottomLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {t_status ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar
                      dataKey="Completed"
                      stackId="a"
                      fill=" #1190C4"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"Completed"} fill="#000" />
                    </Bar>
                    <Bar
                      dataKey="In Progress"
                      stackId="a"
                      fill="#C56468"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey="In Progress"
                        // position="inside"
                        fill="#000"
                        content={renderCustomizedLabels} 
                      />
                    </Bar>
                    <Bar
                      dataKey="Incomplete"
                      stackId="a"
                      fill=" #C4C4C459"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList
                        dataKey="Incomplete"
                        // position="inside"
                        content={renderCustomizedLabels} 
                        fill="#000"
                      />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
          <div className="analytics_bar_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Question Report</b>
            </h5>
            <p>Total Question Assigned - {qs_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!qs_loader ? (
                q_status.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={q_status}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name" />
                    <YAxis>
                      <Label
                        value="Test Assigned"
                        position={isSmallScreen ? "" : "insideLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {q_status ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar
                      dataKey="Correct"
                      stackId="a"
                      fill=" #1190C4"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList fill="#000"  content={renderCustomizedLabels} dataKey={"Correct"} />
                    </Bar>
                    <Bar
                      dataKey="Incorrect"
                      stackId="a"
                      fill="#C56468"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList position="inside" fill="#000"  content={renderCustomizedLabels} dataKey={"Incorrect"} />
                    </Bar>
                    <Bar
                      dataKey="Unattempted"
                      stackId="a"
                      fill=" #C4C4C459"
                      cursor="pointer"
                      onClick={handleShow}
                      maxBarSize={75}
                    >
                      <LabelList position="inside" fill="#000"  content={renderCustomizedLabels} dataKey={"Unattempted"} />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        {/*Second Layer having Bar Graphs of Difficulty Level and Cognitive Level*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Test Assigned Report Of Subject</b>
            </h5>
            <p>Total Tests Assigned - {ta_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!tta_loader ? (
                T_tests.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={T_tests}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <XAxis dataKey="name" />
                    <YAxis>
                      <Label
                        value="Tests Assigned in Subject"
                        position={isSmallScreen ? "" : "insideBottomLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {T_tests ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar dataKey="value" fill="#00a0fc" maxBarSize={75}>
                      {T_tests.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={barColors[index % 20]}
                        />
                      ))}
                      <LabelList dataKey="value" position="top"  content={renderCustomizedLabels}  />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
          <div className="analytics_bar_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Questions Assigned to All Grades</b>
            </h5>
            <p>Total Question Assigned - {qa_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!qa_loader ? (
                qa_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    data={qa_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <XAxis dataKey="name" />
                    <YAxis>
                      <Label
                        value="Questions Assigned in Test"
                        position={isSmallScreen ? "" : "insideBottomLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {qa_data ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar dataKey="value" fill="#00a0fc" maxBarSize={75}>
                      {qa_data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={barColors[index % 20]}
                        />
                      ))}
                      <LabelList  content={renderCustomizedLabels}  dataKey="value" position="top" />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        {/*Third Layer*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card" style={{ marginRight: -50 }}>
            <h5>
              <b>Correct Answers Report of Difficult Level Questions</b>
            </h5>
            <p>Total Tests Assigned - {ts_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!dclcr_loader ? (
                correct_dlq_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    className="analytics_bar_grid"
                    width={600}
                    height={400}
                    data={correct_dlq_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name">
                      <Label value="Chapters" offset={11} position="bottom" />
                    </XAxis>
                    <YAxis>
                      <Label
                        value="Questions"
                        offset={10}
                        position={isSmallScreen ? "" : "insideLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    <Brush dataKey="name" height={10} stroke="#ddd" />
                    <Bar
                      stackId="a"
                      dataKey="easy.Easy"
                      name="Easy"
                      fill="#0092CE"
                    >
                      <LabelList  content={renderCustomizedLabels} dataKey={"easy.Easy"} position="inside" />
                    </Bar>
                    <Bar
                      stackId="a"
                      dataKey={`easy.Total Q'`}
                      tooltipType="none"
                      legendType="none"
                      fill="#CCCCCCB2"
                    >
                      <LabelList  content={renderCustomizedLabels} dataKey={"easy.Total Q'"} position="top" />
                    </Bar>
                    <Bar
                      stackId="b"
                      dataKey="medium.Medium"
                      name="Medium"
                      fill="#C96862"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"medium.Medium"}  position="inside" />
                    </Bar>
                    <Bar
                      stackId="b"
                      tooltipType="none"
                      dataKey={`medium.Total Q'`}
                      legendType="none"
                      fill="#CCCCCCB2"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"medium.Total Q'"} position="top" />
                    </Bar>
                    <Bar
                      stackId="c"
                      dataKey="difficult.Difficult"
                      name="Difficult"
                      fill="#F1C686"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"difficult.Difficult"} position="inside" />
                    </Bar>
                    <Bar
                      stackId="c"
                      tooltipType="none"
                      dataKey={`difficult.Total Q'`}
                      name="Total Q'"
                      fill="#CCCCCCB2"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"difficult.Total Q'"} position="top" />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
          <div className="analytics_bar_card" style={{ marginLeft: -20 }}>
            <h5>
              <b>Correct Answers Report of Cognitive Level Questions</b>
            </h5>
            <p>Total Tests Assigned - {ts_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!dclcr_loader ? (
                correct_clq_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    className="analytics_bar_grid"
                    width={600}
                    height={400}
                    data={correct_clq_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name">
                      <Label value="Chapters" offset={11} position="bottom" />
                    </XAxis>
                    <YAxis>
                      <Label
                        value="Questions"
                        offset={10}
                        position={isSmallScreen ? "" : "insideLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    <Brush dataKey="name" height={10} stroke="#ddd" />
                    <Bar
                      stackId="a"
                      dataKey="knowledge.Knowledge"
                      name="Knowledge"
                      fill="#0092CE"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"knowledge.Knowledge"} position="inside" />
                    </Bar>
                    <Bar
                      stackId="a"
                      dataKey={`knowledge.Total Q'`}
                      tooltipType="none"
                      legendType="none"
                      fill="#CCCCCCB2"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"knowledge.Total Q'"} position="top" />
                    </Bar>
                    <Bar
                      stackId="b"
                      dataKey="understanding.Understanding"
                      name="Understanding"
                      fill="#C96862"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"understanding.Understanding"} position="inside" />
                    </Bar>
                    <Bar
                      stackId="b"
                      tooltipType="none"
                      dataKey={`understanding.Total Q'`}
                      legendType="none"
                      fill="#CCCCCCB2"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"understanding.Total Q'"} position="top" />
                    </Bar>
                    <Bar
                      stackId="c"
                      dataKey="application.Application"
                      name="Application"
                      fill="#F1C686"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"application.Application"} position="inside" />
                    </Bar>
                    <Bar
                      stackId="c"
                      tooltipType="none"
                      legendType="none"
                      dataKey={`application.Total Q'`}
                      fill="#CCCCCCB2"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"application.Total Q'"} position="top" />
                    </Bar>
                    <Bar
                      stackId="d"
                      dataKey="analysis.Analysis"
                      name="Analysis"
                      fill="#6DBCDA"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"analysis.Analysis"} position="inside" />
                    </Bar>
                    <Bar
                      stackId="d"
                      tooltipType="none"
                      dataKey={`analysis.Total Q'`}
                      name="Total Q'"
                      fill="#CCCCCCB2"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"analysis.Total Q'"} position="top" />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        {/*Fourth Layer*/}
        <Row className="analytics_section_layer">
          <div className="analytics_bar_card">
            <h5>
              <b>Learning Strength and Gap All Grades</b>
            </h5>
            <p>Total Test Assigned- {ts_total}</p>
            <ResponsiveContainer className="Analytics_Responsive_Container">
              {!lsg_loader ? (
                lsg_data.length === 0 ? (
                  <div className="no_data">No Data Available</div>
                ) : (
                  <BarChart
                    className="analytics_bar_grid"
                    width={600}
                    height={400}
                    data={lsg_data}
                    barGap={0}
                    margin={{ top: 45, right: 10, left: 20, bottom: 15 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" vertical={false} />
                    <Legend
                      verticalAlign="top"
                      align="right"
                      layout="vertical"
                      height={36}
                    />
                    <XAxis dataKey="name" />
                    <YAxis>
                      <Label
                        value="Strength &amp; Gap (%)"
                        offset={10}
                        position={isSmallScreen ? "" : "insideLeft"}
                        angle={-90}
                      />
                    </YAxis>
                    <Tooltip />
                    {lsg_data ? (
                      <Brush dataKey="name" height={10} stroke="#ddd" />
                    ) : (
                      ""
                    )}
                    <Bar
                      stackId="a"
                      maxBarSize={75}
                      dataKey="Strength"
                      fill="#027EB1"
                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"Strength"} />
                    </Bar>
                    <Bar
                      stackId="a"
                      maxBarSize={75}
                      dataKey="Gap"
                      fill="#C96862"

                    >
                      <LabelList content={renderCustomizedLabels} dataKey={"Gap"} />
                    </Bar>
                  </BarChart>
                )
              ) : (
                <div className="graph_loaders">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </Row>
        <div style={{display:"flex",flexWrap:"wrap",margin:"0px 20px",marginBottom:"10px"}} className="filters">
           {console.log(students)}
           <FilterComponent options={allgrade.length&&allgrade.map(item=>{return{...item,name:item._id}})} name="grade" formData={filterReports} setFormData={setFilterReports}  placeholder="Grade"/>
            <FilterComponent options={students} name="name" formData={filterReports} setFormData={setFilterReports}  placeholder="Name"/>
           
            <FilterComponent options={subjects} name="subject" formData={filterReports} setFormData={setFilterReports} placeholder="Subject"/>
            <FilterComponent options={[{name:"CT",_id:"CT"},{name:"UT",_id:"UT"},{name:"HY",_id:"HY"}]} name="testType" formData={filterReports} setFormData={setFilterReports} placeholder="Test Type"/>
            {/* <FilterComponent placeholder="Date"/> */}
            {/* <FilterComponent placeholder="Date"/> */}
            <CalenderComponent placeholder="date" formData={filterByDate} setFormData={setFilterByDate} name="date"/>
            <div onClick={()=>{setFilterReports({name:"",subject:"",testType:"",grade:""});setFilterByDate({date:""})}} style={{display:"flex",cursor:"pointer",alignItems:"center",marginLeft:"10px"}}>
            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.5 8.25C6.57107 8.25 8.25 6.57107 8.25 4.5C8.25 2.42893 6.57107 0.75 4.5 0.75C2.42893 0.75 0.75 2.42893 0.75 4.5C0.75 6.57107 2.42893 8.25 4.5 8.25Z" stroke="black" stroke-opacity="0.54" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.625 3.375L3.375 5.625" stroke="black" stroke-opacity="0.54" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.375 3.375L5.625 5.625" stroke="black" stroke-opacity="0.54" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

               <span style={{marginLeft:"4px"}}>Clear</span>
            </div>
          </div>
        {/*Class Report Table*/}
        <div className="table_container">
          <div
            className="table_title_section"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {isSmallScreen ? (
              <h5
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  marginLeft: "0.5%",
                  marginTop: "5%",
                }}
              >
                Class Report
              </h5>
            ) : (
              <h2
                style={{
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  marginLeft: "0.5%",
                  marginTop: "0.5%",
                }}
              >
                Class Report
              </h2>
            )}
            {isSmallScreen ? (
              <img
                onClick={exportCsv}
                style={{
                  cursor: "pointer",
                  transform: "scale(0.7)",
                }}
                src={ExportButton}
              />
            ) : (
              <img
                onClick={exportCsv}
                style={{
                  cursor: "pointer",
                  marginLeft: 5,
                }}
                src={ExportButton}
              />
            )}
            <div className="search_container">
              <SearchBar search={searchValue} setSearch={setSearchValue} />
            </div>
          </div>

          <ClassReport rows={tableData} loading={table_loader} />
        </div>
        {/* <div className="analytics_subject_card">
          {cardData.map((item, index) => (
            <SubjectCard
              index={index + 1}
              subject={item.subject}
              date={item.date}
              time={item.time}
              testId={item.testId}
              background={item.background}
            />
          ))}
        </div> */}
      </Container>
    </>
  );
};

export default OnlineTest5;
