/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import './styles/TaskTopic.css';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 'auto',
  },
  submmissionBtn: {
    // backgroundColor: "#5CE0D2",
    borderRadius: '27px',
    // "&:hover": {
    //   backgroundColor: "#5CE0D2",
    // },
  },
}));

const TaskTopic = (props) => {
  document.title =  "2xcell.in";
  const classes = useStyles();
  const taskId = props.match.params.taskid;
  console.log(taskId);

  const [singleTask, setSingleTask] = useState(null);

  useEffect(() => {
    const fetchTask = async () => {
      const authToken = localStorage.getItem('authToken');

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/tasks/${taskId}`,
          {
            headers: {
              token: authToken,
            },
          }
        );

        console.log(response);

        if (response.data.status === 'success') {
          setSingleTask(response.data.data.task);

          //Saving data in local storage to use in fetching submissions
          const section = response.data.data.task.section.split('-');
          window.localStorage.setItem('grade', section[0]);
          window.localStorage.setItem('section', section[1]);
          window.localStorage.setItem('taskId', taskId);
          if (response.data.data.task.onModel === 'test') {
            window.localStorage.setItem('type', 'tests');
          } else {
            window.localStorage.setItem('type', 'tasks');
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchTask();
  }, []);

  return (
    <>
      <section className='taskTopic my-5'>
        <div className='container'>
          <div className='box mb-5'>
            <h1 className='fw-bold'>View Task</h1>
          </div>

          <TableContainer component={Paper} className='task_details'>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>Grade</TableCell>
                  <TableCell align='center'>Subject</TableCell>
                  <TableCell align='center'>Topic</TableCell>
                  <TableCell align='center'>Module</TableCell>
                  <TableCell align='center'>Task Type</TableCell>
                  <TableCell align='center'>Date</TableCell>
                  <TableCell align='center'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {singleTask && (
                  <TableRow>
                    <TableCell align='center'>{singleTask.section}</TableCell>
                    <TableCell align='center'>{singleTask.subject}</TableCell>
                    <TableCell align='center'>{singleTask.topic}</TableCell>
                    <TableCell align='center'>
                    <p
                      style={{
                        width: "300px",
                        wordBreak: "break-word",
                        margin: "auto",
                      }}
                    >
                      {singleTask.content?.name || singleTask?.url}
                    </p>
                    </TableCell>
                    <TableCell align='center'>{singleTask.taskType}</TableCell>
                    <TableCell align='center'>
                      <Moment format='DD-MM-YYYY'>
                        {singleTask.createdAt}
                      </Moment>
                    </TableCell>
                    <TableCell align='center'>
                      <Button
                        size='small'
                        variant='contained'
                        component={NavLink}
                        to={`/tasklist/topic/submission/${singleTask._id}`}
                        className={classes.submmissionBtn}
                      >
                        View Submission
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </section>
    </>
  );
};

export default TaskTopic;
