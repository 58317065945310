import React, { useState, useEffect } from 'react';
import { IconButton, TextField } from '@material-ui/core';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const Option = ({ option, index, correctAnswers, question }) => {
  let correctOption = '';
  for (let item of correctAnswers) {
    if (item.questionId === question.questionId) correctOption = item.answer;
  }
  // console.log(option, correctAnswers, correctOption);

  return (
    <>
      <div className='form-check mb-2 question_option'>
        <input
          checked={correctOption === option.id}
          className='form-check-input'
          type='checkbox'
          value=''
          style={{ marginLeft: '1px' }}
        />
        <p>{option.option}</p>

        {/* <input
          accept="image/*"
          style={{ display: "none",padding:"0px !important" }}
          type="file"
        />
        <label style={{marginTop:"-12px",marginLeft:"auto"}}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            style={{ padding:"0px !important" }}
          >
            <ImageOutlinedIcon />
          </IconButton>
        </label> */}
      </div>
    </>
  );
};

export default Option;
