import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

export const GlobalContext = createContext();

export const GlobalProvider = (props) => {
  const [userDetails, setUserDetails] = useState({
    isAuthenticated: false,
    name: "",
    userId: "",
    schoolId: "",
    role: "",
    profileImageUrl: undefined,
    selectedTab: "Dashboard",
    pathName: {
      subjectName: "",
      chapterName: "",
      topicName: "",
    },
    questions: [],
    grades: [],
    filterquestions: [],
  });
  // const [selectedTab, setSelectedTab] = useState("Dashboard");
  const [loading, setLoading] = useState(false);
  const [assignedSubjects, setAssignedSubjects] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [sectionIdTask, setSectionIdTask] = useState("");
  const [sectionId, setSectionId] = useState("");

  useEffect(() => {
    const checkAuth = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/auth/validate/${authToken}`
        );
        if (response.data.status === "authorized") {
          setUserDetails({
            isAuthenticated: true,
            name: response.data.data.user.name,
            userId: response.data.data.user.userId,
            schoolId: response.data.data.user.school,
            role: response.data.data.user.role,
            grades: response.data.data.user.grades,
            sections: response.data.data.user.sections,
            selectedTab: "Dashboard",
            pathName: userDetails.pathName,
            profileImageUrl: window.localStorage.getItem("profileImageUrl"),
            questions: userDetails.questions,
            filterquestions: userDetails.filterquestions,
          });
        }

        setLoading(true);
      } catch (error) {
        setUserDetails({ isAuthenticated: false });
        setLoading(true);
      }
    };

    checkAuth();
  }, []);

  return (
    <>
      {loading && (
        <GlobalContext.Provider
          value={{
            userDetails,
            setUserDetails,
            assignedSubjects,
            setAssignedSubjects,
            selectedClass,
            setSelectedClass,
            sectionIdTask,
            setSectionIdTask,
            sectionId,
            setSectionId,
          }}
        >
          {props.children}
        </GlobalContext.Provider>
      )}
    </>
  );
};
