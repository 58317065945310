import React, { useEffect } from "react";

const ImageBox = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "2rem",
  marginTop: "1rem",
};

const image = {
  margin: "auto",
};

function Image(props) {
  useEffect(() => {
    if (props?.location?.useProps?.click) {
      props.location.useProps.click();
    }
  }, []);
  return (
    <div style={ImageBox}>
      <img
        style={image}
        src={props.location.useProps.fileUrl}
        alt={props.location.useProps.fileUrl}
      />
    </div>
  );
}

export default Image;
