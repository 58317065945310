export const gradeAPIs = {
  assignedGrades: (userId) =>
    `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userId}/assignSubjects`,
};

export const ChapterAPIs = {
  fetchChapters: (staffId, sectionId, subjectId) =>
    `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${staffId}/sections/${sectionId}/subjects/${subjectId}/chapters`,
};

export const TopicAPIs = {
  fetchTopics: (staffId, sectionId, subjectId, chapterId) =>
    `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${staffId}/sections/${sectionId}/subjects/${subjectId}/chapters/${chapterId}/topics`,
};

export const LessonPlanAPIs = {
  createLessonPlan: (staffId) =>
    `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${staffId}/lessonplans`,
  fetchLessonPlans: (staffId) =>
    `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${staffId}/lessonplans`,
  fetchLessonPlan: (staffId, planId) =>
    `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${staffId}/lessonplans/${planId}`,
  deleteLessonPlan: (staffId, planId) =>
    `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${staffId}/lessonplans/${planId}`,
  updateLessonPlan: (staffId, planId) =>
    `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${staffId}/lessonplans/${planId}/rows`,
  updateResourceCellData: (staffId, planId) =>
    `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${staffId}/lessonplans/${planId}/row-resources`,
  searchModules: (name) =>
    `${process.env.REACT_APP_ORIGIN}/api/v1/modules/search-modules?name=${name}`,
  getModule: (id) => `${process.env.REACT_APP_ORIGIN}/api/v1/modules/${id}`,
};
