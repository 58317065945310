import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ReactComponent as Loader } from "../../../images/Loader.svg";
import { useState } from "react";
import { TablePagination } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  tableRow: {
    textDecoration: "none",
    "& > td": {
      fontWeight: "bold",
      fontSize: "1.02em",
    },
    "&:hover": {
      backgroundColor: "#5ce0d252 !important",
    },
  },
  
  tableHeading: {
    " & > th": {
      color: "#000 !important",
      fontWeight: "bolder",
      fontSize: "1.02em",
    },
  },
  "@media screen and (max-width: 500px)": {
    tableRow: {
      textDecoration: "none",
      "& > td": {
        fontWeight: "bold",
        fontSize: "0.7rem",
      },
      "&:hover": {
        backgroundColor: "#5ce0d252 !important",
      },
    },
    tableHeading: {
      " & > th": {
        color: "#000 !important",
        fontWeight: "bolder",
        fontSize: "0.7rem",
      },
    },
 }
}));

const TestTable = ({ rows, loading }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };
  // console.log(rows);
  return (
    <div className={classes.tableContainer}>
      {!loading ? (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeading}>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Class</TableCell>
                <TableCell align="center">Subject</TableCell>
                <TableCell align="center">Chapter</TableCell>
                <TableCell align="center">Topic</TableCell>
                <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                  Test Title
                </TableCell>
                <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                  Test Type
                </TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                  Start Time Of Test
                </TableCell>
                <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                  Time Spent
                </TableCell>
                <TableCell align="center">Questions</TableCell>
                <TableCell align="center">Marks</TableCell>
                <TableCell align="center">Correct</TableCell>
                <TableCell align="center">Incorrect</TableCell>
                <TableCell align="center">Easy</TableCell>
                <TableCell align="center">Medium</TableCell>
                <TableCell align="center">Difficult</TableCell>
                <TableCell align="center">Knowledge</TableCell>
                <TableCell align="center">Understanding</TableCell>
                <TableCell align="center">Analysis</TableCell>
                <TableCell align="center">Application</TableCell>
                <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                  Marks Obtained
                </TableCell>
                <TableCell align="center">Rank</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length ? (
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      // key={`${row.subject}${index}`}
                      variant="button"
                      component={NavLink}
                      to={`/dashboard/analytics/onlineTest/${row?.studentId?.grade}/${row.studentId?.section}/${row?.subjectId?.name}/${row?.studentId?._id}/${row?.subjectId?._id}/student/${row?.studentId?.name}/${row.testId._id}/${row.submissionId}/${row.testId.questionSet.title}`}
                      className={classes.tableRow}
                      style={{
                        backgroundColor: index % 2 ? "#5ce0d2" : "white",
                      }}
                    >
                      <TableCell scope="row" align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell
                        scope="row"
                        align="center"
                        dangerouslySetInnerHTML={{
                          __html: row.studentId?.name,
                        }}
                      />
                      <TableCell
                        scope="row"
                        align="center"
                        style={{ whiteSpace: "nowrap" }}
                        dangerouslySetInnerHTML={{
                          __html: `${row.studentId?.grade}-${row.studentId?.section}`,
                        }}
                      />
                      <TableCell
                        scope="row"
                        align="center"
                        dangerouslySetInnerHTML={{
                          __html: row.subjectId.name,
                        }}
                      />
                      <TableCell
                        align="center"
                        style={{ width: "17%" }}
                        dangerouslySetInnerHTML={{
                          __html: row.chapters,
                        }}
                      />
                      <TableCell
                        align="center"
                        style={{ width: "17%" }}
                        dangerouslySetInnerHTML={{
                          __html: row.topics,
                        }}
                      />
                      <TableCell align="center">
                        {row.testId.questionSet.title}
                      </TableCell>
                      <TableCell align="center">{row.testType}</TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {row.startedAt.split("T")[0]}
                      </TableCell>
                      <TableCell align="center">
                        {row.startedAt.split("T")[1].slice(0, 8)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ width: "17%", whiteSpace: "nowrap" }}
                      >
                        {Math.floor(row.timeSpent / 3600000)}h{" "}
                        {Math.floor((row.timeSpent % 3600000) / 60000)}m{" "}
                        {Math.floor((row.timeSpent / 1000) % 60)}s
                      </TableCell>
                      <TableCell align="center">{row.questions}</TableCell>
                      <TableCell align="center">{row.totalMarks}</TableCell>
                      <TableCell align="center">{row.correct}</TableCell>
                      <TableCell align="center">{row.incorrect}</TableCell>
                      <TableCell align="center">
                        {row.correctAttempt.easy}
                      </TableCell>
                      <TableCell align="center">
                        {row.correctAttempt.intermediate}
                      </TableCell>
                      <TableCell align="center">
                        {row.correctAttempt.hard}
                      </TableCell>
                      <TableCell align="center">
                        {row.correctAttempt.knowledge}
                      </TableCell>
                      <TableCell align="center">
                        {row.correctAttempt.understanding}
                      </TableCell>
                      <TableCell align="center">
                        {row.correctAttempt.analysis}
                      </TableCell>
                      <TableCell align="center">
                        {row.correctAttempt.application}
                      </TableCell>
                      <TableCell align="center">{row.totalScore}</TableCell>
                      <TableCell align="center">{row.rank}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell scope="row" align="center">
                    -
                  </TableCell>
                  <TableCell scope="row" align="center">
                    -
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            labelRowsPerPage={false}
            rowsPerPageOptions={false}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TestTable;
