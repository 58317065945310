import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
function SearchBar({
  searchQuestion,
  setSearchQuestion,
  searchQuestions,
  setWidthState,
  widthState,
}) {
  return (
    <div className="search_div" style={{ zIndex: 2, visibility: "hidden" }}>
      {!widthState && (
        <SearchIcon
          className="search_icon"
          style={{
            width: widthState ? "" : "10px",
            height: widthState ? "" : "10px",
            left: widthState ? "" : "1.1rem",
          }}
        />
      )}
      <input
        value={searchQuestion}
        onChange={(e) => {
          // if(e.target.value===" "){
          //     e.target.value="";
          // }
          setSearchQuestion(e.target.value);
          searchQuestions();
        }}
        onFocus={() => setWidthState(true)}
        onBlur={() => setWidthState(false)}
        type="text"
        id="header-search"
        className="searchBar"
        style={{
          width: widthState ? "55%" : "25%",
          height: widthState ? "25px" : "20px",
          marginLeft: widthState ? "-45px" : "",
          transition: "all 0.3s ease-in",
        }}
      />
    </div>
  );
}

export default SearchBar;
