import React, { useContext, useState, useEffect } from "react";
import "./styles/TestList.css";
import { GlobalContext } from "../../../context/GlobalState";
import TestTable from "./TestTable/TestTable";
import SearchBar from "./SearchBar/SearchBar";
import TestFilter from "./TestFilter/TestFilter";
import { useAlert } from "react-alert";
import axios from "axios";
import { useParams } from "react-router-dom";
import ExportButton from "../../../images/export (1).svg";
import exportFromJSON from "export-from-json";

const convertFilterToQuery = (filterData) => {
  let query = "?";
  for (let key in filterData) {
    if (filterData[key]) {
      query += `${key}=${filterData[key]}&`;
    }
  }
  return query.slice(0, -1);
};

const convertArrayToString = (array) => {
  if (!array) {
    return "";
  }
  array = array && array.map((item) => item.name);
  return array.join(", ");
};

function TestList() {
  //Creating instances
  const params = useParams();
  const { userDetails } = useContext(GlobalContext);
  const alert = useAlert();

  //States
  const [tableData, setTableData] = useState([]);

  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [filterData, setFilterData] = useState({
    subjectId: "",
    chapter: "",
    chapterId: "",
    topics: "",
    topicId: params.topicId,
    "from[gte]": null,
  });
  const [searchValue, setSearchValue] = useState("");
  // const [loading, setLoading] = useState(false);

  //function to fetch the subjects
  const getSubjects = async () => {
    const response = await axios(
      `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${userDetails.grade}/sections/${userDetails.section}/subjects`,
      {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
      }
    );
    console.log("Response of fetching subjects ====> ", response);
    if (response.data.status === "success") {
      setSubjects(response.data.data.subjects);
    } else {
      alert.error(response.message);
    }
  };

  //function to fetch the chapters
  const getChapters = async (subjectId) => {
    const response = await axios(
      `${process.env.REACT_APP_ORIGIN}/api/v1/subjects/${subjectId}`,
      {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
      }
    );
    console.log("Response of fetching chapters ====> ", response);
    if (response.data.status === "success") {
      setChapters(response.data.data.subject.chapters);
    } else {
      alert.error(response.message);
    }
  };

  //function to fetch the topics
  const getTopics = async () => {
    const response = await axios(
      `${process.env.REACT_APP_ORIGIN}/api/v1/chapters/${filterData.chapterId}`,
      {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
      }
    );
    console.log("Response of fetching topics ====> ", response);
    if (response.data.status === "success") {
      setTopics(response.data.data.chapter.topics);
    } else {
      alert.error(response.message);
    }
  };

  //fetch the assigned tests
  const getAssignedTests = async () => {
    // setLoading(true);
    const response = await axios(
      `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${
        userDetails.grade
      }/sections/${userDetails.section}/assigntests${convertFilterToQuery(
        filterData
      )}`,
      {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
      }
    );
    console.log("Response of fetching assigned tests ====> ", response);
    if (response.data.status === "success") {
      setTableData(response.data.data.assignTests);
    } else {
      alert.error(response.message);
    }
    // setLoading(false);
  };

  //function Search through the data when search value is changed
  const search = () => {
    let searchData = tableData.filter(
      (test) =>
        convertArrayToString(test.questionSet.chapters)
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        convertArrayToString(test.questionSet.topics)
          .toLowerCase()
          .includes(searchValue.toLowerCase())
    );
    setTableData(searchData);
  };

  // function to export table data
  const exportCsv = () => {
    const data = tableData;
    const fileName = "Class Report";
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data, fileName, exportType });
  };

  //Fetch the subjects when teh page loads
  useEffect(() => {
    getAssignedTests();
    getSubjects();
  }, []);

  useEffect(() => {
    getAssignedTests();
  }, [filterData]);

  //Fetch the chapters when the subjectId changes
  useEffect(() => {
    console.log("Filter data ===>", filterData);
    getChapters(filterData.subjectId);
  }, [filterData.subjectId]);

  //Fetch the topics when the chapter id changes
  useEffect(() => {
    getTopics();
  }, [filterData.chapterId]);

  //Call teh search function whenever the user types in search box
  useEffect(() => {
    //when the search box is empty
    if (!searchValue) {
      getAssignedTests();
    } else {
      search();
    }
  }, [searchValue]);

  return (
    <section className="learningResources">
      <div className="container-fluid my-2 mx-5000 upperbox">
        <TestFilter
          filterData={filterData}
          setFilterData={setFilterData}
          subjects={subjects}
          chapters={chapters}
          topics={topics}
        />
        <div className="table_container" style={{ width: "100%" }}>
          <div
            className="table_title_section"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <h2
              style={{
                fontWeight: 700,
                whiteSpace: "nowrap",
                marginLeft: "0.5%",
                marginTop: "0.5%",
              }}
            >
              Class Report
            </h2>
            <img
              onClick={exportCsv}
              style={{
                cursor: "pointer",
                marginLeft: 5,
              }}
              src={ExportButton}
            />
            <div className="search_container">
              <SearchBar search={searchValue} setSearch={setSearchValue} />
            </div>
          </div>

          <TestTable
            rows={tableData}
            // loading={loading}
          ></TestTable>
        </div>
      </div>
    </section>
  );
}

export default TestList;
