import { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Dropdown from "react-bootstrap/Dropdown";
import CheckIcon from "@material-ui/icons/Check";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import ImageIcon from "@material-ui/icons/Image";
import attachImg from "../../images/attach.svg";
import EditIcon from "@material-ui/icons/Edit";
import { useAlert } from "react-alert";
import axios from "axios";
import { validateThumbnail } from "../../utils/helper";
export default function EditCard({
  edit,
  closeEdit,
  question,
  setQuestId,
  setSelQuest,
  isMarks,
}) {
  const alert = useAlert();
  console.log("id", question);
  // setSelQuest(question);
  const onewordFillUp =
    question.questionType === "oneword" || question.questionType === "fillup";
  const answer = [];
  if (onewordFillUp) {
    question.correctAnswers[question.questionType].forEach((x) => {
      answer.push(x.answer);
    });
    console.log("answers", answer);
  }
  let newId = question._id;
  const [inputList, setInputList] = useState(question.options);
  const [quesText, setQuesText] = useState(question.question);
  const [marks, setMarks] = useState(question.marks);
  let Questype = question.questionType;
  const [editQuestionFormData, setEditQuestionFormData] = useState(
    new FormData()
  );
  const [correctAnswers, setCorrectAnswers] = useState(question.correctAnswers);
  const [questionTextImage, setQuestionTextImage] = useState("");
  const getOptionImages = (options) => {
    const optionImages = {};
    options.forEach((option) => {
      optionImages[option.optionId] = false;
    });
    return optionImages;
  };
  const [fieldImages, setFieldImages] = useState({
    questionImage: false,
    ...getOptionImages(question.options),
  }); //map kr k id lelo --question.options
  const [editLoader, setEditLoader] = useState("Save");
  const authToken = localStorage.getItem("authToken");
  console.log(inputList);
  console.log(correctAnswers);
  const [check, setCheck] = useState(true);
  question.options.forEach((x) => {
    console.log(
      question.correctAnswers[question.questionType].includes(x.optionId)
    );
  });
  const [difficulty, setDifficulty] = useState(question.difficulty);
  const [cognitive, setCognitive] = useState(question.cognitive);
  const [visible, setVisible] = useState(question.visibilityStatus);

  const handleSelect2 = (e) => {
    console.log(e);
    setDifficulty(e);
  };
  const handleSelect3 = (e) => {
    console.log(e);
    setCognitive(e);
  };
  const handleSelect4 = (e) => {
    console.log(e);
    setVisible(e);
  };
  const handleCheck = (e, questionType, id) => {
    console.log(correctAnswers);
    if (questionType != "mcq" || questionType != "correctstatement") {
      if (!e.target.checked) {
        // correctAnswers[questionType].filter((x)=>x!=id)
        setCorrectAnswers({
          [questionType]: correctAnswers[questionType].filter((x) => x != id),
        });

        return;
      }
    }
    if (["mcq", "correctstatement"].includes(questionType)) {
      setCorrectAnswers({ [questionType]: id });
    } else {
      correctAnswers[questionType].push(id);
      setCorrectAnswers({
        [questionType]: [...new Set(correctAnswers[questionType])],
      });
    }
  };

  const handleSubmit = async (id) => {
    setEditLoader("Please Wait Question is Updating...");
    setQuestId(newId);
    newId = "";
    console.log("clec", id);
    const question = {
      question: quesText,
      options: onewordFillUp
        ? inputList.map((x) => ({ optionId: x.optionId, value: "" }))
        : inputList,
      correctAnswers: onewordFillUp
        ? {
            [Questype]: inputList.map((x, i) => ({
              blankId: x.optionId,
              answer: x.value != "" ? x.value : answer[i],
            })),
          }
        : correctAnswers,
      marks: marks,
      cognitive: cognitive,
      difficulty: difficulty,
      visibilityStatus: visible,
    };
    editQuestionFormData.append("question", JSON.stringify(question));
    console.log(question);

    setEditQuestionFormData(editQuestionFormData);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questions/${id}`,
        editQuestionFormData,
        {
          headers: {
            "content-type": "multipart/form-data",
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        alert.success("Question Updated.");
        console.log(response.data.data.question, "1212");
        setSelQuest(response.data.data.question);
        // getOwnAssessment();
        setEditQuestionFormData(new FormData());
      }
    } catch (error) {
      alert.error("cant ablle to update");
    }
    setEditQuestionFormData(new FormData());
    setEditLoader("");
    closeEdit();
  };

  // const optionImg=(c,image)=>{
  //     if(optionImage[c][image]===true)
  //     {
  //         return true;
  //     }
  // }
  return (
    <div className="container my-4">
      <div className="authorQues mb-3">
        <div className="row">
          <div className="col-md-6">
            <div className="input-group mb-3">
              <textarea
                className="form-control textQues"
                placeholder={`${question?.question} ?`}
                value={quesText}
                rows="2"
                onChange={(e) => setQuesText(e.target.value)}
              />
              <label htmlFor="Quesimages">
                <AttachFileIcon
                  style={
                    fieldImages["questionImage"]
                      ? { opacity: "1", color: "green" }
                      : { opacity: "0.5" }
                  }
                  className="attach_icon"
                />
                {/* {questionTextImage?
                                    <CheckIcon className="check_icon"/>
                                    :<AttachFileIcon className="attach_icon"/> */}
              </label>
              <input
                style={{ display: "none" }}
                type="file"
                id="Quesimages"
                onChange={(e) => {
                  if (validateThumbnail(e.target.files[0])) {
                    fieldImages["questionImage"] = true;
                    setFieldImages(fieldImages);
                    editQuestionFormData.append(
                      "questionImg",
                      e.target.files[0]
                    );
                    setEditQuestionFormData(editQuestionFormData);
                    alert.success(`Question Image Uploaded`);
                  } else {
                    e.target.value = null;
                    alert.error("Please select valid file");
                  }
                }}
              />
            </div>
            {(onewordFillUp
              ? question.correctAnswers[question.questionType]
              : question.options
            ).map((option, i) => (
              <div className="add_option_section">
                {/* {console.log("s",option.optionId,i,x)} */}
                <div className="form-check styleRadio">
                  <input
                    className="form-check-input"
                    type={
                      question.questionType === "mcq" ||
                      question.questionType === "correctstatement"
                        ? "radio"
                        : "checkbox"
                    }
                    id={`op${i + 1}`}
                    name={
                      question.questionType === "mcq" ||
                      question.questionType === "correctstatement"
                        ? "mcq"
                        : ""
                    }
                    checked={
                      onewordFillUp
                        ? true
                        : // check
                        // question.correctAnswers[question.questionType].includes(option.optionId)
                        correctAnswers[question.questionType].includes(
                            option.optionId
                          )
                        ? check
                        : null
                    }
                    onClick={(e) =>
                      handleCheck(e, question.questionType, option.optionId)
                    }
                  />
                </div>
                <div className="input-group mb-3 input-attach-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={onewordFillUp ? option.answer : option.value}
                    id={onewordFillUp ? option.blankId : option.optionId}
                    value={inputList[i].value}
                    onChange={(e) => {
                      const { value, id } = e.target;
                      const list = [...inputList];
                      list[i]["value"] = value;
                      list[i]["optionId"] = id;
                      setInputList(list);
                    }}
                  />
                  <label htmlFor={`image${i + 1}`}>
                    <AttachFileIcon
                      // style={optionImage[i][`op${i+1}`]===true?{opacity:"1",color:"green"}:{opacity:"0.5"}}
                      className="attach_icon"
                    />
                  </label>

                  <input
                    style={{ display: "none" }}
                    id={`image${i + 1}`}
                    // name="file-input"
                    type="file"
                    onChange={(e) => {
                      if (validateThumbnail(e.target.files[0])) {
                        fieldImages[`op${i + 1}`] = true;
                        setFieldImages(fieldImages);
                        console.log(fieldImages);
                        // optionImage[i]["image"]=true
                        // setOptionImage(optionImage)
                        // setOptionImage(arr=>[...arr,{[`op${i+1}`]:true}])

                        editQuestionFormData.append(
                          `op${i + 1}`,
                          e.target.files[0]
                        );
                        setEditQuestionFormData(editQuestionFormData);
                        alert.success(`Option Image ${i + 1} Uploaded`);
                      } else {
                        e.target.value = null;
                        alert.error("Please select valid file");
                      }
                    }}
                  />
                </div>
              </div>
            ))}
            {console.log("image satte", fieldImages)}
          </div>
          <div className="col-md-6">
            <Dropdown className="option-dropdown mb-3">
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                className="btn-question-type"
              >
                {difficulty}
              </Dropdown.Toggle>

              <Dropdown.Menu className="pra-dropdown edit-dropdown">
                <Dropdown.Item
                  onSelect={handleSelect2}
                  eventKey="easy"
                  className="menu_option pra-drop-list edit_option"
                >
                  easy
                  <div>
                    <input type="radio" name="radio" />
                    <span></span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={handleSelect2}
                  eventKey="intermediate"
                  className="menu_option pra-drop-list edit_option"
                >
                  intermediate
                  <div>
                    <input type="radio" name="radio" />
                    <span></span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={handleSelect2}
                  eventKey="hard"
                  className="menu_option pra-drop-list edit_option"
                >
                  hard
                  <div>
                    <input type="radio" name="radio" />
                    <span></span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="option-dropdown mb-3">
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                className="btn-question-type"
              >
                {cognitive}
              </Dropdown.Toggle>

              <Dropdown.Menu className="pra-dropdown edit-dropdown">
                <Dropdown.Item
                  onSelect={handleSelect3}
                  eventKey="knowledge"
                  className="menu_option pra-drop-list edit_option"
                >
                  knowledge
                  <div>
                    <input type="radio" name="radio" />
                    <span></span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={handleSelect3}
                  eventKey="understanding"
                  className="menu_option pra-drop-list edit_option"
                >
                  understanding
                  <div>
                    <input type="radio" name="radio" />
                    <span></span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={handleSelect3}
                  eventKey="application"
                  className="menu_option pra-drop-list edit_option"
                >
                  application
                  <div>
                    <input type="radio" name="radio" />
                    <span></span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={handleSelect3}
                  eventKey="analysis"
                  className="menu_option pra-drop-list edit_option"
                >
                  analysis
                  <div>
                    <input type="radio" name="radio" />
                    <span></span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="option-dropdown mb-3">
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                className="btn-question-type"
              >
                {visible}
              </Dropdown.Toggle>

              <Dropdown.Menu className="pra-dropdown edit-dropdown">
                <Dropdown.Item
                  onSelect={handleSelect4}
                  eventKey="private"
                  className="menu_option pra-drop-list edit_option"
                >
                  private
                  <div>
                    <input type="radio" name="radio" />
                    <span></span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={handleSelect4}
                  eventKey="publicForSchool"
                  className="menu_option pra-drop-list edit_option"
                >
                  public for school
                  <div>
                    <input type="radio" name="radio" />
                    <span></span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={handleSelect4}
                  eventKey="publicForAll"
                  className="menu_option pra-drop-list edit_option"
                >
                  public for all
                  <div>
                    <input type="radio" name="radio" />
                    <span></span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {isMarks && (
              <div className="authorQues_marks">
                <span>Marks</span>

                <span style={{ backgroundColor: "#90E0EF" }}>
                  <input
                    type="number"
                    value={marks}
                    onChange={(e) => setMarks(e.target.value)}
                    className="marks-input-blue"
                  />
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <button
              type="button"
              className="btn btn-add-option"
              style={{ marginRight: "10px" }}
              onClick={closeEdit}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-add-option"
              onClick={() => handleSubmit(question._id)}
            >
              {editLoader}
            </button>

            <div
              className="opt-edit"
              style={edit ? { display: "none" } : { display: "block" }}
            >
              <span className="round-btn">
                <svg
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.928 7.71145L7.928 19.7114C7.65189 19.9817 7.30537 20.1688 6.928 20.2514L3.388 20.9614C3.06571 21.0252 2.73269 21.0085 2.41837 20.913C2.10404 20.8175 1.81809 20.646 1.58579 20.4137C1.35348 20.1814 1.18198 19.8954 1.08644 19.5811C0.990902 19.2668 0.974263 18.9337 1.038 18.6115L1.738 15.1014C1.82067 14.7241 2.00779 14.3776 2.278 14.1014L14.118 2.25145C14.5046 1.85534 14.9665 1.54056 15.4766 1.32564C15.9866 1.11072 16.5345 1 17.088 1C17.6415 1 18.1894 1.11072 18.6994 1.32564C19.2095 1.54056 19.6714 1.85534 20.058 2.25145C20.7469 3.01139 21.1176 4.00695 21.0934 5.03241C21.0692 6.05787 20.652 7.03485 19.928 7.76145V7.71145Z"
                    fill="#0F93A8"
                    stroke="#0F93A8"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>

              <span className="round-btn">
                <svg
                  width="24"
                  height="26"
                  viewBox="0 0 24 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23 4H16V3C16 2.20435 15.6839 1.44129 15.1213 0.87868C14.5587 0.316071 13.7956 0 13 0H11C10.2044 0 9.44129 0.316071 8.87868 0.87868C8.31607 1.44129 8 2.20435 8 3V4H1C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6H2.09L3.64 21.5C3.76437 22.7376 4.34557 23.8844 5.27012 24.7165C6.19467 25.5486 7.39617 26.0062 8.64 26H15.4C16.6438 26.0062 17.8453 25.5486 18.7699 24.7165C19.6944 23.8844 20.2756 22.7376 20.4 21.5L21.91 6H23C23.2652 6 23.5196 5.89464 23.7071 5.70711C23.8946 5.51957 24 5.26522 24 5C24 4.73478 23.8946 4.48043 23.7071 4.29289C23.5196 4.10536 23.2652 4 23 4ZM10 3C10 2.73478 10.1054 2.48043 10.2929 2.29289C10.4804 2.10536 10.7348 2 11 2H13C13.2652 2 13.5196 2.10536 13.7071 2.29289C13.8946 2.48043 14 2.73478 14 3V4H10V3ZM10.09 19H10C9.74966 19.001 9.50805 18.9081 9.3229 18.7396C9.13775 18.5711 9.02253 18.3393 9 18.09L8.48 12.09C8.45746 11.8248 8.54119 11.5615 8.71279 11.358C8.88439 11.1545 9.12978 11.0275 9.395 11.005C9.66022 10.9825 9.92353 11.0662 10.127 11.2378C10.3305 11.4094 10.4575 11.6548 10.48 11.92L11.01 17.92C11.0209 18.0514 11.0058 18.1837 10.9654 18.3092C10.925 18.4347 10.8602 18.551 10.7747 18.6514C10.6892 18.7518 10.5847 18.8343 10.4672 18.8941C10.3497 18.9539 10.2215 18.9899 10.09 19ZM15 18.09C14.9775 18.3393 14.8623 18.5711 14.6771 18.7396C14.4919 18.9081 14.2503 19.001 14 19H13.91C13.7785 18.9887 13.6505 18.9515 13.5334 18.8905C13.4164 18.8295 13.3126 18.7459 13.228 18.6445C13.1434 18.5432 13.0797 18.4261 13.0405 18.3001C13.0014 18.174 12.9876 18.0414 13 17.91L13.53 11.91C13.5525 11.6448 13.6795 11.3994 13.883 11.2278C14.0865 11.0562 14.3498 10.9725 14.615 10.995C14.8802 11.0175 15.1256 11.1445 15.2972 11.348C15.4688 11.5515 15.5525 11.8148 15.53 12.08L15 18.09Z"
                    fill="#0F93A8"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
