import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import { GlobalProvider } from "./context/GlobalState";
import SecuredRoute from "./components/SecuredRoute/SecuredRoute";
import LPSecuredRoute from "./components/SecuredRoute/LPSecuredRoute";

import TaskList from "./components/TaskList/TaskList";
import TaskTopic from "./components/TaskList/TaskTopic";
import Submission from "./components/TaskList/Submission";
import MyResources from "./components/MyResources/MyResources";
import Subjects from "./components/MyResources/Subjects";
import Chapters from "./components/MyResources/Chapters";
import Modules from "./components/MyResources/Modules";
import PractiseAssessment from "./components/PractiseAssessment/PractiseAssessment";
import TestDetails from "./components/MyResources/TestDetails/TestDetails";
import CreateTest from "./components/Test/CreateTest";
import Analytics from "./components/Analytics/Analytics";
import Setting from "./components/Setting/Setting";
import Login from "./components/Login/Login";
import Logout from "./components/Logout";
import Auth from "./components/auth/Auth";
import PDFDocument from "./components/MyResources/MediaViewers/PdfViewer";
import Document from "./components/MyResources/MediaViewers/Document";
import VideoPlayer from "./components/MyResources/MediaViewers/VideoPlayer";
import AudioPlayer from "./components/MyResources/MediaViewers/AudioPlayer";
import ImageViewer from "./components/MyResources/MediaViewers/ImageViewer";
import MyAssessment from "./components/MyAssessment/MyAssessment";
import OnlineTest from "./components/MyAssessment/OnlineTest";
import PracticeAssessmentAnalytics from "./components/AssessmentAnalytics/PracticeAssessment";
import ClassAnalytics from "./components/AssessmentAnalytics/ClassAnalytics";
import StudentAnalytics from "./components/AssessmentAnalytics/StudentAnalytics";
import QuestionReport from "./components/AssessmentAnalytics/QuestionReport";
import Dashboard from "./components/Dashboard/Dashboard";
import MyClasses from "./components/MyClasses/MyClasses";
import Report from "./components/Reports/Report";
import ReportCard from "./components/Reports/ReportCard";
import OnlineTestAnalytics from "./components/OnlineTestAnalytics/OnlineTest";
import OnlineTest2 from "./components/OnlineTestAnalytics/OnlineTest2";
import OnlineTest4 from "./components/OnlineTestAnalytics/OnlineTest4";
import OnlineTest5 from "./components/OnlineTestAnalytics/OnlineTest5";
import OnlineTest6 from "./components/OnlineTestAnalytics/OnlineTest6";
import OnlineTestStudentAnalytics from "./components/OnlineTestAnalytics/OnlineTestStudentAnalytics";
import OnlineTestQuestionReport from "./components/OnlineTestAnalytics/OnlineTestQuestionReport";
import LessonPlansPage from "./components/LessonPlan/LessonPlansPage";
import LessonPlanPage from "./components/LessonPlan/LessonPlanPage";
import ViewModule from "./components/LessonPlan/Module/ViewModule";

function App() {
  
  return (
    <>
      <GlobalProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/auth/:token/:id" component={Auth} />
          <SecuredRoute exact path="/dashboard" component={Dashboard} />
          <SecuredRoute exact path="/myclasses" component={MyClasses} />
          <SecuredRoute exact path="/tasklist" component={TaskList} />
          <SecuredRoute
            exact
            path="/tasklist/topic/:taskid"
            component={TaskTopic}
          />
          <SecuredRoute
            exact
            path="/tasklist/topic/submission/:taskid"
            component={Submission}
          />
          <SecuredRoute exact path="/myresources" component={MyResources} />
          <SecuredRoute exact path="/myassessment" component={MyAssessment} />
          <SecuredRoute
            exact
            path="/dashboard/analytics/practiceAssessment/:class/:section/:subject"
            component={PracticeAssessmentAnalytics}
          />
          <SecuredRoute
            exact
            path="/dashboard/analytics/practiceAssessment/:class/:section"
            component={PracticeAssessmentAnalytics}
          />
          <LPSecuredRoute
            exact
            path="/lessonplans"
            component={LessonPlansPage}
          />
          <LPSecuredRoute
            exact
            path="/lessonplans/:id"
            component={LessonPlanPage}
          />
          <SecuredRoute
            exact
            path="/modules/:moduleId/"
            component={ViewModule}
          />
          <SecuredRoute
            exact
            path="/myassessment/analytics/class"
            component={ClassAnalytics}
          />
          <SecuredRoute
            exact
            path="/dashboard/analytics/practiceAssessment/:class/:section/:subject/:student/:studentId/:sectionId/:subjectValue"
            component={StudentAnalytics}
          />
          <SecuredRoute
            exact
            path="/dashboard/analytics/practiceAssessment/:class/:section/:subject/:student/:studentId/:sectionId/:subjectValue/question"
            component={QuestionReport}
          />
          <SecuredRoute
            exact
            path="/dashboard/analytics/onlineTest"
            component={OnlineTestAnalytics}
          />
          <SecuredRoute
            exact
            path="/dashboard/analytics/onlineTest/:class/:section/:subject/page2"
            component={OnlineTest2}
          />
          <SecuredRoute
            exact
            path="/dashboard/analytics/onlineTest/:class/:section/:sectionId/:subject/:subjectId"
            component={OnlineTest4}
          />
          <SecuredRoute
            exact
            path="/dashboard/analytics/onlineTest/:class/:section/:sectionId/:subject/:subjectId/:chapterId"
            component={OnlineTest5}
          />
          <SecuredRoute
            exact
            path="/dashboard/analytics/onlineTest/:class/:section/:sectionId/:subject/:subjectId/:chapterId/:topicId"
            component={OnlineTest6}
          />
          <SecuredRoute
            exact
            path="/dashboard/analytics/onlineTest/:class/:section/:subject/:subjectId/student/:sectionId/:student/:studentId"
            component={OnlineTestStudentAnalytics}
          />
          <SecuredRoute
            exact
            path="/dashboard/analytics/onlineTest/:class/:section/:subject/:subjectId/:chapterId/student/:sectionId/:student/:studentId"
            component={OnlineTestStudentAnalytics}
          />
          <SecuredRoute
            exact
            path="/dashboard/analytics/onlineTest/:class/:section/:subject/:subjectId/:chapterId/:topicId/student/:sectionId/:student/:studentId"
            component={OnlineTestStudentAnalytics}
          />
          <SecuredRoute
            exact
            path="/dashboard/analytics/onlineTest/:class/:section/:subject/:studentId/:subjectId/student/:student/:testId/:submissionId/:testTitle"
            component={OnlineTestQuestionReport}
          />
          <SecuredRoute
            exact
            path="/myresources/:subject/:subjectId"
            component={Subjects}
          />
          <SecuredRoute
            exact
            path="/myresources/:subject/:subjectId/:chapter/:chapterId"
            component={Chapters}
          />
          <SecuredRoute
            exact
            path="/myresources/:subject/:subjectId/:chapter/:chapterId/:topic/:topicId"
            component={Modules}
          />
          <SecuredRoute
            exact
            path="/myresources/:subject/:subjectId/:chapter/:chapterId/:topic/:topicId/practiseAssessment"
            component={PractiseAssessment}
          />
          <SecuredRoute
            exact
            path="/myassessment/:subject/:subjectId/practiseAssessment"
            component={PractiseAssessment}
          />
          <SecuredRoute
            exact
            path="/myresources/:subject/:subjectId/:chapter/:chapterId/:topic/:topicId/onlinetest"
            component={OnlineTest}
          />
          <SecuredRoute
            exact
            path="/myassessment/:subject/:subjectId/onlinetest"
            component={OnlineTest}
          />
          <SecuredRoute
            exact
            path="/myresources/:subject/:subjectId/:chapter/:chapterId/:topic/:topicId/:module/show"
            component={ImageViewer}
          />
          <SecuredRoute
            exact
            path="/myresources/:subject/:subjectId/:chapter/:chapterId/:topic/:topicId/:module/watch"
            component={VideoPlayer}
          />
          <SecuredRoute
            exact
            path="/myresources/:subject/:subjectId/:chapter/:chapterId/:topic/:topicId/:module/view"
            component={Document}
          />
          <SecuredRoute
            exact
            path="/myresources/:subject/:subjectId/:chapter/:chapterId/:topic/:topicId/:module/pdfview"
            component={PDFDocument}
          />
          <SecuredRoute
            exact
            path="/myresources/modules/:module/listen"
            component={AudioPlayer}
          />
          <SecuredRoute
            exact
            path="/myresources/modules/:module/show"
            component={ImageViewer}
          />
          <SecuredRoute
            exact
            path="/myresources/modules/:module/watch"
            component={VideoPlayer}
          />
          <SecuredRoute
            exact
            path="/myresources/modules/:module/view"
            component={Document}
          />
          <SecuredRoute
            exact
            path="/myresources/modules/:module/listen"
            component={AudioPlayer}
          />
          <SecuredRoute
            exact
            path="/report/reportcard/:rowid"
            component={ReportCard}
          />
          <SecuredRoute exact path="/report" component={Report} />
          <SecuredRoute exact path="/test/:id" component={TestDetails} />
          <SecuredRoute exact path="/createtest" component={CreateTest} />
          <SecuredRoute exact path="/analytics" component={Analytics} />
          <SecuredRoute exact path="/setting" component={Setting} />
        </Switch>
      </GlobalProvider>
    </>
  );
}

export default App;
