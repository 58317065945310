import React, { useEffect } from "react";
import useDisableContextMenu from "../../../utils/useDisableContextMenu";
import "./mediaViewer.css";

const VideoStyle = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginTop: "1rem",
  alignItems: "center",
  paddingTop: "2rem",
};

function Video(props) {
  useDisableContextMenu();

  useEffect(() => {
    if (props?.location?.useProps?.click) {
      props.location.useProps.click();
    }
  }, []);

  return (
    <div style={VideoStyle}>
      <video
        width="60%"
        style={{
          marginTop: "2rem",
          border: "5px solid #5ce0d2",
          borderRadius: "3px",
        }}
        controls
        controlsList="nodownload"
      >
        <source src={props.location.useProps?.fileUrl} type="video/mp4" />
        <source src={props.location.useProps?.fileUrl} type="video/ogg" />
        <source src={props.location.useProps?.fileUrl} type="video/swf" />
        Your browser does not support HTML video.
      </video>
    </div>
  );
}

export default Video;
