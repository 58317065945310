import "./LessonPlansPage.css";

import { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalState";

import LPNavbar from "./LPNavbar/LPNavbar";
import Grades from "./Grade/Grades";
import Panel from "./Panel/Panel";
import LessonPlanForm from "./LessonPlanForm/LessonPlanForm";
import LessonPlans from "./LessonPlans/LessonPlans";

import GradeSorter from "../../utils/gradeSorter";

import axios from "axios";
import { gradeAPIs, LessonPlanAPIs } from "./api";

function LessonPlanHome(props) {
  const location = useLocation();
  const history = useHistory();
  const { userDetails } = useContext(GlobalContext);

  const panelRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [grades, setGrades] = useState([]);
  const [grade, setGrade] = useState(null);
  const [lessonPlans, setLessonPlans] = useState([]);
  const [tab, setTab] = useState({
    previousIndex: 0,
    currentIndex: 0,
    labels: [
      { label: "Create Lesson Plan", active: true },
      { label: "Lesson Plans", active: false },
    ],
  });

  //
  function goBack() {
    //move back to home page
    if (grade?._id) {
      setGrade(null);
      history.replace("/lessonplans");
      return;
    }
    //move back to dashboard
    window.location.href = "/dashboard";
  }
  function getSearchParams(params) {
    const query = {};
    const search = new URLSearchParams(location.search);
    params.forEach((param) => {
      if (!search.get(param)) return;
      query[param] = search.get(param);
    });
    return query;
  }

  //change tabs
  function changeTab(index) {
    if (tab.currentIndex === index) return;
    tab.labels[tab.currentIndex].active = false;
    tab.labels[index].active = true;
    tab.previousIndex = tab.currentIndex;
    tab.currentIndex = index;
    setTab({ ...tab });
  }

  useEffect(() => {
    if (!panelRef.current) return;
    const childWidth = panelRef.current.children[0].clientWidth;
    panelRef.current.scrollTo(tab.currentIndex * childWidth, 0);
  }, [tab.currentIndex]);

  //change grades page after selecting grade
  useEffect(() => {
    if (!grades.length) return;
    const query = getSearchParams(["grade"]);
    if (!query.grade) return;
    setGrade(grades.find((g) => g._id === query.grade));
  }, [location.search]);

  //set grade page when returned from download page
  useEffect(() => {
    if (!grades.length) return;
    const query = getSearchParams(["grade"]);
    if (!query.grade) return;
    setGrade(grades.find((g) => g._id === query.grade));
  }, [grades]);

  //change tab of grade page
  useEffect(() => {
    if (!grade?._id) {
      return;
    }
    const query = getSearchParams(["tab"]);
    if (!query.tab) return;
    changeTab(Number.parseInt(query.tab));
  }, [grade]);

  useEffect(() => {
    //fetch assigned sections
    const authToken = localStorage.getItem("authToken");
    axios
      .get(gradeAPIs.assignedGrades(userDetails.userId), {
        headers: {
          token: authToken,
        },
        validateStatus: () => true,
      })
      .then((res) => {
        if (res.data.status !== "success") throw res.data;
        const grades = new GradeSorter(res.data.data.sections).sort();
        setGrades(grades);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => setLoading(false));

    //fetch lesson plans
    axios
      .get(
        `${LessonPlanAPIs.fetchLessonPlans(
          userDetails.userId
        )}?sort=startDate,_id`,
        {
          headers: {
            token: authToken,
          },
          validateStatus: () => true,
        }
      )
      .then((res) => {
        if (res.data.status !== "success") throw res.data;
        setLessonPlans(res.data.data.lessonPlans);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="lp-container">
      <LPNavbar
        mainHeading={grade?._id || "Home"}
        picture={userDetails.profileImageUrl}
        username={userDetails.name && userDetails.name.split(" ")[0]}
        goBack={goBack}
      />
      {!grade?._id ? (
        <Grades loading={loading} grades={grades} setGrade={setGrade} />
      ) : (
        <Panel
          style={{ width: "100%", padding: 0 }}
          tabs={tab.labels}
          parentRef={panelRef}
          changeTab={changeTab}
        >
          <LessonPlanForm
            userId={userDetails.userId}
            classname="page"
            grade={grade}
            lessonPlans={lessonPlans}
            setLessonPlans={setLessonPlans}
          />
          <LessonPlans
            userId={userDetails.userId}
            classname="page"
            lessonPlans={lessonPlans.filter(
              (plan) =>
                `${plan.sectionId?.grade}-${plan.sectionId?.section}` ===
                grade?._id
            )}
            setLessonPlans={setLessonPlans}
          />
        </Panel>
      )}
    </div>
  );
}

export default LessonPlanHome;
