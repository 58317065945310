import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ReactComponent as Loader } from "../../../images/Loader.svg";
import { useState } from "react";
import { TablePagination } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  table: {
    // minWidth: "auto",
  },
  tableRow: {
    textDecoration: "none",
    "& > td": {
      fontWeight: "bold",
      fontSize: "1.02em",
    },
    "&:hover": {
      backgroundColor: "#5ce0d252 !important",
    },
  },
  tableHeading: {
    " & > th": {
      color: "#000 !important",
      fontWeight: "bolder",
      fontSize: "1.02em",
    },
  },
}));

const TestTable = ({ rows, loading }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };
  return (
    <div className={classes.tableContainer}>
      {!loading ? (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeading}>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">Class</TableCell>
                <TableCell align="center">Subject</TableCell>
                <TableCell align="center">Chapter</TableCell>
                <TableCell align="center">Topic</TableCell>
                <TableCell align="center">No of Students</TableCell>
                <TableCell align="center">Total Questions</TableCell>
                <TableCell align="center">Total Time</TableCell>
                <TableCell align="center">Questions Attempted</TableCell>
                <TableCell align="center">Correct</TableCell>
                <TableCell align="center">Incorrect</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length ? (
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      // key={`${row.subject}${index}`}
                      variant="button"
                      // component={NavLink}
                      // to={`/myassessment/online-test/tests/${row._id}`}
                      className={classes.tableRow}
                      style={{
                        backgroundColor: index % 2 ? "#5ce0d2" : "white",
                      }}
                    >
                      <TableCell scope="row" align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell scope="row" align="center">
                        {row.grade}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ width: "17%" }}
                        dangerouslySetInnerHTML={{
                          __html: row.subjectId.name,
                        }}
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={{ width: "17%" }}
                        dangerouslySetInnerHTML={{
                          __html: row.chapterId.name,
                        }}
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={{ width: "17%" }}
                        dangerouslySetInnerHTML={{
                          __html: row.topicId.name,
                        }}
                      ></TableCell>
                      <TableCell align="center">{row.students}</TableCell>
                      <TableCell align="center">{row.questions}</TableCell>
                      <TableCell align="center" style={{ width: "17%" }}>
                        {Math.floor(row.timeSpend / 3600000)}h{" "}
                        {Math.floor((row.timeSpend % 3600000) / 60000)}m{" "}
                        {Math.floor((row.timeSpend / 1000) % 60)}s
                      </TableCell>
                      <TableCell align="center">
                        {row.questionsAttempted}
                      </TableCell>
                      <TableCell align="center">{row.correct}</TableCell>
                      <TableCell align="center">{row.incorrect}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell scope="row" align="center">
                    -
                  </TableCell>
                  <TableCell scope="row" align="center">
                    -
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            labelRowsPerPage={false}
            rowsPerPageOptions={false}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TestTable;
