/* eslint-disable react-hooks/exhaustive-deps */

import axios from "axios";
import React, { useEffect, useState } from "react";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import "./styles/module.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import image from "../../utils/helpers";
import DefaultImage from "../../images/modules/pdfImage.png";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";

import {
  fixname,
  decodeHTMLEntities,
  fixFileUrl,
  fileType,
  actionType,
  getModuleType
} from "../../utils/tools";

const Module = ({
  topicId,
  userDetails,
  moduleTaskTypeModalFunc,
  getOwnModules,
  fetchOwnModulesLoader,
  ownModules,
  setOwnModules,
  subjectName,
  chapterName,
  topicName,
  selectedGrade,
  chapterId,
  subjectId,
}) => {
  const [modules, setModules] = useState([]);
  const [fetchModuleLoader, setFetchModuleLoader] = useState(true);
  const [deleteOwnModuleLoader, setDeleteOwnModuleLoader] = useState(false);

  const alert = useAlert();
  const location = useLocation();
  const userId = localStorage.getItem("userId");
  const sectionIdTask = localStorage.getItem("sectionIdTask");
  const language = window.localStorage.getItem("language");
  // const { userId } = useContext(GlobalContext);

  useEffect(() => {
    const getModules = async () => {
      const authToken = localStorage.getItem("authToken");

      try {
        const tempOrigin = `${process.env.REACT_APP_NEWORIGIN}`;
        const response = await axios.get(
          // `${process.env.REACT_APP_ORIGIN}/api/v1/topics/${topicId}?lang=${language}`,
          `${tempOrigin}/api/v1/staffs/${userDetails.userId}/sections/${sectionIdTask}/subjects/${subjectId}/chapters/${chapterId}/topics/${topicId}/modules`,
          {
            headers: {
              token: authToken,
            },
          }
        );

        if (response.data.status === "success") {
          const admin_modules = response.data.data.modules.filter(
            (module) => module.onModel === "admin"
          );
          setModules(admin_modules);
        }
      } catch (error) {
        console.log(error);
      }

      setFetchModuleLoader(false);
      getOwnModules();
    };

    getModules();
  }, []);

  const deleteOwnModule = async (id) => {
    setDeleteOwnModuleLoader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/modules/${id}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        const newOwnModules = ownModules.filter((module) => module._id !== id);
        setOwnModules(newOwnModules);
      }
    } catch (error) {
      if (error.response.data.status === "fail") {
      } else {
        alert.show("Try Again.");
      }
    }

    setDeleteOwnModuleLoader(false);
  };

  //Function to togggle the modal on top right of each box
  const toggleMenu = (id) => {
    if (document.getElementById(id).style.display === "none") {
      document.getElementById(id).style.display = "block";
      // document.getElementById(id).style.transform = `translate(200px,45px)`;
      document.getElementById(id).style.transform = `translate(-90px,45px)`;
    } else {
      document.getElementById(id).style.display = "none";
    }
  };

  //Call the page visit api whenever a module is clicked
  const Visited = async (module, userId) => {
    const subject = subjectName;
    const chapter = chapterName;
    const topic = topicName;
    const teacherId = userDetails.userId;
    const grade = selectedGrade.split("-")[0];
    const section = selectedGrade.split("-")[1];

    try {
      await fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/analytics/pagevisits`,
        {
          method: "POST",
          headers: {
            token: `${window.localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            teacherId,
            subject,
            chapter,
            topic,
            module,
            grade,
            section,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(`Marked ${module} as visited..`);
        });
    } catch (e) {
      console.log(e.message);
    }
  };


  const moduleCompleteStatus = async (id, setStatus) => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${userDetails.userId}/sections/${sectionIdTask}/subjects/${subjectId}/chapters/${chapterId}/topics/${topicId}/modules/${id}/status`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        alert.success(
          response.data.data.completed
            ? "Module marked complete"
            : "Module marked incomplete"
        );
        setStatus(response.data.data.completed);
        window.localStorage.setItem("moduleId", id);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  return (
    <>
      {deleteOwnModuleLoader && (
        <div className="text-center">
          <CircularProgress style={{ color: "#FF075B", fontWeight: "600" }} />
        </div>
      )}

      {fetchModuleLoader ? (
        <div className="text-center">
          <CircularProgress style={{ color: "#FF075B", fontWeight: "600" }} />
        </div>
      ) : (
        <div className="row module my-5 mx-3 gy-4">
          {modules[0] !== null
            ? modules.map((module, index) => {
                let result = getModuleType(module.resources);
                return (
                  <div
                    className="col-sm-2 cardBox"
                    style={{
                      marginBottom: "3rem",
                      textDecoration: "none",
                      display:
                        result.notFound || fileType(result.fileType) === "Image"
                          ? "block"
                          : "block",
                    }}
                    key={index}
                    onClick={() => {
                      Visited(module.name, module?.creator?._id);
                    }}
                  >
                    {result.fileType != "html" ? (
                      <>
                        <NavLink
                          to={{
                            pathname:
                              location.pathname +
                              `/${fixname(module.name)}/${actionType(
                                result.fileType
                              )}`,
                            useProps: !result.notFound
                              ? {
                                  fileUrl: fixFileUrl(
                                    result.resource.fileUrl,
                                    result.fileType
                                  ),
                                  moduleName: result.resource.fileName,
                                }
                              : {
                                  fileUrl: "",
                                  fileName: "",
                                },
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          {/* <div
                        className='text-center'
                        style={ModuleStyle(
                          fixFileUrl(module.thumbnail, module.name)
                        )}
                      > */}
                          <div className="card card-radius card-height">
                            <div className="card-img">
                              <img
                                src={
                                  image(
                                    fixFileUrl(module.thumbnail, module.name)
                                  ) || DefaultImage
                                }
                                className="card-thumbnail"
                                alt={module?.name || "chapter"}
                              />
                            </div>
                            {/* // link */}
                            <div
                              className="text-end text-light"
                              style={{
                                marginBottom: "auto",
                                position: "absolute",
                                zIndex: 100,
                                right: 0,
                              }}
                            >
                              <li
                                className="nav-item dropdown"
                                style={{ listStyle: "none" }}
                              >
                                <IconButton
                                  aria-label="settings"
                                  data-bs-toggle="dropdown"
                                  onClick={() => {
                                    toggleMenu(module._id);
                                  }}
                                >
                                  <MoreVertIcon style={{ color: "black" }} />
                                </IconButton>
                                <ul
                                  className="dropdown-menu"
                                  style={{
                                    display: "none",
                                    position: "absolute",
                                  }}
                                  id={module._id}
                                >
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      onClick={() =>
                                        moduleTaskTypeModalFunc(module._id)
                                      }
                                    >
                                      Assign Task
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </div>

                            <div>
                              <p
                                className="text-dark text-center"
                                style={{ fontWeight: "bold" }}
                              >
                                {decodeHTMLEntities(module.name)}
                              </p>
                              {/* <p style={{ marginBlockEnd: '5px' }}>{fileType(result.fileType) || 'Empty Module'}</p> */}
                            </div>
                          </div>
                        </NavLink>
                        <ToggleSwitch
                          statusFunction={moduleCompleteStatus}
                          id={module._id}
                          completed={module.completed}
                        />
                      </>
                    ) : (
                      <a
                        href={result.resource.fileUrl}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        {/* <div
                         className='text-center'
                        style={ModuleStyle(
                          fixFileUrl(module.thumbnail, module.name)
                        )}
                      > */}
                        <div className="card card-radius card-height">
                          <div className="card-img">
                            <img
                              src={
                                image(
                                  fixFileUrl(module.thumbnail, module.name)
                                ) || DefaultImage
                              }
                              className="card-thumbnail"
                              alt={module?.name || "chapter"}
                            />
                          </div>
                          <div
                            className="text-end text-light"
                            style={{
                              marginBottom: "auto",
                              position: "absolute",
                              right: 0,
                            }}
                          >
                            <li
                              className="nav-item dropdown"
                              style={{ listStyle: "none" }}
                            >
                              <IconButton
                                aria-label="settings"
                                data-bs-toggle="dropdown"
                                onClick={() => {
                                  toggleMenu(module._id);
                                }}
                              >
                                <MoreVertIcon style={{ color: "black" }} />
                              </IconButton>
                              <ul
                                className="dropdown-menu"
                                style={{
                                  display: "none",
                                  position: "absolute",
                                }}
                                id={module._id}
                              >
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    Teacher
                                    onClick={() =>
                                      moduleTaskTypeModalFunc(module._id)
                                    }
                                  >
                                    Assign Task
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </div>
                          <div>
                            <p className="text-dark text-center">
                              {decodeHTMLEntities(module.name)}
                            </p>
                            {/* <p style={{ marginBlockEnd: '5px' }}>{fileType(result.fileType) || 'Empty Module'}</p> */}
                          </div>
                        </div>
                        <ToggleSwitch
                          statusFunction={moduleCompleteStatus}
                          id={module._id}
                          completed={module.completed}
                        />
                      </a>
                    )}
                  </div>
                );
              })
            : // <h3 className="text-center">No modules</h3>
              null}
        </div>
      )}

      {/* upload area */}
      <div className="row mt-4 text-center text-sm-start my_resources_heading_section">
        <div className="col-sm-10">
          <p className="fw-bolder">Teacher's Resources</p>
        </div>
        <div className="col-sm-2">
          <p
            className="fw-bolder"
            data-bs-toggle="modal"
            data-bs-target="#moduleContentUploadModal"
            style={{ cursor: "pointer" }}
          >
            <CloudUploadOutlinedIcon /> Upload
          </p>
        </div>
      </div>
      {/* Teacher Modules (Partitioned Upload Section) */}
      {fetchOwnModulesLoader ? (
        <div className="text-center" style={{ overflow: "hidden" }}>
          <CircularProgress style={{ color: "#FF075B", fontWeight: "600" }} />
        </div>
      ) : (
        <div className="row module my-5 mx-3 gy-4">
          {ownModules[0] ? (
            ownModules.map((module, index) => {
              let result = getModuleType(module.resources);
              return (
                <div
                  className="col-sm-2 cardBox"
                  key={index}
                  style={{
                    display:
                      result.notFound || fileType(result.fileType) === "Image"
                        ? "block"
                        : "block",
                  }}
                  onClick={() => {
                    Visited(module.name, module?.creator?._id);
                  }}
                >
                  {result.fileType !== "html" ? (
                    <>
                      <NavLink
                        to={{
                          pathname:
                            location.pathname +
                            `/${fixname(module.name)}/${actionType(
                              result.fileType
                            )}`,
                          useProps: !result.notFound
                            ? {
                                fileUrl: fixFileUrl(
                                  result.resource.fileUrl,
                                  result.fileType
                                ),
                                moduleName: result.resource.fileName,
                              }
                            : {
                                fileUrl: "",
                                fileName: "",
                              },
                        }}
                        style={{ textDecoration: "none" }}
                        className="text-center"
                        // style={ModuleStyle(fixFileUrl(module.thumbnail,module.name))}
                      >
                        <div className="card card-radius card-height">
                          <div className="card-img">
                            <img
                              src={image(
                                fixFileUrl(module.thumbnail, module.name)
                              )}
                              className="card-thumbnail"
                              alt={module.name || "chapter"}
                            />
                          </div>
                          <div
                            className="text-end text-light"
                            style={{
                              marginBottom: "auto",
                              position: "absolute",
                              right: 0,
                            }}
                          >
                            <li
                              className="nav-item dropdown"
                              style={{ listStyle: "none" }}
                            >
                              <IconButton
                                aria-label="settings"
                                data-bs-toggle="dropdown"
                                onClick={() => {
                                  toggleMenu(module._id);
                                }}
                              >
                                <MoreVertIcon style={{ color: "black" }} />
                              </IconButton>
                              <ul
                                className="dropdown-menu"
                                style={{
                                  display: "none",
                                  position: "absolute",
                                }}
                                id={module._id}
                              >
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={() =>
                                      moduleTaskTypeModalFunc(module._id)
                                    }
                                  >
                                    Assign Tasks
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={() => deleteOwnModule(module._id)}
                                  >
                                    Delete
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </div>
                          <div>
                            <p className="text-dark text-center">
                              {module.name}
                            </p>
                          </div>
                        </div>
                      </NavLink>
                      <ToggleSwitch />
                    </>
                  ) : (
                    <a
                      href={result.resource.fileUrl}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      className="text-center"
                      // style={ModuleStyle(
                      //   fixFileUrl(module.thumbnail, module.name)
                      // )}
                    >
                      <div className="card card-height card-radius">
                        <div className="card-img">
                          <img
                            src={image(
                              fixFileUrl(module.thumbnail, module.name)
                            )}
                            alt={module?.name || "module"}
                            className="card-thumbnail"
                          />
                        </div>
                        <div
                          className="text-end text-light"
                          style={{
                            marginBottom: "auto",
                            position: "absolute",
                            right: 0,
                          }}
                        >
                          <li
                            className="nav-item dropdown"
                            style={{ listStyle: "none" }}
                          >
                            <IconButton
                              aria-label="settings"
                              data-bs-toggle="dropdown"
                              onClick={() => {
                                toggleMenu(module._id);
                              }}
                            >
                              <MoreVertIcon style={{ color: "black" }} />
                            </IconButton>
                            <ul
                              className="dropdown-menu"
                              style={{ display: "none", position: "absolute" }}
                              id={module._id}
                            >
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={() =>
                                    moduleTaskTypeModalFunc(module._id)
                                  }
                                >
                                  Assign Task
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={() => deleteOwnModule(module._id)}
                                >
                                  Delete
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </div>
                        <div>
                          <p className="text-dark text-center">{module.name}</p>
                        </div>
                      </div>
                    </a>
                  )}
                </div>
              );
            })
          ) : (
            <h3 className="text-center">Upload modules</h3>
          )}
        </div>
      )}
    </>
  );
};

export default Module;
