import "./Module.css";

import DefaultIcon from "../icons/default-avatar.jpg";

function Module(props) {
  const getModuleType = (resources) => {
    var result = { notFound: true };
    const modulesTypes = [
      ["html"],
      ["mp4", "mkv", "m4v"],
      ["pptx", "ppt", "docx", "pdf"],
      ["mp3"],
      ["png", "jpg", "jpeg"],
    ];
    var move = 0,
      lastStep = modulesTypes.length - 1;
    while (result.notFound) {
      result = findMainModuleFile(resources, modulesTypes[move], result);
      if (move === lastStep) break;
      move += 1;
    }
    return result;
  };

  const findMainModuleFile = (resources, mainFileTypes, data) => {
    for (let resource of resources) {
      const nameParts = resource.fileName?.split(".");
      const fileType = nameParts[nameParts.length - 1].toLowerCase();
      if (mainFileTypes.includes(fileType)) {
        data["notFound"] = false;
        data["resource"] = resource;
        data["fileType"] = fileType;
        break;
      }
    }
    return data;
  };

  return (
    <div
      className="lp-module"
      style={props.style}
      onClick={() => props.selectModule && props.selectModule()}
    >
      {!props.editor && (
        <a
          href={`/modules/${props.resource.id._id}?type=${
            getModuleType(props.resource?.id?.resources || []).fileType ||
            "none"
          }`}
        ></a>
      )}
      <img
        src={
          props?.resource?.id?.thumbnail
            ? props?.resource?.id?.thumbnail
            : DefaultIcon
        }
        alt="name"
      />
      <div className="lp-module-content">
        <span className={`type ${props.checkbox ? "type-check" : ""}`}>
          {getModuleType(props.resource?.id?.resources || []).fileType || "-"}
        </span>
        <h5>{props.resource?.id?.name || ""}</h5>
        <p>{props.resource?.grade}</p>
        <p>{props.resource?.subject}</p>
        <p>{props.resource?.chapter}</p>
        <p>{props.resource?.topic}</p>
      </div>
      {props.checkbox && (
        <div
          className={`lp-module-checkbox ${
            props.selected && "lp-module-checkbox-active"
          }`}
        ></div>
      )}
    </div>
  );
}

export default Module;
