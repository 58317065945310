import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Select from "react-select";

import CircularProgress from "@material-ui/core/CircularProgress";

export default function TaskAssignModal({
  assignedClasses,
  grades,
  tasks,
  setTasks,
}) {
  const { register, reset, handleSubmit } = useForm();

  const [grade, setGrade] = useState({ grade: "", sections: [] });
  const [sections, setSections] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [modules, setModules] = useState([]);
  const [resourceData, setResourceData] = useState({
    fromDate: null,
    toDate: null,
  });

  const [addContentMessage, setAddContentMessage] = useState("");
  const [addContentLoader, setAddContentLoader] = useState(false);

  // resource data handle
  const handleResourceFromDateChange = (date) => {
    setResourceData({ ...resourceData, fromDate: date });
  };

  const handleResourceToDateChange = (date) => {
    setResourceData({ ...resourceData, toDate: date });
  };

  function getSections(event) {
    reset({
      sectionResource: "",
      subjectResource: "",
      chapterResource: "",
      topicResource: "",
    });
    setGrade({ name: "", sections: [] });
    setSubjects([]);
    setChapters([]);
    setTopics([]);
    const selectedGrade = event.target.value;
    if (!selectedGrade) return;
    const newSections = grades[selectedGrade];
    setGrade({ grade: selectedGrade, sections: newSections });
  }

  function selectSections(selectedOption) {
    const selectedSections = selectedOption.map((section) => section.value);
    setSections([...selectedSections]);
  }

  function getSubjects(sections) {
    reset({
      subjectResource: "",
      chapterResource: "",
      topicResource: "",
    });
    setSubjects([]);
    setChapters([]);
    setTopics([]);

    if (!sections) return;
    const tempArr = [];
    const selectedSections = sections
      .split(",")
      .map((section) => `${grade.grade}-${section}`);

    assignedClasses[0] &&
      assignedClasses[0].forEach((assignedClass) => {
        if (selectedSections.includes(assignedClass._id)) {
          assignedClass.subjects.forEach((data) => {
            tempArr.push({
              subject: data.subject,
              subjectId: data.subjectId,
            });
          });
        }
      });

    setSubjects([...tempArr]);
  }

  const getChapters = async (e) => {
    reset({
      chapterResource: "",
      topicResource: "",
    });
    setChapters([]);
    setTopics([]);

    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/subjects/${e.target.value}`,
        {
          headers: {
            token: authToken,
          },
        }
      );

      if (response.data.status === "success") {
        let dt = response.data.data.subject.chapters.filter(
          (item) => !item.disabled
        );
        setChapters([dt]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTopics = async (e) => {
    //if(!e.targe.value) return;
    reset({
      topicResource: "",
      moduleResource: "",
    });
    setTopics([]);
    setModules([]);

    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/chapters/${e.target.value}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        let dt = response.data.data.chapter.topics.filter(
          (item) => !item.disabled
        );
        setTopics([dt]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getModules = async (e) => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/modules?topicId=${e.target.value}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        let dt = response.data.data.modules;
        setModules([dt.filter((item) => !item.disabled)]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitResourceForm = async (data) => {
    setAddContentMessage("");
    setAddContentLoader(true);

    if (resourceData.fromDate !== null && resourceData.toDate !== null) {
      const {
        subjectResource,
        contentTypeResource,
        topicResource,
        moduleResource,
        taskTypeResource,
      } = data;
      const { fromDate, toDate } = resourceData;

      const authToken = localStorage.getItem("authToken");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_ORIGIN}/api/v1/tasks`,
          {
            subjectId: subjectResource,
            contentId: moduleResource,
            sections: sections.map((section) => `${grade.grade}-${section}`),
            topicId: topicResource,
            contentType: contentTypeResource || "module",
            taskType: taskTypeResource,
            startDate: fromDate,
            lastDate: toDate,
          },
          {
            headers: {
              token: authToken,
            },
          }
        );

        if (response.data.status === "success") {
          setAddContentMessage("Task Created.");
          reset();
          setTasks([response.data.data.task,...tasks]);
        }
      } catch (error) {
        if (error.response?.status === "fail") {
          setAddContentMessage(error.response.message);
        } else {
          setAddContentMessage("Try Again.");
        }
      }
    } else {
      setAddContentMessage("Please, Select Date.");
    }

    setAddContentLoader(false);
  };

  useEffect(() => {
    if (!sections.length) {
      setSubjects([]);
      return;
    }
    getSubjects(sections.join(","));
  }, [sections]);

  return (
    <Fragment>
      <div
        className="modal fade resourceModel mt-5"
        id="resourceModal"
        tabIndex="-1"
        aria-labelledby="resourceModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="resourceModalLabel">
                SELECT RESOURCES
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={handleSubmit(submitResourceForm)}>
              <div className="modal-body">
                <div className="row mb-3">
                  <div className="col-sm">
                    <label htmlFor="classSelect" className="form-label">
                      Class
                    </label>
                    <select
                      className="form-select"
                      id="classSelect"
                      {...register("gradeResource")}
                      onChange={getSections}
                    >
                      <option defaultValue="">Select Class</option>
                      {Object.keys(grades).length &&
                        Object.keys(grades).map((grade, index) => {
                          return (
                            <option key={index} value={grade}>
                              {grade}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-sm">
                    <label htmlFor="sectionSelect" className="form-label">
                      Sections
                    </label>
                    <Select
                      id="sectionSelect"
                      name="sectionResource"
                      {...register("sectionResource")}
                      options={grade.sections.map((section, index) => ({
                        value: section.section,
                        label: section.section,
                        key: index,
                      }))}
                      isMulti
                      onChange={selectSections}
                      placeholder="Select"
                    />
                  </div>
                  <div className="col-sm">
                    <label htmlFor="taskTypeSelect" className="form-label">
                      Task
                    </label>
                    <select
                      className="form-select"
                      id="taskTypeSelect"
                      {...register("taskTypeResource")}
                    >
                      <option defaultValue="">Select Task</option>
                      <option value="revision task">Revision Task</option>
                      <option value="flipped class">Flipped Class</option>
                      <option value="homework">Home Work</option>
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm">
                    <label htmlFor="subjectSelect" className="form-label">
                      Subject
                    </label>
                    <select
                      className="form-select"
                      id="subjectSelect"
                      {...register("subjectResource")}
                      onChange={getChapters}
                    >
                      <option defaultValue="" value={""}>
                        Select Subject
                      </option>
                      {subjects.map((subject, index) => (
                        <option key={index} value={subject.subjectId}>
                          {subject.subject}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm">
                    <label htmlFor="chapterSelect" className="form-label">
                      Chapter
                    </label>
                    <select
                      className="form-select"
                      id="chapterSelect"
                      {...register("chapterResource")}
                      onChange={getTopics}
                    >
                      <option defaultValue="" value="">
                        Select Chapter
                      </option>
                      {chapters[0] &&
                        chapters[0].map((chapter, index) => (
                          <option key={index} value={chapter._id}>
                            {chapter.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm">
                    <label htmlFor="topicSelect" className="form-label">
                      Topic
                    </label>
                    <select
                      className="form-select"
                      id="topicSelect"
                      {...register("topicResource")}
                      onChange={getModules}
                    >
                      <option defaultValue="">Select Topic</option>

                      {topics[0] &&
                        topics[0].map((topic, index) => (
                          <option key={index} value={topic._id}>
                            {topic.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-sm">
                    <label htmlFor="moduleSelect" className="form-label">
                      Module
                    </label>
                    <select
                      className="form-select"
                      id="moduleSelect"
                      {...register("moduleResource")}
                    >
                      <option defaultValue="">Select Module</option>
                      {modules[0] &&
                        modules[0].map((module, index) => (
                          <option key={index} value={module._id}>
                            {module.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm mb-3">
                    <label htmlFor="resourceFromDate">From</label>
                    <DatePicker
                      selected={resourceData.fromDate}
                      dateFormat="dd/MM/yyyy"
                      id="resourceFromDate"
                      className="form-control"
                      onChange={handleResourceFromDateChange}
                      placeholderText="From Date"
                    />
                  </div>
                  <div className="col-sm">
                    <label htmlFor="resourceToDate">To</label>
                    <DatePicker
                      className="form-control"
                      selected={resourceData.toDate}
                      dateFormat="dd/MM/yyyy"
                      id="resourceToDate"
                      onChange={handleResourceToDateChange}
                      placeholderText="To Date"
                    />
                  </div>
                </div>
              </div>

              {addContentMessage && (
                <div className="text-center">
                  <p style={{ color: "#FF075B", fontWeight: "600" }}>
                    {addContentMessage}
                  </p>
                </div>
              )}

              <div className="mb-3 modal_button_container">
                <div className="row">
                  <div className="col d-grid">
                    <button
                      type="button"
                      className="btn resourceModelBtnCancel"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <div className="col d-grid">
                    {addContentLoader ? (
                      <div className="text-center">
                        <CircularProgress
                          style={{ color: "#FF075B", fontWeight: "600" }}
                        />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn resourceModelBtnApply"
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
