import styled from "styled-components";

const StatBar = styled.div`
  padding: 5px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #c4c4c494;
  height: 30px !important;
`;

export default StatBar;
