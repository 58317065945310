import React from "react";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";
// import { ReactComponent as Loader } from "../../../LearningResources/Assement/Questions/Loader/Loader.svg";
import { ReactComponent as Loader } from "../../images/Loader.svg";

const useStyles = makeStyles((theme) => ({
  tableContainer: {},
  table: {
    minWidth: "auto",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 280,
  },
  tableRow: {
    textDecoration: "none",
    "& > td": {
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#5ce0d2 !important",
    },
  },
  tableHeading: {
    " & > th": {
      fontWeight: "bolder",
      fontSize: "1.02em",
    },
  },
}));

// const checkstatus = (test) => {
//   //When test has been submitted
//   if (
//     test.submissions &&
//     test.submissions.length &&
//     test.submissions[0].submissionStatus === "done"
//   ) {
//     return "Done";
//   }

//   //when test has not been submitted and the time to submit has passed
//   if (
//     (!test.submissions || !test.submissions.length) &&
//     test.to < new Date().toISOString()
//   ) {
//     return "Not Attempted";
//   }

//   //When test has started but has not been sbumitted yet
//   if (
//     test.to > new Date().toISOString() &&
//     test.from < new Date().toISOString()
//   ) {
//     return "In Progress";
//   }

//   //When test has not started yet
//   if (test.from > new Date().toISOString()) {
//     return "Assigned";
//   }
// };

const convertArrayToString = (array) => {
  if (!array) {
    return "";
  }
  array = array && array.map((item) => item.name);
  return array.join(", ");
};

const ReportCardTable = ({ rows, loading }) => {
  console.log(rows);
  const classes = useStyles();
  return (
    <div className={classes.tableContainer}>
      {!loading ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeading}>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Class</TableCell>
                <TableCell align="center">Questions</TableCell>
                <TableCell align="center">Correct</TableCell>
                <TableCell align="center">Incorrect</TableCell>
                <TableCell align="center">Unattempted</TableCell>
                <TableCell align="center">Score</TableCell>
                <TableCell align="center">Rank</TableCell>
                <TableCell align="center">Duration</TableCell>
                <TableCell align="center">Start Time</TableCell>
                <TableCell align="center">Submit Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length ? (
                rows.map((row, index) => (
                  <TableRow
                    key={`${row.subject}${index}`}
                    className={classes.tableRow}
                    style={{
                      backgroundColor: index % 2 ? "#5ce0d2" : "white",
                    }}
                  >
                    <TableCell scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell scope="row" align="center">
                      {row?.studentId?.name}
                    </TableCell>
                    <TableCell scope="row" align="center">
                      {`${row?.studentId?.grade}-${row?.studentId?.section}`}
                    </TableCell>
                    <TableCell scope="row" align="center">
                      {row.totalQuestions}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ wordBreak: "break-word", width: "17%" }}
                    >
                      {row.correctAnswers}/{row.totalQuestions}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ wordBreak: "break-word", width: "17%" }}
                    >
                      {row.wrongAnswers}/{row.totalQuestions}
                    </TableCell>
                    <TableCell align="center">
                      {row.unattemptedAnswers}/{row.totalQuestions}
                    </TableCell>
                    <TableCell align="center">
                      {row.totalScore}/{row.totalMarks}
                    </TableCell>
                    <TableCell align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">
                      {Math.floor((new Date(row.submittedAt) - new Date(row.startedAt)) / 3600000)}h{"\n"}
                      {Math.round(((new Date(row.submittedAt) - new Date(row.startedAt)) % 3600000) / 60000)}m
                    </TableCell>
                    <TableCell align="center">
                      {new Date(row.startedAt).toLocaleTimeString()}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(row.submittedAt).toLocaleTimeString()}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell scope="row" align="center">
                    -
                  </TableCell>
                  <TableCell scope="row" align="center">
                    -
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Loader />
        // "Hello"
      )}
    </div>
  );
};

export default ReportCardTable;
