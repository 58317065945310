import "./Grades.css";

import { useHistory } from "react-router-dom";

import GradeBG1 from "../icons/grade-bg-2.svg";
import GradeBG2 from "../icons/grade-bg-4.svg";
import GradeBG3 from "../icons/grade-bg-1.svg";
import GradeBG4 from "../icons/grade-bg-5.svg";
import GradeBG5 from "../icons/grade-bg-3.svg";
import GradeBG6 from "../icons/grade-bg-6.svg";

import Loader from "../../Loading/Loader";
import Grade from "./Grade";

const bgPalette = [
  { icon: GradeBG1, color: "#FFC8DD" },
  { icon: GradeBG2, color: "#EDBC8B" },
  { icon: GradeBG3, color: "#C8B6FF" },
  { icon: GradeBG4, color: "#588157" },
  { icon: GradeBG5, color: "#FF8FA3" },
  { icon: GradeBG6, color: "#9BF5EC" },
];

function Grades(props) {
  const history = useHistory();
  return (
    <div className="lesson-plan-grades">
      {props.loading ? (
        <Loader />
      ) : (
        props.grades.map((grade, index) => {
          return (
            <Grade
              grade={grade._id}
              setGrade={() => {
                history.replace(`/lessonplans?grade=${grade._id}`);
              }}
              palette={bgPalette[5]}
              key={index}
            />
          );
        })
      )}
    </div>
  );
}

export default Grades;
