import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ShareIcon from "@material-ui/icons/Share";
import BuildIcon from "@material-ui/icons/Build";
import { useAlert } from "react-alert";
import ShareModal from "./ShareModal";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import { CircularProgress } from "@material-ui/core";
function MyClass({ select, setSelect, setClassCode }) {
  const { userDetails } = useContext(GlobalContext);
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    // cssEase:"linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const alert = useAlert();
  const [assignedClasses, setAssignedClasses] = useState([]);
  const [checkStatus, setCheckStatus] = useState(false);
  const [share, setShare] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(true);
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${userDetails.userId}/classcodes`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          setFetchLoader(false);
          setAssignedClasses(response.data.data.grades.sections);
          setClassCode(response.data.data.grades.sections[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    console.log("authtoken", authToken);
    fetchClasses();
  }, [checkStatus]);

  console.log("updated", assignedClasses[select]?.sectionId.classCode);
  const generateClassCode = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${userDetails.userId}/sections/${assignedClasses[select]?.sectionId._id}/classcodes`,
        null,
        {
          headers: {
            // 'content-type': 'multipart/form-data',
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        console.log(response.data.data.gradeSection);
        assignedClasses[select].sectionId.classCode =
          response.data.data.gradeSection.classCode;
        setAssignedClasses(assignedClasses);
        alert.success("Code Generated");

        console.log("new code", assignedClasses[select]?.sectionId.classCode);
      }
    } catch (error) {
      console.log(error);
      alert.error("Failed To Generate Code");
    }
    setCheckStatus(!checkStatus);
  };
  return (
    <>
      <div className="myclasses">
        {fetchLoader ? (
          <div className="text-center" style={{ paddingTop: "25px" }}>
            <CircularProgress
              style={{
                color: "#FF075B",
                fontWeight: "600",
              }}
            />
          </div>
        ) : (
          <Slider {...settings}>
            {assignedClasses.map((x, i) => (
              <div
                key={i}
                id={`class ${i}`}
                className={`${
                  i === select ? "myclass_info_sel" : ""
                } myclasses_info`}
                onClick={(e) => {
                  setSelect(i);
                  setClassCode(assignedClasses[i]);
                }}
              >
                <span className="myclasses_name">
                  {x?.grade} {x?.section}
                </span>
              </div>
            ))}
          </Slider>
        )}
        {/* <span className="myclasses_present" id={i} style={selectedClass?{color:"#ffffff !important"}:null}>55/100</span> */}
      </div>
      {select !== null && (
        <div className="class_code">
          <span className="code">
            {assignedClasses[select]?.sectionId.classCode}
          </span>
          <FileCopyIcon
            className="code_icon"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://auth.2xcell.in/signup/code/${assignedClasses[select]?.sectionId.classCode}`
              );
              alert.success("Copied!!!");
            }}
          />
          <ShareIcon
            className="code_icon"
            onClick={() => {
              setShare(!share);
            }}
          />
          <BuildIcon className="code_icon" onClick={generateClassCode} />
        </div>
      )}
      {share && (
        <ShareModal
          url={`https://auth.2xcell.in/signup/code/${assignedClasses[select]?.sectionId.classCode}`}
        />
      )}
    </>
  );
}

export default MyClass;
