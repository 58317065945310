export default class QuestionParser {
  constructor(rawquestion) {
    this.row = 0;
    this.rawQuestion = rawquestion;
    this.options = [];
    this.questionType = "";
    this.questionCategory = "";
    this.correctAnswers = {};
    this.images = {};
    this.messages = {
      ERR404ANS: () =>
        `Error on row ${this.row}, Answer or Option should not be empty, please provide option or correct answer.`,
      ERR404ROW: () => "Please provide row of current question",
      ERR404OPT: ({ type, len }) =>
        `Error on row ${this.row}, ${type} options should not be less than ${len}`,
    };
  }

  throwError(code, data) {
    throw { message: this.messages[code](data) };
  }

  setQuestionCategory() {
    const isSelectType = ["mmcq", "mcq", "correctstatement"].includes(
      this.questionType
    );
    this.questionCategory = isSelectType
      ? "selectiveQuestion"
      : "inputQuestion";
  }

  //Parse options for different type of questions
  /**
   * @selectTypeQuestion correctStatement, mcq, mmcq
   * @inputTypeQuestion oneword, fillup
   * **/
  parseSelectTypeOptions(question) {
    ["Option 1", "Option 2", "Option 3", "Option 4"].forEach(
      (option, index) => {
        const optionValue = question[option];
        if (!optionValue && optionValue != false) {
          console.log("line 35--->", optionValue);
          return;
        }
        //if (!optionValue) this.throwError("ERR404ANS", {});
        this.options.push({
          optionId: `op${index + 1}`,
          value: optionValue,
        });
      }
    );
    console.log(this.options.length);
  }

  parseCorrectAnswers(question) {
    const questionType = this.questionType;
    var correctAnswer = question["Correct Answers"];
    if (!correctAnswer) this.throwError("ERR404ANS", {});
    //correctAnswer = correctAnswer;
    console.log("215", correctAnswer)
    if (["mcq", "correctstatement"].includes(questionType)) {
      this.correctAnswers[questionType] = `op${correctAnswer.toString().trimStart().trimEnd()}`;
      return;
    }
    if (["mmcq"].includes(questionType)) {
      this.correctAnswers[questionType] = correctAnswer
        .split(",")
        .map((answer) => `op${answer.toString().trimStart().trimEnd()}`);
      return;
    }
    //parse both options and correctAnswers
    if (["oneword", "fillup"].includes(questionType)) {
      this.correctAnswers[questionType] = correctAnswer
        .split(",")
        .map((answer, index) => {
          this.options.push({
            optionId: `op${index + 1}`,
          });
          return {
            blankId: `op${index + 1}`,
            answer: answer.toString().trimStart().trimEnd(),
          };
        });
    }
  }

  convertLinkFromShareToView = (link) => {
    if (!link.startsWith("https://drive.google.com")) return link;
    if (link.startsWith("https://drive.google.com/uc?id")) return link;
    return `https://drive.google.com/uc?id=${link.split('/')[5]}`;
  }

  parseImages(question) {
    [
      "Question Image",
      "Option 1 Image",
      "Option 2 Image",
      "Option 3 Image",
      "Option 4 Image",
    ].forEach((image, index) => {
      const prop =
        image === "Question Image" ? "questionImg" : `op${image.split(' ')[1]}`;
      if (!question[image]) return;
      this.images[prop] = this.convertLinkFromShareToView(question[image]);
    })
  }

  //initiate parsing
  parseQuestion(row) {
    console.log("In new parser");
    if (isNaN(row) || row < 0) this.throwError("ERR404ROW");
    this.row = row;
    //Lowercase question Type, difficulty and cognitive in lower case
    ["Question Type", "Difficulty", "Cognitive"].map((field) => {
      const value = this.rawQuestion[field];
      if (!value) return;
      this.rawQuestion[field] = value.toLowerCase();
    });
    this.questionType =
      this.rawQuestion["Question Type"] === "truefalse"
        ? "correctstatement"
        : this.rawQuestion["Question Type"];
    this.setQuestionCategory();
    //Parse options, correctAnswers, images
    switch (this.questionCategory) {
      case "selectiveQuestion":
        this.parseSelectTypeOptions(this.rawQuestion);
        this.parseCorrectAnswers(this.rawQuestion);
        break;
      case "inputQuestion":
        this.parseCorrectAnswers(this.rawQuestion);
        break;
    }
    this.parseImages(this.rawQuestion);
    //validate options before saving value
    if (["correctstatement"].includes(this.questionType)) {
      const optionCount = {
        mmcq: 4,
        mcq: 4,
        correctstatement: 2,
        oneword: 1,
      }[this.questionType];
      if (this.options.length !== optionCount)
        this.throwError("ERR404OPT", {
          type: this.questionType,
          len: optionCount,
        });
    }
    //return new question object
    return {
      question: this.rawQuestion["Question"],
      questionType: this.questionType,
      options: this.options,
      correctAnswers: this.correctAnswers,
      difficulty: this.rawQuestion["Difficulty"],
      cognitive: this.rawQuestion["Cognitive"],
      images: this.images,
      visibiltyStatus: this.rawQuestion["Visibility"],
    };
  }
}