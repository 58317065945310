import "./practiseAssessment.css";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { CSVLink } from "react-csv";
import { useMediaQuery } from "react-responsive";
import Moment from "react-moment";
import { Button, Modal } from "react-bootstrap";
import { useAlert } from "react-alert";
import readXlsxFile from "read-excel-file";

import { GlobalContext } from "../../context/GlobalState";

import CircularProgress from "@material-ui/core/CircularProgress";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CloseIcon from "@material-ui/icons/Close";

import moduleImg from "../../images/module.svg";
import assessmentImg from "../../images/assessment.svg";
import authorQuesImg from "../../images/authorQues.svg";
import uploadQuesImg from "../../images/uploadQues.svg";
import analyticGraphImg from "../../images/analyticGraph.svg";
import HomeIcon from "../../images/HomeIcon.svg";
import assignImg from "../../images/assign.svg";

import LeftArrow from "../../images/LeftArrow";
import RightArrow from "../../images/RightArrow";
import DropDownShutter from "../DropDown/DropDown";
import AuthorQues from "../AuthorQues/AuthorQues";
import TestQuestion from "../Questions/TestQuestions/Question";

import sample from "../../utils/sample.xlsx";
import QuestionParser from "./questionParser";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#edfcfa",
    boxShadow: "none",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const PractiseAssessment = () => {
  let upindex = "";
  const { userDetails, setUserDetails } = useContext(GlobalContext);

  let [page, setPage] = useState(1);
  let [arrQues, setArrQues] = useState(null);
  const [addAuthorQues, setAddAuthorQues] = useState([1]);
  const moduleId = localStorage.getItem("moduleId");
  const authToken = localStorage.getItem("authToken");
  const [questions, setQuestions] = useState([]);
  let [questId, setQuestId] = useState("");
  let [selQuest, setSelQuest] = useState(null);
  const [fetchQuestionLoader, setFetchQuestionLoader] = useState(true);
  const [prevUpload, setPrevUpload] = useState("");
  const alert = useAlert();
  const filterDifficulty = {
    easy: [],
    intermediate: [],
    hard: [],
  };
  const filterCognitive = {
    knowledge: [],
    understanding: [],
    application: [],
    analysis: [],
  };
  const [topicsSel, setTopicsSel] = useState([]);
  const [newQuestions, setNewQuestions] = useState([]);
  const [bulkQuestion, setBulkQuestion] = useState([]);
  const [bulkQuestiontMessage, setBulkQuestiontMessage] = useState("");
  const [bulkQuestiontLoader, setBulkQuestiontLoader] = useState(false);
  let location = useLocation();
  const myassessment = location.pathname.split("/");
  const [authorQues, setAuthorQues] = useState(false);
  const [togglePaper, setTogglePaper] = useState(true);
  const [difficulty, setDifficulty] = useState("Difficulty");
  const [cognitive, setCognitive] = useState("Cognitive");

  const handleSelect1 = (e) => {
    setDifficulty(e);
  };
  const handleSelect2 = (e) => {
    setCognitive(e);
  };
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(
    myassessment[1] !== "myassessment" ? 1 : 2
  );
  const [heading, setHeading] = useState("Assessment");
  const [age, setAge] = React.useState("");
  const [fileName, setFileName] = useState("");
  const subjectId =
    myassessment[1] != "myassessment"
      ? localStorage.getItem("subjectId")
      : location.state.subjectId;
  const chapterId =
    myassessment[1] != "myassessment"
      ? localStorage.getItem("chapterId")
      : location.state.chapterId;
  const topicId =
    myassessment[1] != "myassessment"
      ? localStorage.getItem("topicId")
      : location.state.topicId;
  const sectionId =
    myassessment[1] != "myassessment"
      ? localStorage.getItem("sectionIdTask")
      : location.state.sectionId;
  const [questionsCount, setQuestionsCount] = useState(0);
  const [errorLogs, setErrorLogs] = useState("");
 
  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const changeHeading = (val) => {
    setUserDetails({ ...userDetails, selectedTab: val, pathName: {} });
    history.goBack();
    setHeading(val);
  };
  const Questionquery = (chapterId, topicId) => {
  
    if (topicId !== "")
      return `topicId[in]=${
        typeof topicId === "string" ? topicId : topicId.join(",")
      }&`;
    if (chapterId !== "" && myassessment[1] === "myassessment")
      return `chapterId=${chapterId}&`;
    return "";
  };
  const handleRemoveClickCard = (index) => {
    const list = [...addAuthorQues];
    list.splice(index, 1);
    setAddAuthorQues(list);
  };

  let selectedClass = localStorage.getItem("selectedClass");
  const practiceAssessment = location.state.practiceAssessment;
  let subjectName = location.state.subjectName;
  let chapterName = location.state.chapterName;
  let topicName = location.state.topicName;
  if (myassessment[1] === "myassessment") {
    subjectName = practiceAssessment.subject;
    chapterName = practiceAssessment.chapter;
    topicName = practiceAssessment.topic;
  }
  const [topic, setTopic] = useState({ name: "Topic", id: "" });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setShow1(false);
    setTimeout(window.location.reload(), 2000);
  };
  const handleShow1 = () => {
    setShow1(true);
    setShow(false);
  };
  const [selectedFile, setSelectedFile] = useState("");

  const getQuestions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${
          userDetails.userId
        }/sections/${sectionId}/subjects/${subjectId}/questions?${Questionquery(
          chapterId,
          topicId
        )}page=${page}&limit=5`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setQuestions(response.data.data.questions);
      }
    } catch (error) {
      console.log(error);
    }

    // setFetchTopicLoader(false);
    setFetchQuestionLoader(false);
  };

  const countQuestion = async () => {
  
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${userDetails.userId}/sections/${sectionId}/subjects/${subjectId}/questions/count?topicId[in]=${topicId}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      
      if (response.data.status === "success") {
        setQuestionsCount(response.data.data.totalCounts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuestions();
    countQuestion();
    if (questId != "" && selQuest != null) {
      upindex = questions.findIndex((question) => question._id === questId);
      questions[upindex] = selQuest;
    }
  }, [selQuest, arrQues]);
  useEffect(() => {
    if (questions.length > 0) {
      setUserDetails({ ...userDetails, questions: questions });
    }
  }, [questions]);
  useEffect(() => {
    if (myassessment[1] === "myassessment") {
      if (topicId?.length != 0) {
        topicId.forEach((x, i) => {
          setTopicsSel((arr) => [
            ...arr,
            { name: practiceAssessment.topic[i], id: x },
          ]);
        });
      }
    }
  
  }, [topicId, practiceAssessment?.topic]);
 
  const deleteQuestion = async (id) => {

    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/questions/${id}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        alert.success("Question Deleted");
        const newQuestion = questions.filter((ques) => ques._id !== id);
        setQuestions(newQuestion);
      }
    } catch (error) {
      if (error.response.data.status === "fail") {
        alert.error("Try Again.");
      } else {
        alert.error("Try Again.");
      }
    }

  };
  const excelTOJson = async (e) => {
    setFileName(e.target.files[0].name);
    setSelectedFile(e.target.files[0]);
    if (selectedFile) {
      const type = selectedFile.name.split(".")[1];
      if (type.toLowerCase() !== "xlsx") {
        e.target.value = null;
        return;
      }
    }
  };
  const removeEmptyFields = (obj) => {
    for (let ob in obj) {
      if (["", null].includes(obj[ob])) {
        delete obj[ob];
      }
    }
    return obj;
  };
  const handleSubmit = async () => {
    setFileName("");
    setBulkQuestiontLoader(true);
    await readXlsxFile(selectedFile).then(async (rows) => {
      let headers = rows[0];
      rows.forEach(async (row, index) => {
        if (index != 0) {
          let newObj = {};
          for (let i in headers) {
            newObj[headers[i]] = row[i];
          }
          try {
            const question = new QuestionParser(newObj).parseQuestion(index);
  
            newQuestions.push(question);
          } catch (err) {
            console.log(err);
          }
          setNewQuestions(newQuestions);
        }
      });
      try {
        const queryTopic = topic.id ? topic?.id : topicId;
        const response = await axios.post(
          `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${userDetails.userId}/sections/${sectionId}/subjects/${subjectId}/questions/bulk`,
          {
            ...removeEmptyFields({
              // topicId: topicId,
              topicId: queryTopic,
              chapterId: chapterId,
              moduleId: moduleId,
            }),
            questions: newQuestions,
          },
          {
            headers: {
              // 'content-type': 'multipart/form-data',
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          // setArrQues(newQuestions)`Question Skipped : ${response.data.errors.skipped}, Question Inserted
          //  : ${response.data.errors.inserted}, Total Question : ${response.data.errors.totalQuestions}`
          setBulkQuestiontMessage("File uploaded successfully");

          setNewQuestions([]);
          setErrorLogs(response.data.errors.errorLogs);
          handleShow1();
        }
      } catch (error) {
        setBulkQuestiontMessage(error.message);
      }
      setBulkQuestiontLoader(false);
    });
  };
  const handleFilterDifficulty = () => {
    questions.forEach((question) => {
      if (question.difficulty === "easy") {
        filterDifficulty["easy"].push(question);
      }
      if (question.difficulty === "intermediate") {
        filterDifficulty["intermediate"].push(question);
      }
      if (question.difficulty === "hard") {
        filterDifficulty["hard"].push(question);
      }
    });
  };
  const handleFilterCognitive = () => {
    questions.forEach((question) => {
      if (question.cognitive === "knowledge") {
        filterCognitive["knowledge"].push(question);
      }
      if (question.cognitive === "understanding") {
        filterCognitive["understanding"].push(question);
      }
      if (question.cognitive === "application") {
        filterCognitive["application"].push(question);
      }
      if (question.cognitive === "analysis") {
        filterCognitive["analysis"].push(question);
      }
    });
  };
  handleFilterDifficulty();
  handleFilterCognitive();
  const itemsToShow = questions
    .filter((item) => {
      if (
        difficulty === "easy" ||
        difficulty === "hard" ||
        difficulty === "intermediate"
      ) {
        return item.difficulty === difficulty;
      }
      return true;
    })
    .filter((item) => {
      if (
        cognitive === "knowledge" ||
        cognitive === "understanding" ||
        cognitive === "application" ||
        cognitive === "analysis"
      ) {
        return item.cognitive === cognitive;
      }
      return true;
    });
  const initial = addAuthorQues.length !== 1;

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 320) {
      setVisible(true);
    } else if (scrolled <= 320) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });
  const [dropdown, setdropdown] = useState(isSmallScreen ? false : true);
  const handleShutterOpen = () => {
    setdropdown(!dropdown);
  };
  
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        className="upload_modal"
      >
        <Modal.Body style={{ justifyContent: "center" }}>
          <div className="upload_ques_modal">
            <div className="upload_ques_modal__top">
              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 15C2.79086 15 1 13.2091 1 11C1 9.09295 2.33457 7.4976 4.12071 7.09695C4.04169 6.74395 4 6.37684 4 6C4 3.23858 6.23858 1 9 1C11.4193 1 13.4373 2.71825 13.9002 5.00098C13.9334 5.00033 13.9666 5 14 5C16.7614 5 19 7.23858 19 10C19 12.419 17.2822 14.4367 15 14.9M13 12L10 9M10 9L7 12M10 9L10 21"
                  stroke="#111827"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>UPLOAD QUESTIONS</span>
              <Button className="export_btn" href={sample} download="format">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 15C-2.41411e-08 14.4477 0.447715 14 1 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H1C0.447715 16 2.41411e-08 15.5523 0 15ZM3.29289 7.29289C3.68342 6.90237 4.31658 6.90237 4.70711 7.29289L6 8.58579L6 1C6 0.447716 6.44771 2.41411e-08 7 0C7.55228 -2.41411e-08 8 0.447715 8 1L8 8.58579L9.29289 7.29289C9.68342 6.90237 10.3166 6.90237 10.7071 7.29289C11.0976 7.68342 11.0976 8.31658 10.7071 8.70711L7.70711 11.7071C7.51957 11.8946 7.26522 12 7 12C6.73478 12 6.48043 11.8946 6.29289 11.7071L3.29289 8.70711C2.90237 8.31658 2.90237 7.68342 3.29289 7.29289Z"
                    fill="white"
                  />
                </svg>
                <span>Export File</span>
              </Button>
            </div>
            <div className="upload_ques_modal__mid">
              <span style={{ fontSize: "16px" }}>File</span>
              <div className="file_input">
                <input
                  type="file"
                  id="inputBulk"
                  hidden
                  accept=".xls,.xlsx"
                  onChange={excelTOJson}
                  disabled={bulkQuestiontLoader ? true : false}
                />
                <label for="inputBulk" className="file_input_btn">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 11H11M8 8L8 14M13 19H3C1.89543 19 1 18.1046 1 17V3C1 1.89543 1.89543 1 3 1H8.58579C8.851 1 9.10536 1.10536 9.29289 1.29289L14.7071 6.70711C14.8946 6.89464 15 7.149 15 7.41421V17C15 18.1046 14.1046 19 13 19Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Choose File
                </label>
                <span id="file-chosen">
                  {fileName ? fileName : "No file chosen"}
                </span>
              </div>
              <span className="upload_status">
                upload <span style={{ color: "#0F93A8" }}>.xlsx file</span>
              </span>
              {topicsSel.length > 1 && (
                <Dropdown className="option-dropdown mb-3">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="btn-question-type"
                    // disabled={(visible && visible != "Visible") ? false : true}
                  >
                    {topic.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="pra-dropdown edit-dropdown">
                    {topicsSel?.map((x) => (
                      <Dropdown.Item
                        onSelect={() => {
                          setTopic({
                            ...topic,
                            ["name"]: x.name,
                            ["id"]: x.id,
                          });
                        }}
                        eventKey={x.name}
                        className="menu_option pra-drop-list edit_option"
                      >
                        {x.name}
                        <div>
                          <input type="radio" name="radio" />
                          <span></span>
                        </div>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {bulkQuestiontLoader ? (
                <>
                  <div className="text-center">
                    <CircularProgress
                      style={{ color: "#FF075B", fontWeight: "600" }}
                    />
                  </div>
                </>
              ) : null}
            </div>
            <div className="upload_ques_modal__bottom">
              <button className="btn modal_cancel" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="btn modal_upload"
                id="upload_bulkQues"
                onClick={
                  selectedFile
                    ? handleSubmit
                    : () => alert.error("Please Select a file")
                }
                // onClick={handleShow1}
              >
                Upload
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* success modal */}
      <Modal
        show={show1}
        onHide={handleClose1}
        animation={false}
        centered
        className="upload_modal"
      >
        <Modal.Body>
          <div className="upload_done_modal">
            <div className="upload_done_modal__top">
              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 15C2.79086 15 1 13.2091 1 11C1 9.09295 2.33457 7.4976 4.12071 7.09695C4.04169 6.74395 4 6.37684 4 6C4 3.23858 6.23858 1 9 1C11.4193 1 13.4373 2.71825 13.9002 5.00098C13.9334 5.00033 13.9666 5 14 5C16.7614 5 19 7.23858 19 10C19 12.419 17.2822 14.4367 15 14.9M13 12L10 9M10 9L7 12M10 9L10 21"
                  stroke="#111827"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>UPLOAD QUESTIONS</span>
              <CloseIcon onClick={handleClose1} />
            </div>
            <div className="upload_done_modal__mid">
              <span style={{ fontSize: "16px" }}>{bulkQuestiontMessage}</span>
              <svg
                width="70"
                height="70"
                viewBox="0 0 70 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70ZM51.2186 29.3436C52.9271 27.635 52.9271 24.865 51.2186 23.1564C49.51 21.4479 46.74 21.4479 45.0314 23.1564L30.625 37.5628L24.9686 31.9064C23.26 30.1979 20.49 30.1979 18.7814 31.9064C17.0729 33.615 17.0729 36.385 18.7814 38.0936L27.5314 46.8436C29.24 48.5521 32.01 48.5521 33.7186 46.8436L51.2186 29.3436Z"
                  fill="#5CDA8E"
                />
              </svg>
            </div>
            <div className="upload_done_modal__bottom">
              <button className="btn modal_done" onClick={handleClose1}>
                Done
              </button>
            </div>
            {errorLogs.length > 0 ? (
              <CSVLink
                data={errorLogs}
                style={{ color: "red", textAlign: "center" }}
                className="errorLogs"
              >
                Download Error Logs
              </CSVLink>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
      <section className={isSmallScreen ? "modules my-4" : "modules my-5"}>
        <div
          className={
            isSmallScreen ? "container-fluid dashboard_container" : "container"
          }
        >
          <div className="box">
            <div
              className="upperBox"
              style={{ display: isSmallScreen ? "none" : "" }}
            >
              <div className="alignItem">
                <div className="row">
                  <div className="boxRow">
                    <div className="pathDetail">
                      <h1 className="className">
                        {myassessment[1] === "myassessment"
                          ? `${practiceAssessment["class"]}-${practiceAssessment["section"]}`
                          : selectedClass}
                      </h1>
                      <h2 style={{ fontWeight: "700" }}>Practice Session</h2>
                      <p style={{ fontWeight: "600", color: "#ffffff" }}>
                        {subjectName} &gt; {chapterName} &gt; {topicName} &gt;
                        {heading}
                      </p>
                    </div>
                    <div className="DateBox">
                      <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                        <CalendarTodayIcon />{" "}
                        {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
                      </h5>
                    </div>
                  </div>
                </div>
                {/* <div className='col-sm-2 mt-4'>
                    <Link
                      to='/createtest'
                      className=' fw-bolder createTest'
                      style={{ textDecoration: 'none' }}
                    >
                      <AddBoxOutlinedIcon /> Create Assessment
                    </Link>
                    
                    <p
                      className='fw-bolder my-3'
                      data-bs-toggle='modal'
                      data-bs-target='#assesmentContentUploadModal'
                      style={{ cursor: 'pointer' }}
                    >
                      <CloudUploadOutlinedIcon /> Upload Assessment
                    </p>
                  </div> */}
              </div>
            </div>
            {myassessment[1] !== "myassessment" ? (
              <Paper className={classes.root}>
                <Tabs
                  value={value}
                  style={{
                    background: "#DCFFFB",
                    // marginTop: "-12px",
                    display: dropdown ? "" : "none",
                  }}
                  onChange={handleChange}
                  className="ps"
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={"on"}
                >
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={moduleImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Modules"
                    {...a11yProps(0)}
                    onClick={() => changeHeading("Modules")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assessmentImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assessment"
                    {...a11yProps(1)}
                    onClick={() => changeHeading("Assessment")}
                  />
                </Tabs>
              </Paper>
            ) : (
              <Paper className={classes.root}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  style={{
                    background: "#DCFFFB",
                    marginTop: "-12px",
                    display: dropdown ? "" : "none",
                  }}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={"on"}
                >
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={HomeIcon}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Home"
                    {...a11yProps(0)}
                    onClick={() => changeHeading("Dashboard")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={analyticGraphImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Analytics"
                    {...a11yProps(1)}
                    onClick={() => changeHeading("Analytics")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assignImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assign Task"
                    {...a11yProps(2)}
                    onClick={() => changeHeading("Assign Task")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assessmentImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assessment"
                    {...a11yProps(3)}
                    onClick={() => changeHeading("Assessment")}
                  />
                </Tabs>
              </Paper>
            )}
            {
              <Paper
                className={classes.root}
                style={{
                  background: "#DCFFFB",
                  display: dropdown ? "" : "none",
                }}
              >
                <div className="assesment_header">
                  <div className="assesment_header__left">
                    <button
                      onClick={() => setAuthorQues(!authorQues)}
                      className="authorQuesButton"
                    >
                      <img src={authorQuesImg} />
                      <span>Author Questions</span>
                    </button>
                    <button className="uploadQues" onClick={handleShow}>
                      <img src={uploadQuesImg} />
                      <span>Upload Questions</span>
                    </button>
                    {/* <button className="analytics">
                      <img src={analyticsImg} />
                      <span>Analytics</span>
                  </button> */}
                  </div>
                  <div className="assesment_header__right">
                    <div className="marks">
                      Q <br />
                      {questionsCount}
                    </div>
                    <div className="selector">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {difficulty}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onSelect={handleSelect1}
                            eventKey="Difficulty"
                          >
                            Difficulty<span>{questions.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onSelect={handleSelect1}
                            eventKey="easy"
                          >
                            Easy<span>{filterDifficulty.easy.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onSelect={handleSelect1}
                            eventKey="intermediate"
                          >
                            Intermediate{" "}
                            <span>{filterDifficulty.intermediate.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onSelect={handleSelect1}
                            eventKey="hard"
                          >
                            Hard{" "}
                            <span>{filterDifficulty.hard.length}</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {cognitive}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="drop-menu">
                          <Dropdown.Item
                            className="drop-menu-list"
                            onSelect={handleSelect2}
                            eventKey="Cognitive"
                          >
                            Cognitive<span>{questions.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="drop-menu-list"
                            onSelect={handleSelect2}
                            eventKey="knowledge"
                          >
                            knowledge{" "}
                            <span>{filterCognitive.knowledge.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="drop-menu-list"
                            onSelect={handleSelect2}
                            eventKey="understanding"
                          >
                            understanding{" "}
                            <span>{filterCognitive.understanding.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="drop-menu-list"
                            onSelect={handleSelect2}
                            eventKey="application"
                          >
                            application{" "}
                            <span>{filterCognitive.application.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="drop-menu-list"
                            onSelect={handleSelect2}
                            eventKey="analysis"
                          >
                            analysis{" "}
                            <span>{filterCognitive.analysis.length}</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Paper>
            }

            <div
              className="shutter_PS"
              style={{ marginTop: dropdown ? "0px" : "-15px" }}
            >
              <DropDownShutter
                onClick={handleShutterOpen}
                clicked={dropdown}
                dropdown={dropdown}
                setDropDown={setdropdown}
              />
            </div>
          </div>
        </div>
      </section>
      {authorQues && (
        <>
          {addAuthorQues.map((x, index) => (
            <>
              <AuthorQues
                selSubjectId={subjectId}
                selChapterId={chapterId}
                selTopicId={topicId}
                selSectionId={sectionId}
                setAuthorQues={setAuthorQues}
                handleRemoveClickCard={handleRemoveClickCard}
                index={index}
                initial={initial}
                setArrQues={setArrQues}
                topicsSel={topicsSel}
              />
              <div className="submit_author_question">
                <button
                  type="button"
                  className="btn btn-add-question"
                  onClick={() =>
                    setAddAuthorQues((arr) => [...arr, addAuthorQues.length])
                  }
                >
                  <svg
                    className="MuiSvgIcon-root ico-block"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                  </svg>{" "}
                  Add Question
                </button>
              </div>
            </>
          ))}
        </>
      )}

      {fetchQuestionLoader ? (
        <div className="text-center">
          <CircularProgress style={{ color: "#FF075B", fontWeight: "600" }} />
        </div>
      ) : (
        <div
          className="ListQues"
          style={{
            display: authorQues ? "none" : "block",
            marginTop: isSmallScreen ? "0rem" : "-6rem",
          }}
        >
          <div className="container slider">
            <LeftArrow
              className="slider-img"
              // onClick={(e) => handleSlide(e, true)}
              style={
                page === 1 || itemsToShow.length === 0
                  ? { visibility: "hidden" }
                  : { display: "" }
              }
              onClick={async () => {
                setFetchQuestionLoader(true);
                if (page <= 1) {
                  setFetchQuestionLoader(false);
                  return;
                }
                page = page - 1;
                setPage(page);
                await getQuestions();
                setFetchQuestionLoader(false);
              }}
              color="#5ae893"
            />
            <div className="pageNumber">
              {page}/
              {Math.ceil(questionsCount / 5) === 0
                ? 1
                : Math.ceil(questionsCount / 5)}
            </div>
            <RightArrow
              className="slider-img floatright"
              // onClick={(e) => handleSlide(e)}
              style={
                itemsToShow.length === 0 ||
                page >= Math.ceil(questionsCount / 5)
                  ? { visibility: "hidden" }
                  : { display: "block" }
              }
              onClick={async () => {
                if (page >= Math.ceil(questionsCount / 5)) {
                  return;
                }
                setFetchQuestionLoader(true);
                page = page + 1;
                setPage(page);
                await getQuestions();
                setFetchQuestionLoader(false);
              }}
              color="#5ae893"
            />
          </div>

          {(userDetails?.filterQuestions
            ? userDetails?.filterQuestions
            : itemsToShow
          ).map((question, i) => (
            <TestQuestion
              isPraticeSession
              question={question}
              index={i + 1}
              deleteQuestion={deleteQuestion}
              setQuestId={setQuestId}
              setSelQuest={setSelQuest}
              isMarks={false}
              page={page}
            />
          ))}
          {fetchQuestionLoader ? (
            <div className="text-center">
              <CircularProgress
                style={{ color: "#FF075B", fontWeight: "600" }}
              />
            </div>
          ) : (
            <div
              className="container slider"
              style={
                itemsToShow.length === 0
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <LeftArrow
                className="slider-img"
                style={
                  page === 1 || itemsToShow.length === 0
                    ? { display: "none" }
                    : { display: "" }
                }
                // onClick={(e) => handleSlide(e, true)}
                onClick={async () => {
                  setFetchQuestionLoader(true);
                  if (page <= 1) {
                    setFetchQuestionLoader(false);
                    return;
                  }
                  page = page - 1;
                  setPage(page);
                  await getQuestions();
                  setFetchQuestionLoader(false);
                }}
                color="#5ae893"
              />
              <RightArrow
                className="slider-img"
                // onClick={(e) => handleSlide(e)}
                style={
                  itemsToShow.length === 0 ||
                  page >= Math.ceil(questionsCount / 5)
                    ? { display: "none" }
                    : { display: "block", float: "right" }
                }
                onClick={async () => {
                  if (page >= Math.ceil(questionsCount / 5)) {
                    return;
                  }
                  setFetchQuestionLoader(true);
                  page = page + 1;
                  setPage(page);
                  await getQuestions();
                  setFetchQuestionLoader(false);
                }}
                color="#5ae893"
              />
            </div>
          )}
        </div>
      )}
      <div className="scroll_button_head">
        {visible && (
          <div className="scroll_button" onClick={scrollToTop}>
            <KeyboardArrowUpIcon className="scroll" />
            {/* <span>scroll up</span> */}
          </div>
        )}
      </div>
    </>
  );
};
export default PractiseAssessment;
