import "./QuestionReport.css";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Question from "./Questions/Question";

const QuestionSection = ({ question }) => {
  const questions = [
    {
      question:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. At autem illum molestias provident quam in corrupti? Nesciunt illum qui vitae, laudantium corporis cupiditate eum voluptatibus fugit quia voluptas suscipit voluptate?",
      marks: "2",
      difficulty: "Easy",
      options: [
        { option: "abcd" },
        { option: "efgh" },
        { option: "ijkl" },
        { option: "mnop" },
      ],
      answers: [
        { option: "a", value: "5" },
        { option: "b", value: "10" },
        { option: "c", value: "50" },
        { option: "d", value: "10" },
      ],
      type: "mcq",
    },
    {
      question:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. At autem illum molestias provident quam in corrupti? Nesciunt illum qui vitae, laudantium corporis cupiditate eum voluptatibus fugit quia voluptas suscipit voluptate?",
      marks: "2",
      difficulty: "Easy",
      options: [
        { option: "abcd" },
        { option: "efgh" },
        { option: "ijkl" },
        { option: "mnop" },
      ],
      answers: [
        { option: "a", value: "5" },
        { option: "b", value: "10" },
        { option: "c", value: "50" },
        { option: "d", value: "10" },
      ],
      type: "oneWord",
    },
  ];
  return (
    <Container fluid="xl" className="analytics_main_section">
      <Row
        className="questionanalytics_section_layer my-5"
        style={{ boxShadow: "none" }}
      >
        {question
          .filter((q) =>
            ["mmcq", "mcq", "correctstatement", "oneword"].includes(
              q.questionId.questionType
            )
          )
          .map((q, i) => {
            return (
              <Question
                type={q.questionId.questionType}
                question={q}
                ind={i + 1}
              />
            );
          })}
        {/* <Question type="mcq"></Question>
        <Question type="oneWord"></Question>
        <Question type="TrueFalse"></Question> */}
      </Row>
    </Container>
  );
};

export default QuestionSection;
