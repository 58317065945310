import { set } from "date-fns/esm";
import React, { useEffect, useState } from "react";
import { get } from "react-hook-form";
export default function QuestionMarks({
  blueMarks,
  id,
  reviewQuestion,
  setReviewQuestion,
  getTotalMarks,
  checked,
  mark,
  setTotalMarks,
  totalMarks,
  checkMarksState,
  questions,
  setQuestions,
}) {
  console.log("Id and Marks", id, mark);
  const idx = reviewQuestion.findIndex((x) => x._id === id);
  const init = ((idx === -1) ? 0 : ((reviewQuestion[idx]).marks? ((reviewQuestion[idx]).marks) :0));
  console.log("Checking Mark", reviewQuestion[idx], idx, init);
  let [marks, setMarks] = useState(init);
  useEffect(() => {
    if(reviewQuestion.findIndex((x) => x._id === id) === -1){
      setMarks(0);
    }
  }, [reviewQuestion])
  return (
    <input
      type="number"
      name=""
      onWheel={(e) => e.target.blur()}
      defaultValue={0}
      value={marks || mark}
      disabled={checked ? false : true}
      onChange={(e) => {
        const index = reviewQuestion.findIndex((x) => x._id === id);
        console.log("30");
        if (!reviewQuestion[index]) return;
        console.log("32");
        const index_ques = questions.findIndex((x) => x._id === id);
        console.log("34");
        const inputMarks = Number.parseInt(e.target.value) || 0;
        console.log("36");
        setMarks(inputMarks);
        console.log("38");
        questions[index_ques]["marks"] = inputMarks;
        console.log("40");
        setQuestions(questions);
        console.log("42");
        const questionMarks = reviewQuestion[index]?.["marks"];
        console.log("44");
        reviewQuestion[index]["marks"] = inputMarks;
        console.log("46");
        setReviewQuestion(reviewQuestion);
        console.log("48");
        totalMarks = inputMarks - questionMarks;
        console.log("50");
        getTotalMarks(totalMarks);
        console.log("52");
      }}
      className={blueMarks ? "marks-input-blue" : "marks-input"}
    />
  );
}
