export const convertToKn = (byte) => Math.round(byte / 1024);

const thumbnailTypes = ['jpeg', 'png', 'jpg'];

const doc = ['ppt', 'pptx', 'docx', 'pdf', 'zip', 'csv', 'xls', 'xlsx'];
const media = ['mkv', 'mp4', 'swf', 'mp3', 'm4v'];
const fileType = [...thumbnailTypes, ...media, ...doc];

const getType = (type) => {
  const name = type.split('.');
  return name[name.length - 1];
};

export const validateThumbnail = (file) => {
  if (!file) return true;
  const type = getType(file.name || file.type);
  if (!thumbnailTypes.includes(type.toLowerCase())) return false;

  if (convertToKn(file.size) < 2 * 1024) return true;
  return false;
};

export const validateFile = (file) => {
  if (!file) return;
  const type = getType(file.name || file.type);
  if (!fileType.includes(type)) return false;
  if (media.includes(type)) {
    return convertToKn(file.size) < 100 * 1024;
  }
  if (doc.includes(type)) {
    return convertToKn(file.size) < 30 * 1024;
  }
  if (thumbnailTypes.includes(type.toLowerCase())) {
    return convertToKn(file.size) < 2 * 1024;
  }
};
