import TestFillBlank from "./FillBlank";
import TestMCQ from "./MCQ";
import TestMMCQ from "./MMCQ";
import TestOneWord from "./OneWord";
import TestTFCard from "./TF";

export default function TestQuestion(props) {
  return (
    <>
      {props.question.questionType === "mcq" && <TestMCQ {...props} />}
      {props.question.questionType === "mmcq" && <TestMMCQ {...props} />}
      {props.question.questionType === "correctstatement" && (
        <TestTFCard {...props} />
      )}
      {props.question.questionType === "oneword" && <TestOneWord {...props} />}
      {props.question.questionType === "fillup" && <TestFillBlank {...props} />}
    </>
  );
}
