import { useEffect, useRef, useState } from "react";
import Table from "./Table";

function DownloadTable(props) {
  const [print, setPrint] = useState(false);

  useEffect(() => {
    if (!print) return;
    function openPrint() {
      window.print();
    }
    function handleAfterPrint() {
      window.location.href = `/lessonplans?grade=${props.grade}&tab=${1}`;
    }
    //attach listener
    window.addEventListener("afterprint", handleAfterPrint);

    setTimeout(() => {
      openPrint();
    }, 0);

    //remove listener
    return () => {
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, [print]);

  return (
    <div className="lp-table">
      <Table plan={props.plan} setPrint={setPrint} />
    </div>
  );
}

export default DownloadTable;
