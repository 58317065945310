import React, { useState, Fragment } from "react";
import Select from "react-select";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  select: {
    width: "fit-content",
    padding: "0px 10px",
    "& > .select__control": {
      border: "1px solid #5CE0D2",
      boxShadow: "0px 0px 5px 0px #00000040",
    },
  },
  "@media screen and (max-width:450px)": {
    select: {
      marginBottom: "5px",
    },
    datePicker: {
      width: "94%",
    },
  },
});

function TestFilter({
  subjects,
  chapters,
  topics,
  filterData,
  setFilterData,
  testType,
}) {
  const classes = useStyles();
  const params = useParams();

  //state to enable select boxes
  const [chapterDisabled, setChapterDisabled] = useState(true);
  const [topicDisabled, setTopicDisabled] = useState(true);

  //Filter the subjects to make an label-value pair of options
  subjects = subjects.map((subject) => ({
    value: subject.subjectId,
    label: subject.subject,
  }));

  //Filter the chapters to make an label-value pair of options
  chapters =
    chapters &&
    chapters.length &&
    chapters.map((chapter) => ({
      value: chapter._id,
      label: chapter.name,
    }));

  //Filter the topics to make an label-value pair of options
  topics =
    topics &&
    topics.length &&
    topics.map((topic) => ({
      value: topic._id,
      label: topic.name,
    }));

  //Filter the test type to make an label-value pair of options
  testType =
    testType &&
    testType.length &&
    testType.map((type) => ({
      value: type,
      label: type,
    }));

  const handleSubjectChange = (subject) => {
    setFilterData({ ...filterData, subjectId: subject.value });
    setChapterDisabled(false);
  };
  const handleChapterChange = (chapter) => {
    setFilterData({
      ...filterData,
      ...{ chapter: chapter.label, chapterId: chapter.value },
    });
    setTopicDisabled(false);
  };
  const handleTopicChange = (topic) => {
    setFilterData({
      ...filterData,
      ...{ topic: topic.label, topicId: topic.value },
    });
  };

  return (
    <section className="test_list_filter my-3">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Fragment>
          <Select
            className={classes.select}
            classNamePrefix="select"
            isDisabled={false}
            isClearable
            isSearchable
            name="color"
            options={subjects}
            placeholder="Class"
            onChange={handleSubjectChange}
          />
          {!params.topicId ? (
            <>
              <Select
                className={classes.select}
                classNamePrefix="select"
                isDisabled={false}
                isClearable
                isSearchable
                name="color"
                options={subjects}
                placeholder="Subject"
                onChange={handleSubjectChange}
              />
            </>
          ) : (
            ""
          )}
          <Select
            className={classes.select}
            classNamePrefix="select"
            isDisabled={chapterDisabled}
            isClearable
            isSearchable
            name="color"
            options={chapters}
            placeholder="Chapters"
            onChange={handleChapterChange}
          />
          <Select
            className={classes.select}
            classNamePrefix="select"
            isDisabled={topicDisabled}
            isClearable
            isSearchable
            name="color"
            options={topics}
            placeholder="Topics"
            onChange={handleTopicChange}
          />
        </Fragment>
        <Fragment>
          <Select
            className={classes.select}
            classNamePrefix="select"
            isClearable
            isSearchable
            name="color"
            options={topics}
            placeholder="Difficulty Level"
            onChange={handleTopicChange}
          />
          <Select
            className={classes.select}
            classNamePrefix="select"
            isClearable
            isSearchable
            name="color"
            options={topics}
            placeholder="Cognitive Level"
            onChange={handleTopicChange}
          />
          <Select
            className={classes.select}
            classNamePrefix="select"
            isClearable
            isSearchable
            name="color"
            options={topics}
            placeholder="Correct"
            onChange={handleTopicChange}
          />
        </Fragment>
      </MuiPickersUtilsProvider>
    </section>
  );
}

export default TestFilter;
