import './Modal.css';

function ModalBackdrop(props) {
  return (
    <div
      className="lp-popup-modal"
      style={{ display: props.open ? "flex" : "none" }}
    >
      {props.children}
    </div>
  );
}

export default ModalBackdrop;
