import "./toggleSwitch.css"
import React,{ useState } from "react";
export default function ToggleSwitch({statusFunction,id,completed}) {
  const [status,setStatus] = useState(completed);
    return (
        <div className="toggleSwitch">
             <div className='form-check form-switch tableTask' 
             onClick={()=>statusFunction(id,setStatus)}
             >
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='flexSwitchCheckDefault'
                        checked={status}
                      />
                    </div>
        </div>
    )
}
