import "./LessonPlansPage.css";

import { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { GlobalContext } from "../../context/GlobalState";

import LessonPlanEditor from "./LessonPlanEditor/LessonPlanEditor";

import axios from "axios";
import { LessonPlanAPIs } from "./api";
import LessonPlanTable from "./LessonPlanTable/LessonPlanTable";
import DownloadTable from "./LessonPlanTable/DownloadTable";

function LessonPLanPage(props) {
  const { userDetails } = useContext(GlobalContext);
  const params = useParams("id");
  const location = useLocation();
  const history = useHistory();
  const [action, setAction] = useState("edit");
  const [lessonplan, setLessonplan] = useState({ rows: [] });

  function updateAction(action) {
    const params = new URLSearchParams({
      action: action,
      grade: new URLSearchParams(location.search).get("grade"),
    });
    history.replace({ pathname: location.pathname, search: params.toString() });
    setAction(action);
  }

  useEffect(() => {
    setAction(new URLSearchParams(location.search).get("action") || "edit");
  }, []);

  useEffect(() => {
    axios
      .get(LessonPlanAPIs.fetchLessonPlan(userDetails.userId, params.id), {
        headers: {
          token: localStorage.getItem("authToken"),
        },
        validateStatus: () => true,
      })
      .then((res) => {
        if (res.data.status !== "success") throw res.data;

        if (res.data.data.plan.rows && !res.data.data.plan.rows.length) {
          res.data.data.plan.rows.push({
            rowId: 1,
            activity: "",
            teacher: "",
            question: "",
            answer: "",
            resources: [],
          });
        }
        setLessonplan(res.data.data.plan);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [params.id]);

  return (
    <div className="lp-container">
      {action === "edit" && (
        <LessonPlanEditor
          grade={new URLSearchParams(location.search).get("grade")}
          userId={userDetails.userId}
          updateAction={updateAction}
          plan={lessonplan}
          setLessonplan={setLessonplan}
        />
      )}
      {action === "preview" && (
        <LessonPlanTable
          userId={userDetails.userId}
          updateAction={updateAction}
          plan={lessonplan}
        />
      )}
      {action === "download" && (
        <DownloadTable
          grade={new URLSearchParams(location.search).get("grade")}
          updateAction={updateAction}
          plan={lessonplan}
        />
      )}
    </div>
  );
}

export default LessonPLanPage;
