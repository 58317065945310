import React,{ useState,useEffect} from "react";
import { IconButton, TextField } from "@material-ui/core";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

const OptionTemplate = ({
  optionCount,
  questionCount,
  optionArray,setOptionArray,
  question,setQuestion,
  correctAnswers,setCorrectAnswers,
  formData,setFormData
}) => {
  const deleteOption = (e) => {

    if (optionCount !== 1) {
      let index = 0
      question.options.forEach((item,i)=>{
        if(item.option==option.option){
          index=i
        }
      })
      
      optionArray.filter(item=>item!=optionArray[index]);
      setOptionArray([...optionArray]);
    }
    handleDelete(e)
  };

  const [option,setOption] = useState({
    "id":question.questionId + "OP" + (question.options.length + 1),
    "option":""
  })

  useEffect(() => {
    question.options.push(option)
    setQuestion({...question})
  },[])

  const handleOptionInput = (e)=>{
    option.option=e.target.value
    setOption({...option})
    question.options[optionCount-1]=option 
    setQuestion({...question})
  }

  const handleDelete = (e)=>{
    console.log("To be deleted->",option)
    question.options.filter(item=>item.option!=option.option)
    setQuestion({...question})
  }

  const handleCorrectAnswer =(e)=>{
    if(e.target.checked){
      correctAnswers.push({
        'questionId':question.questionId,
        'answer': e.target.value 
      })
    }
    setCorrectAnswers(correctAnswers)
  }

  const handleImgaeOptionInput =(e)=>{
    formData.append(option.questionId,e.target.files[0])
    setFormData(formData)
  }

  return (
    <>
      <div className="form-check mb-2">
        {/* <input
          className="form-check-input mt-4"
          type="checkbox"
          value=""
          id={`question${questionCount}Option${optionCount}`}
        /> */}
        <TextField
          id="standard-basic"
          label={`Option ${optionCount}`}
          className={`questionOption`}
          onChange={(e)=>{handleOptionInput(e)}}
        />
        {/* <input
          accept="image/*"
          style={{ display: "none" }}
          id={`question${questionCount}Option${optionCount}Image`}
          type="file"
          onChange={(e)=>{handleOptionInput(e)}}
        />
        <label htmlFor={`question${questionCount}Option${optionCount}Image`}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <ImageOutlinedIcon />
          </IconButton>
        </label> */}
        <span onClick={(e)=>{deleteOption(e)}} style={{ cursor: "pointer" }}>
          <CloseOutlinedIcon />
        </span>
        <span>
          &nbsp;
          <input
            className="mt-lg-0 mt-3"
            type="radio"
            name={`question${questionCount}Answer`}
            id={`question${questionCount}option${optionCount}Radio`}
            value={option.id}
            onChange={(e)=>{handleCorrectAnswer(e)}}
          />
          <label
            className="form-check-label"
            htmlFor={`question${questionCount}option${optionCount}Radio`}
          >
            &nbsp; Correct Answer
          </label>
        </span>
      </div>
    </>
  );
};

export default OptionTemplate;
