/* Imports */
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import sideBoardIcon from "../../../images/Icon (4).svg";
import Avatar3 from "../../../images/Avatar (2).svg";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { GlobalContext } from "../../../context/GlobalState";
import GradeSorter from "../../../utils/gradeSorter";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
/* End of imports */

const Sideboard = ({
  sectionId,
  setSectionId,
  subjectValue,
  setSubjectValue,
  chapterValue,
  setChapterValue,
  numStudents,
  setNumStudents,
  ch_loader,
  q_loader,
  dlr_loader,
  clr_loader,
  ls_loader,
  qs_loader,
  qa_loader,
  setAllStudents=()=>{},
  setAllSubjects=()=>{},
  setAllGrade=()=>{}
}) => {
  const location = useLocation();
  let params = useParams();
  const history = useHistory();
  /*Instance for Opening and Closing Sideboard*/
  const [click, setClick] = useState(false);
  /* User Details*/
  const { userDetails, assignedSubjects, setAssignedSubjects } =
    useContext(GlobalContext);
  /* Instance for Classes */
  const [assignedClasses, setAssignedClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [classOpt, setClassOpt] = useState("");
  const [subjectOpt, setSubjectOpt] = useState(
    `${params.subject},${subjectValue}`
  );

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState({
    // subject: true,
    // chapter: true,
    topic: true,
  });
  const [searchValue, setSearchValue] = useState("");
  /*React Media Query*/
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });
  /*Styled Components*/
  /*Wrapper for Side Drawer*/
  const Container = styled.div`
    position: fixed;
    z-index: 1000;
    .active {
      border-right: 4px solid white;
    }
    ${({open})=>open?`
    position: fixed;
    // z-index: 1000;
    top: 50%;
    transform: translateY(-50%);`:
    `
    top: 50%;
    transform: translateY(-50%);
    `}
  `;
  /*Button to open the Drawer*/
  const Button = styled.button`
    background-color: #5ce0d2;
    border: none;
    width: 2.5rem;
    height: 4rem;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    margin: 5rem 0 0 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: ${(props) =>
      props.clicked
        ? isSmallScreen
          ? "15rem"
          : "22rem"
        : !isSmallScreen
        ? "0rem"
        : "0rem"};
    margin-top: 16rem;
    transition: all 0.3s ease;
    img {
      transform: ${(props) =>
        props.clicked ? "rotate(180deg)" : "rotate(0deg)"};
      transition: all 0.3s ease;
    }
  `;
  /*Main Container for Side Drawer*/
  const SlickBar = styled.div`
    background-color: #fff;
    z-index: 1;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    position: absolute;
    top: 4rem;
    left: 0;
    width: ${(props) =>
      props.clicked
        ? isSmallScreen
          ? "15rem"
          : "22rem"
        : !isSmallScreen
        ? "0rem"
        : "0rem"};
    transition: all 0.3s ease;
    border-radius: 30px;
    box-shadow: 0px 0px 7px 0px #00000040;
  `;
  /*Dropdowns aka Filters*/
  const Filter = styled.select`
    border: 1px solid #5ce0d2;
    border-radius: 50px;
    height: fit-content !important;
    padding: 0.5rem 0;
    /* cursor:${disable.subject}; */
    display: flex;
    padding-left: 1rem;
    margin-bottom: 10px;
    margin-right: 30px;
    box-shadow: 0px 0px 7px 0px #0000001a;
    /* width: ${(props) => (props.clicked ? "125px !important" : "0px")}; */
    width: ${(props) =>
      props.clicked
        ? isSmallScreen
          ? "75px !important"
          : "125px !important"
        : !isSmallScreen
        ? "0px"
        : "0px"};
    margin-left: ${(props) =>
      props.clicked
        ? isSmallScreen
          ? "5px"
          : "20px"
        : !isSmallScreen
        ? "-125px !important"
        : "-125px !important"};
    transition: all 0.3s ease;
  `;
  /*Options in the Dropdown*/
  const FilterOption = styled.option`
    color: black;
  `;
  /*Column for the filters*/
  const FilterCol = styled.div`
    display: flex;
    padding-left: 10px !important;
    flex-direction: row;
    width: auto;
  `;
  /*List of Students*/
  const List = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    max-height: 240px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #c4c4c459;
      box-shadow: #c4c4c459;
      border-radius: 15px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 15px;
      -webkit-box-shadow: inset 0 0 6px #5ce0d2;
      box-shadow: #5ce0d2;
    }
  `;
  /*Options in the list*/
  const ListOption = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 40px !important;
    padding-left: 10px;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover {
      border-left: 4px solid #5ce0d2;
      background-image: linear-gradient(to right, #5ce0d252, #fff);
    }
  `;
  /*Wrapper for Search*/
  const FormCover = styled.div`
    margin-bottom: 10px;
    width: ${(props) =>
      props.clicked
        ? isSmallScreen
          ? "150px !important"
          : "300px !important"
        : !isSmallScreen
        ? "0px"
        : "0px"};
    margin-left: ${(props) =>
      props.clicked
        ? isSmallScreen
          ? "20px"
          : "75px"
        : !isSmallScreen
        ? "-125px !important"
        : "-125px !important"};
    transition: all 0.3s ease;
  `;
  const fetchSubjects = (id) => {
    setChapters([]);
    setTopics([]);
    let tempArr = [];
    assignedClasses[0] &&
      assignedClasses[0].forEach((assignedClass) => {
        if (id === assignedClass._id) {
          window.localStorage.setItem("selectedClass", assignedClass._id);
          assignedClass.subjects.forEach((data) => {
            setSectionId(data.sectionId);
            // setSubjectValue(data.subjectId);

            tempArr.push({
              subject: data.subject,
              subjectId: data.subjectId,
              subjectData: data.subjectData,
            });
          });
        }
      });
    setAssignedSubjects([tempArr]);
  };
  const fetchChapters = async (id) => {
    setTopics([]);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${userDetails.userId}/sections/${sectionId}/subjects/${id}/chapters`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setChapters(response.data.data.chapters);
        // setNumChapters(response.data.data.chapters.length);
        setSubjectValue(id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchTopics(chapterValue);
  }, [chapterValue]);
  useEffect(() => {
    if (assignedClasses.length)
      fetchSubjects(`${params.class}-${params.section}`);
    fetchChapters(subjectValue);
  }, [assignedClasses]);
  useEffect(() => {
    const fetchClasses = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/assignSubjects`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          setAssignedClasses([
            new GradeSorter(response.data.data.sections).sort(),

          ]);
          setAllGrade(new GradeSorter(response.data.data.sections).sort());
          let dt=[]
          response.data.data.sections.forEach((item)=>{
            item.subjects.forEach(sub=>{
              dt.push({name:sub.subject,_id:sub.subjectId})
            })
          });
          setAllSubjects(dt);
          
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchClasses();
    fetchSubjects(`${params.class}-${params.section}`);
  }, []);

  const fetchStudents = async (id) => {
    setLoading(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const grade = id.split("-")[0];
      const section = id.split("-")[1];
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/students`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.status === 200) {
        setStudents(response.data.data.students);
        setAllStudents(()=>response.data.data.students);
        setNumStudents(response.data.data.students.length);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchTopics = async (id) => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${userDetails.userId}/sections/${sectionId}/subjects/${subjectValue}/chapters/${id}/topics`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setChapterValue(id);
        setTopics(response.data.data.topics);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const search = () => {
    let searchData = students.filter((student) =>
      student.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setStudents(searchData);
  };

  useEffect(() => {
    //when the search box is empty
    if (!searchValue) {
      fetchStudents(`${params.class}-${params.section}`);
    } else {
      search();
    }
  }, [searchValue]);

  console.log(sectionId);

  return (
    <>
      {ch_loader ||
      q_loader ||
      dlr_loader ||
      clr_loader ||
      ls_loader ||
      qs_loader ||
      qa_loader ? (
        <Container open={false}>
          {/*Button to open and close the drawer*/}
          <Button clicked={click} onClick={() => setClick(!click)}>
            <img src={sideBoardIcon} />
          </Button>
          {/* Container for filters, Search and List */}
          <SlickBar clicked={click} style={{ top: "15rem" }}>
            <FormCover clicked={click}>
              <div className="text-center">
                <div className="spinner">
                  Loading
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              </div>
            </FormCover>
          </SlickBar>
        </Container>
      ) : (
        <Container open={true}>
          {/*Button to open and close the drawer*/}
          <Button clicked={click} onClick={() => setClick(!click)}>
            <img src={sideBoardIcon} />
          </Button>
          {/* Container for filters, Search and List */}
          <SlickBar clicked={click}>
            {/*Filter Section*/}
            <FilterCol>
              <Filter
                onChange={async (e) => {
                  fetchSubjects(e.target.value);
                  fetchStudents(e.target.value);
                  console.log(e.target.value);
                  setDisable({ ...disable, subject: false });
                  const newClass = e.target.value.split("-");
                  setClassOpt(`${newClass[0]}-${newClass[1]}`);
                  try{
                  location.state.practiceAssessment.class = newClass[0];
                  location.state.practiceAssessment.section = newClass[1];
                  }
                  catch(err)
                  {
                    console.log(err);
                  }
                  params.class = newClass[0];
                  params.section = newClass[1];
                  params.subject = "";
                }}
                style={{ cursor: "pointer" }}
                value={classOpt}
                clicked={click}
                exact
                activeClassName="active"
                to="/"
              >
                <FilterOption value="" disabled selected>
                  Classes
                </FilterOption>
                {assignedClasses[0] &&
                  assignedClasses[0].map((assignedClass, index) => (
                    <FilterOption key={index} value={assignedClass._id}>
                      {assignedClass._id}
                    </FilterOption>
                  ))}
              </Filter>
              <Filter
                onChange={async (e) => {
                  const sub = e.target.value.split(",");
                  params.subject = sub[1];
                  setSubjectOpt(sub);
                  fetchChapters(sub[0]);
                  // history.push({
                  //   pathname: `${location.pathname}/${location.state.practiceAssessment.class}/${location.state.practiceAssessment.section}/${sub[1]}/${sub[0]}`,
                  //   state: { sectionId: sectionId },
                  // });
                  history.push({
                    pathname: `/dashboard/analytics/onlineTest/${location?.state?.practiceAssessment?.class||params?.class}/${location?.state?.practiceAssessment?.section||params?.section}/${sectionId||params?.sectionId}/${sub[1]}/${sub[0]}`,
                    state: {
                      sectionId: sectionId,
                      practiceAssessment: location?.state?.practiceAssessment,
                    },
                  });
                  // setDisable({ ...disable, chapter: false });
                }}
                style={{
                  cursor: disable.subject ? "unset" : "pointer",
                  opacity: disable.subject ? "0.5" : "unset",
                }}
                disabled={disable.subject}
                // value={subjectValue}
                clicked={click}
                activeClassName="active"
              >
                <FilterOption value="" disabled selected>
                  Subject
                </FilterOption>
                {assignedSubjects[0] &&
                  assignedSubjects[0].map((assignedSubject, index) => (
                    <FilterOption
                      value={
                        assignedSubject.subjectId +
                        "," +
                        assignedSubject.subject
                      }
                    >
                      {assignedSubject.subject}
                    </FilterOption>
                  ))}
              </Filter>
            </FilterCol>
            <FilterCol>
              <Filter
                onChange={async (e) => {
                  // setDisable({ ...disable, topic: false });
                  fetchTopics(e.target.value);
                  console.log(e.target.value);
                  history.push({
                    // pathname: `${location.pathname}/${e.target.value}`,
                    pathname: `/dashboard/analytics/onlineTest/${params.class}/${params?.section}/${params?.sectionId}/${params?.subject}/${params?.subjectId}/${e.target.value}`,
                    state: {
                      sectionId: sectionId,
                      practiceAssessment: location?.state?.practiceAssessment,
                    },
                  });
                }}
                style={{
                  cursor: disable.chapter ? "unset" : "pointer",
                  opacity: disable.chapter ? "0.5" : "unset",
                }}
                disabled={disable.chapter}
                value={chapterValue}
                clicked={click}
                activeClassName="active"
              >
                <FilterOption value="" disabled selected>
                  Chapter
                </FilterOption>
                {chapters &&
                  chapters.map((chapter, index) => (
                    <FilterOption value={chapter._id}>
                      {chapter.name}
                    </FilterOption>
                  ))}
              </Filter>
              <Filter
                clicked={click}
                onChange={async (e) => {
                  history.push({
                    pathname: `${location.pathname}/${e.target.value}`,
                    state: {
                      sectionId: sectionId,
                      practiceAssessment: location.state.practiceAssessment,
                    },
                  });
                }}
                style={{
                  cursor: !params.chapterId ? "unset" : "pointer",
                  opacity: !params.chapterId ? "0.5" : "unset",
                }}
                disabled={!params.chapterId}
                exact
                activeClassName="active"
              >
                <FilterOption value="" disabled selected>
                  Topic
                </FilterOption>
                {topics &&
                  topics.map((topic, index) => (
                    <FilterOption value={topic._id}>{topic.name}</FilterOption>
                  ))}
              </Filter>
            </FilterCol>
            {/*Search Section*/}
            <FormCover clicked={click}>
              <FormControl
                sx={{ m: "1", width: "100ch" }}
                style={{ marginBottom: 5, transition: "all 0.3s ease" }}
                clicked={click}
                variant="standard"
                id="searchbar"
              >
                <InputLabel htmlFor="standard-adornment-password">
                  Search
                </InputLabel>
                <Input
                  type="text"
                  value={searchValue}
                  autoFocus={searchValue ? true : false}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon style={{ fill: "#5ce0d2" }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
              {/* <SearchBar search={searchValue} setSearch={setSearchValue} /> */}
            </FormCover>
            {/*List Section*/}
            <List style={{maxHeight:"180px"}}>
              {!loading ? (
                students[0]&&params.subjectId ? (
                  students.map((student, index) => (
                    // /dashboard/analytics/onlineTest/:class/:section/:subject/:subjectId/student/:sectionId/:student/:studentId                                                                                                                                
                    
                    <div onClick={()=>{params?.subject&&history.push(`/dashboard/analytics/onlineTest/${params.class}/${params.section}/${params.subject}/${params.subjectId}/student/${sectionId}/${student?.name}/${student._id}`)}}
                      // to={{
                      //   pathname:
                          
                      //     `/dashboard/analytics/onlineTest/student/${sectionId}/${student.name}/${student._id}`,
                      // }}
                      style={{ color: "black" }}
                    >
                      <ListOption key={index} value={student._id}>
                        <img
                          src={
                            student.profile && student.profile.filename != null
                              ? student.profile.filename
                              : Avatar3
                          }
                          style={{ marginRight: 5 }}
                        />
                        {student.name}
                      </ListOption>
                    </div>
                  ))
                ) : (
                  <div className="text-center">No Students Enrolled</div>
                )
              ) : (
                <div className="text-center">
                  <div className="spinner">
                    Loading
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              )}
            </List>
          </SlickBar>
        </Container>
      )}
    </>
  );
};

export default Sideboard;
