import React, { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import image from "../../utils/helpers";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import {
  decodeHTMLEntities,
  fixUrl,
  fixname,
  fixFileUrl,
  fileType,
  actionType,
  getModuleType,
} from "../../utils/tools";

import "./styles/module.css";

export function Contents(props) {
  return props.loading ? (
    <div className="text-center">
      <CircularProgress style={{ color: "#FF075B", fontWeight: "600" }} />
    </div>
  ) : (
    <div className="row my-3 gy-4">
      {props.contents[0] ? (
        props.contents.map((content, index) => (
          <div
            className="col-sm-2 cardBox"
            key={index}
            onClick={() => props.goto(content.name)}
            style={{
              marginBottom: "3rem",
              textDecoration: "none",
              display: content.disabled ? "none" : "block",
            }}
          >
            <NavLink to={props.getURL(content)}>
              <div className="card card-radius card-height boxShadowCard">
                <div className="card-img">
                  <img
                    src={image(fixUrl(content.thumbnail))}
                    className="card-thumbnail"
                    alt={content?.name || "chapter"}
                  />
                </div>
                <div>
                  <p
                    className="text-dark text-center"
                    style={{
                      marginBlockStart: "10px",
                      marginBlockEnd: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {decodeHTMLEntities(content.name)}
                  </p>
                </div>
              </div>
            </NavLink>
            {props.updateStatus && (
              <ToggleSwitch
                statusFunction={props.updateStatus}
                id={content._id}
                completed={content.completed}
              />
            )}
          </div>
        ))
      ) : (
        <h3 className="text-center">{props.placholder}</h3>
      )}
    </div>
  );
}

export function ModuleContents(props) {
  const [active, setActive] = useState("");

  const toggleMenu = (id) => {
    setActive(id === active ? "" : id);
  };

  const toggleTaskModal = (id) => {
    props.selectModule(id);
    const myModal = new Modal(document.getElementById("moduleTaskTypeModal"));
    myModal.toggle();
  };

  return (
    <Fragment>
      {props.contents.length ? (
      <div className="row module my-3 gy-4">
        <h5 style={{ fontWeight: "bold" }}>{props.heading}</h5>
        {props.contents.map((module, index) => {
          let result = getModuleType(module.resources);

          return (
            <div
              className="col-sm-2 cardBox"
              key={index}
              style={{
                display:
                  result.notFound || fileType(result.fileType) === "Image"
                    ? "block"
                    : "block",
                position: "relative",
              }}
            >
              {result.fileType !== "html" ? (
                <>
                  <NavLink
                    to={{
                      pathname: `/myresources/modules/${fixname(
                        module.name
                      )}/${actionType(result.fileType)}`,
                      useProps: !result.notFound
                        ? {
                            fileUrl: fixFileUrl(
                              result.resource.fileUrl,
                              result.fileType
                            ),
                            moduleName: result.resource.fileName,
                            click: () => props.visited(module.name),
                          }
                        : {
                            fileUrl: "",
                            fileName: "",
                          },
                    }}
                    style={{ textDecoration: "none" }}
                    className="text-center"
                  >
                    <div
                      className="card card-radius card-height"
                      style={{ position: "relative" }}
                    >
                      <div className="card-img">
                        <img
                          src={image(fixFileUrl(module.thumbnail, module.name))}
                          className="card-thumbnail"
                          alt={module.name || "chapter"}
                        />
                      </div>
                      <div
                        className="text-end text-light"
                        style={{
                          marginBottom: "auto",
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        <IconButton
                          aria-label="settings"
                          data-bs-toggle="dropdown"
                          onClick={() => {
                            toggleMenu(module._id);
                          }}
                        >
                          <MoreVertIcon style={{ color: "black" }} />
                        </IconButton>
                      </div>
                      <ul
                        className="dropdown-menu"
                        style={{
                          display: active === module._id ? "block" : "none",
                          position: "absolute",
                          right: 0,
                          top: "50px",
                          zIndex: "50",
                          fontSize: "0.8rem",
                        }}
                        id={module._id}
                      >
                        <li>
                          <Link
                            className="dropdown-item"
                            to="?folder=module"
                            onClick={() => {
                              toggleTaskModal(module._id);
                              toggleMenu(module._id);
                            }}
                          >
                            Assign Task
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="?folder=module"
                            onClick={() => {
                              props.toggleVisibility(module);
                              toggleMenu(module._id);
                            }}
                          >
                            {module.public ? "Set Private" : "Set Public"}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="?folder=module"
                            onClick={() => {
                              props.delete(module._id);
                              toggleMenu(module._id);
                            }}
                          >
                            Delete
                          </Link>
                        </li>
                      </ul>
                      <div>
                        <p className="text-dark text-center">{module.name}</p>
                      </div>
                    </div>
                  </NavLink>
                  {props.updateStatus && <ToggleSwitch />}
                </>
              ) : (
                <a
                  href={result.resource.fileUrl}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  className="text-center"
                  onClick={() => props.visited(module.name)}
                >
                  <div className="card card-height card-radius">
                    <div className="card-img">
                      <img
                        src={image(fixFileUrl(module.thumbnail, module.name))}
                        alt={module?.name || "module"}
                        className="card-thumbnail"
                      />
                    </div>
                    <div
                      className="text-end text-light"
                      style={{
                        marginBottom: "auto",
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      <IconButton
                        aria-label="settings"
                        data-bs-toggle="dropdown"
                        onClick={() => {
                          toggleMenu(module._id);
                        }}
                      >
                        <MoreVertIcon style={{ color: "black" }} />
                      </IconButton>
                    </div>
                    <ul
                      className="dropdown-menu"
                      style={{
                        fontSize: "0.8rem",
                        display: active === module._id ? "active" : "none",
                        position: "absolute",
                        top: "50px",
                        right: 0,
                      }}
                      id={module._id}
                    >
                      <li>
                        <Link
                          className="dropdown-item"
                          to="?folder=module"
                          onClick={() => {
                            props.toggleTaskModal(module._id);
                            toggleMenu(module._id);
                          }}
                        >
                          Assign Task
                        </Link>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="?folder=module"
                            onClick={() => {
                              props.makePublic(module);
                              toggleMenu(module._id);
                            }}
                          >
                            {module.public ? "Set Private" : "Set Public"}
                          </Link>
                        </li>
                        <Link
                          className="dropdown-item"
                          to="?folder=module"
                          onClick={() => {
                            props.delete(module._id);
                            toggleMenu(module._id);
                          }}
                        >
                          Delete
                        </Link>
                      </li>
                    </ul>
                    <div>
                      <p className="text-dark text-center">{module.name}</p>
                    </div>
                  </div>
                </a>
              )}
            </div>
          );
        })}
      </div>):<></>}
    </Fragment>
  );
}
