import './styles/Assesment.css';
import { NavLink,useLocation} from 'react-router-dom';
import assessmentImg from "../../images/practice-assessment.svg"
import onlinetestImg from "../../images/online-test.svg"
import { Button, Modal } from "react-bootstrap";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useState } from 'react';
import DropdownComponent from '../DropdownComponent/DropdownComponent';
import Checkbox from '@material-ui/core/Checkbox';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#edfcfa',
    boxShadow: 'none',
  },
}));

const Assesment = (
  {subjectName,
  chapterName,
  topicName})=> {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  console.log(location.pathname+"/practiseAssessment")
  console.log("heh",subjectName)
  console.log("heh2",chapterName)
  console.log("heh3",topicName)
  return (
  <>
  <div className="assesment">
        <div className="assesment__practise">
          <NavLink to={{pathname:location.pathname+"/practiseAssessment",
            state:{subjectName:subjectName,chapterName:chapterName,topicName:topicName}
            }}>
            <button className="assesment__practise__action">
            <img src={assessmentImg}/>
            <span>Practice Assessment</span>
            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM27.8044 17.3206L20.6794 10.1956C19.7519 9.26813 18.2481 9.26813 17.3206 10.1956C16.3931 11.1231 16.3931 12.6269 17.3206 13.5544L20.3912 16.625L11.875 16.625C10.5633 16.625 9.5 17.6883 9.5 19C9.5 20.3117 10.5633 21.375 11.875 21.375H20.3912L17.3206 24.4456C16.3931 25.3731 16.3931 26.8769 17.3206 27.8044C18.2481 28.7319 19.7519 28.7319 20.6794 27.8044L27.8044 20.6794C28.7319 19.7519 28.7319 18.2481 27.8044 17.3206Z" fill="white"/>
            </svg>
            </button>
          </NavLink>
        </div>
        <div className="assesment__onlineTest">
            <NavLink to={{pathname:location.pathname+"/onlinetest",
            state:{subjectName:subjectName,chapterName:chapterName,topicName:topicName,resources:true}
            }}>
            <button className="assesment__onlineTest__action">
            <img src={onlinetestImg}/>
            <span>Online Test</span>
            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM27.8044 17.3206L20.6794 10.1956C19.7519 9.26813 18.2481 9.26813 17.3206 10.1956C16.3931 11.1231 16.3931 12.6269 17.3206 13.5544L20.3912 16.625L11.875 16.625C10.5633 16.625 9.5 17.6883 9.5 19C9.5 20.3117 10.5633 21.375 11.875 21.375H20.3912L17.3206 24.4456C16.3931 25.3731 16.3931 26.8769 17.3206 27.8044C18.2481 28.7319 19.7519 28.7319 20.6794 27.8044L27.8044 20.6794C28.7319 19.7519 28.7319 18.2481 27.8044 17.3206Z" fill="white"/>
            </svg>
            </button>
          </NavLink>
        </div>
    </div>
    </>
  )};

export default Assesment;
