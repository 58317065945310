/* eslint-disable react-hooks/exhaustive-deps */
// .xlsx
import "./styles/Modules.css";

import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import Moment from "react-moment";
import { useAlert } from "react-alert";
import SwipeableViews from "react-swipeable-views";
import Select from "react-select";

import axios from "axios";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import PropTypes from "prop-types";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.js";

import { GlobalContext } from "../../context/GlobalState";
import DropDownShutter from "../DropDown/DropDown";
import Module from "./Module";
import Assesment from "./Assesment";

import { validateFile, validateThumbnail } from "../../utils/helper";

import moduleImg from "../../images/module.svg";
import assessmentImg from "../../images/assessment.svg";
import { groupGradeSections } from "../../utils/groupGrades";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#edfcfa",
    boxShadow: "none",
  },
  dashboardTabStyle: {
    color: "#10A0B6",
    minWidth: "100px",
    fontWeight: "normal !important",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const fixName = (name) => {
  const charArrays = ["?", "+", "/"];
  charArrays.forEach((character) => {
    name = name.replaceAll(character, "");
  });
  return name;
};

function Modules(props) {
  document.title = "2xcell.in";

  const { userDetails, sectionId } = useContext(GlobalContext);
  const selectedClass = window.localStorage.getItem("selectedClass");
  const subjectName = props.match.params.subject;
  const chapterName = props.match.params.chapter;
  const topicName = props.match.params.topic;
  const chapterId = props.match.params.chapterId;
  const subjectId = props.match.params.subjectId;
  const topicId = props.match.params.topicId;
  const sectionIdTask = window.localStorage.getItem("sectionIdTask");
  const language = window.localStorage.getItem("language");

  window.localStorage.setItem("subjectId", subjectId);
  window.localStorage.setItem("topicId", topicId);
  window.localStorage.setItem("userId", userDetails.userId);

  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });

  const alert = useAlert();
  const classes = useStyles();
  const theme = useTheme();
  const [dropdown, setdropdown] = useState(isSmallScreen ? false : true);
  const [value, setValue] = useState(0);
  const [heading, setHeading] = useState("Modules");
  const [moduleMessage, setModuleMessage] = useState("");
  const [moduleContentLoader, setModuleContentLoader] = useState(false);
  const [moduleTaskTypeId, setModuleTaskTypeId] = useState("");
  const [moduleMakeTaskLoader, setModuleMakeTaskLoader] = useState(false);
  const [grade, setGrade] = useState({
    name: "",
    sections: [],
  });
  const [sections, setSections] = useState([]);
  const [ownModules, setOwnModules] = useState([]);
  const [fetchOwnModulesLoader, setFetchOwnModulesLoader] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  const handleShutterOpen = () => {
    setdropdown(!dropdown);
  };

  const getOwnModules = async () => {
    const authToken = localStorage.getItem("authToken");
    const splittedGrade = (
      selectedClass || localStorage.getItem("gradeSection")
    ).split("-");
    var grade = null,
      section = null;
    if (splittedGrade.length === 3) {
      grade = `${splittedGrade[0]}-${splittedGrade[1]}`;
      section = `${splittedGrade[2]}`;
    } else {
      grade = splittedGrade[0];
      section = splittedGrade[1];
    }

    const topicId = window.localStorage.getItem("topicId");
    try {
      setFetchOwnModulesLoader(true);
      const tempOrigin = `${process.env.REACT_APP_FILE_SERVER}`;
      const response = await axios.get(
        `${tempOrigin}/api/v1/staffs/${userDetails.userId}/modules?lang=${language}&section=${grade}-${section}&topicId=${topicId}`,
        {
          headers: {
            "content-type": "multipart/form-data",
            token: authToken,
          },
        }
      );

      if (response.data.status === "success") {
        setOwnModules(response.data.data.modules);
      }
    } catch (error) {
      console.log(error);
    }

    setFetchOwnModulesLoader(false);
  };

  useEffect(() => {
    if (!selectedClass) return;
    const grade = selectedClass.split("-")[0];
    const grades = groupGradeSections(userDetails.grades);
    setGrade({
      name: grade,
      sections: (grades[grade] || []).map((section, index) => ({
        value: section,
        label: section,
        key: index,
      })),
    });
  }, [selectedClass]);

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeHeading = (val) => {
    setHeading(val);
  };

  //state and function to submit the module
  const [moduleFormData, setModuleFormData] = useState(new FormData());
  const submitModuleContentForm = async (data) => {
    setModuleMessage("");
    setModuleContentLoader(true);
    const {
      moduleContentType,
      moduleTaskType,
      moduleMakeTask,
      moduleName,
      lang,
    } = data;

    moduleFormData.append("name", moduleName);
    moduleFormData.append("topicId", topicId);
    moduleFormData.append(
      "sectionId",
      sectionId || localStorage.getItem("currentsectionId")
    );
    moduleFormData.append("subjectId", subjectId);
    moduleFormData.append("makeTask", moduleMakeTask);
    moduleFormData.append("contentType", moduleContentType);
    moduleFormData.append("taskType", moduleTaskType);
    moduleFormData.append("lang", lang);

    setModuleFormData({ ...moduleFormData });

    const authToken = localStorage.getItem("authToken");
    const formData = moduleFormData;
    try {
      await fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/modules`,
        {
          method: "POST",
          headers: {
            token: authToken,
          },
          body: formData,
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setModuleContentLoader(false);
            setModuleMessage("Module Added.");
            getOwnModules();
          } else {
            alert.show("Failed to upload module", { type: "error" });
            setModuleContentLoader(false);
          }
        });
    } catch (error) {
      setModuleContentLoader(false);
      setModuleMessage(error.message);
      alert.show(error.message, { type: "error" });
    }

    setModuleContentLoader(false);
    reset();
    setModuleFormData(new FormData());
  };

  const moduleTaskTypeModalFunc = (id) => {
    setModuleTaskTypeId(id);
    const myModal = new Modal(document.getElementById("moduleTaskTypeModal"));
    myModal.toggle();
  };

  const selectSections = (e) => {
    setSections(e);
  };

  const submitModuleTaskTypeForm = async (data) => {
    const { modulePopUpTaskType, moduleEnableSubmissions } = data;
    setModuleMakeTaskLoader(true);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ORIGIN}/api/v1/tasks`,
        {
          contentId: moduleTaskTypeId,
          subjectId: subjectId,
          sections: sections.map((section) => `${grade.name}-${section.value}`),
          topicId: topicId,
          contentType: "module",
          taskType: modulePopUpTaskType,
          submission: moduleEnableSubmissions,
        },
        {
          headers: {
            token: authToken,
          },
        }
      );

      if (response.data.status === "success") {
        alert.success("Task Added.");
        reset();
      }
    } catch (error) {
      if (error.response?.data.status === "fail") {
        alert.error(error.response.data.message);
      } else {
        alert.show("Try Again.");
      }
    }
    setSections([]);
    setModuleMakeTaskLoader(false);
  };

  //Validatte if file size is correct or not
  const Filevalidation = (file) => {
    // Check if any file is selected.
    const fsizeInByte = file.size;
    const fileSize = Math.round(fsizeInByte / 1024);
    // The size of the file.

    if (fileSize > 100 * 1024) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <section className={isSmallScreen ? "modules my-4" : "modules my-5"}>
        <div
          className={
            isSmallScreen ? "container-fluid dashboard_container" : "container"
          }
        >
          <div className="box">
            <div
              className="upperBox"
              style={{ display: isSmallScreen ? "none" : "" }}
            >
              <div className="boxRow">
                <div className="pathDetail" style={{ color: "white" }}>
                  <h1>
                    {selectedClass || localStorage.getItem("gradeSection")}
                  </h1>
                  <h5>
                    <NavLink
                      to={{
                        pathname: `/myresources/${fixName(
                          subjectName
                        )}/${subjectId}`,
                      }}
                      style={{ color: "white" }}
                    >
                      {subjectName}
                    </NavLink>{" "}
                    &gt;{" "}
                    <NavLink
                      to={{
                        pathname: `/myresources/${fixName(
                          subjectName
                        )}/${subjectId}/${fixName(chapterName)}/${chapterId}`,
                      }}
                      style={{ color: "white" }}
                    >
                      {chapterName}
                    </NavLink>{" "}
                    &gt;{" "}
                    <NavLink
                      to={{
                        pathname: `/myresources/${fixName(
                          subjectName
                        )}/${subjectId}/${fixName(
                          chapterName
                        )}/${chapterId}/${fixName(topicName)}/${topicId}`,
                      }}
                      style={{ color: "white" }}
                    >
                      {topicName}
                    </NavLink>
                    {/* {heading}  */}
                  </h5>
                </div>
                <div className="DateBox">
                  <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                    <CalendarTodayIcon />{" "}
                    {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
                  </h5>
                </div>
              </div>
            </div>

            <Paper
              className={classes.root}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                style={{
                  background: "#DCFFFB",
                  display: dropdown ? "" : "none",
                  width: "100%",
                }}
                className="ps"
                indicatorColor="primary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons={"on"}
              >
                <Tab
                  style={{ color: "#10A0B6" }}
                  icon={
                    <img
                      src={moduleImg}
                      style={{ height: "23px", width: "23px" }}
                    />
                  }
                  label="Modules"
                  {...a11yProps(0)}
                  onClick={() => changeHeading("Modules")}
                />
                <Tab
                  style={{ color: "#10A0B6" }}
                  icon={
                    <img
                      src={assessmentImg}
                      style={{ height: "23px", width: "23px" }}
                    />
                  }
                  label="Assessment"
                  {...a11yProps(1)}
                  onClick={() => changeHeading("Assessment")}
                />
              </Tabs>
              <DropDownShutter
                onClick={handleShutterOpen}
                clicked={dropdown}
                dropdown={dropdown}
                setDropDown={setdropdown}
              />
            </Paper>

            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                style={{ overflowX: "hidden" }}
              >
                {moduleMakeTaskLoader && (
                  <div className="text-center">
                    <CircularProgress
                      style={{ color: "#FF075B", fontWeight: "600" }}
                    />
                  </div>
                )}

                <Module
                  topicId={topicId}
                  userDetails={userDetails}
                  moduleTaskTypeModalFunc={moduleTaskTypeModalFunc}
                  ownModules={ownModules}
                  setOwnModules={setOwnModules}
                  fetchOwnModulesLoader={fetchOwnModulesLoader}
                  getOwnModules={getOwnModules}
                  subjectName={subjectName}
                  chapterName={chapterName}
                  topicName={topicName}
                  selectedGrade={selectedClass}
                  chapterId={chapterId}
                  subjectId={subjectId}
                />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <Assesment
                  subjectName={subjectName}
                  chapterName={chapterName}
                  topicName={topicName}
                />
              </TabPanel>
            </SwipeableViews>
          </div>
        </div>
      </section>

      {/* add content Modal of Module*/}
      <div
        className="modal fade moduleContentUploadModal mt-5"
        id="moduleContentUploadModal"
        tabIndex="-1"
        aria-labelledby="moduleContentUploadModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="moduleContentUploadModalLabel">
                Add Content
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={handleSubmit(submitModuleContentForm)}>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="moduleName" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="moduleName"
                    placeholder="Name"
                    required
                    {...register("moduleName")}
                  />
                </div>
                <div className="row mb-3">
                  <div className="col-sm">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="moduleTaskTypeSelect"
                      {...register("moduleMakeTask")}
                    />{" "}
                    <label
                      htmlFor="moduleTaskTypeSelect"
                      className="form-label"
                    >
                      Assign Task
                    </label>
                    <select
                      className="form-select form-select-sm"
                      id="moduleTaskTypeSelect"
                      required
                      {...register("moduleTaskType")}
                    >
                      <option defaultValue="">Select Task Type</option>
                      <option value="revision task">Revision Task</option>
                      <option value="flipped class">Flipped Task</option>
                      <option value="homework">Homework</option>
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm">
                    <label htmlFor="languageSelect" className="form-label">
                      Language
                    </label>
                    <select
                      className="form-select form-select-sm"
                      id="languageSelect"
                      required
                      {...register("lang")}
                    >
                      <option defaultValue="" disabled>
                        Select Language
                      </option>
                      <option value="english">English</option>
                      <option value="hindi">Hindi</option>
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm">
                    <label htmlFor="moduleThumbnail" className="form-label">
                      Thumbnail
                    </label>
                    <input
                      className="form-control form-control-sm"
                      type="file"
                      id="moduleThumbnail"
                      {...register("moduleThumbnail")}
                      onChange={(e) => {
                        if (validateThumbnail(e.target.files[0])) {
                          moduleFormData.append("thumbnail", e.target.files[0]);
                          setModuleFormData(moduleFormData);
                        } else {
                          alert.error("Please select valid file");
                        }
                      }}
                    />
                    <small>Upload .jpg, .jpeg, .png file</small>
                  </div>
                  <div className="col-sm">
                    <label htmlFor="moduleFile" className="form-label">
                      File
                    </label>
                    <input
                      className="form-control form-control-sm"
                      type="file"
                      required
                      {...register("moduleFile")}
                      onChange={(e) => {
                        if (validateFile(e.target.files[0])) {
                          moduleFormData.append("file", e.target.files[0]);
                          setModuleFormData(moduleFormData);
                        } else {
                          alert.error("Please select valid file");
                        }
                      }}
                    />
                    <small>
                      Upload .png, .jpeg, .jpg, .pdf, .ppt, .mov, .mp3, .mp4,
                      .docx file
                    </small>
                  </div>
                </div>
              </div>

              {moduleMessage && (
                <div className="text-center">
                  <p style={{ color: "#FF075B", fontWeight: "600" }}>
                    {moduleMessage}
                  </p>
                </div>
              )}

              <div className="mb-3 modal_button_container">
                <div className="row">
                  <div className="col d-grid">
                    <button
                      type="button"
                      className="btn moduleContentUploadModalBtnCancel"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <div className="col d-grid">
                    {moduleContentLoader ? (
                      <div className="text-center">
                        <CircularProgress
                          style={{ color: "#FF075B", fontWeight: "600" }}
                        />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn  moduleContentUploadModalBtnApply"
                        id="addModuleModalButton"
                        // data-bs-dismiss="modal"
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Task Type Modal for module */}
      <div
        className="modal fade moduleTaskTypeModal mt-5"
        id="moduleTaskTypeModal"
        tabIndex="-1"
        aria-labelledby="moduleTaskTypeModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="moduleTaskTypeModalLabel">
                Assign Task
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={handleSubmit(submitModuleTaskTypeForm)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm mb-3">
                    <label htmlFor="modulePopUpTaskType">Task Type</label>
                    <select
                      className="form-select"
                      id="modulePopUpTaskType"
                      {...register("modulePopUpTaskType")}
                    >
                      <option defaultValue="">Select Task Type</option>
                      <option value="homework">Home Work</option>
                      <option value="revision task">Revision Task</option>
                      <option value="flipped class">Flipped Class</option>
                    </select>
                  </div>
                  <div className="col-sm mb-3">
                    <label
                      htmlFor="sectionSelect"
                      className="form-label"
                      style={{ margin: 0 }}
                    >
                      Sections
                    </label>
                    <Select
                      id="sectionSelect"
                      name="sections"
                      {...register("sections")}
                      options={grade.sections}
                      isMulti
                      placeholder="Select"
                      onChange={selectSections}
                    />
                  </div>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="moduleEnableSubmissions"
                    {...register("moduleEnableSubmissions")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="moduleEnableSubmissions"
                  >
                    Enable Submissions
                  </label>
                </div>
              </div>

              <div className="mb-3 p-2">
                <div className="row">
                  <div className="col d-grid">
                    <button
                      type="button"
                      className="btn moduleTaskTypeModalBtnCancel"
                      data-bs-dismiss="modal"
                    >
                      Clear
                    </button>
                  </div>
                  <div className="col d-grid">
                    <button
                      type="submit"
                      className="btn  moduleTaskTypeModalBtnApply"
                      // onClick={openSubjectModal}
                      data-bs-dismiss="modal"
                    >
                      Add Task
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modules;
