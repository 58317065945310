import paTick from "../../images/practice assessment.svg";
import otTick from "../../images/online test.svg";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import DropdownComponent from "../DropdownComponent/DropdownComponent";
import axios from "axios";

const Analytics = () => {
  const userId = localStorage.getItem("userId");
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setPracticeAssessment({
      class: "Class",
      section: "Section",
      subject: "Subject",
    });
  };
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setShow1(false);
    setPracticeAssessment({
      class: "Class",
      section: "Section",
      subject: "Subject",
      tyt: "Type Of Test",
      chapter: "Chapter",
      topic: [],
      subtopic: "Sub-Topic",
    });
  };
  const handleShow1 = () => setShow1(true);
  const [practiceAssessment, setPracticeAssessment] = useState({
    class: "Class",
    section: "Section",
    subject: "Subject",
  });
  const [assignedClasses, setAssignedClasses] = useState([]);
  const newClass = [];
  const newgrade = [];
  const grade = [];
  const section = [];
  const newsection = [];
  const subject = [];
  const setSubject = [];
  const [sectionId, setSectionId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const location = useLocation();
  useEffect(() => {
    const fetchClasses = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userId}/assignSubjects`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          setAssignedClasses([response.data.data.sections]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchClasses();
  }, [subjectId, sectionId]);
  assignedClasses?.forEach((list) => {
    list.forEach((x) => {
      newClass.push(x);
    });
  });
  const parseGrades = (grades) => {
    const newGrades = [];
    grades.forEach((gradeSection) => {
      const [grade, section] = gradeSection._id.split("-");
      const gradeIndex = newGrades.findIndex(
        (newGrade) => newGrade.grade === grade
      );
      if (gradeIndex > -1) {
        newGrades[gradeIndex].sections.push({
          section,
          subjects: gradeSection.subjects,
        });
      } else {
        newGrades.push({
          grade,
          sections: [
            {
              section,
              subjects: gradeSection.subjects,
            },
          ],
        });
      }
    });
    newgrade.push(newGrades);
  };
  parseGrades(newClass);
  newgrade?.forEach((x, i) => {
    x.forEach((l, i) => {
      grade.push(l.grade);
      if (l.grade === practiceAssessment.class) {
        newsection.push(l.sections);
      }
    });
  });
  newsection?.forEach((x, i) => {
    x?.forEach((y, i) => {
      section.push(y?.section);
      setSubject.push(y?.subjects);
    });
  });
  setSubject.forEach((x) => {
    x.forEach((y) => {
      subject.push(y);
    });
  });

  useEffect(() => {
    console.log("sections");
    console.log(section);
  },[section]);

  return (
    <>
      {/* Practice Assessment Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        className="practise_assessment_modal_head"
      >
        <Modal.Body>
          <div className="practise_assessment_modal">
            <div className="practise_assessment_modal_top">
              <div className="practise_assessment_heading">
                <svg
                  width="18"
                  height="23"
                  viewBox="0 0 18 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 0C6.67157 0 6 0.643591 6 1.4375C6 2.23141 6.67157 2.875 7.5 2.875H10.5C11.3284 2.875 12 2.23141 12 1.4375C12 0.643591 11.3284 0 10.5 0H7.5Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 4.3125C0 2.72468 1.34315 1.4375 3 1.4375C3 3.81923 5.01472 5.75 7.5 5.75H10.5C12.9853 5.75 15 3.81923 15 1.4375C16.6569 1.4375 18 2.72468 18 4.3125V20.125C18 21.7128 16.6569 23 15 23H3C1.34315 23 0 21.7128 0 20.125V4.3125ZM4.5 10.0625C3.67157 10.0625 3 10.7061 3 11.5C3 12.2939 3.67157 12.9375 4.5 12.9375H4.515C5.34343 12.9375 6.015 12.2939 6.015 11.5C6.015 10.7061 5.34343 10.0625 4.515 10.0625H4.5ZM9 10.0625C8.17157 10.0625 7.5 10.7061 7.5 11.5C7.5 12.2939 8.17157 12.9375 9 12.9375H13.5C14.3284 12.9375 15 12.2939 15 11.5C15 10.7061 14.3284 10.0625 13.5 10.0625H9ZM4.5 15.8125C3.67157 15.8125 3 16.4561 3 17.25C3 18.0439 3.67157 18.6875 4.5 18.6875H4.515C5.34343 18.6875 6.015 18.0439 6.015 17.25C6.015 16.4561 5.34343 15.8125 4.515 15.8125H4.5ZM9 15.8125C8.17157 15.8125 7.5 16.4561 7.5 17.25C7.5 18.0439 8.17157 18.6875 9 18.6875H13.5C14.3284 18.6875 15 18.0439 15 17.25C15 16.4561 14.3284 15.8125 13.5 15.8125H9Z"
                    fill="black"
                  />
                </svg>
                <span>PRACTICE SESSION</span>
              </div>
              <div className="practise_assessment_fields">
                <DropdownComponent
                  type="class"
                  practiceAssessment={practiceAssessment}
                  setPracticeAssessment={setPracticeAssessment}
                  content={grade}
                />
                <DropdownComponent
                  type="section"
                  practiceAssessment={practiceAssessment}
                  setPracticeAssessment={setPracticeAssessment}
                  content={section}
                  disable={
                    practiceAssessment.class &&
                    practiceAssessment.class != "Class"
                      ? false
                      : true
                  }
                />
                <DropdownComponent
                  type="subject"
                  practiceAssessment={practiceAssessment}
                  setPracticeAssessment={setPracticeAssessment}
                  content={subject}
                  setSubjectId={setSubjectId}
                  setSectionId={setSectionId}
                  disable={
                    practiceAssessment.section &&
                    practiceAssessment.section != "Section"
                      ? false
                      : true
                  }
                />
              </div>
            </div>
            <div className="practise_assessment_modal_bot">
              <button
                className="practise_assessment_modal_cancel"
                onClick={handleClose}
              >
                Cancel
              </button>
              {practiceAssessment.class != "Class" &&
              practiceAssessment.section != "Section" &&
              practiceAssessment.subject != "Subject" ? (
                <NavLink
                  to={{
                    pathname:
                      location.pathname +
                      `/analytics/practiceAssessment/${practiceAssessment.class}/${practiceAssessment.section}/${practiceAssessment.subject}`,
                    state: {
                      sectionId: sectionId,
                      subjectId: subjectId,
                      practiceAssessment: practiceAssessment,
                    },
                  }}
                >
                  <button className="practise_assessment_modal_save">
                    Save
                  </button>
                </NavLink>
              ) : (
                <button
                  className="practise_assessment_modal_save"
                  onClick={() => {
                    alert.error(
                      "Error please select your Class, Section, and Subject"
                    );
                  }}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Online Test Modal */}
      <Modal
        show={show1}
        onHide={handleClose1}
        animation={false}
        centered
        className="online_test_modal_head"
      >
        <Modal.Body>
          <div className="online_test_modal">
            <div className="online_test_modal_top">
              <div className="online_test_heading">
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                >
                  <rect width="21" height="21" fill="url(#pattern0)" />
                  <defs>
                    <pattern
                      id="pattern0"
                      patternContentUnits="objectBoundingBox"
                      width="1"
                      height="1"
                    >
                      <use
                        href="#image0_945_27277"
                        transform="translate(-0.0236025) scale(0.00124224)"
                      />
                    </pattern>
                    <image id="image0_945_27277" width="843" height="805" />
                  </defs>
                </svg>

                <span>ONLINE TEST</span>
              </div>
              <div className="online_test_fields">
                <DropdownComponent
                  type="class"
                  practiceAssessment={practiceAssessment}
                  setPracticeAssessment={setPracticeAssessment}
                  content={grade}
                  onlinetest={true}
                />
                <DropdownComponent
                  type="section"
                  practiceAssessment={practiceAssessment}
                  setPracticeAssessment={setPracticeAssessment}
                  content={section}
                  disable={
                    practiceAssessment.class &&
                    practiceAssessment.class != "Class"
                      ? false
                      : true
                  }
                  onlinetest={true}
                />
                <DropdownComponent
                  type="subject"
                  practiceAssessment={practiceAssessment}
                  setPracticeAssessment={setPracticeAssessment}
                  content={subject}
                  setSubjectId={setSubjectId}
                  setSectionId={setSectionId}
                  disable={
                    practiceAssessment.section &&
                    practiceAssessment.section != "Section"
                      ? false
                      : true
                  }
                  onlinetest={true}
                />
              </div>
            </div>
            <div className="online_test_modal_bot">
              <button
                className="online_test_modal_cancel"
                onClick={handleClose1}
              >
                Cancel
              </button>
              {practiceAssessment.class != "Class" &&
              practiceAssessment.section != "Section" &&
              practiceAssessment.subject != "Subject" ? (
                <NavLink
                  to={{
                    pathname:
                      location.pathname +
                      `/analytics/onlineTest/${practiceAssessment.class}/${practiceAssessment.section}/${practiceAssessment.subject}`,
                    state: {
                      sectionId: sectionId,
                      subjectId: subjectId,
                      practiceAssessment: practiceAssessment,
                    },
                  }}
                >
                  <button className="online_test_modal_save">Save</button>
                </NavLink>
              ) : (
                <button
                  className="online_test_modal_save"
                  onClick={() => {
                    alert.error("Error please select Topic");
                  }}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="assesment">
        <div className="assesment__practise" onClick={handleShow}>
          <button className="assesment__practise__action">
            <img src={paTick} style={{ height: "46px", width: "46px" }} />
            <span>Practice Assessment</span>
          </button>
        </div>
        <div className="assesment__onlineTest">
          <NavLink
            to={{
              pathname: location.pathname + `/analytics/onlineTest`,
              state: {
                sectionId: sectionId,
                practiceAssessment: practiceAssessment,
              },
            }}
          >
            <button className="assesment__onlineTest__action">
              <img src={otTick} style={{ height: "46px", width: "46px" }} />
              <span>Online Test</span>
            </button>
          </NavLink>
        </div>
      </div>
    </>
  );
};
export default Analytics;
