import axios from "axios";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { validateThumbnail, validateFile } from "../../../utils/helper";

import { CircularProgress } from "@material-ui/core";

const categories = [
  { name: "Activities", files: ["doc", "pdf"] },
  { name: "Assignments", files: ["doc", "pdf"] },
  {
    name: "E-Content",
    files: ["ppt", "doc", "mp4", "m4v", "avi", "pdf", "xcell", "jpg", "png"],
  },
  { name: "Home work", files: ["doc", "pdf"] },
  { name: "Questions Papers", files: ["doc", "pdf"] },
  { name: "Revision Note", files: ["doc", "pdf"] },
  { name: "Worksheets", files: ["doc", "pdf"] },
];

export default function SubjectModuleModal(props) {
  const { register, handleSubmit, reset, watch } = useForm();
  const [addContentMessage, setAddContentMessage] = useState("");
  const [addContentLoader, setAddContentLoader] = useState(false);

  const acceptedFiles = useMemo(() => {
    const selectedCategory = Number.parseInt(watch("moduleCategory") || "-1");
    if (selectedCategory < 0) return;
    return categories[selectedCategory].files.join(", ");
  }, [watch("moduleCategory")]);

  const submitContentForm = async (data) => {
    setAddContentMessage("");
    const { name, files, moduleCategory } = data;

    const file = files[0];
    const thumbnailPhoto = undefined; //thumbnail[0];
    if (thumbnailPhoto && !validateThumbnail(thumbnailPhoto)) {
      alert.show("Please select valid file", { type: "error" });
      return;
    }
    if (file && !validateFile(file)) {
      alert.show("Please select valid file", { type: "error" });
      return;
    }
    setAddContentLoader(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("topicId", props.subjectId);
    formData.append("sectionId", props.sectionId);
    formData.append("category", categories[moduleCategory]?.name || "");
    formData.append("lang", props.language);
    formData.append("file", file);
    formData.append("thumbnail", thumbnailPhoto);
    formData.append("folder", "subject");
    const authToken = localStorage.getItem("authToken");
    try {
      let url = `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${props.userId}/modules`;
      const response = await axios.post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
          token: authToken,
        },
      });

      if (response.data.status === "success") {
        props.insertModule(response.data.data.module);
        setAddContentMessage("Module Added.");
        reset();
      }
    } catch (error) {
      if (error.response.data.status === "fail") {
        setAddContentMessage(error.response.data.message);
      } else {
        setAddContentMessage("Try Again.");
      }
    }
    setAddContentLoader(false);
  };

  return (
    <div
      className="modal fade contentModal mt-5"
      id="contentModal"
      tabIndex="-1"
      aria-labelledby="contentModalLabel"
      data-bs-backdrop="static"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="contentModalLabel">
              ADD CONTENT
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form
            style={{ fontWeight: "bold" }}
            onSubmit={handleSubmit(submitContentForm)}
          >
            <div className="modal-body">
              <div className="row mb-3">
                <div className="col-sm">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="name"
                    placeholder="Enter Name"
                    {...register("name")}
                  />
                </div>
              </div>
              <div className="row my-3">
                <div className="col-sm">
                  <label htmlFor="s" className="form-label">
                    Resource Type
                  </label>
                  <select
                    className="form-select"
                    id="moduleCategory"
                    {...register("moduleCategory")}
                  >
                    {categories.map((category, index) => {
                      return <option value={index}>{category.name}</option>;
                    })}
                  </select>
                </div>
                <div className="col-sm">
                  <label htmlFor="s" className="form-label">
                    File
                  </label>
                  <input
                    className="form-control form-control-sm"
                    type="file"
                    id="files"
                    {...register("files")}
                  />
                </div>
              </div>
              {acceptedFiles && <small>Upload {acceptedFiles} file</small>}
            </div>

            {addContentMessage && (
              <div className="text-center">
                <p style={{ color: "#FF075B", fontWeight: "600" }}>
                  {addContentMessage}
                </p>
              </div>
            )}

            <div className="modal_button_container">
              <div className="row">
                <div className="col d-grid">
                  <button
                    type="button"
                    className="btn contentModalBtnCancel"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
                <div className="col d-grid">
                  {addContentLoader ? (
                    <div className="text-center">
                      <CircularProgress
                        style={{ color: "#FF075B", fontWeight: "600" }}
                      />
                    </div>
                  ) : (
                    <button type="submit" className="btn contentModalBtnApply">
                      Add
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
