import "./LessonPlanForm.css";
import { useState, useEffect } from "react";

import axios from "axios";

import Input from "../Input/Input";
import CheckList from "../Input/CheckList";
import Select from "../Input/Select";

import { ChapterAPIs, TopicAPIs, LessonPlanAPIs } from "../api";
import PopupModal from "../Modal/Modal";

function LessonPlanForm(props) {
  const [progress, setProgress] = useState(false);
  const [popup, setPopup] = useState({});

  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [selectedChapter, setSelectedChapter] = useState({});
  const [selectedTopic, setSelectedTopic] = useState({});
  const [date, setDate] = useState(null);

  function createLessonPlan() {
    if (progress) return;
    setProgress(true);

    if (!date) {
      setPopup({
        open: true,
        status: "Error",
        message: "Please provide lesson plan start date!",
      });
      setProgress(false);
      return;
    }

    const data = {
      sectionId: selectedSubject.sectionId,
      subjectId: selectedSubject.value,
      chapterId: selectedChapter._id,
      topicId: selectedTopic._id,
      startDate: new Date(date).getTime(),
    };
    axios
      .post(LessonPlanAPIs.createLessonPlan(props.userId), data, {
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("authToken"),
        },
        validateStatus: () => true,
      })
      .then((res) => {
        if (res.data.status !== "success") throw res.data;
        props.lessonPlans.push(res.data.data.lessonPlan);
        props.setLessonPlans([...props.lessonPlans]);
        setPopup({
          open: true,
          status: "Success",
          message: "Lesson plan created!",
        });
        setSelectedTopic({});
        setSelectedChapter({});
        setSelectedSubject({});
        setDate(null);
        window.location.href = `/lessonplans/${
          res.data.data.lessonPlan?._id
        }?action=edit&grade=${
          res.data?.data?.lessonPlan?.sectionId?.grade || ""
        }-${res.data?.data?.lessonPlan?.sectionId?.section}`;
      })
      .catch((err) => {
        setPopup({
          open: true,
          status: "Error",
          message: err.message,
        });
      })
      .finally(() => {
        setProgress(false);
      });
  }

  //Selected Subjects
  useEffect(() => {
    if (!selectedSubject.name) return;
    setSelectedChapter({});
    axios
      .get(
        ChapterAPIs.fetchChapters(
          props.userId,
          selectedSubject.sectionId,
          selectedSubject.value
        ),
        {
          headers: {
            token: localStorage.getItem("authToken"),
          },
          validateStatus: () => true,
        }
      )
      .then((res) => {
        if (res.data.status !== "success") throw res.data;
        setChapters(res.data.data.chapters);
      })
      .catch((err) => {
        setPopup({
          open: true,
          status: "Error",
          message: "Something went wrong while loading chapters!",
        });
      });
  }, [selectedSubject.name]);

  //Selected Subjects
  useEffect(() => {
    if (!selectedChapter.name) return;
    setSelectedTopic({});
    axios
      .get(
        TopicAPIs.fetchTopics(
          props.userId,
          selectedSubject.sectionId,
          selectedSubject.value,
          selectedChapter._id
        ),
        {
          headers: {
            token: localStorage.getItem("authToken"),
          },
          validateStatus: () => true,
        }
      )
      .then((res) => {
        if (res.data.status !== "success") throw res.data;
        setTopics(res.data.data.topics);
      })
      .catch((err) => {
        setPopup({
          open: true,
          status: "Error",
          message: "Something went wrong while loading chapters!",
        });
      });
  }, [selectedChapter.name]);

  return (
    <div className={`${props.classname}`}>
      <div className="lp-form">
        <div className="lp-form-grid">
          <Input
            label="Grade"
            value={props.grade?._id || ""}
            readOnly={true}
            placeholder="Grade"
          />
          <Input
            label="Date"
            placeholder="Grade"
            type="Date"
            value={date || ""}
            handleInput={(e) => setDate(e.target.value)}
          />
        </div>
        <CheckList
          styles={{ marginTop: "20px" }}
          lists={props.grade.subjects.map((subject) => {
            return {
              name: subject.subject,
              value: `${subject.subjectId}`,
              sectionId: subject.sectionId,
            };
          })}
          selectedValue={selectedSubject}
          setSelectedValue={setSelectedSubject}
        />
        <Select
          styles={{ marginTop: "20px" }}
          label="Chapters"
          placeholder="Select Chapters"
          options={chapters}
          lock={!chapters.length}
          selectedValue={selectedChapter}
          setSelectedValue={setSelectedChapter}
        />
        <Select
          styles={{ marginTop: "20px" }}
          label="Topics"
          placeholder="Select Topics"
          options={topics}
          lock={!topics.length}
          selectedValue={selectedTopic}
          setSelectedValue={setSelectedTopic}
        />
        <button className="lp-form-button" onClick={createLessonPlan}>
          {progress ? "Creating" : "Create"}
        </button>
      </div>
      <PopupModal
        open={popup.open}
        heading={popup.status}
        message={popup.message}
      >
        <button
          style={{
            color: "#414141",
          }}
          onClick={() => setPopup({})}
        >
          Close
        </button>
      </PopupModal>
    </div>
  );
}

export default LessonPlanForm;
