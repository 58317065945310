import { useMediaQuery } from "react-responsive";

function IsSmallScreen() {
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });
  return isSmallScreen;
}

export default IsSmallScreen;
