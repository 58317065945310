import React, { useState, useEffect, useContext } from 'react';
import '../styles/TestDetails.css';
import { Button, IconButton, TextField } from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import Question from './Question';
import { GlobalContext } from "../../../context/GlobalState";
function TestDetails() {
  const { userDetails} =useContext(GlobalContext);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [difficulty, setDifficulty] = useState(null);
  const [cognitive, setCognitive] = useState(null);
  const [questionList, setQuestionList] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);

  const getTests = async () => {
    const pageUrl = document.location.href.split('/');
    const testId = pageUrl[pageUrl.length - 1];
    const topicId = window.localStorage.getItem('topicId');

    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/tests/?_id=${testId}&topicId=${topicId}`,
      {
        headers: {
          token: `${window.localStorage.getItem('authToken')}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        if (response.data.tests.length > 0) {
          setName(response.data.tests[0].name);
          setDescription(response.data.tests[0].decsription);
          setDifficulty(response.data.tests[0].difficulty);
          setCognitive(response.data.tests[0].cognitive);
          setQuestionList(response.data.tests[0].questions);
          setCorrectAnswers([...response.data.tests[0].correctAnswers]);
        } else {
          getMyTest();
        }
      });
  };

  const getMyTest = async () => {
    const pageUrl = document.location.href.split('/');
    const testId = pageUrl[pageUrl.length - 1];

    const topicId = window.localStorage.getItem('topicId');

    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/tests?topicId=${topicId}&_id=${testId}`,
      {
        headers: {
          token: `${window.localStorage.getItem('authToken')}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        setName(response.data.tests[0].name);
        setDescription(response.data.tests[0].description);
        setDifficulty(response.data.tests[0].difficulty);
        setCognitive(response.data.tests[0].cognitive);
        setQuestionList(response.data.tests[0].questions);
        setCorrectAnswers([...response.data.tests[0].correctAnswers]);
      });
  };

  useEffect(() => {
    getTests();
  }, []);

  return (
    <section className='test_details my-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 order-sm-first order-last'>
            <div className='border rounded p-3 test_details_top'>
              <form action='#'>
                <div className='mb-2'>
                  <p id='test_name'>{name}</p>
                </div>
                <div className='mb-3'>
                  <p id='test_desription'>{description}</p>
                </div>
              </form>
            </div>

            <div>
              <div className='row my-4'>
                <div className='col-sm mb-3'>
                  <select className='form-select' value={difficulty}>
                    <option defaultValue=''>Difficulty</option>
                    <option value='easy'>Easy</option>
                    <option value='intermediate'>Intermediate</option>
                    <option value='difficult'>difficult</option>
                  </select>
                </div>
                <div className='col-sm mb-3'>
                  <select className='form-select' value={cognitive}>
                    <option defaultValue=''>Cognitive</option>
                    <option value='knowledge'>Knowledge</option>
                    <option value='understanding'>Understanding</option>
                    <option value='application'>Application</option>
                    <option value='analysis'>Analysis</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='testQuestion'>
              {questionList.map((question, index) => (
                <Question
                  question={question}
                  index={index}
                  correctAnswers={correctAnswers}
                ></Question>
              ))}
            </div>
          </div>
        </div>

        {/* <div className='text-center row mt-5'>
          <div className='col-sm mb-3'>
            <Button variant='contained' className='cancelFormBtn'>
              Cancel
            </Button>
          </div>
          <div className='col-sm mb-3'>
            <Button variant='contained' className='saveFormBtn' onClick=''>
              Save
            </Button>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default TestDetails;
