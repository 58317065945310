import "./LessonPlanEditor.css";
import EyeIcon from "../icons/eye.svg";

import LPNavbar from "../LPNavbar/LPNavbar";
import TableEditor from "./Table/TableEditor";

function LessonPlanEditor(props) {
  function addNewRow() {
    const row = {
      rowId: props.plan.rows.length + 1,
      activity: "",
      teacher: "",
      question: "",
      answer: "",
      resources: [],
    };

    if (!props.plan || !props.plan.rows) return;
    props.plan.rows.push(row);
    props.setLessonplan({ ...props.plan });
  }

  function editCell(rowId, cell, value) {
    const rowIndex = props.plan.rows.findIndex((row) => row.rowId === rowId); //find row;
    props.plan.rows[rowIndex][cell] = value;
    props.setLessonplan({ ...props.plan });
    if (!props.plan.rows[rowIndex + 1]) addNewRow();
  }

  return (
    <div className="lp-editor">
      <LPNavbar
        mainHeading={`${props?.grade} ${props?.plan?.subjectId?.name || ""}`}
        goBack={() =>
          (window.location.href = `/lessonplans?grade=${props.grade}&tab=1`)
        }
      >
        <div
          className="actions"
          style={{ width: "fit-content", margin: "auto 0 auto auto" }}
        >
          <img
            onClick={() => props.updateAction("preview")}
            src={EyeIcon}
            alt="eye"
          />
        </div>
      </LPNavbar>
      <TableEditor
        planId={props.plan?._id}
        rows={props?.plan?.rows || []}
        userId={props.userId}
        editCell={editCell}
        addRow={addNewRow}
      />
    </div>
  );
}

export default LessonPlanEditor;
