/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from 'react';
import './LoginBox.css';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { useForm } from 'react-hook-form';
import { GlobalContext } from '../../../context/GlobalState';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';

function LoginBox() {
  document.title =  "2xcell.in";

  const { register, handleSubmit } = useForm();
  const { userDetails, setUserDetails } = useContext(GlobalContext);

  const alert = useAlert();

  useEffect(() => {
    if (userDetails.isAuthenticated) {
      window.location.href = '/dashboard';
    }
  }, []);

  const TogglePassword = () => {
    if (document.getElementById('visible').classList.contains('hidden')) {
      document.getElementById('password-field').type = 'password';
      document.getElementById('visible').classList.remove('hidden');
      document.getElementById('invisible').classList.add('hidden');
    } else {
      document.getElementById('password-field').type = 'text';
      document.getElementById('invisible').classList.remove('hidden');
      document.getElementById('visible').classList.add('hidden');
    }
  };

  const submitForm = async (data) => {
    const { email, password } = data;

    if (!email || !password) {
      alert.error('Fields cannot be empty');
      return;
    }

    try {
      // const response = await axios.post(`${process.env.REACT_APP_ORIGIN}/api/v1/auth/login?user=staff`,{
      //    email,
      //     password,
      //   }
      // );

      let response = await fetch(
        `${process.env.REACT_APP_ORIGIN}/api/v1/auth/login?user=staff`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        }
      );

      response = await response.json();

      console.log(response);
      if (response.status === 'success') {
        localStorage.setItem('authToken', response.data.token);
        setUserDetails({
          isAuthenticated: true,
          name: response.data.payload.name,
          userId: response.data.payload.userId,
          schoolId: response.data.payload.school,
          role: response.data.payload.role,
        });
        window.location.href = '/dashboard';
      } else {
        alert.show(response.message);
        // window.location.href = "${process.env.REACT_APP_REDIRECT}/";
      }
    } catch (error) {
      alert.show(error.message);
      console.log(error);
    }
  };

  return (
    <>
      <div className='LoginBox'>
        <div className='top-section'>
          <h3>Sign In</h3>
          <p>Sign in with your details</p>
        </div>

        <form onSubmit={handleSubmit(submitForm)} className='input-container'>
          <input
            type='text'
            className='input-username'
            placeholder='Username or email address'
            {...register('email')}
            required
          />
          <span>
            <input
              type='password'
              className='input-password'
              id='password-field'
              placeholder='Password'
              {...register('password')}
              required
            />
            <button
              className='toggle-password'
              type='button'
              onClick={() => {
                TogglePassword();
              }}
            >
              <VisibilityOutlinedIcon id='visible' />
              <VisibilityOffOutlinedIcon className='hidden' id='invisible' />
            </button>
          </span>
          <button className='signin-btn' type='submit'>
            Sign in to my account
          </button>
        </form>

        <div className='bottom-section'>
          <p>
            Don't have an account?{' '}
            <a href='https://main.dh0wl8rpj7iux.amplifyapp.com/'>Sign Up</a>
          </p>
          <p>
            Forgot password? <Link to='#'>Password Reset</Link>
          </p>
        </div>
        <div className='linkBox'>
          <a href='https://main.dcim0i4l3vbum.amplifyapp.com/'>
            Login as a student
          </a>
          <br />
          <a href='https://main.dvek77zyogw7h.amplifyapp.com/'>
            Login as an admin
          </a>
        </div>
      </div>
    </>
  );
}

export default LoginBox;
