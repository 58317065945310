import "./input.css";
import SearchIcon from "../icons/Search.svg";

function SearchInput(props) {
  return (
    <div className="search-form-input" style={{ ...props.style }}>
      {props.label && (
        <div className="label">
          <label>{props.label}</label>
        </div>
      )}
      <input
        className="main-style"
        name={props.name}
        onChange={props.handleInput}
        type={props.type}
        value={props.value}
        min={1}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
      />
      <img src={SearchIcon} alt="search" />
    </div>
  );
}

export default SearchInput;
