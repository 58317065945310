/* eslint-disable react-hooks/exhaustive-deps */

import axios from "axios";
import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GlobalContext } from "../../context/GlobalState";
import image from "../../utils/helpers.js";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { useAlert } from "react-alert";
import { useMediaQuery } from "react-responsive";
import Moment from "react-moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
const { isDisabled } = require("../../utils/util.js");

// import DefaultImage from '../../images'

// const image = (url) =>
//   url ? url : process.env.PUBLIC_URL + '/backgrounds/revisionTask/nature.png';
// const MyResourcesStyle = (url) => ({
//   backgroundImage: `url(${
//     url ? url : process.env.PUBLIC_URL + '/backgrounds/revisionTask/nature.png'
//   })`,
//   backgroundSize: 'cover',
//   minHeight: '245px',
//   height: '100%',
//   borderRadius: '17px',
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'flex-end',
//   boxShadow: '#00000040 0px 0px 5px 1px',
// });

const fixName = (name) => {
  const charArrays = ["?", "+", "/"];
  charArrays.forEach((character) => {
    name = name.replaceAll(character, "");
  });
  return name;
};

const Chapters = (props) => {
  document.title = "2xcell.in";
  const alert = useAlert();

  const {
    userDetails,
    setUserDetails,
    assignedSubjects,
    setAssignedSubjects,
    selectedClass,
    setSelectedClass,
  } = useContext(GlobalContext);
  console.log(assignedSubjects);
  const charArrays = ["?", "+"];

  const subjectName = props.match.params.subject;
  const chapterName = fixName(props.match.params.chapter);
  const chapterId = props.match.params.chapterId;
  window.localStorage.setItem("chapterId", chapterId);
  const subjectId = props.match.params.subjectId;
  const sectionIdTask = localStorage.getItem("sectionIdTask");
  const [topics, setTopics] = useState([]);
  const [fetchTopicLoader, setFetchTopicLoader] = useState(true);

  useEffect(() => {
    const getTopics = async () => {
      const authToken = localStorage.getItem("authToken");

      console.log(chapterId, "Dwd");
      try {
        const response = await axios.get(
          // `${process.env.REACT_APP_ORIGIN}/api/v1/chapters/${chapterId}`,
          `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${userDetails.userId}/sections/${sectionIdTask}/subjects/${subjectId}/chapters/${chapterId}/topics`,
          {
            headers: {
              token: authToken,
            },
          }
        );
        if (response.data.status === "success") {
          console.log(response.data, 2333);
          setTopics(response.data.data.topics);
        }
      } catch (error) {
        console.log(error);
      }

      setFetchTopicLoader(false);
    };

    getTopics();
  }, []);

  //convert html, used for url link of each card in different language
  function decodeHTMLEntities(text) {
    var textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  }

  //Function to fix the thumbnail url
  const fixUrl = (url) => {
    url = url.split(" ").join("%20");
    console.log("106");
    console.log(url);
    if (url.startsWith("https://xcell-cms-files.s3.amazonaws.com") || url.startsWith("https//xcell-cms-files.s3.ap-south-1.amazonaws.com")) {
      return url;
    } else {
      // return "https://xcell-cms-files.s3.amazonaws.com" + url;
      return url;
    }
  };

  console.log("Topics", topics);

  const topicCompleteStatus = async (id, setStatus) => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/${userDetails.userId}/sections/${sectionIdTask}/subjects/${subjectId}/chapters/${chapterId}/topics/${id}/status`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        alert.success(
          response.data.data.completed
            ? "Topic marked complete"
            : "Topic marked incomplete"
        );
        setStatus(response.data.data.completed);
        console.log(response.data.data.completed, "topic_status");
      }
    } catch (error) {
      alert.error(error.message);
      console.log(error);
    }
  };
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });

  return (
    <>
      <section
        className={
          isSmallScreen
            ? "myResources my-2 p-lg-0 p-4"
            : "myResources my-5 p-lg-0 p-4"
        }
      >
        <div className="container">
          <div
            className="box mb-5"
            style={{ display: isSmallScreen ? "none" : "" }}
          >
            <div className="boxRow">
              <div className="pathDetail" style={{ color: "white" }}>
                <h1>{selectedClass || localStorage.getItem("gradeSection")}</h1>
                {/* <h5>{chapterName}</h5> */}
                <h5>
                  <NavLink
                    to={{
                      pathname:
                        `/myresources/${fixName(subjectName)}/${subjectId}`
                    }}
                    style={{ color: "white" }}
                  >
                    {subjectName}
                  </NavLink> &gt; <NavLink
                    to={{
                      pathname:
                        `/myresources/${fixName(subjectName)}/${subjectId}/${fixName(chapterName)}/${chapterId}`
                    }}
                    style={{ color: "white" }}
                  >
                    {chapterName}
                  </NavLink>
                </h5>
              </div>
              <div className="DateBox">
                <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                  <CalendarTodayIcon />{" "}
                  {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
                </h5>
              </div>
            </div>
          </div>

          {fetchTopicLoader ? (
            <div className="text-center">
              <CircularProgress
                style={{ color: "#FF075B", fontWeight: "600" }}
              />
            </div>
          ) : (
            <div className="row my-3 gy-4">
              {topics[0] ? (
                topics.map((topic, index) => (
                  <div
                    className="col-sm-2 cardBox"
                    onClick={() =>
                      setUserDetails({
                        ...userDetails,
                        pathName: {
                          subjectName: `${subjectName}`,
                          chapterName: `${chapterName}`,
                          topicName: `${decodeHTMLEntities(topic.name)}`,
                        },
                      })
                    }
                    key={index}
                    style={{
                      marginBottom: "3rem", 
                      textDecoration: "none",
                      display:
                        topic.disabled /*|| isDisabled(topic.modules)*/
                          ? "none"
                          : "block",
                    }}
                  >
                    <NavLink
                      to={`/myresources/${subjectName}/${subjectId}/${chapterName}/${chapterId}/${decodeHTMLEntities(
                        fixName(topic.name)
                      )}/${topic._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      {/* <div
                        className='text-center'
                        style={MyResourcesStyle(fixUrl(topic.thumbnail))}
                      > */}
                      <div className="card card-radius card-height boxShadowCard">
                        <div className="card-img">
                          <img
                            src={
                              topic.thumbnail
                                ? image(fixUrl(topic.thumbnail))
                                : process.env.PUBLIC_URL +
                                  "/backgrounds/revisionTask/nature.png"
                            }
                            className="card-thumbnail"
                            alt={topic?.name || "chapter"}
                          />
                        </div>
                        <div>
                          <p className="text-dark text-center" style={{fontWeight:"bold"}}>
                            {decodeHTMLEntities(topic.name)}
                          </p>
                        </div>
                      </div>
                    </NavLink>
                    <ToggleSwitch
                      statusFunction={topicCompleteStatus}
                      id={topic._id}
                      completed={topic.completed}
                    />
                  </div>
                ))
              ) : (
                <h3 className="text-center">Create topic</h3>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Chapters;
