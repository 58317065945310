import React from "react";
import "./assignedTestDetails.css";
function AssignedTestDetails({
  Testclass,
  section,
  date,
  time,
  testType,
  totalMarks,
  chapters,
  topics,
}) {
  return (
    <div className="assignedTestDetails container my-4">
      <div className="assignedTestDetails_top">
        <div className="assignedTestDetails_info">
          <span className="assignedTestDetails_type">Test Type</span>
          <span className="assignedTestDetails_value">{testType}</span>
        </div>
        <div className="assignedTestDetails_info">
          <span className="assignedTestDetails_type">Date</span>
          <span className="assignedTestDetails_value">
            {date?.toLocaleDateString("en-GB")}
          </span>
        </div>
        <div className="assignedTestDetails_info">
          <span className="assignedTestDetails_type">Time</span>
          <span className="assignedTestDetails_value">{time.hours}hrs</span>
        </div>
      </div>
      <div className="assignedTestDetails_top">
        <div className="assignedTestDetails_info">
          <span className="assignedTestDetails_type">Class</span>
          <span className="assignedTestDetails_value">{Testclass}</span>
        </div>
        <div className="assignedTestDetails_info">
          <span className="assignedTestDetails_type">Section</span>
          <span className="assignedTestDetails_value">{section}</span>
        </div>
        <div className="assignedTestDetails_info">
          <span className="assignedTestDetails_type">Total Marks</span>
          <span className="assignedTestDetails_value">{totalMarks}</span>
        </div>
      </div>
      <div className="assignedTestDetails_bottom">
        <div className="assignedTestDetails_info">
          <span className="assignedTestDetails_type">Chapters</span>
          <span className="assignedTestDetails_value">{chapters}</span>
        </div>
        {topics.length ? (
          <div className="assignedTestDetails_info">
            <span className="assignedTestDetails_type">Topics</span>
            <span className="assignedTestDetails_value">{topics}</span>
          </div>
        ):<></>}
      </div>
    </div>
  );
}

export default AssignedTestDetails;
